import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import Typography from '../../../../../styles/Typography';
import { StyledDefaultRadioProps } from '../../types';

export const StyledRadioWrapper = styled.span`
  display: block;
  width: 100%;
`;

export const StyledRadioControlWrapper = styled.span<StyledDefaultRadioProps>`
  width: 100%;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.radio.border};
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
    `};
  min-height: 4.25rem;
`;

export const StyledRadioMark = styled.span<StyledDefaultRadioProps>`
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;
  transition: ${({ theme }) => theme.transitions.default};
  ${({ theme, checked }) =>
    checked
      ? css`
          border: 7px solid ${theme.colors.radio.mark.active};
        `
      : css`
          border: 2px solid ${theme.colors.radio.mark.inActive};
        `};
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? 'margin-left: 1.75rem;'
      : 'margin-right: 1.75rem;'}
`;

export const StyledRadioLabel = styled(Typography).attrs({
  forwardedAs: 'span',
  variant: 'body',
})`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledRadioInfo = styled(Typography).attrs({
  forwardedAs: 'span',
  variant: 'caption',
})`
  display: block;
  color: ${({ theme }) => rgba(theme.colors.text.primary, 0.54)};
`;

export const StyledRadioInfoWrapper = styled.span`
  display: block;
  width: 100%;
  margin-top: 0.5rem;
  padding-bottom: 0.25rem;
`;

export const StyledRadioDecorWrapper = styled.figure`
  display: inline-flex;
  height: 2.25rem;
  width: 2.25rem;
  flex-shrink: 0;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.background.light};
  border: 1px solid ${({ theme }) => rgba(theme.colors.text.primary, 0.15)};
  overflow: hidden;
  justify-content: center;
  align-items: center;

  ${({ theme }) =>
    theme.direction === 'rtl' ? 'margin: 0 auto 0 0;' : 'margin: 0 0 0 auto;'}
`;

export const StyledRadioDecor = styled.img`
  height: auto;
  width: 100%;
  max-width: 100%;
  display: block;
`;
