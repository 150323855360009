import React from 'react';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'react-final-form';

import Button from '../../../components/atoms/Button';
import { FormInput } from '../../../components/atoms/Fields/Input';
import { StyledBox } from '../../../components/styles/Grid/styles';
import Link from '../../../components/atoms/Link';
import routes from '../../../setup/consts/routes';

import { StyledForm, StyledParagraph } from './styles';
import Typography from '../../../components/styles/Typography';
import Seo from '../../../components/Seo';
import LoginInnerLayout from '../Layout';
import { SignUpFormValues } from './types';
import { countries, SIGN_UP_FORM_INITIAL_VALUES } from './consts';
import { useSignUpFormSubmitHandler } from './hooks';
import { FormSimpleSelect } from '../../../components/atoms/Fields/Select/templates/SimpleSelect';

const SignUpPage: React.FC = () => {
  const intl = useIntl();
  const submitHandler = useSignUpFormSubmitHandler();

  return (
    <LoginInnerLayout
      isBig
      withDecor
      title={intl.formatMessage({
        id: 'login.signUp',
        defaultMessage: 'Sign Up',
      })}
    >
      <Seo
        title={intl.formatMessage({
          id: 'login.signUp',
          defaultMessage: 'Sign Up',
        })}
      />
      <Form<SignUpFormValues>
        onSubmit={submitHandler}
        initialValues={SIGN_UP_FORM_INITIAL_VALUES}
      >
        {({ handleSubmit, submitting }) => (
          <StyledForm onSubmit={handleSubmit}>
            <Row>
              <Col>
                <StyledBox mb='2rem'>
                  <Typography variant='bodyBold' as='h3'>
                    <FormattedMessage
                      id='login.personalInfo'
                      defaultMessage='Professional Info'
                    />
                  </Typography>
                </StyledBox>
                <StyledBox>
                  <FormInput
                    disabled={submitting}
                    name='firstName'
                    label={intl.formatMessage({
                      id: 'forms.fields.firstName',
                      defaultMessage: 'First Name',
                    })}
                  />
                </StyledBox>
                <StyledBox>
                  <FormInput
                    disabled={submitting}
                    name='lastName'
                    label={intl.formatMessage({
                      id: 'forms.fields.lastName',
                      defaultMessage: 'Last Name',
                    })}
                  />
                </StyledBox>
                <StyledBox>
                  <FormInput
                    disabled={submitting}
                    name='companyName'
                    label={intl.formatMessage({
                      id: 'forms.fields.companyName',
                      defaultMessage: 'Company Name',
                    })}
                  />
                </StyledBox>
                <StyledBox>
                  <FormInput
                    disabled={submitting}
                    name='jobTitle'
                    label={intl.formatMessage({
                      id: 'forms.fields.jobTitle',
                      defaultMessage: 'Job Title',
                    })}
                  />
                </StyledBox>
                <StyledBox>
                  <FormInput
                    disabled={submitting}
                    name='monthlyProcessingVolume'
                    label={intl.formatMessage({
                      id: 'forms.fields.monthlyProcessingVolume',
                      defaultMessage: 'Monthly Processing Volume',
                    })}
                  />
                </StyledBox>
              </Col>
              <Col>
                <StyledBox mb='2rem'>
                  <Typography variant='bodyBold' as='h3'>
                    <FormattedMessage
                      id='login.contactInfo'
                      defaultMessage='Contact Info'
                    />
                  </Typography>
                </StyledBox>
                <StyledBox>
                  <FormInput
                    disabled={submitting}
                    name='email'
                    label={intl.formatMessage({
                      id: 'forms.fields.emailAddress',
                      defaultMessage: 'Email Address',
                    })}
                    type='email'
                  />
                </StyledBox>
                <StyledBox>
                  <FormInput
                    disabled={submitting}
                    name='password'
                    label={intl.formatMessage({
                      id: 'forms.fields.password',
                      defaultMessage: 'Password',
                    })}
                    type='password'
                  />
                </StyledBox>
                <StyledBox>
                  <FormInput
                    disabled={submitting}
                    name='repeatPassword'
                    label={intl.formatMessage({
                      id: 'forms.fields.repeatPassword',
                      defaultMessage: 'Repeat password',
                    })}
                    type='password'
                  />
                </StyledBox>
                <StyledBox>
                  <FormInput
                    disabled={submitting}
                    name='phoneNumber'
                    label={intl.formatMessage({
                      id: 'forms.fields.phone',
                      defaultMessage: 'Phone Number',
                    })}
                  />
                </StyledBox>
                <StyledBox>
                  <FormInput
                    disabled={submitting}
                    name='websiteUrl'
                    label={intl.formatMessage({
                      id: 'forms.fields.yourWebsiteUrl',
                      defaultMessage: 'Your Website URL',
                    })}
                  />
                </StyledBox>
                <StyledBox>
                  <FormSimpleSelect
                    disabled={submitting}
                    placeholder={intl.formatMessage({
                      id: 'forms.fields.countryPLaceholder',
                      defaultMessage: 'Select your country',
                    })}
                    name='country'
                    options={countries}
                    label={intl.formatMessage({
                      id: 'forms.fields.country',
                      defaultMessage: 'Country',
                    })}
                  />
                </StyledBox>
              </Col>
            </Row>
            <StyledBox alignItems='center' mt='0.5rem'>
              <Button type='submit' disabled={submitting}>
                <FormattedMessage id='login.signUp' defaultMessage='Sign up' />
              </Button>
              <StyledBox ml='2rem'>
                <StyledParagraph>
                  <FormattedMessage
                    id='login.haveAnAccount'
                    defaultMessage='Already have an account?'
                  />{' '}
                  <Link to={routes.SIGN_IN}>
                    <FormattedMessage
                      id='login.signIn'
                      defaultMessage='Sign in'
                    />{' '}
                  </Link>
                </StyledParagraph>
              </StyledBox>
            </StyledBox>
          </StyledForm>
        )}
      </Form>
    </LoginInnerLayout>
  );
};

export default SignUpPage;
