import React, { useCallback, useMemo } from 'react';
import { useMutation } from 'react-query';
import { IntlShape, useIntl } from 'react-intl';
import { useToasts } from 'react-toast-notifications';
import isValid from 'date-fns/isValid';
import format from 'date-fns/format';
import { ColumnDef } from '@tanstack/react-table';
import noop from 'lodash/noop';
import { IconEdit, IconDelete } from '../../../../assets/svg/icons';
import { GetUsersResponseSingleItem } from '../../../../setup/apiTypes/users';
import UsersBadgeList from '../partials/UsersBadgeList';
import { StyledBox } from '../../../../components/styles/Grid/styles';
import IconButton from '../../../../components/atoms/IconButton';

import { MAKE_DELETE_USER_MUTATION_FN } from './consts';
import { mapApiErrorsToFormErrors } from '../../../../utils/finalForm';

export const useOnDeleteUserClickHandler = (
  userId: string | undefined,
  onDeleteComplete: () => Promise<void> | void,
  closeModal: () => void = noop
): (() => void) => {
  const { mutateAsync: deleteUser } = useMutation(
    MAKE_DELETE_USER_MUTATION_FN(userId ?? '')
  );
  const intl = useIntl();
  const { addToast } = useToasts();

  return useCallback(async () => {
    try {
      closeModal();
      await deleteUser({});

      if (onDeleteComplete) {
        await onDeleteComplete();
      }
    } catch (error) {
      const { generalError } = mapApiErrorsToFormErrors(error, intl);

      if (!generalError) {
        return;
      }

      addToast(generalError, {
        appearance: 'error',
      });
    }
  }, [addToast, deleteUser, intl, onDeleteComplete, closeModal]);
};

export const useUsersTableColumns = (
  intl: IntlShape,
  onUserEdit: (userId: string) => void,
  onUserDelete: (userId: string) => void
): ColumnDef<GetUsersResponseSingleItem>[] =>
  useMemo(
    () => [
      {
        header: intl.formatMessage({
          id: 'enitiesAndUsers.usersTable.name',
          defaultMessage: 'Name',
        }),
        accessorKey: 'name',
      },
      {
        header: intl.formatMessage({
          id: 'enitiesAndUsers.usersTable.dateAdded',
          defaultMessage: 'Date Added',
        }),
        accessorFn: ({ createdAt }: GetUsersResponseSingleItem): string => {
          const date = new Date(createdAt);

          return isValid(date) ? format(date, 'dd/MM/yyyy') : '-';
        },
      },
      {
        header: intl.formatMessage({
          id: 'enitiesAndUsers.usersTable.phoneNumber',
          defaultMessage: 'Phone',
        }),
        accessorKey: 'phoneNumber',
        cell: ({ getValue }) => <>{getValue() ?? '-'}</>,
      },
      {
        header: intl.formatMessage({
          id: 'enitiesAndUsers.usersTable.email',
          defaultMessage: 'Email',
        }),
        accessorKey: 'email',
      },
      {
        header: intl.formatMessage({
          id: 'enitiesAndUsers.usersTable.entities',
          defaultMessage: 'Entities',
        }),
        accessorKey: 'entities',
        cell: ({ getValue }) => {
          const entities = getValue() as GetUsersResponseSingleItem['entities'];

          return entities.length ? (
            <UsersBadgeList
              itemsToShow={1}
              items={entities.map(({ name }) => name)}
            />
          ) : (
            '-'
          );
        },
      },
      {
        header: intl.formatMessage({
          id: 'enitiesAndUsers.usersTable.role',
          defaultMessage: 'Role',
        }),
        accessorKey: 'role',
      },
      {
        header: '',
        id: 'edit',
        accessorKey: 'id',
        cell: ({ getValue }): ReactComponentReturnType => (
          <StyledBox justifyContent='flex-end'>
            <IconButton
              icon={IconEdit}
              onClick={() => onUserEdit(getValue() as string)}
            />
          </StyledBox>
        ),
        width: 40,
        align: 'right',
      },
      {
        header: '',
        id: 'delete',
        cell: ({ getValue }): ReactComponentReturnType => (
          <StyledBox justifyContent='flex-end'>
            <IconButton
              icon={IconDelete}
              onClick={() => onUserDelete(getValue() as string)}
            />
          </StyledBox>
        ),
        width: 40,
        align: 'right',
      },
    ],
    [intl, onUserEdit, onUserDelete]
  );
