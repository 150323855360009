import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import Typography from '../../../../../styles/Typography';
import { StyledDefaultRadioProps } from '../../types';

export const StyledRadioWrapper = styled.span`
  display: block;
  width: 100%;
`;

export const StyledRadioControlWrapper = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const StyledRadioMark = styled.span<StyledDefaultRadioProps>`
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;
  transition: ${({ theme }) => theme.transitions.default};
  ${({ theme, checked }) =>
    checked
      ? css`
          border: 7px solid ${theme.colors.radio.mark.active};
        `
      : css`
          border: 2px solid ${theme.colors.radio.mark.inActive};
        `};
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledRadioLabel = styled(Typography).attrs({
  forwardedAs: 'span',
  variant: 'body',
})`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.text.primary};
  margin-left: 0.75rem;
`;

export const StyledRadioDecorWrapper = styled.figure`
  display: inline-flex;
  height: 2rem;
  width: 2rem;
  flex-shrink: 0;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.background.light};
  border: 1px solid ${({ theme }) => rgba(theme.colors.text.primary, 0.15)};
  overflow: hidden;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
`;

export const StyledRadioDecor = styled.img`
  height: auto;
  width: 100%;
  max-width: 100%;
  display: block;
`;

export const StyledRadioCheckboxesList = styled.ul`
  list-style: none;
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? 'padding: 1rem 3rem 0 0;'
      : 'padding: 1rem 0 0 3rem;'}
  margin: 0;
  width: 100%;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -3px;
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? css`
            right: 0.5625rem;
          `
        : css`
            left: 0.5625rem;
          `};
    height: 75%;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.radio.line};
  }
`;

export const StyledRadioCheckboxesListItem = styled.li`
  width: 100%;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? css`
            right: -2.4375rem;
          `
        : css`
            left: -2.4375rem;
          `};
    top: -0.5625rem;
    height: 1.25rem;
    width: 2.3125rem;
    border-color: ${({ theme }) =>
      theme.direction === 'rtl'
        ? css`transparent ${theme.colors.radio.line} ${theme.colors.radio.line} transparent`
        : css`transparent transparent ${theme.colors.radio.line} ${theme.colors.radio.line}`};
    border-width: 2px;
    border-style: solid;
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? css`
            border-bottom-right-radius: 0.5rem;
          `
        : css`
            border-bottom-left-radius: 0.5rem;
          `};
  }
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;
