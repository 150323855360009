import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { OrderResolutionProps } from './types';
import { CautionIconWrapper, StyledOrderResolution } from './styles';
import { CautionIcon } from '../../../assets/svg/icons';
import Typography from '../../styles/Typography';
import { StyledBox } from '../../styles/Grid/styles';
import ButtonLink from '../../atoms/Button/buttonLink';
import Table from '../Table';
import { dashboardOrderTableColumns, tableDate } from './consts';

const OrderResolution: React.FC<OrderResolutionProps> = () => {
  const intl = useIntl();
  const tableColumns = useMemo(() => dashboardOrderTableColumns(intl), [intl]);

  return (
    <StyledOrderResolution>
      <CautionIconWrapper>
        <CautionIcon />
      </CautionIconWrapper>

      <StyledBox justifyContent='space-between' width='100%'>
        <StyledBox flexDirection='column'>
          {/* Title */}
          <StyledBox alignItems='center' gap='1rem' pt='0.9rem'>
            <Typography variant='bodyBold'>
              <FormattedMessage
                id='dashboard.orderResolution'
                defaultMessage='Orders'
              />
            </Typography>

            <Typography variant='caption'>
              <FormattedMessage id='seeAll' defaultMessage='seeAll' />
            </Typography>
          </StyledBox>

          {/* Order */}
          <StyledBox width='650px'>
            <Table disabledPagination columns={tableColumns} data={tableDate} />
          </StyledBox>
        </StyledBox>

        {/* Resolve Button */}
        <ButtonLink to='#'>
          <FormattedMessage id='resolve' defaultMessage='Resolve' />
        </ButtonLink>
      </StyledBox>
    </StyledOrderResolution>
  );
};
export default OrderResolution;
