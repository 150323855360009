import styled from 'styled-components';
import Typography from '../../../styles/Typography';

export const StyledErrorWrapper = styled.div`
  min-height: 1.5rem;
  padding: 0.25rem 0;
`;

export const StyledErrorText = styled(Typography).attrs({
  variant: 'caption',
  forwardedAs: 'span',
})`
  color: ${({ theme }) => theme.colors.text.error};
`;
