import React, { useContext, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import noop from 'lodash/noop';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { ThemeContext } from 'styled-components';
import ModalBase from '../..';
import { CustomerDetailsModalProps, CustomerOrder } from './types';
import {
  StyledAddressesCollapsibleCard,
  StyledCustomerInfoWrapper,
} from './styles';
import { StyledBox } from '../../../../styles/Grid/styles';
import Typography from '../../../../styles/Typography';

import CustomerLastOrderCard from '../../../../molecules/CustomerLastOrderCard';
import CustomerOrdersTable from '../../../../molecules/CustomerOrdersTable';
import { useCustomerSummary } from './hooks';
import Loader from '../../../../atoms/Loader';
import { LoaderMode } from '../../../../atoms/Loader/enums';
import {
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
} from '../../../../../assets/svg/icons';

const CustomerDetailsModal: React.FC<CustomerDetailsModalProps> = (props) => {
  const { customerId, onClose = noop } = props;
  const [addressesIsOpen, setAddressesIsOpen] = useState<boolean>(false);

  const { customer, orders, loading } = useCustomerSummary(customerId);

  const lastOrder: CustomerOrder | undefined = orders[0];

  const theme = useContext(ThemeContext);

  const isRtl = theme.direction === 'rtl';

  const toggleAddresses = () => {
    setAddressesIsOpen(!addressesIsOpen);
  };

  return (
    <ModalBase {...{ ...props, onClose }} withCloseButton>
      <Container>
        {loading ? (
          <Loader mode={LoaderMode.CustomerSummary} />
        ) : (
          <>
            <Row>
              <Col sm={12}>
                <StyledBox flexDirection='column' gap='0.5rem'>
                  <Typography variant='h2' as='h2'>
                    {customer?.name}
                  </Typography>

                  <StyledCustomerInfoWrapper>
                    <Typography variant='label'>
                      <FormattedMessage
                        id='customerId'
                        defaultMessage='Customer Id'
                      />
                    </Typography>
                    <Typography>{customerId}</Typography>
                  </StyledCustomerInfoWrapper>
                </StyledBox>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                {/* Phone */}
                <StyledCustomerInfoWrapper>
                  <Typography variant='label'>
                    <FormattedMessage
                      id='customers.phone'
                      defaultMessage='Phone'
                    />
                  </Typography>
                  <Typography variant='bodyBold'>{customer?.phone}</Typography>
                </StyledCustomerInfoWrapper>

                {/* City */}
                <StyledCustomerInfoWrapper>
                  <Typography variant='label'>
                    <FormattedMessage id='city' defaultMessage='City' />
                  </Typography>

                  <Typography variant='bodyBold'>
                    {
                      customer?.addresses[customer?.addresses?.length - 1]
                        ?.state
                    }
                  </Typography>
                </StyledCustomerInfoWrapper>
              </Col>

              <Col sm={4}>
                {/* LTV */}
                <StyledCustomerInfoWrapper>
                  <Typography variant='label'>
                    <FormattedMessage
                      id='customers.ltv'
                      defaultMessage='LTV IQD'
                    />
                  </Typography>
                  <Typography variant='bodyBold'>
                    <FormattedNumber
                      maximumFractionDigits={2}
                      minimumFractionDigits={2}
                      value={customer?.totalPrice || 0}
                    />
                  </Typography>
                </StyledCustomerInfoWrapper>

                {/* Recent Address */}
                <StyledCustomerInfoWrapper>
                  <Typography variant='label'>
                    <FormattedMessage
                      id='recentAddress'
                      defaultMessage='Recent Address'
                    />
                  </Typography>

                  <Typography variant='bodyBold'>
                    {customer?.addresses[0]?.address}
                  </Typography>
                </StyledCustomerInfoWrapper>
              </Col>

              <Col sm={4}>
                <StyledCustomerInfoWrapper>
                  <Typography variant='label'>
                    <FormattedMessage
                      id='totalOrders'
                      defaultMessage='Total Orders'
                    />
                  </Typography>
                  <Typography variant='bodyBold'>
                    {customer?.totalOrders}
                  </Typography>
                </StyledCustomerInfoWrapper>
              </Col>
            </Row>

            {customer?.addresses && customer.addresses?.length > 1 ? (
              <Row>
                <Col sm={6}>
                  <StyledAddressesCollapsibleCard onClick={toggleAddresses}>
                    <StyledBox gap='0.5rem'>
                      {addressesIsOpen ? (
                        <IconChevronDown width={15} />
                      ) : isRtl ? (
                        <IconChevronLeft width={15} />
                      ) : (
                        <IconChevronRight width={15} />
                      )}
                      <Typography variant='bodyBold'>
                        <FormattedMessage
                          id='previousAddresses'
                          defaultMessage='Previews Addresses'
                        />
                      </Typography>
                    </StyledBox>

                    <StyledBox
                      flexDirection='column'
                      className={addressesIsOpen ? '' : 'hidden'}
                      gap='0.75rem'
                      ml='1.5rem'
                    >
                      {customer?.addresses?.slice(1).map((address, idx) => (
                        <Row key={idx}>
                          <Col sm={4}>
                            <StyledCustomerInfoWrapper withoutMargins>
                              <Typography variant='label'>
                                <FormattedMessage
                                  id='city'
                                  defaultMessage='City'
                                />
                              </Typography>
                              <Typography variant='bodyBold'>
                                {address.state}
                              </Typography>
                            </StyledCustomerInfoWrapper>
                          </Col>

                          <Col sm={8}>
                            <StyledCustomerInfoWrapper withoutMargins>
                              <Typography variant='label'>
                                <FormattedMessage
                                  id='address'
                                  defaultMessage='Address'
                                />
                              </Typography>
                              <Typography variant='bodyBold'>
                                {address.address}
                              </Typography>
                            </StyledCustomerInfoWrapper>
                          </Col>
                        </Row>
                      ))}
                    </StyledBox>
                  </StyledAddressesCollapsibleCard>
                </Col>
              </Row>
            ) : null}

            {/* Last Order */}
            {lastOrder ? (
              <Row>
                <Col sm={12}>
                  <StyledBox mt={4} flexDirection='column' gap='0.5rem'>
                    <Typography variant='bodyBold'>
                      <FormattedMessage
                        id='lastOrder'
                        defaultMessage='Last Order'
                      />
                    </Typography>

                    <CustomerLastOrderCard orderData={lastOrder} />
                  </StyledBox>

                  {/* Customer Orders Table */}
                  {orders.length ? (
                    <CustomerOrdersTable orders={orders} />
                  ) : null}
                </Col>
              </Row>
            ) : null}
          </>
        )}
      </Container>
    </ModalBase>
  );
};

export default CustomerDetailsModal;
