import React, { useContext } from 'react';
import ContentLoader from 'react-content-loader';
import { ThemeContext } from 'styled-components';

const WarehouseReportTemplate: React.FC = () => {
  const theme = useContext(ThemeContext);

  return (
    <ContentLoader
      speed={2}
      width={1191}
      height={600}
      viewBox='0 0 1191 600'
      backgroundColor={theme.colors.loader.background}
      foregroundColor={theme.colors.loader.foreground}
    >
      <rect x='60' y='26' rx='27' ry='27' width='600' height='257' />
      <rect x='60' y='305' rx='27' ry='27' width='600' height='180' />
      <rect x='740' y='26' rx='27' ry='27' width='420' height='470' />
    </ContentLoader>
  );
};

export default WarehouseReportTemplate;
