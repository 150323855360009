import React from 'react';
import ModalBase from '../..';
import Button from '../../../../atoms/Button';
import { ButtonMode } from '../../../../atoms/Button/enums';
import { StyledBox } from '../../../../styles/Grid/styles';
import Typography from '../../../../styles/Typography';
import {
  StyledConfirmationModalAction,
  StyledConfirmationModalIconWrapper,
} from './styles';
import { ConfirmationModalProps } from './types';
import Spinner from '../../../../atoms/Spinner';
import { SpinnerSize } from '../../../../atoms/Spinner/enums';

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onClose,
  isWarning,
  icon: Icon,
  title,
  description,
  cancelButtonLabel,
  confirmButtonLabel,
  loadingConfirm,
  onConfirmButtonClick,
  ...props
}) => {
  const confirmButtonMode = isWarning
    ? ButtonMode.Tertiary
    : ButtonMode.Primary;

  return (
    <ModalBase isPopup {...props}>
      {Icon && (
        <StyledBox mb='1rem' justifyContent='center'>
          <StyledConfirmationModalIconWrapper {...{ isWarning }}>
            <Icon />
          </StyledConfirmationModalIconWrapper>
        </StyledBox>
      )}
      <StyledBox mb='0.5rem' justifyContent='center'>
        <Typography as='h2' variant='h2' textAlign='center'>
          {title}
        </Typography>
      </StyledBox>
      <StyledBox mb='1.625rem' justifyContent='center'>
        <Typography textAlign='center'>{description}</Typography>
      </StyledBox>
      <StyledConfirmationModalAction>
        {cancelButtonLabel && (
          <Button
            mode={ButtonMode.Secondary}
            onClick={onClose}
            disabled={loadingConfirm}
          >
            {cancelButtonLabel}
          </Button>
        )}
        <Button
          mode={confirmButtonMode}
          onClick={onConfirmButtonClick}
          disabled={loadingConfirm}
        >
          <StyledBox gap='0.5rem'>
            {confirmButtonLabel}
            {loadingConfirm ? <Spinner size={SpinnerSize.ExtraSmall} /> : null}
          </StyledBox>
        </Button>
      </StyledConfirmationModalAction>
    </ModalBase>
  );
};

export default ConfirmationModal;
