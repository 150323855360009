import React from 'react';
import { ContactLinkProps } from './types';
import { StyledContactLink, StyledLabel } from './styles';
import { IconWhatsApp } from '../../../assets/svg/icons';

const ContactLink: React.FC<ContactLinkProps> = ({
  label,
  whatsAppNumber,
  ...props
}) => (
  <StyledContactLink
    href={`https://api.whatsapp.com/send?phone=${whatsAppNumber}`}
    target='_blank'
    {...props}
  >
    <IconWhatsApp /> <StyledLabel>{label}</StyledLabel>
  </StyledContactLink>
);

export default ContactLink;
