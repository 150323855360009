import React from 'react';
import ErrorMessage from '../ErrorMessage';
import { StyledFieldWrapperLabel, StyledFieldWrapper } from './styles';
import { FieldWrapperProps } from './types';

const FieldWrapper: React.FC<FieldWrapperProps> = ({
  label,
  isFakeLabel,
  control,
  error,
  hideErrorMessageWrapper,
}) => (
  <StyledFieldWrapper as={isFakeLabel ? 'div' : 'label'}>
    {label && <StyledFieldWrapperLabel>{label}</StyledFieldWrapperLabel>}
    {control}
    {!error && hideErrorMessageWrapper ? null : <ErrorMessage {...{ error }} />}
  </StyledFieldWrapper>
);

export default FieldWrapper;
