import { IntlShape } from 'react-intl';
import { TabsNavigationItem } from '../../../components/molecules/TabsNavigation/types';
import routes from '../../../setup/consts/routes';

export const tabsNavigationItems = (intl: IntlShape): TabsNavigationItem[] => [
  {
    label: intl.formatMessage({
      id: 'enitiesAndUsers.enitites',
      defaultMessage: 'Entities',
    }),
    to: routes.ENTITIES,
    key: '1',
  },
  {
    label: intl.formatMessage({
      id: 'enitiesAndUsers.users',
      defaultMessage: 'Users',
    }),
    to: routes.USERS,
    key: '2',
  },
];
