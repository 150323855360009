import styled from 'styled-components';

export const StyledForm = styled.form`
  width: 100%;
  max-width: ${({ theme }) => theme.dimensions.searchForm.width};
  z-index: ${({ theme }) => theme.zindexes.search};
`;

export const StyledAction = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 1rem;
`;

export const StyledProductListWrapper = styled.div`
  width: 100%;
`;

export const StyledFilterWrapper = styled.div`
  min-width: 12rem;
  z-index: 2;
`;
