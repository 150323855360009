import React, { ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AnimatePresence } from 'framer-motion';
import { useDebounce } from 'use-debounce';
import { StyledForm, StyledTableWrapper } from './styles';
import apiRoutes from '../../setup/consts/apiRoutes';
import useHeaderTitleContext from '../../hooks/useHeaderTitleContext';
import { GetCustomersResponseSingleItem } from '../../setup/apiTypes/customers';
import { StyledBox } from '../../components/styles/Grid/styles';
import DataTable from '../../components/molecules/Table/dataTable';
import { customersTableColumns } from './consts';
import CustomerDetailsModal from '../../components/organisms/Modal/templates/CustomerDetailsModal';
import Input from '../../components/atoms/Fields/Input';
import { FieldMode } from '../../components/atoms/Fields/enums';
import { IconClose, IconSearch } from '../../assets/svg/icons';
import Spinner from '../../components/atoms/Spinner';
import { SpinnerMode, SpinnerSize } from '../../components/atoms/Spinner/enums';
import { StyledClearButton } from '../../components/atoms/Fields/Autocomplete/styles';
import { DataTableAPI } from '../../components/molecules/Table/types';

const CustomerPage: React.FC = () => {
  // States
  const [detailsCustomerId, setDetailsCustomerId] = useState<string>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [customersTableAPI, setCustomersTableAPI] = useState<DataTableAPI>();

  // Hooks
  const { setHeaderTitle } = useHeaderTitleContext();
  const intl = useIntl();
  const tableColumns = useMemo(() => customersTableColumns(intl), [intl]);
  const [debouncedQuery] = useDebounce(searchQuery, 3000);

  // Handlers
  const handleSearchQueryChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchQuery(e.target.value);
  };

  const clearSearchQuery = () => {
    setSearchQuery('');
  };

  useEffect(() => {
    setHeaderTitle(
      intl.formatMessage({
        id: 'mainNavigation.customer',
        defaultMessage: 'Customer',
      })
    );
  }, [intl, setHeaderTitle]);

  return (
    <>
      {/* Autocomplete */}
      <StyledBox mb='0.25rem'>
        {/* Search Input */}
        <StyledForm onSubmit={(e) => e.preventDefault()}>
          <Input
            mode={FieldMode.Tertiary}
            placeholder={intl.formatMessage({
              id: 'search.searchCustomers',
              defaultMessage: 'Search Customers',
            })}
            isRounded
            prefixComponent={<IconSearch />}
            suffixComponent={
              (customersTableAPI?.loading && searchQuery) ||
              (debouncedQuery && !searchQuery) ? (
                <Spinner
                  mode={SpinnerMode.Dark}
                  size={SpinnerSize.ExtraSmall}
                />
              ) : debouncedQuery ? (
                <StyledClearButton type='button' onClick={clearSearchQuery}>
                  <IconClose />
                </StyledClearButton>
              ) : null
            }
            value={searchQuery}
            onChange={handleSearchQueryChange}
            type='input'
            disabled={customersTableAPI?.loading}
          />
        </StyledForm>
      </StyledBox>
      <StyledBox
        mb='0.25rem'
        justifyContent='space-between'
        alignItems='center'
      >
        {/* Flags */}
        <StyledBox mb='1.2rem' gap='0.5rem'>
          {/*  <Button
            icon={IconVip}
            mode={ButtonMode.Secondary}
            size={ButtonSize.Small}
          >
            {intl.formatMessage({
              id: 'vip',
              defaultMessage: 'Vip',
            })}
          </Button>
          <Button
            icon={IconBlock}
            mode={ButtonMode.Secondary}
            size={ButtonSize.Small}
          >
            {intl.formatMessage({
              id: 'block',
              defaultMessage: 'Block',
            })}
          </Button>
          <Button
            icon={IconStar}
            mode={ButtonMode.Secondary}
            size={ButtonSize.Small}
          >
            {intl.formatMessage({
              id: 'favorite',
              defaultMessage: 'Favorite',
            })}
          </Button>*/}
        </StyledBox>

        <StyledBox mb='0.25rem' gap='1rem' alignItems='center'>
          {/* Download Button */}
          {/* <Button
            icon={IconDownload}
            mode={ButtonMode.Secondary}
            size={ButtonSize.Small}
          >
            {intl.formatMessage({
              id: 'downloadXlOrPdf',
              defaultMessage: 'Download (xl/pdf)',
            })}
          </Button> */}

          {/* Filter Select */}
          {/* <StyledFilterWrapper>
            <SelectBase
              placeholder='Select filter...'
              icon={IconDropdown}
              options={[
                { value: 'most-popular', label: 'Most popular' },
                { value: 'date-created', label: 'Date created' },
                { value: 'price', label: 'By price' },
                { value: 'ordered', label: 'By ordered' },
              ]}
              notSeparated
              onChange={noop}
            />
          </StyledFilterWrapper> */}
        </StyledBox>
      </StyledBox>

      {/* Data Table */}
      <StyledTableWrapper>
        <DataTable<GetCustomersResponseSingleItem>
          endpoint={apiRoutes.CUSTOMERS}
          columns={tableColumns}
          tableAPIRef={setCustomersTableAPI}
          query={{
            ...(debouncedQuery ? { query: debouncedQuery } : {}),
          }}
          onRowClick={({ original }) => setDetailsCustomerId(original.id)}
          queryOptions={{
            cacheTime: 0,
          }}
          placeholderProps={{
            title: intl.formatMessage({
              id: 'customers.noData',
              defaultMessage: 'No Data Found',
            }),
          }}
          pageParamKey='pageIndex'
        />

        <AnimatePresence>
          {detailsCustomerId ? (
            <CustomerDetailsModal
              customerId={detailsCustomerId}
              onClose={() => setDetailsCustomerId(undefined)}
            />
          ) : null}
        </AnimatePresence>
      </StyledTableWrapper>
    </>
  );
};

export default CustomerPage;
