import styled from 'styled-components';
import colors from '../../../theme/config/colors';

export const AccordionContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

export const AccordionHeader = styled.div<{ isOpen: boolean }>`
  background-color: ${({ theme }) => colors.accordionHeader.background};
  color: ${({ theme }) => colors.accordionHeader.color};
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
`;

export const AccordionContent = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  transition: max-height 0.4s ease; /* Smooth transition */
  background-color: ${({ theme }) => theme.colors.background};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.4s ease, max-height 0.4s ease;
  > div {
    padding: 15px;
  }
`;

export const AccordionIcon = styled.span<{ isOpen: boolean }>`
  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0')});
  transition: transform 0.3s ease;
`;
