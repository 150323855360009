import React from 'react';
import { PercentIconBoxProps } from './types';
import {
  StyledIconWrapper,
  StyledPercentValue,
  StyledPercentWrapper,
} from './styles';
import Typography from '../../styles/Typography';

const PercentIconBox: React.FC<PercentIconBoxProps> = ({
  label,
  icon: Icon,
  percent,
}) => (
  <>
    <StyledIconWrapper>
      <Icon />
    </StyledIconWrapper>
    <StyledPercentWrapper>
      <StyledPercentValue>
        <Typography variant='percentBold'>{percent}</Typography>
      </StyledPercentValue>
      <Typography variant='h2'>%</Typography>
    </StyledPercentWrapper>
    <Typography variant='caption' textAlign='center'>
      {label}
    </Typography>
  </>
);

export default PercentIconBox;
