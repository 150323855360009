import { IntlShape } from 'react-intl';
import { ColumnDef } from '@tanstack/react-table';
import { GetDamagedProductTransactionsResponseSingleItem } from '../../../setup/apiTypes/products';

export const damagedProductTableFakeData: GetDamagedProductTransactionsResponseSingleItem[] =
  [
    {
      addedOn: new Date().toDateString(),
      createdOn: new Date().toDateString(),
      id: 1,
      productId: 3,
      productName: 'Nike',
      productSku: 'sku-29sk',
      quantity: 1,
      warehouseId: 3,
      notes: '',
    },
    {
      addedOn: new Date().toDateString(),
      createdOn: new Date().toDateString(),
      id: 1,
      productId: 3,
      productName: 'Nike',
      productSku: 'sku-29sk',
      quantity: 1,
      warehouseId: 3,
      notes: '',
    },
    {
      addedOn: new Date().toDateString(),
      createdOn: new Date().toDateString(),
      id: 1,
      productId: 3,
      productName: 'Nike',
      productSku: 'sku-29sk',
      quantity: 1,
      warehouseId: 3,
      notes: '',
    },
    {
      addedOn: new Date().toDateString(),
      createdOn: new Date().toDateString(),
      id: 1,
      productId: 3,
      productName: 'Nike',
      productSku: 'sku-29sk',
      quantity: 1,
      warehouseId: 3,
      notes: '',
    },
    {
      addedOn: new Date().toDateString(),
      createdOn: new Date().toDateString(),
      id: 1,
      productId: 3,
      productName: 'Nike',
      productSku: 'sku-29sk',
      quantity: 1,
      warehouseId: 3,
      notes: '',
    },
    {
      addedOn: new Date().toDateString(),
      createdOn: new Date().toDateString(),
      id: 1,
      productId: 3,
      productName: 'Nike',
      productSku: 'sku-29sk',
      quantity: 1,
      warehouseId: 3,
      notes: '',
    },
    {
      addedOn: new Date().toDateString(),
      createdOn: new Date().toDateString(),
      id: 1,
      productId: 3,
      productName: 'Nike',
      productSku: 'sku-29sk',
      quantity: 1,
      warehouseId: 3,
      notes: '',
    },
    {
      addedOn: new Date().toDateString(),
      createdOn: new Date().toDateString(),
      id: 1,
      productId: 3,
      productName: 'Nike',
      productSku: 'sku-29sk',
      quantity: 1,
      warehouseId: 3,
      notes: '',
    },
    {
      addedOn: new Date().toDateString(),
      createdOn: new Date().toDateString(),
      id: 1,
      productId: 3,
      productName: 'Nike',
      productSku: 'sku-29sk',
      quantity: 1,
      warehouseId: 3,
      notes: '',
    },
  ];

export const damagedProductTransactionsTableColumns = (
  intl: IntlShape
): ColumnDef<GetDamagedProductTransactionsResponseSingleItem>[] => [
  {
    header: intl.formatMessage({
      id: 'id',
      defaultMessage: 'Id',
    }),
    accessorKey: 'id',
  },
  {
    header: intl.formatMessage({
      id: 'sku',
      defaultMessage: 'Sku',
    }),
    accessorKey: 'productSku',
  },
  {
    header: intl.formatMessage({
      id: 'products.product',
      defaultMessage: 'Product',
    }),
    accessorKey: 'productName',
  },
  {
    header: intl.formatMessage({
      id: 'products.quantity',
      defaultMessage: 'Quantity',
    }),
    accessorKey: 'quantity',
  },
  {
    header: intl.formatMessage({
      id: 'addedOn',
      defaultMessage: 'Added On',
    }),
    accessorKey: 'addedOn',
  },
  {
    header: intl.formatMessage({
      id: 'notes',
      defaultMessage: 'Notes',
    }),
    accessorKey: 'note',
  },
];
