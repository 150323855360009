import { dispatchGlobalEvent } from '../../utils/browser';
import { LOCAL_STORAGE_CHANGE } from './consts';
import ls from 'localstorage-slim';

ls.config.encrypt = true;
ls.config.secret = 90;

export const getLocalStorageValue = (
  key: string,
  decrypt: boolean = false
): string | null => {
  try {
    if (decrypt) {
      return ls.get(key);
    }
    return localStorage.getItem(key);
  } catch (err) {
    return null;
  }
};

export const setLocalStorageValue = <T>(
  key: string,
  val: T,
  encrypt: boolean = false
): boolean => {
  try {
    const stringifiedValue = JSON.stringify(val);
    if (encrypt) {
      ls.set(key, stringifiedValue);
    } else {
      localStorage.setItem(key, stringifiedValue);
    }
    dispatchGlobalEvent(LOCAL_STORAGE_CHANGE);
    return true;
  } catch (err) {
    return false;
  }
};

export const removeLocalStorageValue = (key: string): void => {
  localStorage.removeItem(key);
  dispatchGlobalEvent(LOCAL_STORAGE_CHANGE);
};
