import React from 'react';
import { components } from 'react-select';
import { SelectOptionProps } from './types';

function Option<T = string>({
  children,
  renderOptionContent,
  data,
  ...rest
}: SelectOptionProps<T>): ReactComponentReturnType {
  return (
    <components.Option {...{ data, ...rest }}>
      {(renderOptionContent && renderOptionContent(data)) || children}
    </components.Option>
  );
}

export default Option;
