import React, { ChangeEvent, useMemo } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useIntl } from 'react-intl';
import { IconGlobalLocation } from '../../../../../../../../assets/svg/icons';
import Autocomplete from '../../../../../../../../components/atoms/Fields/Autocomplete';
import { BaseAutocompleteItem } from '../../../../../../../../components/atoms/Fields/Autocomplete/types';
import CheckboxList from '../../../../../../../../components/atoms/Fields/CheckboxList';

import Placeholder from '../../../../../../../../components/molecules/Placeholder';
import { PlaceholderSize } from '../../../../../../../../components/molecules/Placeholder/enums';
import { StyledBox } from '../../../../../../../../components/styles/Grid/styles';
import { GetEntitiesResponseSingleItem } from '../../../../../../../../setup/apiTypes/entities';
import apiRoutes from '../../../../../../../../setup/consts/apiRoutes';
import {
  StyledAssignedEntitiesWrapper,
  StyledPlaceholderWrapper,
  StyledSearchWrapper,
} from './styles';
import {
  AssignedEntitiesFieldProps,
  AssignedEntitiesProps,
  EntityFormItem,
} from './types';

const AssignedEntities: React.FC<AssignedEntitiesProps> = ({
  fields,
  openEntitiesModal,
}) => {
  const intl = useIntl();
  const checkboxItems = useMemo(
    () =>
      fields.value.map(({ label, checked, value }, index) => ({
        key: value,
        label,
        checked,
        onChange: (event: ChangeEvent<HTMLInputElement>) => {
          fields.update(index, {
            label,
            value,
            checked: event.target.checked,
          });
        },
      })),
    [fields]
  );

  return (
    <StyledAssignedEntitiesWrapper>
      <StyledSearchWrapper>
        <Autocomplete<string, { items: GetEntitiesResponseSingleItem[] }>
          endpoint={apiRoutes.ENTITIES}
          onSuggestionClick={(item) =>
            fields.push({
              ...item,
              checked: true,
            })
          }
          mapApiResponseToSuggestions={({ items }) =>
            items.reduce<BaseAutocompleteItem<string>[]>(
              (result, { id, name }) => {
                const isItemSelected = fields.value.find(
                  ({ value }) => value === id
                );

                if (isItemSelected) {
                  return result;
                }

                return [...result, { label: name, value: id }];
              },
              []
            )
          }
          placeholder={intl.formatMessage({
            id: 'search.searchEntities',
            defaultMessage: 'Search entities',
          })}
          buttonLabel={intl.formatMessage({
            id: 'enitiesAndUsers.createNewEntity',
            defaultMessage: 'Create New Entity',
          })}
          onButtonClick={openEntitiesModal}
          placeholderProps={{
            title: intl.formatMessage({
              id: 'enitiesAndUsers.entitiesPlaceholderTitle',
              defaultMessage: 'You have not added any entities yet',
            }),
            description: intl.formatMessage({
              id: 'enitiesAndUsers.entitiesPlaceholderDescription',
              defaultMessage:
                'You can add multiple Entities to your business, these entities can represent sub-brands, locations, etc.',
            }),
            icon: IconGlobalLocation,
          }}
          clearInputOnSuggestionClick
        />
      </StyledSearchWrapper>
      <StyledBox>
        {!checkboxItems.length ? (
          <StyledPlaceholderWrapper>
            <Placeholder
              title={intl.formatMessage({
                id: 'enitiesAndUsers.entitiesPlaceholderTitle',
                defaultMessage: 'You have not added any entities yet',
              })}
              description={intl.formatMessage({
                id: 'enitiesAndUsers.entitiesPlaceholderDescription',
                defaultMessage:
                  'You can add multiple Entities to your business, these entities can represent sub-brands, locations, etc.',
              })}
              icon={IconGlobalLocation}
              buttonLabel={intl.formatMessage({
                id: 'enitiesAndUsers.createNewEntity',
                defaultMessage: 'Create New Entity',
              })}
              onClick={openEntitiesModal}
              size={PlaceholderSize.Small}
            />
          </StyledPlaceholderWrapper>
        ) : (
          <CheckboxList items={checkboxItems} />
        )}
      </StyledBox>
    </StyledAssignedEntitiesWrapper>
  );
};

const AssignedEntitiesField: React.FC<AssignedEntitiesFieldProps> = ({
  name,
  ...rest
}) => (
  <FieldArray<EntityFormItem> {...{ name }}>
    {({ fields }) => <AssignedEntities {...{ fields, ...rest }} />}
  </FieldArray>
);

export default AssignedEntitiesField;
