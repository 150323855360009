import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import ModalBase from '../..';
import Button from '../../../../atoms/Button';
import { ButtonMode } from '../../../../atoms/Button/enums';
import AvatarUpload, {
  FormAvatarUpload,
} from '../../../../molecules/AvatarUpload';
import {
  StyledSettingsModalInfoWrapper,
  StyledSettingsModalAvatarWrapper,
  StyledSettingsModalTitle,
  StyledSettingsModalMainFieldset,
  StyledSeettingsModalAction,
} from './styles';
import { SettingsModalProps } from './types';

const SettingsModal: React.FC<SettingsModalProps> = ({
  label,
  leftColumn,
  rightColumn,
  cancelButton,
  deleteButton,
  submitButton,
  avatarLabel,
  avatarFieldName,
  handleSubmit,
  ...props
}) => {
  const { title: leftColumnTitle, content: leftColumnContent } = leftColumn;
  const { title: rightColumnTitle, content: rightColumnContent } = rightColumn;

  return (
    <ModalBase {...props}>
      <form onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Col md={7}>
              <StyledSettingsModalTitle>
                {leftColumnTitle}
              </StyledSettingsModalTitle>
              <StyledSettingsModalInfoWrapper>
                <StyledSettingsModalAvatarWrapper>
                  {avatarFieldName ? (
                    <FormAvatarUpload
                      name={avatarFieldName}
                      label={avatarLabel}
                    />
                  ) : (
                    <AvatarUpload label={avatarLabel} />
                  )}
                </StyledSettingsModalAvatarWrapper>
                <StyledSettingsModalMainFieldset>
                  {leftColumnContent}
                  <StyledSeettingsModalAction>
                    <Button
                      mode={ButtonMode.Secondary}
                      type='button'
                      {...cancelButton}
                    >
                      {cancelButton.label}
                    </Button>
                    {deleteButton && (
                      <Button
                        mode={ButtonMode.Secondary}
                        type='button'
                        {...deleteButton}
                      >
                        {deleteButton.label}
                      </Button>
                    )}
                    <Button {...submitButton} type='submit'>
                      {submitButton.label}
                    </Button>
                  </StyledSeettingsModalAction>
                </StyledSettingsModalMainFieldset>
              </StyledSettingsModalInfoWrapper>
            </Col>
            <Col md={4}>
              <StyledSettingsModalTitle>
                {rightColumnTitle}
              </StyledSettingsModalTitle>
              {rightColumnContent}
            </Col>
          </Row>
        </Container>
      </form>
    </ModalBase>
  );
};

export default SettingsModal;
