import React from 'react';
import { FaqItem } from './partials/FaqList/types';

export const FAQ_ITEMS: FaqItem[] = [
  {
    key: '01',
    heading: 'General Questions',
    description: 'To start, our most frequently asked FAQs.',
    questions: [
      {
        key: '001',
        question: 'What does Sandoog services cover?',
        answer: (
          <>
            <strong>Sandoog</strong> is a technology-driven, local Fulfilment
            and Delivery solution, designed to solve one main problem:
            Delivering your promise to your customers. When you use{' '}
            <strong>Sandoog</strong>, your team will receive less phone calls
            from customers about their orders — we take your shipments where
            they need to go, hassle-free.
          </>
        ),
      },
      {
        key: '002',
        question: 'How Does Sandoog Work with us?',
        answer: (
          <>
            It all comes down to the <strong>Sandoog Center</strong> platform.
            When you connect your online store with our system and we receive
            your orders at our facilities - We are able to properly store, pick,
            pack and deliver packages faster and more efficiently. You can also
            track the delivery statuses in real time in the order section
            [Hyperlink] and monitor the Cash-On-Delivery transactions we make on
            your behalf in the finances section [Hyperlink].
          </>
        ),
      },
      {
        key: '003',
        question: 'How Can I Contact Your Customer Service Team?',
        answer: (
          <>
            You can contact our Client Relations team directly - You can
            call/WhatsApp our Client Relations team between 8am - 6pm on:
            +9647823003004 or email:{' '}
            <a href='mailto:CS@sandoog.net' target='blank'>
              CS@sandoog.net
            </a>
          </>
        ),
      },
    ],
  },
  {
    key: '02',
    heading: 'Payment Queries',
    description:
      'Being clear about charges and payments from the start will prevent complications that could delay your order.',
    questions: [
      {
        key: '004',
        question: 'How Do I choose the preferred currency and payment method?',
        answer: (
          <>
            You can choose how you would like to be paid and in which currency -
            USD or IQD - in the Account settings &gt; Billing &amp; Payments.
            You can contact our Client Relations team with specific requirements
            and to setup payment methods with our Accounts Team.
          </>
        ),
      },
      {
        key: '005',
        question: 'When Will I Receive My Cash-On-Delivery (C.O.Ds) payments?',
        answer: (
          <>
            We send out domestic transfers once a week. International transfers
            can be arranged to your preferences - the minimum is two weeks
            between each payout.
          </>
        ),
      },
      {
        key: '006',
        question: 'When Do I Need to Pay for my service charges?',
        answer: (
          <>
            You’ll receive an invoice at the last week of the month. After this,
            you can choose to pay us within 7 days or we can also deduct the
            amount from your wallet.
          </>
        ),
      },
      {
        key: '007',
        question:
          'Will I Need to Pay a Fee to Remit C.O.D payments from Sandoog?',
        answer: (
          <>
            We have a 2,000 IQD ($1.40) admin fee for processing each statement.
            However, you may need to pay a fee to your bank or transfer service
            provider if you are receiving the payment internationally.
          </>
        ),
      },
      {
        key: '008',
        question: 'Shipping Queries',
        answer:
          'Need to know how to get packages to us - We’ve got you covered!',
      },
      {
        key: '009',
        question: 'I Need a new international shipping provider?',
        answer: (
          <>
            No problem - Our Client Relations team can suggest a service that
            has been used by other Sandoog clients.
          </>
        ),
      },
    ],
  },
  {
    key: '03',
    heading: 'Orders and Delivery Queries',
    description:
      'We sometimes receive questions on how we receive orders and and how we deliver them - here’s some important things to keep in mind…',
    questions: [
      {
        key: '010',
        question: 'How can I send you our orders?',
        answer: (
          <>
            You can use our API [Hyperlink] to integrate your online store with
            our system so we can receive your orders in real-time.
          </>
        ),
      },
      {
        key: '011',
        question: 'Can I upload orders in bulk to my account?',
        answer: (
          <>
            Yes - Use our{' '}
            <a
              href='https://drive.google.com/file/d/1JxfEpvUeV_wijysoZf9zmd_oHw8DyiYp/view?usp=sharing'
              target='_blank'
              rel='noopener noreferrer'
            >
              template file
            </a>{' '}
            to enter your orders and then you can click the ‘Upload Orders’
            button at the top-right of the Orders section.
          </>
        ),
      },
      {
        key: '012',
        question: 'Can I enter an order directly into Sandoog Center?',
        answer: (
          <>
            Yes - Click the ‘Create Order’ button on the top-right of the Orders
            section. Add the products to the order, enter the customer detail
            and edit the price if you need - Take a second-look at the order and
            then click confirm.
          </>
        ),
      },
      {
        key: '013',
        question: 'Can I Arrange a Pickup for My Orders in Iraq?',
        answer: (
          <>
            Pickups are possible for our Dropship clients - Our Client Relations
            team will arrange all the necessary pick-up arrangements once
            provided with the details from your side.
          </>
        ),
      },
      {
        key: '014',
        question: 'At What Time Will the carrier Pick Up the orders?',
        answer: (
          <>
            For pick-ups, you choose the pickup time slot offered by our Client
            Relations team who will arrange with our operations team on your
            behalf.
          </>
        ),
      },
      {
        key: '015',
        question: 'Can I Have Multiple Pickup Locations?',
        answer: <>Yes; you can set as many pickup locations as you need.</>,
      },
      {
        key: '016',
        question: 'When Do You Deliver?',
        answer: (
          <>
            Our delivery schedules operate continuously from 8am to 10pm from
            Saturday to Thursday.
          </>
        ),
      },
      {
        key: '017',
        question: 'Can Someone Other Than the Recipient Receive an Order?',
        answer: (
          <>
            For both, C.O.D and Prepaid shipments, if the named recipient is
            unavailable, someone else can receive the order on their behalf - if
            their details are added to the order form. If they are not named,
            our Call Center team will need confirmation from your side before
            approving transaction between our delivery agent and the recipient.
          </>
        ),
      },
      {
        key: '018',
        question:
          'What If I Need to Change the Contact Number or the C.O.D Amount for a Shipment?',
        answer: (
          <>
            You can change recipient details, such as, the contact number or
            C.O.D amount for shipments that are yet to be delivered directly on
            the Orders section [Hyperlink].
          </>
        ),
      },
    ],
  },
  {
    key: '03',
    heading: 'Tracking status',
    description:
      'With Sandoog, you’re able to track and trace the status of your orders. Here’s how!',
    questions: [
      {
        key: '019',
        question: 'How Can I Track My Orders?',
        answer: (
          <>
            Just head to the Orders section [Hyperlink] and search for a
            specific order using the recipient name, phone number, receipt
            number, and you’ll see the status of all of your orders. We will
            also send status updates to your customers through WhatsApp to keep
            them updated.
          </>
        ),
      },
      {
        key: '020',
        question: 'Can I Reschedule My Order?',
        answer: (
          <>
            Yes - Just search for the order in in the Orders section.
            Alternatively, you can send a request to our Client Relations team.
            Your clients can also respond to the WhatsApp Message and request to
            change the delivery date and details with our Call Center.
          </>
        ),
      },
      {
        key: '021',
        question: 'What Are ‘Placed’ orders on the Orders status section?',
        answer: (
          <>
            Once the order has been confirmed on our system it will show up as
            ‘Placed’ and awaiting processing by our warehouse team.
          </>
        ),
      },
      {
        key: '022',
        question: 'What Are ‘Packing’ orders on the Orders status section?',
        answer: (
          <>
            This means our warehouse team is in the process of packing the order
            and dispatching it with the delivery team
          </>
        ),
      },
      {
        key: '023',
        question:
          'What Are ‘Out for Delivery’ orders on the Orders status section?',
        answer: (
          <>
            You will receive this status once the order is dispatched and is out
            for delivery with a designated delivery agent.
          </>
        ),
      },
      {
        key: '024',
        question: 'What Are ‘Delivered’ order on the Orders status section?',
        answer: (
          <>
            ‘Delivered’ means your customers have received them and we are
            waiting to receive the cash form our delivery agent if it is a C.O.D
            order.
          </>
        ),
      },
      {
        key: '025',
        question:
          'What Are ‘Cash Collected’ orders on the Orders status section?',
        answer: (
          <>
            If the order requires a COD transaction, then this status will
            indicate that our delivery agent has completed the transaction and
            has collected the cash from your customer.
          </>
        ),
      },
      {
        key: '026',
        question:
          'What Are ‘Order Resolution’ orders on the Orders status section?',
        answer: (
          <>
            You will see this only If there is an issue with a specific order -
            You will be notified by our Call Center team to make the necessary
            correction directly from the Order section by clicking on that
            specific order in question.
          </>
        ),
      },
      {
        key: '027',
        question: 'What Are ‘returned’ orders on the Orders status section?',
        answer: (
          <>
            These are orders that we have not been able to deliver for any given
            reason - you can see the delivery comments in the order’s page. We
            will attempt to resend the item if the recipient still wants it.
          </>
        ),
      },
      {
        key: '028',
        question: 'What Are ‘failed’ orders on the Orders status section?',
        answer: (
          <>
            If the recipient refuses to receive the item or we are unable to
            contact the customer with no further instructions from your side,
            the order status will be classified as ‘Failed’.
          </>
        ),
      },
    ],
  },
  {
    key: '04',
    heading: 'Packing Queries',
    description:
      'Appropriate packaging is essential to prevent damages to your shipment. We have some recommendations.',
    questions: [
      {
        key: '029',
        question: 'What’s the Best Way to Package My Shipment for Pick-Ups?',
        answer: (
          <>
            The right packaging depends on what you’re shipping. We advise you
            to ensure the packaging is secure and sturdy enough to handle
            transportation. Consider using bubblewrap, cardboard boxes/wrap and
            durable delivery bags for the appropriate items, including fragile
            items
          </>
        ),
      },
      {
        key: '030',
        question: 'What About Packaging for Fulfillment Services?',
        answer: (
          <>
            For fulfillment shipments, the right packaging is relative to how
            fragile your products are. We advise you to get in touch with our
            Client Relations team to discuss whether you need additional
            packaging. We can use your provided branded packaging or, upon your
            request, we can provide you with bubblewrap, cardboard boxes, and
            other types of protective packaging. This may incur additional
            charges.
          </>
        ),
      },
    ],
  },
  {
    key: '05',
    heading: 'Order issues and Claims Queries',
    description:
      'With Sandoog, we aim to solve any order issues swiftly, and you can claim for damaged or a lost shipment.',
    questions: [
      {
        key: '031',
        question: 'How Can I Claim Compensation for a Lost or Damaged Item?',
        answer: (
          <>
            If your item is lost or damaged, you can raise a claim and our
            Client Relations team will investigate. You must let us know about
            any damage to your shipment within 24 hours of the delivery. Reach
            out to <a href='mailto:CS@Sandoog.net'>CS@Sandoog.net</a> - Provide
            us with your tracking number along with the reason and supporting
            evidence for your claim.
            <br />
            <br />
            As soon as we are aware of the situation, we will conduct an
            internal audit. This will let us know how the items were packaged
            and delivered. If we find that the the damage was due to an
            operational error by Sandoog, we will provide compensation in the
            form of a credit note or payment at the value of your shipment.
            <br />
            <br />
            That’s it! If there’s anything you still want to know that we
            haven’t covered here, including any specific questions about our
            service, send an email to:{' '}
            <a href='mailto:CS@Sandoog.us'>CS@Sandoog.us</a> or call
            us/WhatsApp: +9647823003004
            <br />
            <br />
            We are Sandoog; a start-up specialized in local Order-fulfillment
            and Last-Mile Delivery. We help e-commerce businesses like yours
            grow their business all over Iraq - Let’s make it happen together -
            Your success is our success!
          </>
        ),
      },
    ],
  },
];
