import styled from 'styled-components';
import Typography from '../../../../styles/Typography';

export const StyledSettingsModalTitle = styled(Typography).attrs({
  variant: 'h2',
  forwardedAs: 'h2',
})`
  margin-bottom: 2.875rem;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledSettingsModalInfoWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(8.75rem, 20%) 1fr;
  grid-gap: 2rem;
`;

export const StyledSettingsModalAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;

export const StyledSettingsModalMainFieldset = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledSeettingsModalAction = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 1.25rem;
  justify-content: flex-start;
  margin-top: 1rem;
`;
