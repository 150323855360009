import styled from 'styled-components';
import Button from '../../../components/atoms/Button';

export const StyledForm = styled.form`
  width: 100%;
`;

export const VerifyButton = styled(Button)`
  margin-inline-end: 1rem;
  width: 100%;
`;

export const VerificationInputWrapper = styled.div`
  padding: 20px 0;

  .character {
    border-radius: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
