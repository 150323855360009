import { noop } from 'lodash';
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { useDebounce } from 'use-debounce/lib';
import { PERMISSIONS } from '../../../../../common/permissions';
import useUserContext from '../../../../../hooks/useUserContext';
import { useEntityContext } from '../../../../../pages/financials/EntityContext/hooks';
import { PreferredCurrency } from '../../../../../setup/apiTypes/enums';
import { mapApiErrorsToFormErrors } from '../../../../../utils/finalForm';
import { mapCurrencyToCurrencyCode } from '../../../../../utils/misc';
import {
  GET_SETTLEMENTS_MUTATION_FN,
  SUBMIT_CASH_OUT_MUTATION_FN,
  SUBMIT_DISPUTE_MUTATION_FN,
} from './consts';
import {
  TransactionsSummaryTableItem,
  UseDisputeReturnType,
  UseGetSettlementsMutationReturnType,
  UseOrdersSearchReturnType,
  UseSettlementsReturnType,
  UseSubmitCashOutReturnType,
} from './types';

export const useGetSettlementsMutation =
  (): UseGetSettlementsMutationReturnType => {
    const intl = useIntl();
    const { selectedEntities, setCutOffDate } = useEntityContext();

    const { addToast } = useToasts();
    const { mutateAsync: getSettlements } = useMutation(
      GET_SETTLEMENTS_MUTATION_FN
    );

    return useCallback(async () => {
      try {
        const cutOffDate = new Date();
        setCutOffDate(cutOffDate);

        const { response } = await getSettlements({
          data: {
            selectedEntities,
            date: cutOffDate,
          },
        });

        return response;
      } catch (error) {
        const { generalError } = mapApiErrorsToFormErrors(error, intl);

        const defaultError = intl.formatMessage({
          id: 'financials.requestCashOutError',
          defaultMessage: 'Failed to get settlements',
        });

        addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
          appearance: 'error',
        });

        return null;
      }
    }, [setCutOffDate, getSettlements, selectedEntities, intl, addToast]);
  };

export const useSettlements = (): UseSettlementsReturnType => {
  const intl = useIntl();
  const getSettlements = useGetSettlementsMutation();

  const [isLoading, setIsLoading] = useState(true);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [availableBalanceCurrency, setAvailableBalanceCurrency] =
    useState<string>(PreferredCurrency.IQD);
  const [settlements, setSettlements] = useState<
    TransactionsSummaryTableItem[]
  >([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await getSettlements();

      if (!response) {
        setSettlements([]);
      } else {
        setAvailableBalance(response.availableBalance);
        setAvailableBalanceCurrency(
          mapCurrencyToCurrencyCode(response.availableBalanceCurrency)
        );
        setSettlements(
          response.settlements.map(
            ({ type, paidToMerchantAmount, createdDate, id }) => ({
              activityTypeCol: intl.formatMessage({
                id: `financials.activityTypes.${type}`,
                defaultMessage: type,
              }),
              dateCol: intl.formatDate(new Date(createdDate)),
              transactionNumberCol: id,
              valueIqdCol: paidToMerchantAmount,
            })
          )
        );
      }

      setIsLoading(false);
    })();
  }, [intl, getSettlements]);

  return useMemo(
    () => ({
      settlements,
      availableBalance,
      availableBalanceCurrency,
      isLoading,
    }),
    [settlements, availableBalance, availableBalanceCurrency, isLoading]
  );
};

export const useSubmitCashOut = (
  closeModal: () => void = noop
): UseSubmitCashOutReturnType => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { selectedEntities, cutOffDate } = useEntityContext();

  const { mutateAsync: submitCashOut } = useMutation(
    SUBMIT_CASH_OUT_MUTATION_FN
  );

  return useCallback(async () => {
    try {
      if (!cutOffDate) {
        return null;
      }

      const { response } = await submitCashOut({
        data: {
          selectedEntities,
          date: cutOffDate,
        },
      });

      queryClient.invalidateQueries();
      queryClient.clear();
      closeModal();

      return response;
    } catch (error) {
      const { generalError } = mapApiErrorsToFormErrors(error, intl);

      const defaultError = intl.formatMessage({
        id: 'financials.submitCashOutError',
        defaultMessage: 'Failed to submit a cash-out',
      });

      addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
        appearance: 'error',
      });

      return null;
    }
  }, [
    cutOffDate,
    submitCashOut,
    selectedEntities,
    queryClient,
    closeModal,
    intl,
    addToast,
  ]);
};

export const useOrdersSearch = (
  items: TransactionsSummaryTableItem[]
): UseOrdersSearchReturnType => {
  const [dataSet, setDataSet] = useState(false);
  const [query, setQuery] = useState('');
  const [result, setResult] = useState(items);
  const [debouncedQuery] = useDebounce(query.toLocaleLowerCase(), 200);

  const handleQueryChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { value } = event.target;

      setQuery(value);
    },
    []
  );

  useEffect(() => {
    if (items.length > 0 && !dataSet) {
      setResult(items);
      setDataSet(true);
    }
  }, [items, dataSet]);

  useEffect(() => {
    setResult(
      items.filter(
        (item) =>
          item.activityTypeCol.toLowerCase().includes(debouncedQuery) ||
          item.transactionNumberCol.toString().includes(debouncedQuery)
      )
    );
  }, [debouncedQuery, items]);

  return [result, { query, handleQueryChange }] as const;
};

export const useDispute = (): UseDisputeReturnType => {
  const intl = useIntl();
  const { addToast } = useToasts();
  const [dispute, setDispute] = useState('');
  const { selectedEntities, cutOffDate } = useEntityContext();

  const { mutateAsync, isLoading } = useMutation(SUBMIT_DISPUTE_MUTATION_FN);

  const submitDispute = useCallback(async () => {
    if (!cutOffDate) {
      return;
    }

    try {
      await mutateAsync({
        data: {
          dispute,
          selectedEntities,
          date: cutOffDate,
        },
      });

      setDispute('');
      addToast(`Your dispute has been sent.`, {
        appearance: 'success',
      });
    } catch (error) {
      const { generalError } = mapApiErrorsToFormErrors(error, intl);

      const defaultError = intl.formatMessage({
        id: 'financials.disputeError',
        defaultMessage: 'Failed to send a dispute',
      });

      addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
        appearance: 'error',
      });
    }
  }, [cutOffDate, mutateAsync, dispute, selectedEntities, addToast, intl]);

  const handleDisputeChange: ChangeEventHandler<HTMLTextAreaElement> =
    useCallback((event) => {
      const { value } = event.target;

      setDispute(value);
    }, []);

  return [submitDispute, { dispute, handleDisputeChange, isLoading }] as const;
};

export const useUserHasPermissionsToSendDispute = (): boolean => {
  const { user } = useUserContext();

  return useMemo(
    () => !!user?.permissions.includes(PERMISSIONS.requestDispute),
    [user]
  );
};
