import { useQuery } from 'react-query';
import { UseWarehouseReportReturnType, WarehouseReportData } from './types';
import apiRoutes from '../../../setup/consts/apiRoutes';

export const useWarehouseReport = (): UseWarehouseReportReturnType => {
  const { data, isLoading } = useQuery<UseQueryResponse<WarehouseReportData>>(
    apiRoutes.WAREHOUSE_REPORT,
    {
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      },
    }
  );

  return {
    data: data?.response,
    isLoading,
  };
};
