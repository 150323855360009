import styled from 'styled-components';
import { flexbox, grid, space } from 'styled-system';
import { StyledBoxProps, StyledGridBoxProps } from './types';

export const StyledBox = styled.div<
  StyledBoxProps & { gap?: string; width?: string }
>`
  ${space};
  ${flexbox};
  display: flex;
  gap: ${(props) => props.gap || ''};
  width: ${(props) => props.width || ''};
`;

export const StyledGridBox = styled.div<StyledGridBoxProps>`
  ${space};
  ${grid};
  display: grid;
  width: 100%;
`;
