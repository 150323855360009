import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../components/atoms/Button';
import ContactLink from '../../components/atoms/ContactLink';
import Input from '../../components/atoms/Fields/Input';
import SimpleSelect from '../../components/atoms/Fields/Select/templates/SimpleSelect';
import Textarea from '../../components/atoms/Fields/Textarea';
import { StyledBox } from '../../components/styles/Grid/styles';
import useHeaderTitleContext from '../../hooks/useHeaderTitleContext';

const CotactUsPage: React.FC = () => {
  const { setHeaderTitle } = useHeaderTitleContext();
  const intl = useIntl();

  useEffect(() => {
    setHeaderTitle(
      intl.formatMessage({
        id: 'sidebar.settings.contactUs',
        defaultMessage: 'Contact Us',
      })
    );
  }, [intl, setHeaderTitle]);

  return (
    <form>
      <Row>
        <Col md={4}>
          <StyledBox mb='3.25rem'>
            <ContactLink
              label={intl.formatMessage({
                id: 'common.contactUsViaWhatsApp',
                defaultMessage: 'Contact us via WhatsApp',
              })}
              whatsAppNumber={9647823003004}
            />
          </StyledBox>
          <Input
            label={intl.formatMessage({
              id: 'forms.fields.fullName',
              defaultMessage: 'Full Name',
            })}
          />
          <Input
            label={intl.formatMessage({
              id: 'forms.fields.emailAddress',
              defaultMessage: 'Email address',
            })}
            type='email'
          />
          <Input
            label={intl.formatMessage({
              id: 'forms.fields.phoneNumber',
              defaultMessage: 'Phone number',
            })}
            type='tel'
          />
          <SimpleSelect
            options={[
              { value: 'chocolate', label: 'Chocolate' },
              { value: 'strawberry', label: 'Strawberry' },
              { value: 'vanilla', label: 'Vanilla' },
            ]}
            label={intl.formatMessage({
              id: 'forms.fields.issueType',
              defaultMessage: 'Issue Type',
            })}
          />
          <Textarea
            label={intl.formatMessage({
              id: 'forms.fields.message',
              defaultMessage: 'Message',
            })}
            placeholder={intl.formatMessage({
              id: 'forms.placeholders.inputMessageHere',
              defaultMessage: 'Input message here',
            })}
          />
          <StyledBox mt='1.5rem'>
            <Button type='submit'>
              <FormattedMessage id='forms.buttons.send' defaultMessage='Send' />
            </Button>
          </StyledBox>
        </Col>
      </Row>
    </form>
  );
};

export default CotactUsPage;
