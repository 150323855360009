import styled from 'styled-components';

export const StyledShippingLabelCard = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem 2rem 2.75rem;
  background-color: ${({ theme }) => theme.colors.shoppingLabelCard.background};
  border-radius: 1rem;
`;

export const StyledShippingLabelCardImageWrapper = styled.figure`
  width: ${({ theme }) => theme.dimensions.shippingLabelCardImage.width};
  height: ${({ theme }) => theme.dimensions.shippingLabelCardImage.height};
  overflow: hidden;
  border-radius: 0.3125rem;
`;

export const StyledShippingLabelCardImage = styled.img`
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
`;

export const StyledShippingLabelCardContent = styled.div`
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? 'margin-right: 1.5rem;'
      : 'margin-left: 1.5rem;'}
`;

export const StyledShippingLabelCardAction = styled.div`
  margin-top: 1.5rem;
  display: flex;
`;

export const StyledPrintButtonWrapper = styled.div`
  ${({ theme }) =>
    theme.direction === 'rtl' ? 'margin-right: 1rem;' : 'margin-left: 1rem;'}
`;
