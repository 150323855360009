import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useEntityContext } from '../../../../../pages/financials/EntityContext/hooks';
import Checkbox from '../../../../atoms/Fields/Checkbox';
import PriceWithCurrency from '../../../../atoms/PriceWithCurrency';
import { PriceWithCurrencySize } from '../../../../atoms/PriceWithCurrency/enums';
import { GET_SETTLEMENT_DETAILS } from './consts';
import PendingBalanceCell from './Partials/PendingBalanceCell';
import { StyledCheckboxWrapper } from './styles';
import {
  EntityListTableItem,
  UseSettlementDetailsReturnType,
  UseTableColumnsReturnType,
} from './types';
import { settlementDetailsToEntityListTableItemMapperFn } from './utils';

export const useSettlementDetails = (): UseSettlementDetailsReturnType => {
  const [data, setData] = useState<EntityListTableItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { mutateAsync } = useMutation(GET_SETTLEMENT_DETAILS);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const { response } = await mutateAsync({});

      if (!response || !response.settlementDetails) {
        return setIsLoading(false);
      }

      setData(
        response.settlementDetails.map(
          settlementDetailsToEntityListTableItemMapperFn
        )
      );

      return setIsLoading(false);
    })();
  }, [mutateAsync]);

  return { data, isLoading };
};

export const useTableColumns = (): UseTableColumnsReturnType => {
  const intl = useIntl();
  const { selectedEntities, onChange } = useEntityContext();

  const tableColumns = useMemo<ColumnDef<EntityListTableItem>[]>(
    () => [
      {
        accessorKey: 'idCol',
        header: '',
        cell: ({ cell: { getValue }, row }) => {
          const { getValue: getCellValue } = row;
          const pendingBalanceCol = getCellValue('pendingBalanceCol');
          const availableBalanceCol = getCellValue('availableBalanceCol');

          const disabled =
            typeof pendingBalanceCol === 'object' || availableBalanceCol === 0;

          return (
            <StyledCheckboxWrapper>
              <Checkbox
                checked={!!selectedEntities.includes(getValue() as any)}
                label=''
                onChange={(event) => onChange(event, getValue() as any)}
                {...{ disabled }}
              />
            </StyledCheckboxWrapper>
          );
        },
        size: 36,
      },
      {
        header: intl.formatMessage({
          id: 'financials.entityTable.nameColumnHeader',
          defaultMessage: 'Name',
        }),
        accessorKey: 'nameCol',
      },
      {
        header: intl.formatMessage({
          id: 'financials.entityTable.availableBalanceColumnHeader',
          defaultMessage: 'Available Balance',
        }),
        accessorKey: 'availableBalanceCol',
        cell: ({ cell: { getValue, row } }) => {
          const { getValue: getCellValue } = row;
          const currency = getCellValue('currency');

          return (
            <PriceWithCurrency
              size={PriceWithCurrencySize.Big}
              price={getValue() as number}
              currency={currency as string}
            />
          );
        },
      },
      {
        header: intl.formatMessage({
          id: 'financials.entityTable.pendingBalanceColumnHeader',
          defaultMessage: 'Pending Balance',
        }),
        accessorKey: 'pendingBalanceCol',
        cell: PendingBalanceCell as any,
      },
    ],
    [intl, onChange, selectedEntities]
  );

  return {
    tableColumns,
    selectedEntities,
    hasSelectedEntities: !!selectedEntities.length,
  };
};
