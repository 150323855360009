import React, { useMemo } from 'react';

import { useIntl } from 'react-intl';
import { getMenuItems, sideMenuAdditionalItemsWithIcons } from './consts';
import LayoutBase from '../LayoutBase';
import { menuItems as settingsMenuItems } from '../SettingsLayout/consts';
import { useSideMenuItems } from '../hooks';
import { SideMenuItem } from '../../molecules/SideMenu/types';
import useUserContext from '../../../hooks/useUserContext';

const MainLayout: React.FC = ({ children }) => {
  const { user } = useUserContext();
  const intl = useIntl();
  const items = useSideMenuItems(
    getMenuItems({
      isAdvancedUser: Boolean(user?.isAdvancedUser),
      isSandoogUser: Boolean(user?.isSandoogUser),
    }) as unknown as SideMenuItem[]
  );
  const menuAdditionalItems = useMemo(
    () => sideMenuAdditionalItemsWithIcons(intl),
    [intl]
  );
  const settingsItems = useSideMenuItems(
    settingsMenuItems as unknown as SideMenuItem[]
  );

  return (
    <LayoutBase
      {...{ items, settingsItems }}
      additionalItems={menuAdditionalItems}
    >
      {children}
    </LayoutBase>
  );
};

export default MainLayout;
