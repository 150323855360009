import React, { useMemo } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalBase from '../..';
import Button from '../../../../atoms/Button';
import { ButtonMode } from '../../../../atoms/Button/enums';
import Textarea from '../../../../atoms/Fields/Textarea';
import Table from '../../../../molecules/Table';
import { StyledBox } from '../../../../styles/Grid/styles';
import Typography from '../../../../styles/Typography';
import { transactionsSummaryTableColumns } from './consts';
import CashoutBox from './partials/CashoutBox';
import {
  StyledForm,
  StyledSearchForm,
  StyledTextareaDescription,
} from './styles';
import Input from '../../../../atoms/Fields/Input';
import { IconSearch } from '../../../../../assets/svg/icons';
import { FieldMode } from '../../../../atoms/Fields/enums';
import {
  useDispute,
  useOrdersSearch,
  useSettlements,
  useSubmitCashOut,
  useUserHasPermissionsToSendDispute,
} from './hooks';
import { ModalBaseProps } from '../../types';
import Loader from '../../../../atoms/Loader';
import { LoaderMode } from '../../../../atoms/Loader/enums';

const TransactionsSummaryModal: React.FC<ModalBaseProps> = (props) => {
  const { onClose } = props;
  const intl = useIntl();

  const hasPermissionsToSendDispute = useUserHasPermissionsToSendDispute();
  const { settlements, availableBalance, availableBalanceCurrency, isLoading } =
    useSettlements();
  const submitCashOut = useSubmitCashOut(onClose);

  const [result, { query, handleQueryChange }] = useOrdersSearch(settlements);
  const [submitDispute, { dispute, handleDisputeChange }] = useDispute();

  const tableColumns = useMemo(
    () => transactionsSummaryTableColumns(intl),
    [intl]
  );

  return (
    <ModalBase {...props}>
      <Container>
        <Row>
          <Col md={12}>
            <StyledBox alignItems='center' mb='2.5rem'>
              <Typography variant='h2' as='h2'>
                <FormattedMessage
                  id='financials.transactionSummary'
                  defaultMessage='Transaction Summary'
                />
              </Typography>
              <StyledSearchForm>
                <Input
                  mode={FieldMode.Tertiary}
                  placeholder={intl.formatMessage({
                    id: 'search.searchFulfilledOrders',
                    defaultMessage: 'Search Fulfilled Orders',
                  })}
                  value={query}
                  onChange={handleQueryChange}
                  isRounded
                  prefixComponent={<IconSearch />}
                />
              </StyledSearchForm>
            </StyledBox>
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            {isLoading ? (
              <Loader mode={LoaderMode.Table} />
            ) : (
              <Table data={result} columns={tableColumns} disabledPagination />
            )}
          </Col>
          <Col md={5}>
            <StyledBox mb='2rem'>
              {isLoading ? (
                <Loader mode={LoaderMode.CashoutBox} />
              ) : (
                <CashoutBox
                  price={availableBalance}
                  currency={availableBalanceCurrency}
                  buttonLabel={intl.formatMessage({
                    id: 'financials.submitCashoutRequest',
                    defaultMessage: 'Submit Cashout Request',
                  })}
                  onButtonClick={submitCashOut}
                />
              )}
            </StyledBox>
            {hasPermissionsToSendDispute && (
              <StyledForm>
                <StyledBox mb='1rem'>
                  <Typography variant='bodyBold' as='h3'>
                    <FormattedMessage
                      id='financials.textareaLabel'
                      defaultMessage='Problem with the amount above?'
                    />
                  </Typography>
                </StyledBox>
                <Textarea value={dispute} onChange={handleDisputeChange} />
                <StyledTextareaDescription>
                  <FormattedMessage
                    id='financials.textareaDescription'
                    defaultMessage='If there are issues with the transactions or the overall amount please describe below and submit'
                  />
                </StyledTextareaDescription>
                <StyledBox mb='1.25rem' />
                <Button
                  mode={ButtonMode.Secondary}
                  type='button'
                  onClick={submitDispute}
                >
                  <FormattedMessage
                    id='financials.submitADispute'
                    defaultMessage='Submit a dispute'
                  />
                </Button>
              </StyledForm>
            )}
          </Col>
        </Row>
      </Container>
    </ModalBase>
  );
};

export default TransactionsSummaryModal;
