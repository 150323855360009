import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import noop from 'lodash/noop';
import { FormattedMessage } from 'react-intl';
import ReactJson from '@microlink/react-json-view';
import ModalBase from '../..';
import { WebhookLogsModalProps } from './types';
import { StyledBox } from '../../../../styles/Grid/styles';
import Typography from '../../../../styles/Typography';

import { useWebhookLogs } from './hooks';
import Loader from '../../../../atoms/Loader';
import { LoaderMode } from '../../../../atoms/Loader/enums';
import { StyledOrderIdLabel } from '../OrderSummaryModal/styles';
import Accordion from '../../../../molecules/Accordion';
import { dateTimeFormat } from '../../../../atoms/Fields/Datepicker/utils';

const WebhookLogsModal: React.FC<WebhookLogsModalProps> = (props) => {
  // Props
  const { order, onClose = noop } = props;

  // Hooks
  const { data, loading } = useWebhookLogs(order.id);

  return (
    <ModalBase {...{ ...props, onClose }} withCloseButton>
      <Container>
        {loading ? (
          <Loader mode={LoaderMode.CustomerSummary} />
        ) : (
          <>
            {/* Header */}
            <Row>
              <Col sm={12}>
                <StyledBox flexDirection='column'>
                  <Typography variant='h2' as='h2'>
                    <FormattedMessage
                      id='webhookLogs'
                      defaultMessage='Webhook Logs'
                    />
                  </Typography>
                  <StyledBox marginTop='0.25em'>
                    <StyledOrderIdLabel>
                      <FormattedMessage
                        id='orders.orderIdLabel'
                        defaultMessage='Order ID'
                      />
                    </StyledOrderIdLabel>
                    <Typography>{order.id}</Typography>
                  </StyledBox>
                  <StyledBox marginTop='0.25em'>
                    <StyledOrderIdLabel>
                      <FormattedMessage
                        id='orders.receiptNo'
                        defaultMessage='Receipt No'
                      />
                    </StyledOrderIdLabel>
                    <Typography>{order.externalReference}</Typography>
                  </StyledBox>
                </StyledBox>
              </Col>
            </Row>

            {/* Content */}
            <Row>
              <Col sm={12}>
                {data.length ? (
                  <StyledBox flexDirection='column' gap='1rem' mt='0.75rem'>
                    {data.map((log, idx) => (
                      <Accordion
                        key={idx}
                        title={dateTimeFormat(log.eventTimestamp)}
                      >
                        <StyledBox flexDirection='column' gap='1.5rem'>
                          <div>
                            <Typography variant='h2'>Request Header</Typography>
                            {log.requestHeader ? (
                              <ReactJson
                                src={JSON.parse(log.requestHeader)}
                                theme='monokai'
                                style={{
                                  padding: '20px',
                                  marginTop: '5px',
                                  borderRadius: '5px',
                                }}
                                enableClipboard={false}
                                displayDataTypes={false}
                                displayObjectSize={false}
                              />
                            ) : (
                              '-'
                            )}
                          </div>

                          <div>
                            <Typography variant='h2'>Request Body</Typography>
                            {log.requestBody ? (
                              <ReactJson
                                src={JSON.parse(log.requestBody)}
                                theme='monokai'
                                style={{
                                  padding: '20px',
                                  marginTop: '5px',
                                  borderRadius: '5px',
                                }}
                                enableClipboard={false}
                                displayDataTypes={false}
                                displayObjectSize={false}
                              />
                            ) : null}
                          </div>

                          <div>
                            <Typography variant='h2'>Response Body</Typography>
                            {log.responseBody ? (
                              <ReactJson
                                src={JSON.parse(log.responseBody)}
                                theme='monokai'
                                style={{
                                  padding: '20px',
                                  marginTop: '5px',
                                  borderRadius: '5px',
                                }}
                                enableClipboard={false}
                                displayDataTypes={false}
                                displayObjectSize={false}
                              />
                            ) : null}
                          </div>
                        </StyledBox>
                      </Accordion>
                    ))}
                  </StyledBox>
                ) : (
                  <StyledBox mt='1rem'>
                    <Typography variant='h2'>
                      <FormattedMessage id='noDataFount' />
                    </Typography>
                  </StyledBox>
                )}
              </Col>
            </Row>
          </>
        )}
      </Container>
    </ModalBase>
  );
};

export default WebhookLogsModal;
