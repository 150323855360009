import axios from 'axios';
import get from 'lodash/get';
import { useState, ChangeEventHandler, useCallback, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useDebounce } from 'use-debounce';
import { makeApiUrl } from '../../utils/reactQuery';
import { PageInfo, UseSearchOptions, UseSearchReturnType } from './types';

const DEBOUNCE_DELAY_MILLISECONDS = 500;
const PAGE_SIZE = 10;
const FIRST_PAGE_INDEX = 1;

const useSearch = <TResult extends PageInfo>({
  apiRoute,
  debounce = DEBOUNCE_DELAY_MILLISECONDS,
  pageSize = PAGE_SIZE,
  pageIndex = FIRST_PAGE_INDEX,
  params = {},
}: UseSearchOptions): UseSearchReturnType<TResult> => {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, debounce);

  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery<UseQueryResponse<TResult>>(
      [
        apiRoute,
        {
          query: debouncedQuery,
          pageSize,
        },
      ],
      async ({ queryKey, pageParam = pageIndex }) => {
        const [_, variables] = queryKey;
        const { data: res, headers } = await axios.get(makeApiUrl(apiRoute), {
          params: {
            query: get(variables, 'query'),
            pageIndex: pageParam,
            pageSize: get(variables, 'pageSize'),
            ascending: true,
            ...params,
          },
        });

        return {
          response: res,
          headers,
        };
      },
      {
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage.response.pageIndex + 1 <= lastPage.response.pagesCount
            ? lastPage.response.pageIndex + 1
            : undefined,
      }
    );

  const handleQueryChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { value } = event.target;

      setQuery(value);
    },
    []
  );

  const fetchMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return {
    query,
    handleQueryChange,
    isLoading: isFetching,
    result: useMemo(() => {
      if (!data) {
        return undefined;
      }

      return data.pages;
    }, [data]),
    fetchMore,
    hasMore: !!hasNextPage,
    isFetchingNextPage,
  };
};

export default useSearch;
