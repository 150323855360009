import { motion } from 'framer-motion';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { textStyles } from '../../../../theme/config/typography';

export const StyledMultiSelectWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledMultiSelectButton = styled.button.attrs({
  variant: 'caption',
})`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.multiSelect.background};
  box-shadow: ${({ theme }) => theme.shadows.dropdown};
  border-radius: 1rem;
  border: 0;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  text-align: left;
  ${variant({
    variants: {
      ...textStyles,
    },
  })};
  &[disabled],
  &:disabled {
    pointer-events: none;
    opacity: 0.8;
  }
`;

export const StyledMultiSelectButtonText = styled.span`
  display: inline-block;
  width: calc(100% - 1.75rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledMultiSelectButtonDecor = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 1rem;
  color: inherit;
  svg {
    height: 1em;
    width: 1em;
    font-size: 0.75rem;
    fill: currentColor;
  }
`;

export const StyledMultiSelectContent = styled(motion.div).attrs({
  key: 'multiselect-dropdown',
  initial: { opacity: 0, y: '-16px' },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: '-16px' },
  transition: { duration: 0.2, type: 'tween' },
})`
  width: calc(100% + 10rem);
  position: absolute;
  top: calc(100% + 0.5rem);
  background-color: ${({ theme }) => theme.colors.multiSelect.background};

  box-shadow: ${({ theme }) => theme.shadows.mid};
  border-radius: 1rem;
  padding: 1rem;
  max-height: 20rem;
  overflow: auto;
  z-index: 1;
`;
