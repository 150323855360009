import styled from 'styled-components';
import { TagStatus } from './enums';
import { TagProps } from './types';

export const StyledTag = styled.div<TagProps>`
  display: inline-flex;
  align-items: center;
  line-height: 1.25;

  &::before {
    content: '';
    width: ${({ theme }) => theme.dimensions.tag.dot.size};
    height: ${({ theme }) => theme.dimensions.tag.dot.size};
    margin-inline-end: 5px;
    background: ${({ theme, status = TagStatus.Success, color }) =>
      color || theme.colors.tag[status].background};
    border-radius: 100%;
    flex-shrink: 0;
  }
`;
