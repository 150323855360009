import noop from 'lodash/noop';
import React from 'react';
import { useIntl } from 'react-intl';
import ModalBase from '../..';
import { IconUpload } from '../../../../../assets/svg/icons';
import Avatar from '../../../../atoms/Avatar';
import { AvatarSize } from '../../../../atoms/Avatar/enums';
import Button from '../../../../atoms/Button';
import { ButtonMode } from '../../../../atoms/Button/enums';
import Spinner from '../../../../atoms/Spinner';
import { SpinnerMode } from '../../../../atoms/Spinner/enums';
import { StyledBox } from '../../../../styles/Grid/styles';
import Typography from '../../../../styles/Typography';
import { getMessageForStatus } from './consts';
import { UploadModalStatus } from './enums';
import { useOrderUploadStatus } from './hooks';
import { StyledUploadOrderStatus } from './styles';
import { UploadOrderStatusModalProps } from './types';

const UploadOrderModal: React.FC<UploadOrderStatusModalProps> = ({
  onClose,
  taskId,
  closeButtonLabel,
  onUploadCompleted = noop,
  ...props
}) => {
  const intl = useIntl();
  const { ordersCount, status, message } = useOrderUploadStatus(
    taskId,
    onUploadCompleted
  );

  return (
    <ModalBase isPopup {...props}>
      <StyledBox mb='1rem' justifyContent='center'>
        <Avatar
          size={AvatarSize.Medium}
          noBorder
          placeholder={<IconUpload />}
        />
      </StyledBox>
      <StyledBox mb='1rem' justifyContent='center'>
        <StyledUploadOrderStatus {...{ status }}>
          <Typography textAlign='center' variant='bodyBold'>
            {UploadModalStatus.Failed === status
              ? message
              : getMessageForStatus(status, ordersCount, intl)}
          </Typography>
        </StyledUploadOrderStatus>
      </StyledBox>
      {status === UploadModalStatus.Loading ? (
        <StyledBox justifyContent='center'>
          <Spinner mode={SpinnerMode.Dark} />
        </StyledBox>
      ) : (
        <StyledBox justifyContent='center'>
          <Button onClick={onClose} mode={ButtonMode.Secondary}>
            {closeButtonLabel}
          </Button>
        </StyledBox>
      )}
    </ModalBase>
  );
};

export default UploadOrderModal;
