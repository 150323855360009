import colors from './config/colors';
import {
  fontSizes,
  fontWeights,
  lineHeights,
  fonts,
  textStyles,
} from './config/typography';

const theme = {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  fonts,
  textStyles,
  transitions: {
    default: '250ms ease-in-out',
  },
  zindexes: {
    loaderOverlay: 21,
    autocomplete: 20,
    modal: 15,
    search: 10,
    bar: 5,
    stickyHeader: 10,
    selectMenu: 25,
  },
  shadows: {
    small: '0px 2px 4px rgba(172, 191, 205, 0.26)',
    mid: '0px 4px 8px rgba(228, 231, 242, 0.5)',
    modal: '0px -8px 15px rgba(0, 0, 0, 0.21)',
    dropdown: '0px 4px 15px rgba(0, 0, 0, 0.06)',
    bar: '0px -4px 15px rgba(0, 0, 0, 0.11)',
  },
  dimensions: {
    searchForm: {
      width: '30rem',
    },
    input: {
      height: { small: '2.5rem', big: '3.125rem' },
      prefixWidth: { small: '3rem', big: '3.75rem' },
      suffixWidth: { small: '3rem', big: '3.75rem' },
    },
    textarea: {
      height: '11.25rem',
    },
    avatar: { size: { small: '2.625rem', medium: '4.25rem', big: '5.7rem' } },
    button: {
      height: { small: '2rem', big: '2.5rem' },
    },
    buttonClose: {
      width: '3.5rem',
      height: '3.5rem',
    },
    sidebar: {
      width: '14rem',
      list: {
        minHeight: '13.875rem',
      },
    },
    tag: {
      dot: { size: '0.8125rem' },
    },
    placeholder: {
      maxWidth: '25.25rem',
      decorWrapper: {
        big: '11.375rem',
        small: '6.3125rem',
      },
      icon: {
        big: '5.6875rem',
        small: '3.25rem',
      },
    },
    menuDropdown: {
      minWidth: '12.5rem',
    },
    signInBox: {
      maxWidth: '35rem',
    },
    signUpWrapper: {
      maxWidth: '82rem',
    },
    signUpBox: {
      maxWidth: '56rem',
    },
    spinner: {
      extraSmall: '0.12rem',
      big: '0.5rem',
      small: '0.25rem',
    },
    contactLink: {
      maxWidth: '25.375rem',
    },
    productOrderCard: {
      minHeight: '7.125rem',
    },
    productOrderCardList: {
      maxWidth: '69.875rem',
    },
    productThumbnail: {
      big: '5rem',
      small: '3.125rem',
    },
    orderApprovalBar: {
      maxWidth: '80rem',
    },
    shippingLabelCardImage: {
      width: '6.0625rem',
      height: '6.5rem',
    },
    ordersSummaryContactList: {
      maxWidth: '26rem',
    },
    googleMap: {
      width: '17.5rem',
      height: '14.8rem',
    },
    modalPopup: {
      maxWidth: '25.5rem',
      iconWrapper: {
        width: '3.5rem',
        height: '3.5rem',
      },
    },
    warehouseProductCard: {
      minHeight: '7.125rem',
    },
    imageSlider: {
      imageWrapper: {
        height: '15.375rem',
      },
      paginationBullet: {
        width: '0.625rem',
        height: '0.625rem',
      },
    },
  },
};

export const rtlTheme = {
  direction: 'rtl',
  ...theme,
};

export const ltrTheme = {
  direction: 'ltr',
  ...theme,
};

export default theme;

export type Theme = typeof ltrTheme;

export type WithTheme<T = unknown> = { theme: Theme } & T;
