import React from 'react';
import { useIntl } from 'react-intl';
import { StatusSelectProps } from './types';
import MultiSelect from '../../../MultiSelect';
import { MultiSelectProps } from '../../../MultiSelect/types';
import renderRadioItemWithCheckboxes from '../../../Radio/content/withCheckboxes';
import { StyledStatusSelectWrapper } from './styles';
import Tag from '../../../../Tag';
import { StyledBox } from '../../../../../styles/Grid/styles';

const StatusSelect: React.FC<StatusSelectProps> = ({
  options,
  placeholder,
  label: fieldLabel,
  disabled,
  value,
  onChange,
}) => {
  const intl = useIntl();

  const renderSelectedItems: MultiSelectProps['renderSelectedItems'] = (
    selected
  ) =>
    renderRadioItemWithCheckboxes(
      selected,
      true,
      onChange
    )({
      label: intl.formatMessage({
        id: 'orders.orderStatus',
      }),
      value: 'status',
      checkboxes: options,
      checked: true,
    });

  const SelectedValuesPlaceholder = () => {
    const getOption = (v: string) => options.find((o) => o.value === v);

    return (
      <>
        {value.map((val, idx) => (
          <StyledBox mb={idx !== value.length - 1 ? '0.5rem' : ''}>
            <Tag color={getOption(val)?.color}>{getOption(val)?.label}</Tag>
          </StyledBox>
        ))}
      </>
    );
  };

  return (
    <StyledStatusSelectWrapper>
      <MultiSelect
        placeholder={value.length ? <SelectedValuesPlaceholder /> : placeholder}
        items={[
          {
            checkboxes: options.map((o) => ({
              label: o.label,
              value: o.value,
            })),
            value: fieldLabel || 'status',
          },
        ]}
        value={{
          checkedSubItems: value,
          value: fieldLabel || 'status',
        }}
        disabled={disabled}
        renderSelectedItems={renderSelectedItems}
      />
    </StyledStatusSelectWrapper>
  );
};

export default StatusSelect;
