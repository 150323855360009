export enum PreferredPayment {
  BankTransfer = 'BankTransfer',
  MoneyTransfer = 'MoneyTransfer',
  Cash = 'Cash',
  Check = 'Check',
}

export enum PreferredCurrency {
  IQD = 'IQD',
  USD = 'USD',
}

export enum OrderTaskStatus {
  Running = 'Running',
  Completed = 'Completed',
  Failed = 'Failed',
}
