import React, { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import ReactTooltip from 'react-tooltip';
import { WarehouseProductCardProps } from './types';
import {
  StyledWarehouseCategoryNameLabel,
  StyledWarehouseProductCard,
  StyledWarehouseProductCardAction,
  StyledWarehouseProductCardDamagedUnitsWrapper,
  StyledWarehouseProductCardDateCreatedWrapper,
  StyledWarehouseProductCardImageWrapper,
  StyledWarehouseProductCardInfoGroup,
  StyledWarehouseProductCardNameWrapper,
  StyledWarehouseProductCardOrderedWrapper,
  StyledWarehouseProductCardPriceWrapper,
  StyledWarehouseProductCardRemainingUnitsWrapper,
  StyledWarehouseProductCurrency,
  StyledWarehouseProductLabel,
  StyledWarehouseProductNameLabel,
  StyledWarehouseProductPrice,
} from './styles';
import Button from '../../atoms/Button';
import { ButtonMode } from '../../atoms/Button/enums';
import IconButton from '../../atoms/IconButton';
import { IconEdit, WarningIcon } from '../../../assets/svg/icons';
import Typography from '../../styles/Typography';
import ProductThumbnail from '../../atoms/ProductThumbnail';
import ImageSlider from '../ImageSlider';
import routes from '../../../setup/consts/routes';
import { StyledBox } from '../../styles/Grid/styles';
import DamagedProductTransactions from '../DamagedProductTransactions';
import useToggle from '../../../hooks/useToggle';

const WarehouseProductCard: React.FC<WarehouseProductCardProps> = (props) => {
  // ** Props
  const {
    isHorizontal,
    images,
    coverImage,
    productName,
    categoryName,
    orderedQuantity,
    onHandQuantity,
    productId,
    createdOn,
    sku,
    priceIqd,
    damagedQuantity,
  } = props;

  // ** Hooks
  const history = useHistory();
  const [
    damagedProductTransactionsModalIsOpen,
    { toggle: toggleDamagedProductTransactionsModal },
  ] = useToggle();

  // ** Vars
  const formattedDate = createdOn.includes('-')
    ? createdOn
    : new Date(createdOn).toLocaleDateString();
  const formattedPrice = Intl.NumberFormat().format(priceIqd);

  // ** Handlers
  const onEditButtonClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    history.push(`/warehouse/product/${productId}`);
  };

  const onCreateOrderButtonClick: MouseEventHandler = (e) => {
    e.stopPropagation();

    history.push(
      generatePath(routes.PLACE_PRODUCT_ORDER, {
        productId,
      })
    );
  };

  const onClickProductCard = () => {
    history.push(
      generatePath(routes.SHOW_PRODUCT, {
        id: productId,
      })
    );
  };

  const onDamagedButtonClick = (e: any) => {
    e.stopPropagation();
    toggleDamagedProductTransactionsModal();
  };

  const infoFields = (
    <>
      {/* Ordered */}
      <StyledWarehouseProductCardOrderedWrapper
        {...{ isHorizontal }}
        onClick={isHorizontal ? onClickProductCard : undefined}
      >
        <StyledWarehouseProductLabel>
          <FormattedMessage id='warehouse.ordered' defaultMessage='Ordered' />
        </StyledWarehouseProductLabel>
        <Typography as='span' variant={isHorizontal ? 'body' : 'captionBold'}>
          {orderedQuantity}
        </Typography>
      </StyledWarehouseProductCardOrderedWrapper>

      {/* Remaining */}
      <StyledWarehouseProductCardRemainingUnitsWrapper {...{ isHorizontal }}>
        <StyledWarehouseProductLabel>
          <FormattedMessage
            id='warehouse.remaining'
            defaultMessage='Remaining'
          />
        </StyledWarehouseProductLabel>

        <StyledBox gap='0.5rem' alignItems='end'>
          <Typography as='span' variant={isHorizontal ? 'body' : 'captionBold'}>
            {onHandQuantity}
          </Typography>
        </StyledBox>
      </StyledWarehouseProductCardRemainingUnitsWrapper>

      {/* Damaged */}
      <StyledWarehouseProductCardDamagedUnitsWrapper {...{ isHorizontal }}>
        <StyledWarehouseProductLabel>
          <FormattedMessage id='warehouse.damaged' defaultMessage='Damaged' />
        </StyledWarehouseProductLabel>

        <StyledBox gap='0.5rem' alignItems='end'>
          <Typography as='span' variant={isHorizontal ? 'body' : 'captionBold'}>
            {damagedQuantity}
          </Typography>

          {damagedQuantity ? (
            <IconButton
              style={{ width: '20px', height: '20px', border: 0 }}
              icon={WarningIcon}
              onClick={onDamagedButtonClick}
            />
          ) : null}
        </StyledBox>
      </StyledWarehouseProductCardDamagedUnitsWrapper>

      {/* Created Date */}
      {isHorizontal ? (
        <StyledWarehouseProductCardDateCreatedWrapper {...{ isHorizontal }}>
          <StyledWarehouseProductLabel>
            <FormattedMessage id='warehouse.created' defaultMessage='Created' />
          </StyledWarehouseProductLabel>

          <Typography as='span' variant={isHorizontal ? 'body' : 'captionBold'}>
            {formattedDate}
          </Typography>
        </StyledWarehouseProductCardDateCreatedWrapper>
      ) : null}
    </>
  );

  return (
    <>
      <AnimatePresence>
        {damagedProductTransactionsModalIsOpen ? (
          <DamagedProductTransactions
            productId={productId}
            onClose={toggleDamagedProductTransactionsModal}
          />
        ) : null}
      </AnimatePresence>

      <StyledWarehouseProductCard {...{ isHorizontal }}>
        <StyledWarehouseProductCardImageWrapper {...{ isHorizontal }}>
          {isHorizontal ? (
            <ProductThumbnail isBig src={coverImage} alt={productName} />
          ) : (
            <ImageSlider
              images={[coverImage, ...images].map((imageSrc) => ({
                alt: productName,
                key: imageSrc,
                src: imageSrc,
              }))}
            />
          )}
        </StyledWarehouseProductCardImageWrapper>

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}

        <StyledWarehouseProductCardNameWrapper
          {...{ isHorizontal }}
          onClick={onClickProductCard}
        >
          <StyledBox alignItems='center' justifyContent='space-between'>
            {/* Category Name */}
            <div data-tip={categoryName?.length > 35 ? categoryName : null}>
              <StyledWarehouseCategoryNameLabel isHorizontal={isHorizontal}>
                {categoryName || '-'}
              </StyledWarehouseCategoryNameLabel>

              <ReactTooltip multiline />
            </div>

            {/* Created Date */}
            {!isHorizontal ? (
              <StyledWarehouseProductLabel>
                {formattedDate}
              </StyledWarehouseProductLabel>
            ) : null}
          </StyledBox>

          {/* Sku */}
          <Typography as='span'>{sku}</Typography>

          {/* Product Name */}
          <div data-tip={productName?.length > 35 ? productName : null}>
            <StyledWarehouseProductNameLabel isHorizontal={isHorizontal}>
              {productName || '-'}
            </StyledWarehouseProductNameLabel>

            <ReactTooltip multiline />
          </div>
        </StyledWarehouseProductCardNameWrapper>

        {isHorizontal ? (
          infoFields
        ) : (
          <StyledWarehouseProductCardInfoGroup
            {...{ isHorizontal }}
            onClick={onClickProductCard}
          >
            {infoFields}
          </StyledWarehouseProductCardInfoGroup>
        )}
        <StyledWarehouseProductCardPriceWrapper
          {...{ isHorizontal }}
          onClick={onClickProductCard}
        >
          {isHorizontal && (
            <StyledWarehouseProductLabel>
              <FormattedMessage id='warehouse.price' defaultMessage='Price' />
            </StyledWarehouseProductLabel>
          )}
          <StyledWarehouseProductPrice onClick={onClickProductCard}>
            <Typography variant={isHorizontal ? 'body' : 'h2'} as='span'>
              {formattedPrice}
            </Typography>
            <StyledWarehouseProductCurrency>IQD</StyledWarehouseProductCurrency>
          </StyledWarehouseProductPrice>
        </StyledWarehouseProductCardPriceWrapper>
        <StyledWarehouseProductCardAction
          {...{ isHorizontal }}
          onClick={onClickProductCard}
        >
          {/* Edit */}
          <IconButton icon={IconEdit} onClick={onEditButtonClick} />

          <Button
            mode={ButtonMode.Secondary}
            onClick={onCreateOrderButtonClick}
          >
            <FormattedMessage
              id='warehouse.productCardButtonLabel'
              defaultMessage='Create an order'
            />
          </Button>
        </StyledWarehouseProductCardAction>
      </StyledWarehouseProductCard>
    </>
  );
};

export default WarehouseProductCard;
