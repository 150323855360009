import React from 'react';
import { CheckboxProps } from './types';
import {
  StyledCheckbox,
  StyledCheckboxIcon,
  StyledCheckboxLabel,
  StyledCheckboxLabelText,
  StyledColorTag,
} from './styles';
import { IconTick } from '../../../../assets/svg/icons';

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  tagColor,
  disabled,
  ...props
}) => (
  <StyledCheckboxLabel {...{ disabled }}>
    <StyledCheckbox type='checkbox' {...{ disabled, ...props }} />

    <StyledCheckboxIcon>
      <IconTick />
    </StyledCheckboxIcon>

    <StyledCheckboxLabelText>
      {tagColor ? <StyledColorTag tagColor={tagColor} /> : null}
      {label}
    </StyledCheckboxLabelText>
  </StyledCheckboxLabel>
);

export default Checkbox;
