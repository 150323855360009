import styled from 'styled-components';
import Typography from '../../../../../components/styles/Typography';

export const StyledFaqList = styled.ul`
  width: 100%;
`;

export const StyledFaqListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }
`;

export const StyledQuestionList = styled.ul`
  width: 100%;
`;

export const StyledQuestionListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const StyledHeading = styled(Typography).attrs({
  forwardedAs: 'h3',
  variant: 'bodyBold',
})`
  margin-bottom: 1rem;
`;

export const StyledQuestionListAnswer = styled(Typography).attrs({
  variant: 'body',
})`
  strong {
    font-weight: 500;
  }

  a {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;
