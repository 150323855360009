import React from 'react';
import { StyledButtonExternalLink } from './styles';
import { ButtonExternalLinkProps } from './types';
import { ButtonMode, ButtonSize } from './enums';

const ButtonExternalLink: React.FC<ButtonExternalLinkProps> = ({
  mode = ButtonMode.Primary,
  size = ButtonSize.Big,
  icon: Icon,
  children,
  ...props
}) => {
  const buttonTextVariant = size === ButtonSize.Big ? 'body' : 'caption';

  return (
    <StyledButtonExternalLink
      variant={buttonTextVariant}
      {...{ mode, size, ...props }}
    >
      {Icon && <Icon />}
      {children}
    </StyledButtonExternalLink>
  );
};

export default ButtonExternalLink;
