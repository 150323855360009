import styled from 'styled-components';

export const StyledForm = styled.form`
  width: 100%;
`;

export const StyledAction = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 1rem;
`;
