import React from 'react';
import { BalanceBoxProps } from './types';
import {
  StyledBalanceBox,
  StyledBalanceBoxIconWrapper,
  StyledBalanceBoxTitle,
} from './styles';
import PriceWithCurrency from '../../atoms/PriceWithCurrency';
import { StyledBox } from '../../styles/Grid/styles';
import { PriceWithCurrencySize } from '../../atoms/PriceWithCurrency/enums';

const BalanceBox: React.FC<BalanceBoxProps> = ({
  icon: Icon,
  price,
  currency,
  title,
  children,
}) => (
  <StyledBalanceBox>
    <StyledBox flexDirection='column' alignItems='center'>
      <StyledBalanceBoxIconWrapper>
        <Icon />
      </StyledBalanceBoxIconWrapper>
      <StyledBox mb='0.75rem'>
        <PriceWithCurrency
          size={PriceWithCurrencySize.Big}
          {...{ price, currency }}
        />
      </StyledBox>
      <StyledBalanceBoxTitle>{title}</StyledBalanceBoxTitle>
    </StyledBox>
    {children && (
      <StyledBox alignItems='center' flexDirection='column'>
        {children}
      </StyledBox>
    )}
  </StyledBalanceBox>
);

export default BalanceBox;
