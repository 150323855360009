import styled from 'styled-components';
import Typography from '../../../../../components/styles/Typography';

export const StyledProductDetailLabel = styled(Typography).attrs({
  forwardedAs: 'span',
  variant: 'caption',
})`
  margin-bottom: 0.75rem;
  display: block;
`;

export const StyledProductDetailBox = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledProductDetailValue = styled(Typography).attrs({
  variant: 'body',
})`
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledProductImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
`;

export const StyledProductImageBox = styled.div`
  border-radius: 0.5rem;
  width: 150px;
  height: 150px;
`;
