import { useQuery } from 'react-query';
import qs from 'qs';
import urljoin from 'url-join';
import { CustomerSummaryResponse, UseCustomerSummaryReturnType } from './types';
import apiRoutes from '../../../../../setup/consts/apiRoutes';

export const useCustomerSummary = (
  customerId: string | undefined
): UseCustomerSummaryReturnType => {
  const queryAsString = qs.stringify(
    {
      customerId,
      pageSize: 20,
    },
    {
      addQueryPrefix: true,
    }
  );

  const { data, isLoading } = useQuery<
    UseQueryResponse<CustomerSummaryResponse>
  >(urljoin(apiRoutes.CUSTOMER_SUMMARY, queryAsString), {
    enabled: !!customerId,
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  });

  return {
    orders: data?.response.orders || [],
    customer: data?.response.customer || null,
    loading: isLoading,
  };
};
