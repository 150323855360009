import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useInfiniteQuery, useQueryClient } from 'react-query';

import {
  AUTH_TOKENS_LOCAL_STORAGE_KEY,
  ROLE_TYPE,
} from '../../../contexts/User/consts';
import { useAuthTokens } from '../../../contexts/User/hooks';
import { AuthTokensShape } from '../../../contexts/User/types';
import useCookiesContext from '../../../hooks/useCookiesContext';
import useCookieValue from '../../../hooks/useCookieValue';
import useGlobalLoaderContext from '../../../hooks/useGlobalLoaderContext';
import {
  removeLocalStorageValue,
  setLocalStorageValue,
} from '../../../hooks/useLocalStorageValue/utils';
import {
  GetClientsResponse,
  GetClientTokenResponse,
  ListClientModel,
} from '../../../setup/apiTypes/auth';
import apiRoutes from '../../../setup/consts/apiRoutes';
import {
  createClientSelectValueCookie,
  getExpirationDate,
} from '../../../utils/auth';
import { ClientSelectValueCookieShape } from '../../../utils/auth/types';
import { makeApiUrl } from '../../../utils/reactQuery';
import { MultiSelectValue } from '../../atoms/Fields/MultiSelect/types';
import { RadioItemWithCheckboxesProps } from '../../atoms/Fields/Radio/content/withCheckboxes/types';
import { CLIENTS_PAGE_SIZE, CLIENT_SELECT_VALUE_COOKIE_NAME } from './consts';
import { UseClientsDropdownItemsReturnType } from './types';

export const useOnLogoutClick = (): (() => void) => {
  const cookies = useCookiesContext();

  return useCallback(() => {
    removeLocalStorageValue(AUTH_TOKENS_LOCAL_STORAGE_KEY);
    removeLocalStorageValue(ROLE_TYPE);
    cookies.remove(CLIENT_SELECT_VALUE_COOKIE_NAME, { path: '/' });
  }, [cookies]);
};

export const useClientsDropdownItems =
  (): UseClientsDropdownItemsReturnType => {
    const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<
      UseQueryResponse<GetClientsResponse>
    >(
      apiRoutes.CLIENTS,
      async ({ pageParam }) => {
        const { data: response, headers } = await axios.get(
          makeApiUrl(apiRoutes.CLIENTS),
          {
            params: {
              page: pageParam,
              pageSize: CLIENTS_PAGE_SIZE,
            },
          }
        );

        return {
          response,
          headers,
        };
      },
      {
        keepPreviousData: true,
        getNextPageParam: (lastPage) => {
          const {
            response: { currentPage, totalPages },
          } = lastPage;

          return currentPage + 1 <= totalPages ? currentPage + 1 : undefined;
        },
      }
    );
    const cookies = useCookiesContext();

    return {
      items: useMemo(() => {
        if (!data) {
          return [];
        }

        const { pages } = data;

        const clients = pages.reduce<ListClientModel>((acc, page) => {
          const {
            response: { clients: pageData },
          } = page;

          return [...acc, ...pageData];
        }, []);

        return clients.map(({ id, name, logoUrl, entities }) => ({
          value: id,
          label: name,
          decor: logoUrl || undefined,
          checkboxes: entities.map((entity) => ({
            label: entity.name,
            value: entity.id,
            onChange: async () => {
              const currentValue = cookies.get<
                ClientSelectValueCookieShape | undefined
              >(CLIENT_SELECT_VALUE_COOKIE_NAME, { doNotParse: false });

              if (!currentValue || currentValue.clientId !== id) {
                const newValue = createClientSelectValueCookie(
                  id,
                  entities.map(({ id: entityId }) => entityId)
                );

                cookies.set(CLIENT_SELECT_VALUE_COOKIE_NAME, newValue, {
                  path: '/',
                });

                return;
              }

              const currentEntities = currentValue?.entities || [];

              const newValue = (() => {
                const filteredEntities = currentEntities.filter(
                  (entityId) => entityId !== entity.id
                );

                if (currentEntities.length !== entities.length) {
                  return createClientSelectValueCookie(
                    id,
                    currentEntities.includes(entity.id)
                      ? filteredEntities
                      : [...currentEntities, entity.id]
                  );
                }

                return createClientSelectValueCookie(id, filteredEntities);
              })();

              cookies.set(CLIENT_SELECT_VALUE_COOKIE_NAME, newValue, {
                path: '/',
              });
            },
          })),
        }));
      }, [data, cookies]),
      isLoading,
      fetchMore: fetchNextPage,
      hasNextPage: !!hasNextPage,
    };
  };

export const useOnClientSelectChange = (): ((
  value: RadioItemWithCheckboxesProps
) => void) => {
  const cookies = useCookiesContext();

  return useCallback(
    async ({ value, checkboxes }) => {
      const clientSelectValueCookie = createClientSelectValueCookie(
        value,
        checkboxes.map(({ value: entityId }) => entityId)
      );

      cookies.set(CLIENT_SELECT_VALUE_COOKIE_NAME, clientSelectValueCookie, {
        path: '/',
      });
    },
    [cookies]
  );
};

export const useClientSelectValue = (): MultiSelectValue | undefined => {
  const clientSelectValueCookie = useCookieValue<
    ClientSelectValueCookieShape | undefined
  >(CLIENT_SELECT_VALUE_COOKIE_NAME);

  return useMemo(() => {
    if (!clientSelectValueCookie) {
      return undefined;
    }

    const { clientId, entities } = clientSelectValueCookie;

    return {
      value: clientId,
      checkedSubItems: entities,
    };
  }, [clientSelectValueCookie]);
};

export const useRefetchQueriesOnClientChange = (
  clientId: string | undefined,
  entityIds: string[] | undefined
): void => {
  const intl = useIntl();
  const clientIdRef = useRef(clientId);
  const isFirstCheckRef = useRef(true);
  const queryClient = useQueryClient();
  const authTokens = useAuthTokens();

  const { hideLoader, showLoader } = useGlobalLoaderContext();

  useEffect(() => {
    if (!clientId || !entityIds || !authTokens || isFirstCheckRef.current) {
      isFirstCheckRef.current = false;

      return;
    }

    (async () => {
      try {
        const clientChanged = clientIdRef.current !== clientId;

        if (authTokens.sdgAuthToken && clientChanged) {
          showLoader(
            intl.formatMessage({
              id: 'loaderMessages.switchingClient',
              defaultMessage: 'Switching client...',
            })
          );

          const {
            response: { accessToken, expiresIn, refreshToken },
          } = await queryClient.fetchQuery<
            UseQueryResponse<GetClientTokenResponse>
          >(apiRoutes.CLIENT_TOKEN(clientId), {
            cacheTime: 0,
          });

          const newTokens: AuthTokensShape = {
            ...authTokens,
            refreshToken,
            sdgAuthToken: accessToken,
            expirationDate: getExpirationDate(expiresIn),
          };

          setLocalStorageValue(AUTH_TOKENS_LOCAL_STORAGE_KEY, newTokens);
        }

        setTimeout(() => {
          queryClient.invalidateQueries();
          queryClient.clear();
        });
      } finally {
        setTimeout(hideLoader, 1000);
      }

      clientIdRef.current = clientId;
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, JSON.stringify(entityIds)]);
};
