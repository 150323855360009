import { rgba } from 'polished';
import colors from '../colors';

export const fontSizes = [12, 14, 16, 20, 26, 32, 46];
export const fontWeights = [300, 500];
export const lineHeights = [1.16, 1.2];
export const fonts = {
  default: '"Neo Sans W23", sans-serif',
};

export const textStyles = {
  h1: {
    fontSize: 4,
    lineHeight: 1,
    fontWeight: 0,
    fontFamily: fonts.default,
  },
  h2: {
    fontSize: 3,
    lineHeight: 1,
    fontWeight: 1,
    fontFamily: fonts.default,
  },
  body: {
    fontSize: 2,
    lineHeight: 1,
    fontWeight: 0,
    fontFamily: fonts.default,
  },
  bodyBold: {
    fontSize: 2,
    lineHeight: 1,
    fontWeight: 1,
    fontFamily: fonts.default,
  },
  bodyHeavy: {
    fontSize: 1,
    lineHeight: 1,
    fontWeight: 1,
    fontFamily: fonts.default,
  },
  caption: {
    fontSize: 1,
    lineHeight: 1,
    fontWeight: 0,
    fontFamily: fonts.default,
  },
  captionBold: {
    fontSize: 1,
    lineHeight: 1,
    fontWeight: 1,
    fontFamily: fonts.default,
  },
  currency: {
    fontSize: 0,
    lineHeight: 0,
    fontWeight: 0,
    fontFamily: fonts.default,
  },
  currencyBold: {
    fontSize: 0,
    lineHeight: 0,
    fontWeight: 1,
    fontFamily: fonts.default,
  },
  percentBold: {
    fontSize: 5,
    lineHeight: 1,
    fontWeight: 1,
    fontFamily: fonts.default,
  },
  priceLarge: {
    fontSize: 6,
    lineHeight: 0,
    fontWeight: 0,
    fontFamily: fonts.default,
  },
  label: {
    display: 'block',
    color: rgba(colors.text.primary, 0.54),
    marginBottom: '0.75rem',
  },
};
