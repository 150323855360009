import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { SimpleSelectOption } from '../../components/atoms/Fields/Select/templates/SimpleSelect/types';
import apiRoutes from '../../setup/consts/apiRoutes';
import SupportedLanguages from '../../setup/intl/enums';
import { GetCitiesListResponse, UseCitiesListReturnType } from './types';

export const useCitiesList = (): UseCitiesListReturnType => {
  const { locale } = useIntl();
  const { data, isLoading } = useQuery<UseQueryResponse<GetCitiesListResponse>>(
    apiRoutes.CITIES_LIST
  );

  return {
    list: useMemo(() => {
      const { response } = data ?? {};
      const { cities } = response ?? {};

      if (!Array.isArray(cities)) {
        return [];
      }

      const inEnglish = locale === SupportedLanguages.English;

      return cities.map<SimpleSelectOption>(({ cityCode, enName, arName }) => ({
        value: cityCode,
        label: inEnglish ? enName : arName,
      }));
    }, [data]),
    isLoading,
  };
};
