import React from 'react';
import { useLocation, matchPath, Redirect } from 'react-router-dom';

import { getAppRoutes } from './consts';
import useUserContext from '../../hooks/useUserContext';
import { AuthAccessibility } from './enums';
import routes from '../../setup/consts/routes';
import NotFoundPage from '../not-found';
import LoginLayout from '../../components/layouts/LoginLayout';

export const useRedirectContent = (): ReactComponentReturnType => {
  const { user } = useUserContext();
  const { permissions } = user ?? {};
  const { pathname } = useLocation();
  const route = getAppRoutes({
    isAdvancedUser: Boolean(user?.isAdvancedUser),
    isSandoogUser: Boolean(user?.isSandoogUser),
  }).find(({ path }) => matchPath(pathname, { path, exact: true }) !== null);

  if (!user) {
    if (
      route &&
      (route.authAccessibility === undefined ||
        route.authAccessibility === AuthAccessibility.onlyLoggedIn)
    ) {
      return <Redirect to={routes.SIGN_IN} />;
    }
  } else if (
    route &&
    route.authAccessibility === AuthAccessibility.onlyNotLoggedIn
  ) {
    return <Redirect to={routes.ORDERS} />;
  } else if (
    route &&
    permissions &&
    route.permission &&
    !permissions.includes(route.permission)
  ) {
    if (route.path === routes.DASHBOARD) {
      return <Redirect to={routes.ORDERS} />;
    }

    return (
      <LoginLayout>
        <NotFoundPage />
      </LoginLayout>
    );
  }

  return null;
};
