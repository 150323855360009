import React, { useCallback, useEffect, useMemo, useState } from 'react';
import noop from 'lodash/noop';
import SupportedLanguages from '../setup/intl/enums';
import useUserContext from '../hooks/useUserContext';
import { User } from './User/types';
import { getLocalStorageValue } from '../hooks/useLocalStorageValue/utils';
import { LOCAL_STORAGE_LANGUAGE_KEY } from './consts';
import { getEnumValueByStringValue } from '../utils/misc';

export interface LanguageContextValue {
  language: SupportedLanguages;
  setLanguage: (language: SupportedLanguages) => void;
}

export const LanguageContext = React.createContext<LanguageContextValue>({
  language: SupportedLanguages.English,
  setLanguage: noop,
});

export const { Provider } = LanguageContext;

const getLanguage = (user: User | undefined) => {
  if (user) {
    const userLanguage = user.accountDetails.language;
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, userLanguage);

    return userLanguage;
  }

  const locale =
    getEnumValueByStringValue(
      SupportedLanguages,
      getLocalStorageValue(LOCAL_STORAGE_LANGUAGE_KEY) ?? ''
    ) ?? navigator.language.split(/[-_]/)[0];

  const defaultLanguage =
    locale === SupportedLanguages.English ||
    locale === SupportedLanguages.Arabic
      ? locale
      : SupportedLanguages.English;

  return defaultLanguage;
};

export const LanguageContextProvider: React.FC = ({ children }) => {
  const { user } = useUserContext();
  const [language, setLang] = useState(getLanguage(user));

  const setLanguage = useCallback((lang: SupportedLanguages) => {
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, lang);
    setLang(lang);
  }, []);

  const LanguageContextValue = useMemo(
    () => ({
      language,
      setLanguage,
    }),
    [language, setLanguage]
  );

  useEffect(() => {
    const newLanguage = getLanguage(user);

    if (newLanguage !== language) {
      setLanguage(newLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <Provider value={LanguageContextValue}>{children}</Provider>;
};
