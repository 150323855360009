import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';
import { AnimatePresence } from 'framer-motion';
import SettingsModal from '../../../../../../components/organisms/Modal/templates/SettingsModal';
import AssignedEntities from './partials/AssignedEntities';
import Information from './partials/Information';
import { AddUsersModalProps, UserFormValues } from './types';
import { USER_FORM_INITIAL_VALUES } from './consts';
import { useUserDetails, useUserFormSubmitHandler } from './hooks';
import { useOnDeleteUserClickHandler } from '../../hooks';
import { COMMON_MUTATORS } from '../../../../../../utils/finalForm';
import useToggle from '../../../../../../hooks/useToggle';
// eslint-disable-next-line import/no-cycle
import AddEntitiesModal from '../../../Entities/components/Modal';

const AddUsersModal: React.FC<AddUsersModalProps> = ({
  onCloseClick,
  refetchUsers,
  userId,
}) => {
  const [isEntitiesModalOpen, { toggleOn, toggleOff }] = useToggle();

  const { user, isLoading } = useUserDetails(userId);
  const intl = useIntl();
  const submitHandler = useUserFormSubmitHandler(
    refetchUsers,
    onCloseClick,
    userId
  );
  const onDelete = useCallback(() => {
    if (refetchUsers) {
      refetchUsers();
    }

    onCloseClick();
  }, [onCloseClick, refetchUsers]);
  const onDeleteClick = useOnDeleteUserClickHandler(userId || '', onDelete);

  return (
    <>
      <Form<UserFormValues>
        onSubmit={submitHandler}
        mutators={COMMON_MUTATORS}
        initialValues={user || USER_FORM_INITIAL_VALUES}
      >
        {({ handleSubmit, submitting }) => (
          <SettingsModal
            {...{ handleSubmit }}
            leftColumn={{
              title: intl.formatMessage({
                id: 'enitiesAndUsers.userInformation',
                defaultMessage: 'User Information',
              }),
              content: (
                <Information
                  disabled={isLoading || submitting}
                  isEdit={!!userId}
                />
              ),
            }}
            rightColumn={{
              title: intl.formatMessage({
                id: 'enitiesAndUsers.assignedEntities',
                defaultMessage: 'Assigned entities',
              }),
              content: (
                <AssignedEntities
                  name='entities'
                  openEntitiesModal={toggleOn}
                />
              ),
            }}
            cancelButton={{
              disabled: submitting,
              label: intl.formatMessage({
                id: 'forms.buttons.cancel',
                defaultMessage: 'Cancel',
              }),
              onClick: onCloseClick,
            }}
            deleteButton={
              userId
                ? {
                    disabled: submitting,
                    label: intl.formatMessage({
                      id: 'forms.buttons.delete',
                      defaultMessage: 'Delete',
                    }),
                    onClick: onDeleteClick,
                  }
                : undefined
            }
            submitButton={{
              disabled: submitting,
              label: userId
                ? intl.formatMessage({
                    id: 'forms.buttons.save',
                    defaultMessage: 'Save',
                  })
                : intl.formatMessage({
                    id: 'forms.buttons.invite',
                    defaultMessage: 'Invite',
                  }),
            }}
            onClose={onCloseClick}
            avatarLabel={intl.formatMessage({
              id: 'common.addProfilePhoto',
              defaultMessage: 'Add Profile Photo',
            })}
            avatarFieldName='avatar'
          />
        )}
      </Form>

      <AnimatePresence>
        {isEntitiesModalOpen && (
          <AddEntitiesModal {...{ onCloseClick: toggleOff }} />
        )}
      </AnimatePresence>
    </>
  );
};

export default AddUsersModal;
