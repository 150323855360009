import React from 'react';
import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';
import { AnimatePresence } from 'framer-motion';
import SettingsModal from '../../../../../../components/organisms/Modal/templates/SettingsModal';
import AssignedUsersField from './partials/AssignedUsers';
import Information from './partials/Information';
import { AddEntitiesModalProps, EntityFormValues } from './types';
import { ENTITY_FORM_INITIAL_VALUES } from './consts';
import { useEntityDetails, useEntityFormSubmitHandler } from './hooks';
import { COMMON_MUTATORS } from '../../../../../../utils/finalForm';
// eslint-disable-next-line import/no-cycle
import AddUsersModal from '../../../Users/components/Modal';
import useToggle from '../../../../../../hooks/useToggle';

const AddEntitiesModal: React.FC<AddEntitiesModalProps> = ({
  onCloseClick,
  refetchEntities,
  entityId,
}) => {
  const [isUsersModalOpen, { toggleOn, toggleOff }] = useToggle();
  const { entity, isLoading } = useEntityDetails(entityId);
  const intl = useIntl();
  const submitHandler = useEntityFormSubmitHandler(
    refetchEntities,
    onCloseClick,
    entityId
  );

  return (
    <>
      <Form<EntityFormValues>
        onSubmit={submitHandler}
        initialValues={entity || ENTITY_FORM_INITIAL_VALUES}
        mutators={COMMON_MUTATORS}
      >
        {({ handleSubmit, submitting }) => (
          <SettingsModal
            {...{ handleSubmit }}
            avatarFieldName='logo'
            leftColumn={{
              title: intl.formatMessage({
                id: 'enitiesAndUsers.entityInformation',
                defaultMessage: 'Entity Information',
              }),
              content: <Information disabled={isLoading || submitting} />,
            }}
            rightColumn={{
              title: intl.formatMessage({
                id: 'enitiesAndUsers.assignedUsers',
                defaultMessage: 'Assigned users',
              }),
              content: (
                <AssignedUsersField name='users' openUsersModal={toggleOn} />
              ),
            }}
            cancelButton={{
              disabled: isLoading || submitting,
              label: intl.formatMessage({
                id: 'forms.buttons.cancel',
                defaultMessage: 'Cancel',
              }),
              onClick: onCloseClick,
            }}
            submitButton={{
              disabled: isLoading || submitting,
              label: intl.formatMessage(
                entityId
                  ? {
                      id: 'forms.buttons.save',
                      defaultMessage: 'Save',
                    }
                  : {
                      id: 'forms.buttons.add',
                      defaultMessage: 'Add',
                    }
              ),
            }}
            onClose={onCloseClick}
            avatarLabel={intl.formatMessage({
              id: 'common.addLogo',
              defaultMessage: 'Add Logo',
            })}
          />
        )}
      </Form>

      <AnimatePresence>
        {isUsersModalOpen && <AddUsersModal {...{ onCloseClick: toggleOff }} />}
      </AnimatePresence>
    </>
  );
};

export default AddEntitiesModal;
