import React, { useCallback } from 'react';
import { ShippingLabelCardProps } from './types';
import {
  StyledPrintButtonWrapper,
  StyledShippingLabelCard,
  StyledShippingLabelCardAction,
  StyledShippingLabelCardContent,
  StyledShippingLabelCardImage,
  StyledShippingLabelCardImageWrapper,
} from './styles';
import Typography from '../../styles/Typography';
import Button from '../../atoms/Button';
import imageShippingLabelPlaceholder from '../../../assets/images/shipping-label-placeholder.png';
import ButtonExternalLink from '../../atoms/Button/buttonExternalLink';

const ShippingLabelCard: React.FC<ShippingLabelCardProps> = ({
  src,
  title,
  downloadLink,
  downloadButtonLabel,
  onPrintClick,
  printButtonLabel,
  isCancelled,
}) => {
  const handlePrintClick = useCallback(() => {
    onPrintClick(downloadLink);
  }, [downloadLink, onPrintClick]);

  return (
    <StyledShippingLabelCard>
      <StyledShippingLabelCardImageWrapper>
        <StyledShippingLabelCardImage
          src={src || imageShippingLabelPlaceholder}
          alt={title}
        />
      </StyledShippingLabelCardImageWrapper>
      <StyledShippingLabelCardContent>
        <Typography>{title}</Typography>
        <StyledShippingLabelCardAction>
          <ButtonExternalLink
            href={downloadLink}
            disabled={isCancelled}
            download
          >
            {downloadButtonLabel}
          </ButtonExternalLink>
          <StyledPrintButtonWrapper>
            <Button disabled={isCancelled} onClick={handlePrintClick}>
              {printButtonLabel}
            </Button>
          </StyledPrintButtonWrapper>
        </StyledShippingLabelCardAction>
      </StyledShippingLabelCardContent>
    </StyledShippingLabelCard>
  );
};
export default ShippingLabelCard;
