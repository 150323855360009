import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { StyledLinkProps } from './types';

export const StyledLink = styled(Link)<StyledLinkProps & { color?: string }>`
  color: ${({ theme, color }) => color || theme.colors.link};
  transition: ${({ theme }) => theme.transitions.default};
  text-decoration: none;
  line-height: 1;
  &:hover {
    opacity: 0.7;
  }
`;
