import React from 'react';
import { CheckboxListProps } from './types';
import {
  StyledCheckboxList,
  StyledCheckboxListItem,
  StyledCheckboxWrapper,
  StyledSelectWrapper,
} from './styles';
import Checkbox from '../Checkbox';
import SimpleSelect from '../Select/templates/SimpleSelect';

const CheckboxList: React.FC<CheckboxListProps> = ({ items }) => (
  <StyledCheckboxList>
    {items.map(({ label, disabled, checked, selectItems, key, onChange }) => (
      <StyledCheckboxListItem {...{ key }}>
        <StyledCheckboxWrapper>
          <Checkbox {...{ label, disabled, checked, onChange }} />
        </StyledCheckboxWrapper>
        {selectItems && (
          <StyledSelectWrapper>
            <SimpleSelect options={selectItems} />
          </StyledSelectWrapper>
        )}
      </StyledCheckboxListItem>
    ))}
  </StyledCheckboxList>
);

export default CheckboxList;
