import styled from 'styled-components';
import { typography, variant } from 'styled-system';
import { textStyles } from '../../../theme/config/typography';
import { TypographyProps } from './types';

export const StyledText = styled.p<TypographyProps>`
  ${typography};
  ${variant({
    variants: {
      ...textStyles,
    },
  })};
`;
