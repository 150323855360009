import styled from 'styled-components';
import Typography from '../../styles/Typography';

export const StyledSalesByCityBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem 1.5rem 2rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.background.light};
  box-shadow: ${({ theme }) => theme.shadows.mid};
`;

export const StyledSalesByCityBoxLabel = styled(Typography).attrs({
  variant: 'bodyBold',
})`
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 1.875rem;
`;

export const StyledSalesByCityBoxChartWrapper = styled.div``;
