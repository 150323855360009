import { rgba } from 'polished';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { variant } from 'styled-system';
import { textStyles } from '../../../theme/config/typography';
import { StyledSideMenuProps } from './types';

export const StyledSideMenu = styled.div`
  width: 100%;
`;

export const StyledSideMenuRow = styled.nav`
  width: 100%;
  padding: 0.75rem 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.navigation.border};
  }
`;

export const StyledSideMenuLink = styled(NavLink).attrs({
  variant: 'body',
  activeClassName: 'is-active',
})`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text.secondary};
  background-color: transparent;
  transition: ${({ theme }) => theme.transitions.default};
  ${variant({
    variants: {
      ...textStyles,
    },
  })};
  &.is-active {
    background-color: ${({ theme }) =>
      rgba(theme.colors.navigation.background, 0.9)};
  }
  &:hover {
    background-color: ${({ theme }) =>
      rgba(theme.colors.navigation.background, 0.7)};
  }
`;

export const StyledSideMenuList = styled.ul<StyledSideMenuProps>`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  ${({ hasMinHeight, theme }) =>
    hasMinHeight &&
    css`
      min-height: ${theme.dimensions.sidebar.list.minHeight};
    `}
`;

export const StyledSideMenuListItem = styled.li`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const StyledSideMenuIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.875rem;
  flex-shrink: 0;

  color: inherit;
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? 'margin-left: 1.375rem;'
      : 'margin-right: 1.375rem;'}
  svg {
    height: 1em;
    width: 1.4em;
    font-size: 1.6875rem;
    fill: currentColor;
  }
`;
