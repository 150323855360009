import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { removeLocalStorageValue } from '../../hooks/useLocalStorageValue/utils';
import { asyncNoop } from '../../utils/lodash';
import { AUTH_TOKENS_LOCAL_STORAGE_KEY } from './consts';
import { useAuthTokens } from './hooks';
import { User, UserContextProviderProps } from './types';
import { fetchUser } from './utils';

export interface UserContextValue {
  user: User | undefined;
  isUserLoading: boolean;
  refetchUserData: () => Promise<void>;
}

export const UserContext = React.createContext<UserContextValue>({
  user: undefined,
  isUserLoading: false,
  refetchUserData: asyncNoop,
});

const { Provider } = UserContext;

export const UserContextProvider: React.FC<UserContextProviderProps> = ({
  children,
}) => {
  const authTokens = useAuthTokens();
  const [user, setUser] = useState<User>();
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [isUserInitialLoading, setIsUserInitialLoading] = useState(true);
  const fetchUserData = useCallback(async () => {
    setIsUserLoading(true);

    try {
      if (authTokens) {
        const userData = await fetchUser(authTokens);

        setUser(userData);
      } else {
        setUser(undefined);
      }
    } catch (_) {
      removeLocalStorageValue(AUTH_TOKENS_LOCAL_STORAGE_KEY);
    }

    setIsUserInitialLoading(false);
    setIsUserLoading(false);
  }, [authTokens]);

  const userContextValue = useMemo(
    () => ({
      user,
      isUserLoading,
      refetchUserData: fetchUserData,
    }),
    [user, isUserLoading, fetchUserData]
  );

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authTokens]);

  return (
    <Provider value={userContextValue}>
      {children(isUserInitialLoading)}
    </Provider>
  );
};
