import styled from 'styled-components';
import { StyledBox } from '../../../../styles/Grid/styles';

export const StyledCustomerInfoWrapper = styled(StyledBox).attrs({
  gap: '1.5rem',
})<{
  withoutMargins?: boolean;
}>`
  margin-bottom: ${({ withoutMargins }) => (withoutMargins ? '' : '1rem')};
`;

export const StyledAddressesCollapsibleCard = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem;
  gap: 15px;
  box-shadow: ${({ theme }) => theme.shadows.small};
  border-radius: 1rem;
  margin-top: 15px;
  cursor: pointer;

  .hidden {
    display: none;
  }
`;
