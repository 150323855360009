import React from 'react';
import { IntlShape } from 'react-intl';
import { ColumnDef } from '@tanstack/react-table';
import apiRoutes from '../../../../../setup/consts/apiRoutes';
import {
  getShipmentLabelSrc,
  mapCurrencyToCurrencyCode,
} from '../../../../../utils/misc';
import { makeMutationFn } from '../../../../../utils/reactQuery';
import { MutationFn } from '../../../../../utils/reactQuery/types';
import PriceWithCurrency from '../../../../atoms/PriceWithCurrency';
import ProductThumbnail from '../../../../atoms/ProductThumbnail';
import { OrderSummaryContactListItem } from '../../../../molecules/OrderSummaryContactList/types';
import {
  CancelOrderMutationVariables,
  OrderSummaryModel,
  ProductsSummaryTableItem,
} from './types';

export const productsSummaryTableData: ProductsSummaryTableItem[] = [
  {
    imageCol: 'https://source.unsplash.com/50x50/?products',
    productCol: 'Nike Air Force 1 ‘07',
    quantityCol: 2,
    notesCol: 'Notes, eg: color, size',
    priceCol: { price: 10000, currency: 'IQD' },
    totalCol: { price: 20000, currency: 'IQD' },
  },
  {
    imageCol: 'https://source.unsplash.com/50x50/?products',
    productCol: 'Nike Air Force 1 ‘07',
    quantityCol: 2,
    notesCol: 'Notes, eg: color, size',
    priceCol: { price: 10000, currency: 'IQD' },
    totalCol: { price: 20000, currency: 'IQD' },
  },
  {
    imageCol: 'https://source.unsplash.com/50x50/?products',
    productCol: 'Killshot 2 Leather',
    quantityCol: 2,
    notesCol: 'Notes, eg: color, size',
    priceCol: { price: 10000, currency: 'IQD' },
    totalCol: { price: 20000, currency: 'IQD' },
  },
  {
    imageCol: 'https://source.unsplash.com/50x50/?products',
    productCol: 'Nike Air Force 1 ‘07',
    quantityCol: 2,
    notesCol: 'Notes, eg: color, size',
    priceCol: { price: 10000, currency: 'IQD' },
    totalCol: { price: 20000, currency: 'IQD' },
  },
  {
    imageCol: 'https://source.unsplash.com/50x50/?products',
    productCol: 'Nike Air Force 1 ‘07',
    quantityCol: 2,
    notesCol: 'Notes, eg: color, size',
    priceCol: { price: 10000, currency: 'IQD' },
    totalCol: { price: 20000, currency: 'IQD' },
  },
  {
    imageCol: 'https://source.unsplash.com/50x50/?products',
    productCol: 'Nike Air Force 1 ‘07',
    quantityCol: 2,
    notesCol: 'Notes, eg: color, size',
    priceCol: { price: 10000, currency: 'IQD' },
    totalCol: { price: 20000, currency: 'IQD' },
  },
  {
    imageCol: 'https://source.unsplash.com/50x50/?products',
    productCol: 'Nike Air Force 1 ‘07',
    quantityCol: 2,
    notesCol: 'Notes, eg: color, size',
    priceCol: { price: 10000, currency: 'IQD' },
    totalCol: { price: 20000, currency: 'IQD' },
  },
  {
    imageCol: '',
    productCol: 'Nike Air Force 1 ‘07',
    quantityCol: 2,
    notesCol: 'Notes, eg: color, size',
    priceCol: { price: 10000, currency: 'IQD' },
    totalCol: { price: 20000, currency: 'IQD' },
  },
  {
    imageCol: 'https://source.unsplash.com/50x50/?products',
    productCol: 'Nike Air Force 1 ‘07',
    quantityCol: 2,
    notesCol: 'Notes, eg: color, size',
    priceCol: { price: 10000, currency: 'IQD' },
    totalCol: { price: 20000, currency: 'IQD' },
  },
  {
    imageCol: 'https://source.unsplash.com/50x50/?products',
    productCol: 'Nike Air Force 1 ‘07',
    quantityCol: 2,
    notesCol: 'Notes, eg: color, size',
    priceCol: { price: 10000, currency: 'IQD' },
    totalCol: { price: 20000, currency: 'IQD' },
  },
  {
    imageCol: 'https://source.unsplash.com/50x50/?products',
    productCol: 'Nike Air Force 1 ‘07',
    quantityCol: 2,
    notesCol: 'Notes, eg: color, size',
    priceCol: { price: 10000, currency: 'IQD' },
    totalCol: { price: 20000, currency: 'IQD' },
  },
  {
    imageCol: 'https://source.unsplash.com/50x50/?products',
    productCol: 'Nike Air Force 1 ‘07',
    quantityCol: 2,
    notesCol: 'Notes, eg: color, size',
    priceCol: { price: 10000, currency: 'IQD' },
    totalCol: { price: 20000, currency: 'IQD' },
  },
  {
    imageCol: 'https://source.unsplash.com/50x50/?products',
    productCol: 'Nike Air Force 1 ‘07',
    quantityCol: 2,
    notesCol: 'Notes, eg: color, size',
    priceCol: { price: 10000, currency: 'IQD' },
    totalCol: { price: 20000, currency: 'IQD' },
  },
  {
    imageCol: 'https://source.unsplash.com/50x50/?products',
    productCol: 'Nike Air Force 1 ‘07',
    quantityCol: 2,
    notesCol: 'Notes, eg: color, size',
    priceCol: { price: 10000, currency: 'IQD' },
    totalCol: { price: 20000, currency: 'IQD' },
  },
  {
    imageCol: 'https://source.unsplash.com/50x50/?products',
    productCol: 'Nike Air Force 1 ‘07',
    quantityCol: 2,
    notesCol: 'Notes, eg: color, size',
    priceCol: { price: 10000, currency: 'IQD' },
    totalCol: { price: 20000, currency: 'IQD' },
  },
];

export const getTableData = (
  order: OrderSummaryModel | undefined
): ProductsSummaryTableItem[] => {
  if (!order?.deliveryItems) {
    return [];
  }

  const currency = mapCurrencyToCurrencyCode(order?.currency ?? '');
  return (order?.deliveryItems ?? []).map(
    ({ name, imageUrl, quantity, description, price }) => ({
      imageCol: imageUrl,
      productCol: name,
      quantityCol: quantity,
      notesCol: description,
      priceCol: { price, currency },
      totalCol: { price: price * quantity, currency },
    })
  );
};

export const getCustomerInfo = (
  order: OrderSummaryModel | undefined,
  intl: IntlShape,
  onClickCustomerId?: () => void
): OrderSummaryContactListItem[] => {
  if (!order) {
    return [];
  }

  return [
    {
      key: '1',
      label: intl.formatMessage({
        id: 'forms.fields.customer',
        defaultMessage: 'Customer',
      }),
      description: order.customerName,
    },

    {
      key: '2',
      label: intl.formatMessage({
        id: 'forms.fields.phone',
        defaultMessage: 'Phone',
      }),
      description: order.customerPhone,
    },

    {
      key: '6',
      label: intl.formatMessage({
        id: 'forms.fields.secondPhoneNumber',
        defaultMessage: 'Second Phone Number',
      }),
      description: order.secondCustomerPhone,
    },

    {
      key: '3',
      label: intl.formatMessage({
        id: 'forms.fields.location',
        defaultMessage: 'Location',
      }),
      description: order.state,
    },
    {
      key: '4',
      label: intl.formatMessage({
        id: 'forms.fields.locationNotes',
        defaultMessage: 'Location notes',
      }),
      description: order.notes,
    },
    {
      key: '5',
      label: intl.formatMessage({
        id: 'customerId',
        defaultMessage: 'Customer ID',
      }),
      description: order.customerId,
      onClick: onClickCustomerId,
    },
  ];
};

export const productsSummaryTableColumns = (
  intl: IntlShape
): ColumnDef<ProductsSummaryTableItem>[] => [
  {
    header: '',
    accessorKey: 'imageCol',
    size: 50,
    cell: ({ cell: { getValue } }) => (
      <ProductThumbnail
        src={getValue() as ProductsSummaryTableItem['imageCol']}
      />
    ),
  },
  {
    header: intl.formatMessage({
      id: 'products.product',
      defaultMessage: 'Product',
    }),
    accessorKey: 'productCol',
    size: 200,
  },
  {
    header: intl.formatMessage({
      id: 'products.quantity',
      defaultMessage: 'Quantity',
    }),
    accessorKey: 'quantityCol',
    size: 20,
  },
  {
    header: intl.formatMessage({
      id: 'products.notes',
      defaultMessage: 'Notes',
    }),
    accessorKey: 'notesCol',
    size: 200,
  },
  {
    header: intl.formatMessage({
      id: 'products.price',
      defaultMessage: 'Price',
    }),
    accessorKey: 'priceCol',
    cell: ({ cell: { getValue } }) => (
      <PriceWithCurrency
        {...(getValue() as ProductsSummaryTableItem['priceCol'])}
      />
    ),
  },
  {
    header: intl.formatMessage({
      id: 'products.total',
      defaultMessage: 'Total',
    }),
    accessorKey: 'totalCol',
    cell: ({ cell: { getValue } }) => (
      <PriceWithCurrency
        {...(getValue() as ProductsSummaryTableItem['totalCol'])}
      />
    ),
  },
];

export const getShippingLabelSrc = (url: string): string => {
  const shippingLabelSrc = new URL(getShipmentLabelSrc());
  shippingLabelSrc.searchParams.append('url', url);

  return shippingLabelSrc.toString();
};

export const CANCEL_ORDER_MUTATION_FN = (
  orderId: string
): MutationFn<CancelOrderMutationVariables> =>
  makeMutationFn(apiRoutes.CANCEL_ORDER(orderId));
