import { FORM_ERROR } from 'final-form';
import { useCallback } from 'react';
import { SubmitHandler } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import routes from '../../../setup/consts/routes';
import { mapApiErrorsToFormErrors } from '../../../utils/finalForm';
import { SIGN_UP_MUTATION_FN } from './consts';
import { SignUpFormValues } from './types';

export const useSignUpFormSubmitHandler = (): SubmitHandler<SignUpFormValues> => {
  const { mutateAsync: signUp } = useMutation(SIGN_UP_MUTATION_FN);
  const intl = useIntl();
  const { addToast } = useToasts();
  const history = useHistory();

  return useCallback(
    async ({ repeatPassword, password, ...formValues }) => {
      if (repeatPassword !== password) {
        return {
          repeatPassword: intl.formatMessage({
            id: 'login.passwordsNotEqualError',
            defaultMessage: 'Passwords are not equal',
          }),
        };
      }

      try {
        await signUp({
          data: {
            password,
            ...formValues,
          },
        });

        addToast(
          intl.formatMessage({
            id: 'login.signUpSuccess',
            defaultMessage: 'Account created successfully!',
          }),
          {
            appearance: 'success',
          }
        );

        history.push(routes.SIGN_IN);

        return undefined;
      } catch (error) {
        const { errors, generalError } = mapApiErrorsToFormErrors(error, intl);

        if (errors) {
          return errors;
        }

        const defaultError = intl.formatMessage({
          id: 'login.signUpFailed',
          defaultMessage: 'Sign up failed',
        });

        addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
          appearance: 'error',
        });

        return {
          [FORM_ERROR]: defaultError,
        };
      }
    },
    [addToast, history, intl, signUp]
  );
};
