import styled from 'styled-components';

export const StyledCashoutInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr auto;
  align-items: center;
  grid-gap: 1rem;
`;

export const StyledDate = styled.time``;
