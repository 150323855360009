import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/atoms/Loader';
import { LoaderMode } from '../../../components/atoms/Loader/enums';
import useHeaderTitleContext from '../../../hooks/useHeaderTitleContext';
import ProductDetails from './components/ProductDetails';
import { useProductDetails } from '../hooks';

const ProductPage: React.FC = () => {
  // ** Hooks
  const intl = useIntl();
  const { id: productId } = useParams<{ id: string | undefined }>();
  const { setHeaderTitle } = useHeaderTitleContext();
  const { product, loading } = useProductDetails(Number(productId));

  // ** Handlers
  useEffect(() => {
    setHeaderTitle(
      intl.formatMessage({
        id: 'mainNavigation.warehouse',
        defaultMessage: 'Warehouse',
      })
    );
  }, [intl, setHeaderTitle]);

  return loading ? (
    <Loader mode={LoaderMode.WarehouseShowProduct} />
  ) : (
    <ProductDetails product={product} />
  );
};

export default ProductPage;
