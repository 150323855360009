import React from 'react';
import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';
import toString from 'lodash/toString';

import { Field } from 'react-final-form';
import Radio from './radioBase';
import { BaseRadioItem, FormRadioListProps, RadioListProps } from './types';
import ErrorMessage from '../ErrorMessage';
import {
  StyledRadioList,
  StyledRadioListItem,
  StyledRadioListWrapper,
} from './styles';

function RadioList<
  V = string,
  T extends BaseRadioItem<V> = BaseRadioItem<V>,
  S = V
>({
  items,
  renderRadioContent,
  value,
  onChange = noop,
  name,
  disabled,
  error,
  makeItemKey = (item): string => toString(item.value),
  allowUnselect,
  isValueCheckedCallback = isEqual,
}: RadioListProps<V, T, S>): ReactComponentReturnType {
  return (
    <StyledRadioListWrapper>
      <StyledRadioList>
        {items.map((item) => (
          <StyledRadioListItem key={makeItemKey(item)}>
            <Radio<V, T>
              checked={isValueCheckedCallback(item.value, value)}
              radioItem={item}
              onChange={(event): void => {
                const { target } = event;

                onChange(target.checked ? item?.value : undefined);
              }}
              disabled={disabled || item.disabled}
              renderRadioContent={renderRadioContent(value)}
              {...{
                name,
                allowUnselect,
              }}
            />
          </StyledRadioListItem>
        ))}
      </StyledRadioList>
      <ErrorMessage {...{ error }} />
    </StyledRadioListWrapper>
  );
}

export function FormRadioList<
  V = string,
  T extends BaseRadioItem<V> = BaseRadioItem<V>
>({ name, ...props }: FormRadioListProps<V, T>): ReactComponentReturnType {
  return (
    <Field<V> {...{ name }}>
      {({ input, meta: { error, submitError } }) => (
        <RadioList
          onChange={input.onChange}
          value={input.value}
          error={error || submitError}
          {...props}
        />
      )}
    </Field>
  );
}

export default RadioList;
