// eslint-disable-next-line @typescript-eslint/ban-types
export const transformObjectToFormData = <T extends {}>(
  objectToTransform: T
): FormData => {
  const formData = new FormData();
  const entries = Object.entries(objectToTransform);

  const data = entries.reduce((result, [key, value]) => {
    if (value instanceof File) {
      formData.append(key, value, value.name);

      return result;
    }

    return {
      ...result,
      [key]: value,
    };
  }, {});

  formData.append('data', JSON.stringify(data));

  return formData;
};
