import styled from 'styled-components';
import Typography from '../../../components/styles/Typography';

export const StyledResetPasswordForm = styled.form`
  width: 100%;
  margin-top: 1.5rem;
`;

export const StyledResetPasswordCaption = styled(Typography).attrs({
  variant: 'caption',
  forwardedAs: 'p',
})`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledResetPasswordDescription = styled(Typography).attrs({
  variant: 'body',
  forwardedAs: 'p',
})`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledResetPasswordInfoButton = styled.button.attrs({
  type: 'button',
})`
  color: ${({ theme }) => theme.colors.link};
  transition: ${({ theme }) => theme.transitions.default};
  text-decoration: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  text-align: left;
  outline: none;
  font: inherit;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

export const StyledResetPasswordDecor = styled.div`
  background-color: ${({ theme }) => theme.colors.background.light};
  color: ${({ theme }) => theme.colors.text.secondary};
  border-radius: 1.5rem;
  padding: 1.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  svg {
    height: 1em;
    width: 1em;
    font-size: 1.75rem;
  }
`;

export const StyledResetPasswordDecorSeparator = styled.div`
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
  svg {
    font-size: 0.75rem;
  }
`;
