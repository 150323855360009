import React from 'react';
import { ImageUploaderProps } from './types';
import {
  StyledCoverImageWrapper,
  StyledImageUploader,
  StyledUploadedImage,
  StyledUploadedImageWrapper,
} from './styles';
import Upload from '../../atoms/Upload';

const ImageUploader: React.FC<ImageUploaderProps> = ({
  coverImageLabel,
  coverImage,
  uploadedImages,
  onChangeCoverImage,
  onChangeUploadedImages,
  disabled,
}) => (
  <StyledImageUploader className={disabled ? 'disabled' : ''}>
    <StyledCoverImageWrapper>
      <Upload
        label={coverImageLabel}
        isBig
        {...{ coverImage }}
        onChange={(files) => onChangeCoverImage(files[0])}
        isMultiple={false}
        disabled={disabled}
      />
    </StyledCoverImageWrapper>
    {uploadedImages &&
      uploadedImages.map(({ src, alt }, idx) => (
        <StyledUploadedImageWrapper key={idx.toString()}>
          <StyledUploadedImage {...{ src, alt }} />
        </StyledUploadedImageWrapper>
      ))}
    <Upload isMultiple disabled={disabled} onChange={onChangeUploadedImages} />
  </StyledImageUploader>
);

export default ImageUploader;
