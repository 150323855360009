export enum ButtonMode {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Info = 'info',
}

export enum ButtonSize {
  Small = 'small',
  Big = 'big',
}
