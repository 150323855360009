import styled from 'styled-components';
import { UploadModalStatus } from './enums';
import { StyledUploadOrderStatusModalProps } from './types';

export const StyledUploadOrderStatus = styled.div<StyledUploadOrderStatusModalProps>`
  color: ${({ theme, status }) =>
    (status === UploadModalStatus.Loading && theme.colors.text.secondary) ||
    (status === UploadModalStatus.Completed && theme.colors.text.tertiary) ||
    (status === UploadModalStatus.Failed && theme.colors.text.error)};
`;
