import React from 'react';
import { HeaderProps } from './types';
import {
  StyledHeader,
  StyledHeaderAction,
  StyledHeaderActionColumn,
  StyledHeaderTitle,
} from './styles';
import Avatar from '../../atoms/Avatar';
import useHeaderTitleContext from '../../../hooks/useHeaderTitleContext';
import MenuDropdown from '../../molecules/MenuDropdown';
import Seo from '../../Seo';

const Header: React.FC<HeaderProps> = ({ avatar, menu, centerComponent }) => {
  const { title } = useHeaderTitleContext();

  return (
    <>
      <Seo {...{ title }} />
      <StyledHeader>
        {title && <StyledHeaderTitle>{title}</StyledHeaderTitle>}
        {centerComponent && centerComponent}
        <StyledHeaderAction>
          {menu && (
            <StyledHeaderActionColumn>
              <MenuDropdown {...menu} />
            </StyledHeaderActionColumn>
          )}
          <StyledHeaderActionColumn>
            <Avatar {...avatar} />
          </StyledHeaderActionColumn>
        </StyledHeaderAction>
      </StyledHeader>
    </>
  );
};

export default Header;
