import React from 'react';
import { FormattedNumber, IntlShape } from 'react-intl';
import { ColumnDef } from '@tanstack/react-table';
import apiRoutes from '../../../../../setup/consts/apiRoutes';
import { makeMutationFn } from '../../../../../utils/reactQuery';
import {
  GetSettlementsMutationVariables,
  GetSettlementsMutationVariablesReturnType,
  SubmitCashOutMutationReturnType,
  SubmitCashOutMutationVariables,
  SubmitDisputeMutationVariables,
  TransactionsSummaryTableItem,
} from './types';

export const GET_SETTLEMENTS_MUTATION_FN = makeMutationFn<
  GetSettlementsMutationVariables,
  GetSettlementsMutationVariablesReturnType
>(apiRoutes.SETTLEMENTS);

export const SUBMIT_CASH_OUT_MUTATION_FN = makeMutationFn<
  SubmitCashOutMutationVariables,
  SubmitCashOutMutationReturnType
>(apiRoutes.SUBMIT_CASH_OUT);

export const SUBMIT_DISPUTE_MUTATION_FN = makeMutationFn<
  SubmitDisputeMutationVariables,
  undefined
>(apiRoutes.SUBMIT_DISPUTE);

export const transactionsSummaryTableData: TransactionsSummaryTableItem[] = [
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
  {
    activityTypeCol: 'Cash Received',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    transactionNumberCol: 587458656,
  },
];

export const transactionsSummaryTableColumns = (
  intl: IntlShape
): ColumnDef<TransactionsSummaryTableItem>[] => [
  {
    header: intl.formatMessage({
      id: 'financials.activityType',
      defaultMessage: 'Activity Type',
    }),
    accessorKey: 'activityTypeCol',
  },
  {
    header: intl.formatMessage({
      id: 'orders.valueIQD',
      defaultMessage: 'Value IQD',
    }),
    accessorKey: 'valueIqdCol',
    cell: ({ cell: { getValue } }) => (
      <FormattedNumber value={getValue() as number} />
    ),
  },
  {
    header: intl.formatMessage({
      id: 'orders.date',
      defaultMessage: 'Date',
    }),
    accessorKey: 'dateCol',
  },
  {
    header: intl.formatMessage({
      id: 'financials.transactionNo',
      defaultMessage: 'transaction No.',
    }),
    accessorKey: 'transactionNumberCol',
  },
];
