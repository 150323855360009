import React from 'react';
import { AvatarProps } from './types';
import {
  StyledAvatar,
  StyledAvatarImage,
  StyledAvatarPlaceholder,
  StyledAvatarPlaceholderText,
} from './styles';
import { AvatarSize } from './enums';

const Avatar: React.FC<AvatarProps> = ({
  size = AvatarSize.Small,
  src,
  alt,
  noBorder,
  placeholder,
}) => (
  <StyledAvatar {...{ size, noBorder }}>
    {src ? (
      <StyledAvatarImage {...{ src, alt }} />
    ) : (
      <>
        {placeholder || (
          <StyledAvatarPlaceholder>
            <StyledAvatarPlaceholderText {...{ size }}>
              Logo
            </StyledAvatarPlaceholderText>
          </StyledAvatarPlaceholder>
        )}
      </>
    )}
  </StyledAvatar>
);

export default Avatar;
