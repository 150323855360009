import React from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import VerificationInput from 'react-verification-input';
import { StyledBox } from '../../../components/styles/Grid/styles';
import { useVerifySubmitHandler } from './hooks';
import { VerifyFormValues } from './types';
import Typography from '../../../components/styles/Typography';
import LoginInnerLayout from '../Layout';
import { VerifyButton, StyledForm, VerificationInputWrapper } from './styles';
import useUserContext from '../../../hooks/useUserContext';

const VerifyPage: React.FC = () => {
  const intl = useIntl();
  const { isUserLoading } = useUserContext();
  const verifySubmitHandler = useVerifySubmitHandler();

  return (
    <LoginInnerLayout
      title={intl.formatMessage({
        id: 'verify',
        defaultMessage: 'Verify',
      })}
      subtitle={intl.formatMessage({
        id: 'verifySubtitle',
      })}
    >
      <StyledBox>
        <Form<VerifyFormValues>
          onSubmit={verifySubmitHandler}
          initialValues={{
            code: '',
          }}
        >
          {({ handleSubmit, submitting, submitError, form }) => (
            <StyledForm onSubmit={handleSubmit}>
              {submitError ? (
                <StyledBox mb='1rem' style={{ color: 'red' }}>
                  <Typography variant='body' as='p'>
                    {submitError}
                  </Typography>
                </StyledBox>
              ) : null}

              <VerificationInputWrapper>
                <VerificationInput
                  autoFocus
                  inputProps={{
                    name: 'code',
                    value: form.getState().values.code,
                    onChange: (e) => {
                      form.change('code', e.target.value);
                    },
                  }}
                  value={form.getState().values.code}
                  classNames={{
                    container: 'container',
                    character: 'character',
                    characterInactive: 'character--inactive',
                    characterSelected: 'character--selected',
                    characterFilled: 'character--filled',
                  }}
                />
              </VerificationInputWrapper>

              <StyledBox alignItems='center' justifyContent='space-between'>
                <VerifyButton
                  type='submit'
                  disabled={submitting || isUserLoading}
                >
                  <FormattedMessage id='verify' />
                </VerifyButton>
              </StyledBox>
            </StyledForm>
          )}
        </Form>
      </StyledBox>
    </LoginInnerLayout>
  );
};

export default VerifyPage;
