import React from 'react';
import { CashoutBoxProps } from './types';
import { StyledCashoutBox } from './styles';
import PriceWithCurrency from '../../../../../../atoms/PriceWithCurrency';
import { IconBankGood } from '../../../../../../../assets/svg/icons';
import { StyledBox } from '../../../../../../styles/Grid/styles';
import Button from '../../../../../../atoms/Button';
import { PriceWithCurrencySize } from '../../../../../../atoms/PriceWithCurrency/enums';

const CashoutBox: React.FC<CashoutBoxProps> = ({
  price,
  currency,
  buttonLabel,
  onButtonClick,
}) => (
  <StyledCashoutBox>
    <StyledBox mb='1.25rem'>
      <IconBankGood />
    </StyledBox>
    <StyledBox mb='1.25rem'>
      <PriceWithCurrency
        size={PriceWithCurrencySize.Large}
        {...{ price, currency }}
      />
    </StyledBox>
    <Button onClick={onButtonClick}>{buttonLabel}</Button>
  </StyledCashoutBox>
);

export default CashoutBox;
