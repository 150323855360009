import React, { useMemo } from 'react';
import { AnimatePresence } from 'framer-motion';
import InfiniteScroll, {
  Props as InfiniteScrollProps,
} from 'react-infinite-scroll-component';
import { IconChevronDown } from '../../../../assets/svg/icons';
import useToggle from '../../../../hooks/useToggle';
import {
  StyledMultiSelectButton,
  StyledMultiSelectButtonDecor,
  StyledMultiSelectButtonText,
  StyledMultiSelectContent,
  StyledMultiSelectWrapper,
} from './styles';
import { MultiSelectProps, MultiSelectValue } from './types';
import RadioList from '../Radio';
import renderRadioItemWithCheckboxes from '../Radio/content/withCheckboxes';
import { RadioItemWithCheckboxesProps } from '../Radio/content/withCheckboxes/types';
import { StyledBox } from '../../../styles/Grid/styles';

const MultiSelect: React.FC<MultiSelectProps> = ({
  placeholder,
  items,
  disabled,
  value,
  infiniteScrollProps,
  onChange,
  renderSelectedItems,
}) => {
  const [isOpen, { toggle, toggleOff, registerContainerRef }] = useToggle();

  const { scrollableTarget } = infiniteScrollProps ?? {};

  const RadioListParent = infiniteScrollProps ? InfiniteScroll : React.Fragment;

  const filteredItems = useMemo(() => {
    if (!renderSelectedItems) {
      return items;
    }

    return items.filter(({ value: itemValue }) => itemValue !== value?.value);
  }, [items, renderSelectedItems, value?.value]);

  return (
    <StyledMultiSelectWrapper ref={registerContainerRef}>
      <StyledMultiSelectButton type='button' onClick={toggle} {...{ disabled }}>
        <StyledMultiSelectButtonText>
          {items.find((item) => item.value === value?.value)?.label ||
            placeholder}
        </StyledMultiSelectButtonText>
        <StyledMultiSelectButtonDecor>
          <IconChevronDown />
        </StyledMultiSelectButtonDecor>
      </StyledMultiSelectButton>
      <AnimatePresence>
        {isOpen && (
          <StyledMultiSelectContent
            id={
              typeof scrollableTarget === 'string'
                ? scrollableTarget
                : undefined
            }
          >
            <RadioListParent
              {...((infiniteScrollProps as InfiniteScrollProps) ?? {})}
            >
              {renderSelectedItems && (
                <StyledBox mb='1rem'>{renderSelectedItems(value)}</StyledBox>
              )}

              <RadioList<string, RadioItemWithCheckboxesProps, MultiSelectValue>
                {...{ value }}
                items={filteredItems}
                onChange={(selectedValue) => {
                  if (value?.value !== selectedValue) {
                    toggleOff();
                  }

                  const selectedItem = items.find(
                    (item) => item.value === selectedValue
                  );

                  if (!selectedItem || !onChange) {
                    return;
                  }

                  onChange(selectedItem);
                }}
                renderRadioContent={renderRadioItemWithCheckboxes}
                isValueCheckedCallback={(valueToCompare, selectedValue) => {
                  const selectedItem = items.find(
                    (item) => item.value === selectedValue?.value
                  );

                  return (
                    selectedValue?.value === valueToCompare &&
                    !!selectedItem &&
                    selectedItem.checkboxes.every((checkbox) =>
                      selectedValue.checkedSubItems.includes(checkbox.value)
                    )
                  );
                }}
              />
            </RadioListParent>
          </StyledMultiSelectContent>
        )}
      </AnimatePresence>
    </StyledMultiSelectWrapper>
  );
};

export default MultiSelect;
