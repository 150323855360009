import { useMemo, useContext } from 'react';
import { StylesConfig } from 'react-select';
import { ThemeContext } from 'styled-components/macro';

export const useCustomStyles = <T>(
  notSeparated?: boolean,
  isError?: boolean,
  isAsyncSelect?: boolean
): StylesConfig<T, false> => {
  const theme = useContext(ThemeContext);

  return useMemo(
    () => ({
      container: (provided) => ({
        ...provided,
      }),
      control: (provided, { menuIsOpen, isDisabled }) => ({
        ...provided,
        backgroundColor: theme.colors.select.background,
        color: theme.colors.select.color,
        // eslint-disable-next-line no-nested-ternary
        borderRadius: notSeparated
          ? menuIsOpen
            ? '1rem 1rem 0 0'
            : '1rem'
          : '0.5rem',
        padding: notSeparated
          ? '0.875rem 1.25rem 0.875rem 1.125rem'
          : '0.5rem 0.875rem',
        // eslint-disable-next-line no-nested-ternary
        borderColor: isError
          ? theme.colors.field.primary.border.error
          : notSeparated
          ? theme.colors.select.notSeparated.border
          : theme.colors.select.default.border,
        boxShadow: notSeparated ? theme.shadows.small : 'none',
        minHeight: theme.dimensions.input.height.small,
        cursor: 'pointer',
        svg: {
          height: '1em',
          width: '1em',
          fill: 'currentColor',
          fontSize: notSeparated ? '1.25rem' : '0.75rem',
        },
        opacity: isDisabled ? 0.5 : 1,
        '&:hover': {
          borderColor: notSeparated
            ? theme.colors.select.notSeparated.border
            : theme.colors.select.default.border,
        },
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: theme.zindexes.selectMenu,
        margin: notSeparated ? '0' : '0.25rem 0',
        boxShadow: notSeparated ? theme.shadows.mid : 'none',
        borderRadius: notSeparated ? '0 0 1rem 1rem' : '1rem',
        borderColor: notSeparated
          ? theme.colors.select.notSeparated.menuBorder
          : theme.colors.select.default.menuBorder,
        overflow: 'hidden',
      }),
      menuList: (provided) => ({
        ...provided,
        zIndex: theme.zindexes.selectMenu,
        padding: 0,
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginRight: '1rem',
        '> div': {
          width: '100%',
          height: '100%',
        },
        '> div > span': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }),
      option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        zIndex: theme.zindexes.selectMenu,
        transition: '250ms ease-in-out',
        padding: notSeparated
          ? '0.5rem 1.25rem 0.5rem 1.125rem'
          : '0.5rem 0.875rem',
        backgroundColor: state.isSelected
          ? theme.colors.select.selected
          : 'transparent',
      }),
      placeholder: (provided) => ({
        ...provided,
        color: 'inherit',
        margin: 0,
        height: 'fit-content !important',
        ...(isAsyncSelect ? { lineHeight: 1.5 } : {}),
      }),
      singleValue: (provided) => ({
        ...provided,
        height: 'fit-content !important',
        ...(isAsyncSelect ? { lineHeight: 1.5 } : {}),
      }),
    }),
    [
      isError,
      notSeparated,
      theme.colors.field.primary.border.error,
      theme.colors.select.background,
      theme.colors.select.color,
      theme.colors.select.default.border,
      theme.colors.select.default.menuBorder,
      theme.colors.select.notSeparated.border,
      theme.colors.select.notSeparated.menuBorder,
      theme.colors.select.selected,
      theme.dimensions.input.height.small,
      theme.shadows.mid,
      theme.shadows.small,
    ]
  );
};
