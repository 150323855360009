import { FORM_ERROR } from 'final-form';
import { noop } from 'lodash';
import { useCallback } from 'react';
import { SubmitHandler, useFormState } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { GetFeesProfileByEntityIdResponse } from '../../../../../setup/apiTypes/feesProfiles';
import apiRoutes from '../../../../../setup/consts/apiRoutes';
import { transformObjectToFormData } from '../../../../../utils/api';
import { mapApiErrorsToFormErrors } from '../../../../../utils/finalForm';
import { UPLOAD_ORDERS_MUTATION_FN } from './consts';

import { OrdersUploadFormData } from './types';

export const useUploadOrdersFormSubmitHandler = (
  onClose: () => void = noop,
  refetchOrders: () => void = noop
): SubmitHandler<OrdersUploadFormData> => {
  const intl = useIntl();
  const { addToast } = useToasts();
  const { mutateAsync: uploadOrders } = useMutation(UPLOAD_ORDERS_MUTATION_FN);

  return useCallback(
    async ({ entity: entityId, file }) => {
      try {
        const formData = transformObjectToFormData({
          entityId,
          file,
        });

        await uploadOrders({
          data: formData,
        });

        refetchOrders();
        addToast(
          intl.formatMessage({
            id: 'enitiesAndUsers.ordersCreatedMessage',
            defaultMessage: 'Orders have been created',
          }),
          {
            appearance: 'success',
          }
        );
        onClose();

        return undefined;
      } catch (error) {
        const { errors, generalError } = mapApiErrorsToFormErrors(error, intl);

        if (errors) {
          return errors;
        }

        const defaultError = intl.formatMessage({
          id: 'orders.uploadOrdersFailed',
          defaultMessage: 'Failed to upload orders.',
        });

        addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
          appearance: 'error',
        });

        return {
          [FORM_ERROR]: defaultError,
        };
      }
    },
    [intl, uploadOrders, addToast, onClose, refetchOrders]
  );
};

export const useEntityHasProfileFees = (): boolean => {
  const {
    values: { entity },
  } = useFormState<OrdersUploadFormData>();

  const { error } = useQuery<
    UseQueryResponse<GetFeesProfileByEntityIdResponse>
  >(apiRoutes.FEES_PROFILE_BY_ENTITY_ID(entity || ''), {
    enabled: !!entity,
    retry: false,
    keepPreviousData: false,
  });

  return !!error;
};
