import React from 'react';
import { FormattedMessage, FormattedNumber, IntlShape } from 'react-intl';
import { ColumnDef } from '@tanstack/react-table';
import ButtonExternalLink from '../../components/atoms/Button/buttonExternalLink';
import { ButtonMode, ButtonSize } from '../../components/atoms/Button/enums';
import { SimpleSelectOption } from '../../components/atoms/Fields/Select/templates/SimpleSelect/types';
import { StyledBox } from '../../components/styles/Grid/styles';
import { makeDateCellRenderer } from '../../utils/tableRenderers';
import { StatementsTableItem } from './types';

export const statementsTableData: StatementsTableItem[] = [
  {
    statusCol: 'Statement',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    statementIdCol: 587458656,
    downloadCol: { pdfUrlAr: 'download.url' },
  },
  {
    statusCol: 'Statement',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    statementIdCol: 587458656,

    downloadCol: { pdfUrlAr: 'download.url' },
  },
  {
    statusCol: 'Statement',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    statementIdCol: 587458656,
    downloadCol: { pdfUrlAr: 'download.url' },
  },
  {
    statusCol: 'Statement',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    statementIdCol: 587458656,
    downloadCol: { pdfUrlAr: 'download.url' },
  },
  {
    statusCol: 'Statement',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    statementIdCol: 587458656,
    downloadCol: { pdfUrlAr: 'download.url' },
  },
  {
    statusCol: 'Statement',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    statementIdCol: 587458656,
    downloadCol: { pdfUrlAr: 'download.url' },
  },
  {
    statusCol: 'Statement',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    statementIdCol: 587458656,
    downloadCol: { pdfUrlAr: 'download.url' },
  },
  {
    statusCol: 'Statement',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    statementIdCol: 587458656,
    downloadCol: { pdfUrlAr: 'download.url' },
  },
  {
    statusCol: 'Statement',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    statementIdCol: 587458656,
    downloadCol: { pdfUrlAr: 'download.url' },
  },
  {
    statusCol: 'Statement',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    statementIdCol: 587458656,
    downloadCol: { pdfUrlAr: 'download.url' },
  },
  {
    statusCol: 'Statement',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    statementIdCol: 587458656,
    downloadCol: { pdfUrlAr: 'download.url' },
  },
  {
    statusCol: 'Statement',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    statementIdCol: 587458656,
    downloadCol: { pdfUrlAr: 'download.url' },
  },
  {
    statusCol: 'Statement',
    valueIqdCol: 25000,
    dateCol: '01/02/2020',
    statementIdCol: 587458656,
    downloadCol: { pdfUrlAr: 'download.url' },
  },
];

export const statementsTableColumns = (
  intl: IntlShape
): ColumnDef<StatementsTableItem>[] => [
  {
    header: intl.formatMessage({
      id: 'financials.status',
      defaultMessage: 'Status',
    }),
    accessorKey: 'statusCol',
  },
  {
    header: intl.formatMessage({
      id: 'orders.valueIQD',
      defaultMessage: 'Value IQD',
    }),
    accessorKey: 'valueIqdCol',
    cell: ({ cell: { getValue } }) => (
      <FormattedNumber value={getValue() as number} />
    ),
  },
  {
    header: intl.formatMessage({
      id: 'orders.date',
      defaultMessage: 'Date',
    }),
    accessorKey: 'dateCol',
    cell: makeDateCellRenderer(),
  },
  {
    header: intl.formatMessage({
      id: 'financials.statementId',
      defaultMessage: 'Statement ID',
    }),
    accessorKey: 'statementIdCol',
  },
  {
    accessorKey: 'downloadCol',
    header: '',
    cell: ({ cell: { getValue } }) => {
      const statementUrls = getValue() as StatementsTableItem['downloadCol'];

      return (
        <StyledBox justifyContent='space-evenly'>
          {statementUrls.pdfUrlAr && (
            <ButtonExternalLink
              mode={ButtonMode.Secondary}
              href={statementUrls.pdfUrlAr}
              download
              size={ButtonSize.Small}
            >
              <FormattedMessage
                id='forms.buttons.download'
                defaultMessage='Download Ar'
              />
            </ButtonExternalLink>
          )}
          {statementUrls.pdfUrlEn && (
            <ButtonExternalLink
              mode={ButtonMode.Secondary}
              href={statementUrls.pdfUrlEn}
              download
              size={ButtonSize.Small}
            >
              <FormattedMessage
                id='forms.buttons.downloadEn'
                defaultMessage='Download En'
              />
            </ButtonExternalLink>
          )}
          {statementUrls.excelUrl && (
            <ButtonExternalLink
              mode={ButtonMode.Primary}
              href={statementUrls.excelUrl}
              download
              size={ButtonSize.Small}
            >
              <FormattedMessage
                id='forms.buttons.export'
                defaultMessage='Export to excel'
              />
            </ButtonExternalLink>
          )}
        </StyledBox>
      );
    },
  },
];

export const yearDropdownOptions: SimpleSelectOption[] = Array.from(
  {
    length: 25,
  },
  (_, index) => {
    const year = (index + 2021).toString();

    return {
      label: year,
      value: year,
    };
  }
);
