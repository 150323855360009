import { FormEvent, useCallback, useRef, useState } from 'react';

import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { IconGlobalLocation, IconCustomer2 } from '../../../assets/svg/icons';
import Autocomplete from '../../../components/atoms/Fields/Autocomplete';
import { BaseAutocompleteItem } from '../../../components/atoms/Fields/Autocomplete/types';
import routes from '../../../setup/consts/routes';
import { UseAutocompletePlaceholderValuesReturnType } from './types';

export const useAutocompletePlaceholderValues =
  (): UseAutocompletePlaceholderValuesReturnType => {
    const intl = useIntl();
    const { pathname } = useLocation();

    const buttonLabel =
      (pathname === routes.ENTITIES &&
        intl.formatMessage({
          id: 'enitiesAndUsers.createNewEntity',
          defaultMessage: 'Create New Entity',
        })) ||
      (pathname === routes.USERS &&
        intl.formatMessage({
          id: 'enitiesAndUsers.createNewUsers',
          defaultMessage: 'Create New User',
        })) ||
      '';
    const placeholderTitle =
      (pathname === routes.ENTITIES &&
        intl.formatMessage({
          id: 'enitiesAndUsers.addEntities',
          defaultMessage: 'Add Entities',
        })) ||
      (pathname === routes.USERS &&
        intl.formatMessage({
          id: 'enitiesAndUsers.addUsers',
          defaultMessage: 'Add Users',
        })) ||
      '';
    const placeholderDescription =
      (pathname === routes.ENTITIES &&
        intl.formatMessage({
          id: 'enitiesAndUsers.entitiesPlaceholderDescription',
          defaultMessage:
            'You can add multiple Entities to your business, these entities can represent sub-brands, locations, etc.',
        })) ||
      (pathname === routes.USERS &&
        intl.formatMessage({
          id: 'enitiesAndUsers.usersPlaceholderDescription',
          defaultMessage:
            'You can add and assign roles to users and associate them with one or more of your business entities.',
        })) ||
      '';
    const placeholderIcon =
      pathname === routes.ENTITIES ? IconGlobalLocation : IconCustomer2;

    return {
      buttonLabel,
      placeholderTitle,
      placeholderDescription,
      placeholderIcon,
    };
  };

interface UseSearchFormHandlersReturnType {
  searchQuery: string | undefined;
  onSearchFormSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onInputChange: (value: string) => void;
  autocompleteRefGetter: (ref: Autocomplete<string, unknown> | null) => void;
  onSuggestionClick: (suggestion: BaseAutocompleteItem<string>) => void;
  onClearButtonClick: () => void;
}

export const useSearchFormHandlers = (): UseSearchFormHandlersReturnType => {
  const autocompleteRef = useRef<Autocomplete<string, unknown> | null>(null);
  const [autocompleteSearchInputValue, setAutocompleteSearchInputValue] =
    useState('');
  const [searchQuery, setSearchQuery] = useState<string>();
  const onSearchFormSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const autocomplete = autocompleteRef.current;

      if (autocomplete && autocomplete.inputRef) {
        autocomplete.inputRef.blur();
      }

      if (autocompleteSearchInputValue) {
        setSearchQuery(autocompleteSearchInputValue);
      }
    },
    [autocompleteSearchInputValue]
  );
  const onInputChange = useCallback((value) => {
    setAutocompleteSearchInputValue(value);

    if (!value) {
      setSearchQuery(undefined);
    }
  }, []);
  const autocompleteRefGetter = useCallback(
    (ref: Autocomplete<string, unknown> | null) => {
      autocompleteRef.current = ref;
    },
    []
  );
  const onSuggestionClick = useCallback(
    (suggestion: BaseAutocompleteItem<string>) => {
      setSearchQuery(suggestion.label);
    },
    []
  );
  const onClearButtonClick = useCallback(() => {
    setSearchQuery(undefined);

    const autocomplete = autocompleteRef.current;

    if (autocomplete) {
      autocomplete.setState({ value: '' });
    }
  }, []);

  return {
    searchQuery,
    onSearchFormSubmit,
    onInputChange,
    autocompleteRefGetter,
    onSuggestionClick,
    onClearButtonClick,
  };
};
