import isValid from 'date-fns/isValid';
import format from 'date-fns/format';

export const makeDateCellRenderer =
  (dateFormat = 'dd/MM/yyyy'): any =>
  ({ getValue }: any) => {
    const date = new Date(getValue());

    return isValid(date) ? format(date, dateFormat) : '-';
  };
