import styled from 'styled-components';

export const StyledImageSlider = styled.div`
  width: 100%;
  padding-bottom: 1.875rem;
  position: relative;
  .swiper-container {
    position: initial;
  }
  .swiper-pagination {
    display: flex;
    justify-content: center;
  }
  .swiper-pagination-bullet {
    width: ${({ theme }) =>
      theme.dimensions.imageSlider.paginationBullet.width};
    height: ${({ theme }) =>
      theme.dimensions.imageSlider.paginationBullet.height};
    border: 1px solid ${({ theme }) => theme.colors.background.dark};
    background-color: transparent;
    opacity: 0.56;
  }
  .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.colors.background.dark};
    opacity: 1;
  }
  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 0.3125rem;
  }
`;

export const StyledImageWrapper = styled.figure`
  width: 100%;
  height: ${({ theme }) => theme.dimensions.imageSlider.imageWrapper.height};
  overflow: hidden;
  border-radius: 1rem;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
