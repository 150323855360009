export const parseJson = <T>(jsonString: string | null): T | null => {
  if (!jsonString) {
    return null;
  }

  try {
    return JSON.parse(jsonString) as T;
  } catch (error) {
    return null;
  }
};

export function downloadFile(blobRes: Blob, fileName: string): void {
  // create file link in browser's memory
  const href = window.URL.createObjectURL(new Blob([blobRes]));

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');

  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}
