import styled from 'styled-components';

export const StyledAssignedUsersWrapper = styled.div`
  padding-top: 1.525rem;
`;

export const StyledSearchWrapper = styled.div`
  z-index: ${({ theme }) => theme.zindexes.search};
  position: relative;
  width: 100%;
  max-width: 17.5rem;
  margin-bottom: 2.25rem;
`;

export const StyledPlaceholderWrapper = styled.div`
  width: 100%;
  max-width: 17.5rem;
`;
