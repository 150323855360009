import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useIntl } from 'react-intl';
import { WarehouseProductCardListProps } from './types';
import {
  ProductsPlaceholderWrapper,
  StyledWarehouseProductCardList,
  StyledWarehouseProductCardListItem,
} from './styles';
import WarehouseProductCard from '../WarehouseProductCard';
import { StyledBox } from '../../styles/Grid/styles';
import Spinner from '../../atoms/Spinner';
import { SpinnerMode } from '../../atoms/Spinner/enums';
import Placeholder from '../Placeholder';

const WarehouseProductCardList: React.FC<WarehouseProductCardListProps> = ({
  items,
  isHorizontal,
  hasMore,
  fetchMoreData,
  refetch,
}) => {
  const handleFetchMoreData = useCallback(() => {
    fetchMoreData();
  }, [fetchMoreData]);
  const noData = items.length === 0;
  const intl = useIntl();

  return (
    <>
      {noData ? (
        <ProductsPlaceholderWrapper>
          <Placeholder
            title={intl.formatMessage({
              id: 'warehouse.noResults',
              defaultMessage: 'No results',
            })}
          />
        </ProductsPlaceholderWrapper>
      ) : null}

      <InfiniteScroll
        style={{ overflow: 'hidden' }}
        dataLength={items.length}
        next={handleFetchMoreData}
        loader={
          <StyledBox justifyContent='center' alignItems='center' p='2rem'>
            <Spinner mode={SpinnerMode.Dark} />
          </StyledBox>
        }
        hasChildren
        scrollableTarget='main-content'
        {...{ hasMore }}
      >
        <StyledWarehouseProductCardList {...{ isHorizontal }}>
          {items.map((item) => (
            <StyledWarehouseProductCardListItem
              {...{ key: item.productId, isHorizontal }}
            >
              <WarehouseProductCard
                {...{ isHorizontal, ...item }}
                refetchProducts={refetch}
              />
            </StyledWarehouseProductCardListItem>
          ))}
        </StyledWarehouseProductCardList>
      </InfiniteScroll>
    </>
  );
};

export default WarehouseProductCardList;
