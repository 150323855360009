import React from 'react';
import { DeliveryTimelineProps } from './types';
import {
  StyledDeliveryTimelineBox,
  StyledDeliveryTimelineDate,
  StyledDeliveryTimelineDescription,
  StyledDeliveryTimelineList,
  StyledDeliveryTimelineListItem,
  StyledDeliveryTimelineStatus,
  StyledDeliveryTimelineStatusWrapper,
  StyledDeliveryTimelineTime,
  StyledDeliveryTimelineTitle,
} from './styles';
import { formatDate } from '../../atoms/Fields/Datepicker/utils';

const DeliveryTimeline: React.FC<DeliveryTimelineProps> = ({ items }) => (
  <StyledDeliveryTimelineList>
    {items.map(({ title, description, date, time, color }, index) => (
      <StyledDeliveryTimelineListItem key={index} isActive={index === 0}>
        <StyledDeliveryTimelineBox>
          <div>
            <StyledDeliveryTimelineDate>
              {formatDate(new Date(date))}
            </StyledDeliveryTimelineDate>
            <StyledDeliveryTimelineTime>{time}</StyledDeliveryTimelineTime>
          </div>
          <StyledDeliveryTimelineStatusWrapper>
            <StyledDeliveryTimelineStatus
              isActive={index === 0}
              {...{ color }}
            />
          </StyledDeliveryTimelineStatusWrapper>
          <div>
            <StyledDeliveryTimelineTitle>{title}</StyledDeliveryTimelineTitle>

            <StyledDeliveryTimelineDescription>
              {description}
            </StyledDeliveryTimelineDescription>
          </div>
        </StyledDeliveryTimelineBox>
      </StyledDeliveryTimelineListItem>
    ))}
  </StyledDeliveryTimelineList>
);

export default DeliveryTimeline;
