import React from 'react';
import { Field } from 'react-final-form';
import { IconChevronDown } from '../../../../../../assets/svg/icons';
import SelectBase from '../../base';
import {
  FormAsyncSelectProps,
  FormSelectProps,
  SimpleAsyncSelectProps,
  SimpleSelectProps,
} from './types';
import AsyncSelectBase from '../../base/async';

const SimpleSelect: React.FC<SimpleSelectProps> = ({
  options,
  errorMessage,
  isError,
  placeholder,
  label: fieldLabel,
  disabled,
  value,
  onChange,
}) => (
  <SelectBase
    icon={IconChevronDown}
    label={fieldLabel}
    {...{
      options,
      errorMessage,
      isError,
      placeholder,
      disabled,
      value,
      onChange,
    }}
  />
);

const AsyncSimpleSelect: React.FC<SimpleAsyncSelectProps> = ({
  options,
  errorMessage,
  isError,
  placeholder,
  label: fieldLabel,
  disabled,
  value,
  onChange,
  loadOptions,
}) => (
  <AsyncSelectBase
    icon={IconChevronDown}
    label={fieldLabel}
    {...{
      options,
      errorMessage,
      isError,
      placeholder,
      disabled,
      value,
      onChange,
      loadOptions,
    }}
  />
);

export const FormSimpleSelect: React.FC<FormSelectProps> = ({
  name,
  ...props
}) => (
  <Field<string> {...{ name }}>
    {({ input, meta: { error, submitError } }) => (
      <SimpleSelect
        {...props}
        isError={!!(error || submitError)}
        errorMessage={error || submitError}
        {...input}
      />
    )}
  </Field>
);

export const FormSimpleAsyncSelect: React.FC<FormAsyncSelectProps> = ({
  name,
  ...props
}) => (
  <Field<string> {...{ name }}>
    {({ input, meta: { error, submitError } }) => (
      <AsyncSimpleSelect
        {...props}
        isError={!!(error || submitError)}
        errorMessage={error || submitError}
        {...input}
      />
    )}
  </Field>
);

export default SimpleSelect;
