import React from 'react';
import { LoaderProps } from './types';
import { loaderTemplate } from './utils';

const Loader: React.FC<LoaderProps> = ({ mode }) => {
  const LoaderTemplate = loaderTemplate(mode);

  return <LoaderTemplate />;
};

export default Loader;
