import { useEffect, useMemo, useState } from 'react';
import Geocode from 'react-geocode';
import { useDebounce } from 'use-debounce';
import { DEBOUNCE_TIME_MS, DEFAULT_COORDS } from './consts';
import { Coordinates, UseGeocodeAddressOptions } from './types';

const getCoordsFromAddress = async (address: string): Promise<Coordinates> => {
  try {
    const {
      results: [result],
    } = await Geocode.fromAddress(address);
    const { lat, lng } = result.geometry.location;

    return {
      lat,
      lng,
    };
  } catch (error) {
    return DEFAULT_COORDS;
  }
};

const useGeocodeAddress = (options: UseGeocodeAddressOptions): Coordinates => {
  const [apiKeySet, setApiKeySet] = useState(false);
  const [coords, setCoords] = useState(DEFAULT_COORDS);

  const query = useMemo(() => {
    if (typeof options === 'string') {
      return options;
    }

    const { address = '', city = '' } = options;

    return `${city}${city.length > 0 ? ', ' : ''}${address}`;
  }, [options]);

  const [debouncedQuery] = useDebounce(query, DEBOUNCE_TIME_MS);

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
      setApiKeySet(true);
    }
  }, []);

  useEffect(() => {
    async function geocode() {
      setCoords(await getCoordsFromAddress(debouncedQuery));
    }

    if (apiKeySet) {
      geocode();
    }
  }, [apiKeySet, debouncedQuery]);

  return coords;
};

export default useGeocodeAddress;
