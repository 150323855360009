import { defineMessages, IntlShape } from 'react-intl';
import {
  IconWarehouse2,
  IconOrders,
  IconFinance,
  IconCustomer2,
  IconMail,
} from '../../../assets/svg/icons';
import routes from '../../../setup/consts/routes';
import { SideMenuItem } from '../../molecules/SideMenu/types';
import { SideMenuItemDefinition } from '../types';

const {
  DASHBOARD,
  WAREHOUSE,
  ORDERS,
  FINANCIALS,
  CUSTOMERS,
  CONTACT_US,
  SETTINGS_SECTION,
  ENTITIES_AND_USERS,
  ENTITIES,
  BILLING_AND_PAYMENTS,
  ACCOUNT_DETAILS,
} = routes;

const menuItemsIntl = defineMessages({
  [DASHBOARD]: {
    id: 'mainNavigation.dashboard',
    defaultMessage: 'Dashboard',
  },
  [WAREHOUSE]: {
    id: 'mainNavigation.warehouse',
    defaultMessage: 'Warehouse',
  },
  [ORDERS]: {
    id: 'mainNavigation.orders',
    defaultMessage: 'Orders',
  },
  [FINANCIALS]: {
    id: 'mainNavigation.financials',
    defaultMessage: 'Financials',
  },
  [CUSTOMERS]: {
    id: 'mainNavigation.customers',
    defaultMessage: 'Customers',
  },
});

export const getMenuItems = ({
  isSandoogUser,
  isAdvancedUser,
}: {
  isSandoogUser: boolean;
  isAdvancedUser: boolean;
}): SideMenuItemDefinition[] => [
  // {
  //   label: menuItemsIntl[DASHBOARD],
  //   to: DASHBOARD,
  //   icon: IconDashboard,
  //   key: '1',
  // },
  ...(isAdvancedUser
    ? [
        {
          label: menuItemsIntl[WAREHOUSE],
          to: WAREHOUSE,
          icon: IconWarehouse2,
          key: '2',
        },
      ]
    : []),
  {
    label: menuItemsIntl[ORDERS],
    to: ORDERS,
    icon: IconOrders,
    key: '3',
  },
  {
    label: menuItemsIntl[FINANCIALS],
    to: FINANCIALS,
    icon: IconFinance,
    key: '4',
  },
  ...(isSandoogUser
    ? [
        {
          label: menuItemsIntl[CUSTOMERS],
          to: CUSTOMERS,
          icon: IconCustomer2,
          key: '5',
        },
      ]
    : []),
];

export const sideMenuAdditionalItemsWithIcons = (
  intl: IntlShape
): SideMenuItem[] => [
  {
    label: intl.formatMessage({
      id: 'mainNavigation.contactUs',
      defaultMessage: 'Contact Us',
    }),
    to: CONTACT_US,
    key: '1',
    icon: IconMail,
  },
];

export enum UserViewPermissions {
  Dashboard = 'view:sdg-dashboard',
  Settings = 'view:sdg-settings',
  Orders = 'view:sdg-orders',
  Warehouse = 'view:sdg-warehouse',
  Financials = 'view:sdg-financials',
  Customer = 'view:sdg-customer',
  EntitiesUser = 'view:sdg-entities-user',
}

export const menuItemPermissionsMap: Record<string, UserViewPermissions> = {
  [DASHBOARD]: UserViewPermissions.Dashboard,
  [SETTINGS_SECTION]: UserViewPermissions.Settings,
  [ORDERS]: UserViewPermissions.Orders,
  [WAREHOUSE]: UserViewPermissions.Warehouse,
  [FINANCIALS]: UserViewPermissions.Financials,
  [CUSTOMERS]: UserViewPermissions.Customer,
  [ENTITIES_AND_USERS]: UserViewPermissions.EntitiesUser,
  [ENTITIES]: UserViewPermissions.EntitiesUser,
  [BILLING_AND_PAYMENTS]: UserViewPermissions.Settings,
  [ACCOUNT_DETAILS]: UserViewPermissions.Settings,
};
