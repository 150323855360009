import React from 'react';
import { PlaceholderProps } from './types';
import {
  StyledPlaceholder,
  StyledPlaceholderAction,
  StyledPlaceholderDecor,
  StyledPlaceholderDescription,
  StyledPlaceholderTitle,
} from './styles';
import Button from '../../atoms/Button';
import { PlaceholderSize } from './enums';
import { ButtonMode } from '../../atoms/Button/enums';

const Placeholder: React.FC<PlaceholderProps> = ({
  title,
  description,
  size = PlaceholderSize.Big,
  buttonLabel,
  onClick,
  icon: Icon,
}) => {
  const titleVariant = size === PlaceholderSize.Big ? 'h1' : 'bodyBold';
  const descriptionVariant = size === PlaceholderSize.Big ? 'body' : 'caption';

  return (
    <StyledPlaceholder>
      {Icon ? (
        <StyledPlaceholderDecor {...{ size }}>
          <Icon />
        </StyledPlaceholderDecor>
      ) : null}

      <StyledPlaceholderTitle
        variant={titleVariant}
        forwardedAs='h3'
        {...{ size }}
      >
        {title}
      </StyledPlaceholderTitle>
      {description && (
        <StyledPlaceholderDescription
          variant={descriptionVariant}
          {...{ size }}
        >
          {description}
        </StyledPlaceholderDescription>
      )}
      {buttonLabel && (
        <StyledPlaceholderAction>
          <Button {...{ onClick }} type='button' mode={ButtonMode.Secondary}>
            + {buttonLabel}
          </Button>
        </StyledPlaceholderAction>
      )}
    </StyledPlaceholder>
  );
};

export default Placeholder;
