import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import useHeaderTitleContext from '../../../hooks/useHeaderTitleContext';
import { OrderFormValues } from './types';
import Loader from '../../../components/atoms/Loader';
import { LoaderMode } from '../../../components/atoms/Loader/enums';
import { useOrderFormSubmitHandler, useOrderInitialValues } from './hooks';
import OrderForm from './components/OrderForm';
import { COMMON_MUTATORS } from '../../../utils/finalForm';
import Typography from '../../../components/styles/Typography';

const OrderFormPage: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const { loading, initialValues, error } = useOrderInitialValues(orderId);
  const { setHeaderTitle } = useHeaderTitleContext();
  const intl = useIntl();
  const { loadingSubmit, submitHandler } = useOrderFormSubmitHandler(orderId);

  useEffect(() => {
    setHeaderTitle(
      intl.formatMessage(
        orderId
          ? {
              id: 'orders.editOrder',
              defaultMessage: 'Edit Order',
            }
          : {
              id: 'orders.placeOrder',
              defaultMessage: 'Place Order',
            }
      )
    );
  }, [intl, orderId, setHeaderTitle]);

  if (error) {
    return <Typography>Failed to load order</Typography>;
  }

  return loading ? (
    <Loader mode={LoaderMode.PlaceOrder} />
  ) : (
    <Form<OrderFormValues>
      onSubmit={submitHandler}
      mutators={{
        ...COMMON_MUTATORS,
        removeRow: ([index], state, utils) => {
          utils.changeValue(state, 'products', (value) => [
            ...value.slice(0, index),
            ...value.slice(index + 1),
          ]);
        },
      }}
      {...{ initialValues }}
    >
      {({ handleSubmit }) => (
        <OrderForm
          isEdit={!!orderId}
          loadingSubmit={loadingSubmit}
          {...{ handleSubmit }}
        />
      )}
    </Form>
  );
};

export default OrderFormPage;
