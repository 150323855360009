import { FORM_ERROR } from 'final-form';
import { useCallback, useMemo } from 'react';
import { SubmitHandler } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { GetEntityResponse } from '../../../../../../setup/apiTypes/entities';
import apiRoutes from '../../../../../../setup/consts/apiRoutes';
import { transformObjectToFormData } from '../../../../../../utils/api';
import { mapApiErrorsToFormErrors } from '../../../../../../utils/finalForm';
import {
  CREATE_ENTITY_MUTATION_FN,
  MAKE_UPDATE_ENTITY_MUTATION_FN,
} from './consts';
import { EntityFormValues, UseEntityDetailsReturnType } from './types';

export const useEntityFormSubmitHandler = (
  refetchEntities: (() => Promise<void>) | undefined,
  closeModal: () => void,
  entityId: string | undefined
): SubmitHandler<EntityFormValues> => {
  const { mutateAsync: createEntity } = useMutation(CREATE_ENTITY_MUTATION_FN);
  const { mutateAsync: updateEntity } = useMutation(
    MAKE_UPDATE_ENTITY_MUTATION_FN(entityId || '')
  );
  const intl = useIntl();
  const { addToast } = useToasts();

  return useCallback(
    async (values) => {
      const { users, logo, ...data } = values;
      const dataToSend = {
        ...data,
        users: users.filter(({ checked }) => checked).map(({ value }) => value),
      };
      const formData = transformObjectToFormData({
        ...dataToSend,
        ...(logo && typeof logo !== 'string' ? { logo } : {}),
      });

      try {
        const mutationFn = entityId ? updateEntity : createEntity;

        await mutationFn({
          data: formData,
        });

        await refetchEntities?.();
        closeModal();

        const successMessageDescriptior = entityId
          ? {
              id: 'enitiesAndUsers.entityUpdatedMessage',
              defaultMessage: 'Entity updated successfuly',
            }
          : {
              id: 'enitiesAndUsers.entityCreatedMessage',
              defaultMessage: 'Entity has been created',
            };

        addToast(intl.formatMessage(successMessageDescriptior), {
          appearance: 'success',
        });

        return undefined;
      } catch (error) {
        const { errors, generalError } = mapApiErrorsToFormErrors(error, intl);

        if (errors) {
          return errors;
        }

        const errorMessageDescriptior = entityId
          ? {
              id: 'enitiesAndUsers.updateEntityFailed',
              defaultMessage: 'Failed to update entity',
            }
          : {
              id: 'enitiesAndUsers.createEntityFailed',
              defaultMessage: 'Failed to create entity',
            };
        const defaultError = intl.formatMessage(errorMessageDescriptior);

        addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
          appearance: 'error',
        });

        return {
          [FORM_ERROR]: defaultError,
        };
      }
    },
    [entityId, updateEntity, createEntity, closeModal, addToast, intl]
  );
};

export const useEntityDetails = (
  entityId: string | undefined
): UseEntityDetailsReturnType => {
  const { isLoading, data } = useQuery<UseQueryResponse<GetEntityResponse>>(
    apiRoutes.ENTITY_DETAILS(entityId || ''),
    {
      enabled: !!entityId,
    }
  );

  return {
    isLoading,
    entity: useMemo(() => {
      if (!data) {
        return undefined;
      }

      const { businessType, city, email, logo, name, phoneNumber, users } =
        data.response;

      return {
        name,
        phoneNumber,
        email,
        city,
        businessType,
        users: users.map(({ name: userName, email: userEmail, id }) => ({
          label: `${userName} (${userEmail})`,
          value: id,
          checked: true,
        })),
        logo: logo || undefined,
      };
    }, [data]),
  };
};
