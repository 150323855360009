import React, { HTMLProps, useEffect } from 'react';
import { FormattedNumber, IntlShape } from 'react-intl';
import { ColumnDef } from '@tanstack/react-table';
import { makeDateCellRenderer } from '../../../utils/tableRenderers';
import { useStatusOptions } from '../../../pages/orders/hooks';
import Tag from '../../atoms/Tag';
import Typography from '../../styles/Typography';
import ItemsPopup from '../ItemsPopup';
import { CustomerOrder } from '../../organisms/Modal/templates/CustomerDetailsModal/types';
import { StyledDeliveryLabelLink } from './styles';
import Checkbox from '../../atoms/Fields/Checkbox';

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current) {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, indeterminate]);

  return <Checkbox label='' type='checkbox' ref={ref} {...rest} />;
}
export const customerOrdersTableColumns = (
  intl: IntlShape
): ColumnDef<CustomerOrder>[] => [
  {
    id: 'select-col',

    header: ({ table }) => (
      <div className='px-1'>
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      </div>
    ),
    cell: ({ row }) => (
      <div className='px-1'>
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  },
  {
    header: intl.formatMessage({
      id: 'orders.products',
      defaultMessage: 'Products',
    }),
    accessorKey: 'delivery.deliveryItems',
    cell: ({ cell: { getValue } }) => {
      const products = getValue() as CustomerOrder['delivery']['deliveryItems'];

      const productsSummary = `${products[0]?.name} ${
        products.length > 1 ? `+ ${products.length - 1}` : ''
      }`;

      return (
        <>
          <Typography data-tip data-for='order-items'>
            {products.length > 1 ? productsSummary : products[0]?.name || '-'}
          </Typography>

          <ItemsPopup tooltipId='order-items' products={products} />
        </>
      );
    },
  },
  {
    header: intl.formatMessage({
      id: 'date',
      defaultMessage: 'Date',
    }),
    accessorKey: 'createdOn',
    cell: makeDateCellRenderer(),
  },
  {
    header: intl.formatMessage({
      id: 'receiptNo',
      defaultMessage: 'Receipt No.',
    }),
    accessorKey: 'externalReference',
  },
  {
    header: intl.formatMessage({
      id: 'orders.status',
      defaultMessage: 'Order Status',
    }),
    accessorKey: 'status',
    cell: ({ cell: { getValue } }) => {
      const { options } = useStatusOptions();
      const { color, label } =
        options.find((option) => option.value === getValue()) ?? {};

      return <Tag {...{ color }}>{label}</Tag>;
    },
  },
  {
    header: intl.formatMessage({
      id: 'customers.deliveryLabel',
      defaultMessage: 'Delivery Label',
    }),
    accessorKey: 'deliveryLabel',
    cell: ({ cell: { getValue } }) => {
      const value = getValue() as string;

      if (!value) {
        return '-';
      }

      return (
        <StyledDeliveryLabelLink>
          <a href={value} target='_blank' rel='no noreferrer'>
            {value.slice(0, 20)}...
          </a>
        </StyledDeliveryLabelLink>
      );
    },
  },
  {
    header: intl.formatMessage({
      id: 'customers.valueIQD',
      defaultMessage: 'Value IQD',
    }),
    accessorKey: 'totalPrice',
    cell: ({ cell: { getValue } }) => (
      <FormattedNumber value={getValue() as number} />
    ),
  },
];
