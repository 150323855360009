import React, { useEffect, useMemo, useState } from 'react';
import Table from '.';
import Loader from '../../atoms/Loader';
import { LoaderMode } from '../../atoms/Loader/enums';
import Placeholder from '../Placeholder';
import { useTableData } from './hooks';
import { DataTableProps } from './types';

// eslint-disable-next-line @typescript-eslint/ban-types
function DataTable<T extends object>({
  endpoint,
  placeholderProps,
  tableAPIRef,
  filterItems,
  queryOptions,
  query = {},
  pageSize = 10,
  mockData,
  pageParamKey = 'page',
  ...props
}: DataTableProps<T>): ReactComponentReturnType {
  const [page, setPage] = useState(1);
  const { loading, data, refetch, pageCount, totalCount } = useTableData<T>(
    endpoint,
    page,
    pageSize,
    query,
    pageParamKey,
    queryOptions
  );

  const tableData = mockData ?? data;

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query)]);

  useEffect(() => {
    if (!tableAPIRef) {
      return;
    }

    tableAPIRef({
      page,
      pageSize,
      loading,
      isEmpty: data.length === 0,
      totalCount,
      refetch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, refetch]);

  const filteredData = useMemo(() => {
    if (!filterItems) {
      return tableData;
    }

    return tableData.filter(filterItems);
  }, [tableData, filterItems]);

  return loading ? (
    <Loader mode={LoaderMode.Table} />
  ) : (
    <>
      {filteredData.length ? (
        <Table<T>
          data={filteredData}
          onPageChange={setPage}
          disabledPagination={totalCount === undefined}
          pageSize={totalCount === undefined ? tableData.length : pageSize}
          currentPage={page}
          {...{ ...props, pageCount, totalCount }}
        />
      ) : (
        <Placeholder {...placeholderProps} />
      )}
    </>
  );
}

export default DataTable;
