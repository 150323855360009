import styled, { css } from 'styled-components';
import { StyledWarehouseProductCardListProps } from './types';

export const StyledWarehouseProductCardList = styled.ul<StyledWarehouseProductCardListProps>`
  ${({ isHorizontal }) =>
    isHorizontal
      ? css`
          width: 100%;
        `
      : css`
          display: flex;
          flex-wrap: wrap;
          width: calc(100% + 1.5rem);
          margin-left: -0.75rem;
          margin-right: -0.75rem;
          margin-top: -0.75rem;
          margin-bottom: -0.75rem;
        `};
`;

export const StyledWarehouseProductCardListItem = styled.li<StyledWarehouseProductCardListProps>`
  ${({ isHorizontal }) =>
    isHorizontal
      ? css`
          &:not(:last-child) {
            margin-bottom: 1.125rem;
          }
        `
      : css`
          width: 25%;
          padding: 0.75rem 0.75rem 0.75rem 0.75rem;
        `};
`;

export const ProductsPlaceholderWrapper = styled.div`
  > div {
    margin: 0;

    h3 {
      text-align: start;
    }
  }
`;
