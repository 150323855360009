import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

const useDidUpdateEffect = (fn: EffectCallback, deps: DependencyList): void => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useDidUpdateEffect;
