import { SubmitHandler } from 'react-final-form';
import { useMutation, useQuery } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { useIntl } from 'react-intl';
import { useMemo, useState } from 'react';
import { FORM_ERROR } from 'final-form';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
  CREATE_PRODUCT_MUTATION_FN,
  MAKE_UPDATE_PRODUCT_MUTATION_FN,
} from './consts';
import { mapApiErrorsToFormErrors } from '../../../../../utils/finalForm';
import apiRoutes from '../../../../../setup/consts/apiRoutes';
import { GetProductDetailsResponse } from '../../../../../setup/apiTypes/warehouse';
import { makeApiUrl } from '../../../../../utils/reactQuery';
import {
  ProductFormValues,
  UploadedProductImage,
  UseProductInitialValuesReturnType,
} from '../../types';
import routes from '../../../../../setup/consts/routes';

export const useProductFormSubmitHandler = ({
  productId,
  images,
  coverImage,
}: {
  productId: string | undefined;
  images: File[];
  coverImage?: File;
}): { submitHandler: SubmitHandler<ProductFormValues>; isLoading: boolean } => {
  const { mutateAsync: createProduct, isLoading: loadingCreate } = useMutation(
    CREATE_PRODUCT_MUTATION_FN
  );
  const { mutateAsync: updateProduct, isLoading: loadingUpdate } = useMutation(
    MAKE_UPDATE_PRODUCT_MUTATION_FN(productId || '')
  );

  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToasts();
  const [loadingUploadImages, setLoadingUploadImages] =
    useState<boolean>(false);

  const imagesToSend = new FormData();
  // eslint-disable-next-line no-unused-expressions
  coverImage && imagesToSend.append('coverImage', coverImage);
  // eslint-disable-next-line no-unused-expressions
  images.length &&
    images.forEach((image, idx) => {
      imagesToSend.append(`images`, image);
    });

  const submitHandler: SubmitHandler<ProductFormValues> = async ({
    ...values
  }) => {
    try {
      const mutateProductFn = productId ? updateProduct : createProduct;

      await mutateProductFn({
        data: {
          ...values,
          categoryId: Number(values.categoryId),
        },
      }).then(({ response: res }: any) => {
        // ** Handle send the images after creating the product
        if (res?.id && (coverImage || images.length)) {
          setLoadingUploadImages(true);
          axios
            .post(
              makeApiUrl(apiRoutes.UPLOAD_PRODUCT_IMAGES(res.id)),
              imagesToSend,
              {
                headers: { 'Content-type': 'multipart/form-data' },
              }
            )
            .then((imagesRes) => {
              if (imagesRes.status === 200) history.push(routes.WAREHOUSE);
            })
            .catch((err) => {
              addToast(err.response.data.message, {
                appearance: 'error',
              });
            })
            .finally(() => setLoadingUploadImages(false));
        } else {
          history.push(routes.WAREHOUSE);
        }
      });

      const successMessageDescriptior = productId
        ? {
            id: 'warehouse.productUpdatedMessage',
            defaultMessage: 'Product updated successfully.',
          }
        : {
            id: 'warehouse.productCreatedMessage',
            defaultMessage: 'Product has been created.',
          };

      addToast(intl.formatMessage(successMessageDescriptior), {
        appearance: 'success',
      });

      return undefined;
    } catch (error) {
      const { errors, generalError } = mapApiErrorsToFormErrors(error, intl);

      if (errors) {
        return errors;
      }

      const errorMessageDescriptior = productId
        ? {
            id: 'warehouse.updateProductFailed',
            defaultMessage: 'Failed to update product.',
          }
        : {
            id: 'warehouse.createProductFailed',
            defaultMessage: 'Failed to create product.',
          };
      const defaultError = intl.formatMessage(errorMessageDescriptior);

      addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
        appearance: 'error',
      });

      return {
        [FORM_ERROR]: defaultError,
      };
    }
  };

  return {
    submitHandler,
    isLoading: loadingCreate || loadingUpdate || loadingUploadImages,
  };
};

export const useSaveProductImagesHandler = ({
  productId,
  images,
  coverImage,
}: {
  productId: number;
  images: UploadedProductImage['file'][];
  coverImage?: File;
}): { submitHandler: any; isLoading: boolean } => {
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToasts();
  const [loadingUploadImages, setLoadingUploadImages] =
    useState<boolean>(false);

  const imagesToSend = new FormData();
  // eslint-disable-next-line no-unused-expressions
  coverImage && imagesToSend.append('coverImage', coverImage);
  // eslint-disable-next-line no-unused-expressions
  images.length &&
    images.forEach((image, idx) => {
      if (image) {
        imagesToSend.append(`images`, image);
      }
    });

  const submitHandler = async () => {
    try {
      if ((coverImage || images.length) && productId) {
        setLoadingUploadImages(true);

        await axios
          .post(
            makeApiUrl(apiRoutes.UPLOAD_PRODUCT_IMAGES(productId)),
            imagesToSend,
            {
              headers: { 'Content-type': 'multipart/form-data' },
            }
          )
          .then((imagesRes) => {
            if (imagesRes.status === 200) {
              history.push(routes.WAREHOUSE);

              const successMessageDescriptior = productId
                ? {
                    id: 'warehouse.productUpdatedMessage',
                    defaultMessage: 'Product updated successfully.',
                  }
                : {
                    id: 'warehouse.productCreatedMessage',
                    defaultMessage: 'Product has been created.',
                  };

              addToast(intl.formatMessage(successMessageDescriptior), {
                appearance: 'success',
              });
            }
          })
          .catch((err) => {
            addToast(err.response.data.message, {
              appearance: 'error',
            });
          })
          .finally(() => setLoadingUploadImages(false));
      } else {
        history.push(routes.WAREHOUSE);
      }

      return undefined;
    } catch (error) {
      const { errors, generalError } = mapApiErrorsToFormErrors(error, intl);

      if (errors) {
        return errors;
      }

      const errorMessageDescriptior = productId
        ? {
            id: 'warehouse.updateProductFailed',
            defaultMessage: 'Failed to update product.',
          }
        : {
            id: 'warehouse.createProductFailed',
            defaultMessage: 'Failed to create product.',
          };
      const defaultError = intl.formatMessage(errorMessageDescriptior);

      addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
        appearance: 'error',
      });

      return {
        [FORM_ERROR]: defaultError,
      };
    }
  };

  return {
    submitHandler,
    isLoading: loadingUploadImages,
  };
};

export const useProductInitialValues = (
  productId: string | undefined
): UseProductInitialValuesReturnType => {
  const { data, isLoading, error } = useQuery<
    UseQueryResponse<GetProductDetailsResponse>
  >(apiRoutes.PRODUCT_DETAILS(productId || ''), {
    enabled: !!productId,
  });

  return {
    initialValues: useMemo((): ProductFormValues | undefined => {
      if (!data || !data.response) {
        return undefined;
      }

      const values = data.response.product;

      return {
        categoryId: values.categoryId.toString(),
        description: values.description,
        name: values.name,
        price: values.sellingPriceIqd,
        quantity: -100,
      };
    }, [data]),
    loading: isLoading,
    productData: data?.response.product,
    error: !!error,
  };
};
