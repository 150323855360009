import styled, { css } from 'styled-components';
import Typography from '../../styles/Typography';
import { StyledDeliveryTimelineProps } from './types';

export const StyledDeliveryTimelineList = styled.ul`
  width: 100%;
`;

export const StyledDeliveryTimelineBox = styled.div`
  display: grid;
  grid-template-columns: 6rem 1.25rem 1fr;
  grid-gap: 1.5rem;
`;

export const StyledDeliveryTimelineStatusWrapper = styled.div`
  width: 100%;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 1.25rem;
    bottom: -2rem;
    left: 50%;
    transform: translateX(-50%);
    border-left: 2px dashed
      ${({ theme }) => theme.colors.deliveryTimeline.decorColor};
    opacity: 0.3;
  }
`;

export const StyledDeliveryTimelineStatus = styled.div<StyledDeliveryTimelineProps>`
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;

  border-width: 2px;
  border-style: solid;
  ${({ isActive, color, theme }) =>
    isActive
      ? css`
          background-color: ${color};
          border-color: ${color};
        `
      : css`
          background-color: ${theme.colors.deliveryTimeline.status.background};
          border-color: ${theme.colors.deliveryTimeline.status.border};
        `};
`;

export const StyledDeliveryTimelineTitle = styled(Typography).attrs({
  forwardedAs: 'h3',
  variant: 'bodyBold',
})`
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 0.375rem;
  opacity: 0.54;
`;

export const StyledDeliveryTimelineDescription = styled(Typography).attrs({
  variant: 'caption',
})`
  color: ${({ theme }) => theme.colors.text.primary};

  opacity: 0.54;
`;

export const StyledDeliveryTimelineDate = styled(Typography).attrs({
  variant: 'caption',
})`
  color: ${({ theme }) => theme.colors.text.primary};

  opacity: 0.54;
  margin-bottom: 0.5rem;
  text-align: right;
`;

export const StyledDeliveryTimelineTime = styled(Typography).attrs({
  variant: 'caption',
})`
  color: ${({ theme }) => theme.colors.text.primary};
  text-align: right;
`;

export const StyledDeliveryTimelineListItem = styled.li<StyledDeliveryTimelineProps>`
  width: 100%;
  &:not(:last-child) {
    ${StyledDeliveryTimelineBox} {
      padding-bottom: 2rem;
    }
  }
  &:last-child {
    ${StyledDeliveryTimelineStatusWrapper}::after {
      display: none;
    }
  }
  ${({ isActive }) =>
    isActive &&
    css`
      ${StyledDeliveryTimelineTitle} {
        opacity: 1;
      }
      ${StyledDeliveryTimelineDescription} {
        opacity: 0.87;
      }
    `};
`;
