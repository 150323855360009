import { FORM_ERROR } from 'final-form';
import { orderBy } from 'lodash';
import noop from 'lodash/noop';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import apiRoutes from '../../../../../setup/consts/apiRoutes';
import SupportedLanguages from '../../../../../setup/intl/enums';
import { mapApiErrorsToFormErrors } from '../../../../../utils/finalForm';
import { mapCurrencyToCurrencyCode } from '../../../../../utils/misc';
import { CANCEL_ORDER_MUTATION_FN } from './consts';
import {
  OrderDetailsModel,
  OrderSummaryModel,
  UseCancelOrderReturnType,
} from './types';

export interface OrderSummaryData extends OrderSummaryModel {
  isCancelled: boolean;
}

interface UseOrderDetailsReturnType {
  order: OrderSummaryData | undefined;
  loading: boolean;
}

export const useOrderDetails = (
  orderId: string | undefined,
  onClose: () => void
): UseOrderDetailsReturnType => {
  const intl = useIntl();
  const { addToast } = useToasts();
  const { data, isLoading } = useQuery<UseQueryResponse<OrderDetailsModel>>(
    apiRoutes.ORDER_SUMMARY_ORDER_DETAILS(orderId || ''),
    {
      enabled: !!orderId,
      onError: (error) => {
        const { generalError } = mapApiErrorsToFormErrors(error, intl);

        const defaultError = intl.formatMessage({
          id: 'order.fetchOrderSummaryFailed',
          defaultMessage: "Couldn't load order summary.",
        });

        addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
          appearance: 'error',
        });

        onClose();
      },
    }
  );

  const isEnglish = useMemo(
    () => intl.locale === SupportedLanguages.English,
    [intl.locale]
  );

  return {
    order: useMemo(() => {
      if (!data) {
        return undefined;
      }

      const currency = mapCurrencyToCurrencyCode(data.response.currency);
      return {
        ...data.response,
        currency,
        deliveryItems: data.response.deliveryItems.map((item) => ({
          ...item,
          currency,
        })),
        deliveryTimeline: orderBy(
          data.response.deliveryTimeline,
          (item) => new Date(item.date),
          'desc'
        ).map(
          ({
            enStatusName,
            arStatusName,
            enDescription,
            arDescription,
            date,
            color,
          }) => ({
            title: isEnglish ? enStatusName : arStatusName,
            date,
            time: intl.formatTime(new Date(date)),
            description: isEnglish ? enDescription : arDescription,
            color,
          })
        ),
        isCancelled: data.response.deliveryTimeline.some(
          (item) => item.step === 'Cancelled'
        ),
      };
    }, [data, intl, isEnglish]),
    loading: isLoading,
  };
};

export const useCancelOrder = (
  orderId: string,
  fulfillmentId: string | undefined,
  onSuccess: () => void = noop
): UseCancelOrderReturnType => {
  const intl = useIntl();
  const { addToast } = useToasts();

  const { mutateAsync: cancelOrder, isLoading } = useMutation(
    CANCEL_ORDER_MUTATION_FN(orderId)
  );

  return {
    cancelOrder: useCallback(async () => {
      if (!fulfillmentId) {
        return undefined;
      }

      try {
        await cancelOrder({
          data: {
            fulfillmentId,
          },
        });
        onSuccess();

        addToast(
          intl.formatMessage({
            id: 'order.cancelOrderSuccess',
            defaultMessage: 'The order has been canceled',
          }),
          {
            appearance: 'success',
          }
        );

        return undefined;
      } catch (error) {
        const { errors, generalError } = mapApiErrorsToFormErrors(error, intl);

        if (errors) {
          return errors;
        }

        const defaultError = intl.formatMessage({
          id: 'order.cancelOrderFailed',
          defaultMessage: 'There was an error canceling the order',
        });

        addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
          appearance: 'error',
        });

        return {
          [FORM_ERROR]: defaultError,
        };
      }
    }, [fulfillmentId, cancelOrder, onSuccess, addToast, intl]),
    isLoading,
  };
};
