import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { variant } from 'styled-system';
import { textStyles } from '../../../theme/config/typography';
import Typography from '../../styles/Typography';
import { StyledTableRowProps } from './types';

export const StyledTable = styled.table`
  width: 100%;
`;

export const StyledTableHeadText = styled(Typography).attrs({
  forwardedAs: 'h3',
  variant: 'caption',
})`
  color: ${({ theme }) => rgba(theme.colors.text.secondary, 0.54)};
  padding: 1rem 0;
`;

export const StyledTableBody = styled.tbody`
  width: 100%;
`;

export const StyledTableRow = styled.tr<StyledTableRowProps>`
  transition: ${({ theme }) => theme.transitions.default};
  border-bottom: 1px solid
    ${({ theme }) => rgba(theme.colors.text.primary, 0.1)};

  &:hover {
    ${({ clickable }) =>
      clickable &&
      css`
        cursor: pointer;
      `}
    background-color: ${({ theme }) => theme.colors.background.medium};
  }
`;

export const StyledTableHead = styled.thead`
  border-bottom: 1px solid
    ${({ theme }) => rgba(theme.colors.text.primary, 0.2)};
  padding: 0.25rem 0;

  & > ${StyledTableRow}:hover {
    cursor: initial;
    background-color: initial;
  }
`;

export const StyledTableCell = styled.td.attrs({
  variant: 'body',
})`
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.text.secondary};
  ${variant({
    variants: {
      ...textStyles,
    },
  })};
  &:not(:last-child) {
    padding-right: 1rem;
  }
`;

export const StyledTableCellContent = styled.div`
  min-height: 3.875rem;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
