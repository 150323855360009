import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ThemeContext } from 'styled-components';
import { OrdersBarsWrapper, StatsBox } from '../styles';
import { IconOrders } from '../../../../assets/svg/icons';
import { StyledBox } from '../../../styles/Grid/styles';
import Typography from '../../../styles/Typography';
import ProgressBar from '../../../atoms/ProgressBar';
import DashboardSalesByCityChart from './SalesByCity';
import colors from '../../../../theme/config/colors';
import Link from '../../../atoms/Link';

const OrdersStats: React.FC = () => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);

  return (
    <StatsBox>
      {/* Icon */}
      <IconOrders height='30px' />

      <StyledBox flexDirection='column' width='100%'>
        {/* Title */}
        <StyledBox alignItems='center' gap='1rem'>
          <Typography variant='bodyBold'>
            <FormattedMessage id='dashboard.orders' defaultMessage='Orders' />
          </Typography>

          <Typography variant='caption'>
            <Link to='/orders' color={colors.text.secondary}>
              <FormattedMessage id='seeAll' defaultMessage='seeAll' />
            </Link>
          </Typography>
        </StyledBox>

        <StyledBox
          justifyContent='space-between'
          width='100%'
          alignItems='center'
        >
          {/* Bars */}
          <OrdersBarsWrapper>
            <ProgressBar
              label={intl.formatMessage({
                id: 'dashboard.returns',
                defaultMessage: 'Returns',
              })}
              barColor={theme.colors.progressBar.returnsBar}
              percent={30}
            />
            <ProgressBar
              label={intl.formatMessage({
                id: 'dashboard.disputes',
                defaultMessage: 'Disputes',
              })}
              barColor={theme.colors.progressBar.disputesBar}
              percent={10}
            />
          </OrdersBarsWrapper>

          {/* Sales By City */}
          <DashboardSalesByCityChart />
        </StyledBox>
      </StyledBox>
    </StatsBox>
  );
};

export default OrdersStats;
