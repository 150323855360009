import React, { useContext } from 'react';
import ReactSelect, { components } from 'react-select';
import { ThemeContext } from 'styled-components';
import FieldWrapper from '../../FieldWrapper';
import { useCustomStyles } from './hooks';
import IndicatorsContainer from './IndicatorsContainer';
import Option from './Option';
import Placeholder from './Placeholder';
import { BaseSelectItem, SelectProps } from './types';

function SelectBase<
  T = string,
  P extends BaseSelectItem<T> = BaseSelectItem<T>
>({
  renderOptionContent,
  renderPlaceholderContent,
  renderControlContent,
  icon,
  withoutIndicatorRotation,
  notSeparated = false,
  isError = false,
  errorMessage,
  label,
  disabled,
  options,
  value,
  onChange,
  hideErrorMessageWrapper = false,
  ...props
}: SelectProps<T, P>): ReactComponentReturnType {
  const theme = useContext(ThemeContext);
  const customStyles = useCustomStyles<P>(notSeparated, isError);
  const selectedItem = options?.find((option) => option.value === value);

  return (
    <FieldWrapper
      error={isError ? errorMessage : ''}
      hideErrorMessageWrapper={hideErrorMessageWrapper}
      control={
        <ReactSelect<P>
          onChange={
            onChange
              ? (option) => {
                  if (option && option.value !== undefined) {
                    onChange(option.value);
                  }
                }
              : undefined
          }
          value={selectedItem || null}
          components={{
            SingleValue: ({ children, ...singleValueProps }) => (
              <components.SingleValue {...singleValueProps}>
                {renderControlContent
                  ? renderControlContent(
                      singleValueProps.selectProps.value as any
                    )
                  : children}
              </components.SingleValue>
            ),
            IndicatorsContainer: (indicatorsContainerProps) => (
              <IndicatorsContainer
                {...{
                  icon,
                  withoutIndicatorRotation,
                  ...indicatorsContainerProps,
                }}
              />
            ),
            Option: (optionProps) => (
              <Option {...{ renderOptionContent, ...optionProps }} />
            ),
            Placeholder: (placeholderProps) => (
              <Placeholder
                {...{ renderPlaceholderContent, ...placeholderProps }}
              />
            ),
          }}
          menuPlacement='auto'
          isRtl={theme.direction === 'rtl'}
          styles={customStyles}
          isDisabled={disabled}
          {...{ ...props, options }}
        />
      }
      {...{ label }}
    />
  );
}

export default SelectBase;
