import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { FormattedMessage } from 'react-intl';
import { CustomerLastOrderCardProps } from './types';
import { StyledCustomerLastOrderCard } from './styles';
import { StyledBox } from '../../styles/Grid/styles';
import Typography from '../../styles/Typography';
import Button from '../../atoms/Button';
import { ButtonMode } from '../../atoms/Button/enums';
import OrderSummaryModal from '../../organisms/Modal/templates/OrderSummaryModal';
import useToggle from '../../../hooks/useToggle';
import ItemsPopup from '../ItemsPopup';

const CustomerLastOrderCard: React.FC<CustomerLastOrderCardProps> = (props) => {
  const { orderData } = props;
  const { id } = orderData;
  const [summaryModalIsOpen, { toggle: toggleSummaryModal }] = useToggle();
  const productsLength = orderData?.delivery?.deliveryItems?.length;

  const productsSummary = `${orderData?.delivery?.deliveryItems[0]?.name}  ${
    productsLength > 1 ? `+ ${orderData.delivery.deliveryItems.length - 1}` : ''
  }`;

  const formattedDate = new Date(orderData.createdOn).toLocaleDateString();

  return (
    <>
      <AnimatePresence>
        {summaryModalIsOpen ? (
          <OrderSummaryModal orderId={id} onClose={toggleSummaryModal} />
        ) : null}
      </AnimatePresence>

      <ItemsPopup
        tooltipId='order-products'
        products={orderData?.delivery?.deliveryItems}
      />

      <StyledCustomerLastOrderCard>
        <StyledBox flexDirection='column'>
          <Typography variant='label'>
            <FormattedMessage id='products.product' />
          </Typography>
          <Typography data-tip data-for='order-products' variant='bodyBold'>
            {productsSummary}
          </Typography>
        </StyledBox>
        <StyledBox flexDirection='column'>
          <Typography variant='label'>
            <FormattedMessage id='orders.date' />
          </Typography>
          <Typography variant='bodyBold'>{formattedDate}</Typography>
        </StyledBox>
        <StyledBox flexDirection='column'>
          <Typography variant='label'>
            <FormattedMessage id='priceIqd' />
          </Typography>
          <Typography variant='bodyBold'>{orderData.totalPrice}</Typography>
        </StyledBox>
        <StyledBox flexDirection='column'>
          <Typography variant='label'>
            <FormattedMessage id='orders.receiptNo' />
          </Typography>
          <Typography variant='bodyBold'>
            {orderData.externalReference || '-'}
          </Typography>
        </StyledBox>
        <StyledBox>
          <Button mode={ButtonMode.Secondary} onClick={toggleSummaryModal}>
            <FormattedMessage id='viewOrderSummary' />
          </Button>
        </StyledBox>
      </StyledCustomerLastOrderCard>
    </>
  );
};

export default CustomerLastOrderCard;
