import axios from 'axios';
import {
  authErrorResponseInterceptor,
  authRequestInterceptor,
  authResponseInterceptor,
} from './interceptors/auth';

import selectedEntitiesInterceptor from './interceptors/selectedEntities';

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  authResponseInterceptor,
  authErrorResponseInterceptor
);
axios.interceptors.request.use(selectedEntitiesInterceptor);
