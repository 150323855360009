import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotFoundSvg } from '../assets/svg/images';
import ButtonLink from '../components/atoms/Button/buttonLink';
import { StyledBox } from '../components/styles/Grid/styles';
import Typography from '../components/styles/Typography';
import routes from '../setup/consts/routes';
import LoginInnerLayout from './login/Layout';

const NotFoundPage: React.FC = () => (
  <LoginInnerLayout>
    <StyledBox mb='1.25rem' justifyContent='center'>
      <NotFoundSvg />
    </StyledBox>
    <StyledBox mb='1.25rem' justifyContent='center'>
      <Typography as='h1' variant='h1'>
        <FormattedMessage
          id='notFoundPage.pageNotFound'
          defaultMessage='Page Not Found'
        />
      </Typography>
    </StyledBox>
    <StyledBox justifyContent='center'>
      <ButtonLink to={routes.HOME}>
        <FormattedMessage id='notFoundPage.back' defaultMessage='Back' />
      </ButtonLink>
    </StyledBox>
  </LoginInnerLayout>
);

export default NotFoundPage;
