import React from 'react';
import { UploadProps } from './types';
import {
  StyledUploadCoverImage,
  StyledIconWrapper,
  StyledUpload,
  StyledUploadInput,
  StyledUploadLabel,
} from './styles';
import { IconUpload } from '../../../assets/svg/icons';

const Upload: React.FC<UploadProps> = ({
  label,
  coverImage,
  isBig,
  onChange,
  isMultiple,
  disabled,
}) => {
  const { src, alt } = coverImage || {};

  return (
    <StyledUpload {...{ isBig }}>
      <StyledUploadInput
        type='file'
        onChange={(e) =>
          onChange && e.target?.files && onChange(e.target.files)
        }
        multiple={isMultiple}
        disabled={disabled}
        accept='image/png, image/jpg, image/jpeg'
      />
      {label && <StyledUploadLabel>{label}</StyledUploadLabel>}
      {src ? (
        <StyledUploadCoverImage {...{ src, alt }} />
      ) : (
        <StyledIconWrapper>
          <IconUpload />
        </StyledIconWrapper>
      )}
    </StyledUpload>
  );
};

export default Upload;
