import React, { useContext } from 'react';
import ContentLoader from 'react-content-loader';
import { ThemeContext } from 'styled-components';

const CashoutBoxTemplate: React.FC = () => {
  const theme = useContext(ThemeContext);

  return (
    <ContentLoader
      speed={2}
      width='100%'
      height={258}
      viewBox='0 0 100% 258'
      backgroundColor={theme.colors.loader.background}
      foregroundColor={theme.colors.loader.foreground}
    >
      <rect x='0' y='0' rx='16' ry='16' width='100%' height='258' />
    </ContentLoader>
  );
};

export default CashoutBoxTemplate;
