import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';
import {
  IconClose,
  IconGrid,
  IconList,
  IconSearch,
} from '../../assets/svg/icons';
import { FieldMode } from '../../components/atoms/Fields/enums';
import Input from '../../components/atoms/Fields/Input';
import Loader from '../../components/atoms/Loader';
import { LoaderMode } from '../../components/atoms/Loader/enums';
import WarehouseProductCardList from '../../components/molecules/WarehouseProductCardList';
import { StyledBox } from '../../components/styles/Grid/styles';
import useHeaderTitleContext from '../../hooks/useHeaderTitleContext';
import { StyledAction, StyledForm, StyledProductListWrapper } from './styles';
import IconButton from '../../components/atoms/IconButton';
import { useWarehouseProducts } from './hooks';
import Spinner from '../../components/atoms/Spinner';
import { SpinnerMode, SpinnerSize } from '../../components/atoms/Spinner/enums';
import { StyledClearButton } from '../../components/atoms/Fields/Autocomplete/styles';

const WarehousePage: React.FC = () => {
  const { setHeaderTitle } = useHeaderTitleContext();
  const intl = useIntl();
  const [isHorizontalView, setIsHorizontalView] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedQuery] = useDebounce(searchQuery, 3000);
  const { items, hasMore, isFetching, isLoading, fetchMore, refetch } =
    useWarehouseProducts({
      pageSize: 8,
      query: debouncedQuery,
    });

  useEffect(() => {
    setHeaderTitle(
      intl.formatMessage({
        id: 'mainNavigation.warehouse',
        defaultMessage: 'Warehouse',
      })
    );
  }, [intl, setHeaderTitle]);

  useEffect(() => {
    if (!debouncedQuery) refetch();
  }, [debouncedQuery, refetch]);

  const handleSearchQueryChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchQuery(e.target.value);
  };

  const clearSearchQuery = () => {
    setSearchQuery('');
  };

  return (
    <>
      <StyledBox mb='2rem' justifyContent='space-between'>
        <StyledForm onSubmit={(e) => e.preventDefault()}>
          <Input
            mode={FieldMode.Tertiary}
            placeholder={intl.formatMessage({
              id: 'search.searchProducts',
              defaultMessage: 'Search Products',
            })}
            isRounded
            prefixComponent={<IconSearch />}
            suffixComponent={
              (isFetching && searchQuery) ||
              (debouncedQuery && !searchQuery) ? (
                <Spinner
                  mode={SpinnerMode.Dark}
                  size={SpinnerSize.ExtraSmall}
                />
              ) : debouncedQuery ? (
                <StyledClearButton type='button' onClick={clearSearchQuery}>
                  <IconClose />
                </StyledClearButton>
              ) : null
            }
            value={searchQuery}
            onChange={handleSearchQueryChange}
            type='input'
            disabled={isLoading || isFetching}
          />
        </StyledForm>
        {/* <StyledFilterWrapper>
          <SelectBase
            placeholder='Select filter...'
            icon={IconDropdown}
            options={[{ value: 'date-created', label: 'Date created' }]}
            notSeparated
            onChange={noop}
          />
        </StyledFilterWrapper> */}
        <StyledAction>
          <IconButton
            isSecondary
            isActive={!isHorizontalView}
            icon={IconGrid}
            onClick={() => setIsHorizontalView(!isHorizontalView)}
          />
          <IconButton
            isSecondary
            isActive={isHorizontalView}
            icon={IconList}
            onClick={() => setIsHorizontalView(!isHorizontalView)}
          />
        </StyledAction>
      </StyledBox>
      <StyledProductListWrapper>
        {isLoading ? (
          <Loader
            mode={
              isHorizontalView
                ? LoaderMode.ProductOrderCardList
                : LoaderMode.WarehouseGridList
            }
          />
        ) : (
          <WarehouseProductCardList
            {...{ hasMore, fetchMoreData: fetchMore }}
            isHorizontal={isHorizontalView}
            items={items}
            refetch={refetch}
          />
        )}
      </StyledProductListWrapper>
    </>
  );
};

export default WarehousePage;
