import { useMemo } from 'react';
import { useQuery } from 'react-query';
import qs from 'qs';
import urljoin from 'url-join';
import { SimpleSelectOption } from '../../components/atoms/Fields/Select/templates/SimpleSelect/types';
import apiRoutes from '../../setup/consts/apiRoutes';
import {
  GetCategoriesListResponse,
  UseCategoriesListReturnType,
} from './types';

export const useCategoriesList = (): UseCategoriesListReturnType => {
  const queryAsString = qs.stringify(
    {
      pageSize: 10,
    },
    {
      addQueryPrefix: true,
    }
  );

  const { data, isLoading } = useQuery<
    UseQueryResponse<GetCategoriesListResponse>
  >(urljoin(apiRoutes.CATEGORIES_LIST, queryAsString));

  return {
    list: useMemo(() => {
      const { response } = data ?? {};
      const { categories } = response ?? {};

      if (!Array.isArray(categories)) {
        return [];
      }

      return categories.map<SimpleSelectOption>(({ name, id }) => ({
        value: id.toString(),
        label: name,
      }));
    }, [data]),
    isLoading,
  };
};
