import { QueryClient } from 'react-query';

import { defaultQueryFunction } from '../../utils/reactQuery';

const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      queryFn: defaultQueryFunction,
    },
  },
});

export default reactQueryClient;
