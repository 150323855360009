import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../../components/atoms/Button';
import { FormInput } from '../../../components/atoms/Fields/Input';
import { FormRadioList } from '../../../components/atoms/Fields/Radio';
import renderDefaultRadioItem from '../../../components/atoms/Fields/Radio/content/default';
import { FormAvatarUpload } from '../../../components/molecules/AvatarUpload';
import { StyledBox } from '../../../components/styles/Grid/styles';
import Typography from '../../../components/styles/Typography';
import useHeaderTitleContext from '../../../hooks/useHeaderTitleContext';
import useUserContext from '../../../hooks/useUserContext';
import SupportedLanguages from '../../../setup/intl/enums';
import { languageRadioItems } from './consts';
import { useAccountFormSubmitHandler } from './hooks';
import { StyledRadioListWrapper } from './styles';
import { AccountFormValues } from './types';

const AccountDetailsPage: React.FC = () => {
  const { user, isUserLoading } = useUserContext();
  const { setHeaderTitle } = useHeaderTitleContext();
  const intl = useIntl();
  const submitHandler = useAccountFormSubmitHandler();

  useEffect(() => {
    setHeaderTitle(
      intl.formatMessage({
        id: 'sidebar.settings.accountDetails',
        defaultMessage: 'Account details',
      })
    );
  }, [intl, setHeaderTitle]);

  const {
    companyAddress,
    companyName,
    emailAddress,
    language: accountLanguage,
    phoneNumber,
    logo,
  } = user?.accountDetails || {};

  return (
    <Form<AccountFormValues>
      onSubmit={submitHandler}
      initialValues={{
        companyAddress: companyAddress || '',
        companyName: companyName || '',
        emailAddress: emailAddress || '',
        language: accountLanguage || SupportedLanguages.English,
        phoneNumber: phoneNumber || '',
        logo: logo || undefined,
      }}
    >
      {({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Row gutterWidth={96}>
            <Col md={4}>
              <FormInput
                name='companyName'
                label={intl.formatMessage({
                  id: 'forms.fields.companyName',
                  defaultMessage: 'Company Name',
                })}
                disabled={isUserLoading || submitting}
              />
              <FormInput
                name='companyAddress'
                label={intl.formatMessage({
                  id: 'forms.fields.companyAddress',
                  defaultMessage: 'Company address',
                })}
                disabled={isUserLoading || submitting}
              />
              <FormInput
                name='emailAddress'
                label={intl.formatMessage({
                  id: 'forms.fields.emailAddress',
                  defaultMessage: 'Email address',
                })}
                disabled={isUserLoading || submitting}
                type='email'
              />
              <FormInput
                name='phoneNumber'
                label={intl.formatMessage({
                  id: 'forms.fields.phoneNumber',
                  defaultMessage: 'Phone number',
                })}
                disabled={isUserLoading || submitting}
                type='tel'
              />
              <StyledBox mt='1.375rem'>
                <Typography variant='body' as='h3'>
                  <FormattedMessage
                    id='common.preferredSystemLanguage'
                    defaultMessage='Preferred system language'
                  />
                </Typography>
              </StyledBox>
              <StyledRadioListWrapper mt='1rem'>
                <FormRadioList<SupportedLanguages>
                  disabled={isUserLoading || submitting}
                  name='language'
                  items={languageRadioItems(intl)}
                  renderRadioContent={renderDefaultRadioItem}
                />
              </StyledRadioListWrapper>
              <StyledBox mt='1.5rem'>
                <Button type='submit' disabled={isUserLoading || submitting}>
                  <FormattedMessage
                    id='forms.buttons.save'
                    defaultMessage='Save'
                  />
                </Button>
              </StyledBox>
            </Col>
            <Col md={3}>
              <StyledBox mt='1.75rem'>
                <FormAvatarUpload
                  name='logo'
                  label={
                    values.logo
                      ? intl.formatMessage({
                          id: 'common.changeLogo',
                          defaultMessage: 'Change Logo',
                        })
                      : intl.formatMessage({
                          id: 'common.addLogo',
                          defaultMessage: 'Add Logo',
                        })
                  }
                  isBig
                />
              </StyledBox>
            </Col>
          </Row>
        </form>
      )}
    </Form>
  );
};

export default AccountDetailsPage;
