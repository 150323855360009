import { useMemo } from 'react';
import useSearch from '../../../../../hooks/useSearch';
import { PreferredCurrency } from '../../../../../setup/apiTypes/enums';
import { GetProductsResponse } from '../../../../../setup/apiTypes/products';
import apiRoutes from '../../../../../setup/consts/apiRoutes';
import { ProductOrderCardListItem } from '../../../../molecules/ProductOrderCardList/types';
import { UseProductSearchReturnType } from './types';

interface PageResult<T> {
  items: T;
  pageIndex: number;
  pageSize: number;
  pagesCount: number;
  total: number;
}

export const useProductSearch = (
  currency: string,
  entityId?: string
): UseProductSearchReturnType => {
  const { result, ...rest } = useSearch<PageResult<GetProductsResponse>>({
    apiRoute: apiRoutes.PRODUCT_SEARCH,
    ...(entityId ? { params: { entityId } } : {}),
  });

  return {
    ...rest,
    products: useMemo(() => {
      const items = result?.reduce(
        (acc, page) => [...acc, ...page.response.items],
        [] as GetProductsResponse
      );

      return (items ?? []).map<ProductOrderCardListItem>((product) => {
        const {
          productId,
          productName,
          ordersCount,
          createdOn,
          priceIqd,
          priceUsd,
          coverImage,
          sku,
        } = product;

        return {
          key: productId.toString(),
          productNameLabel: '',
          productName,
          orderedQuantityLabel: '',
          orderedQuantity: ordersCount,
          dateCreatedLabel: '',
          dateCreated: new Date(createdOn),
          priceLabel: '',
          price: currency === PreferredCurrency.IQD ? priceIqd : priceUsd,
          currency,
          buttonLabel: '',
          onButtonClick: () => undefined,
          imageUrl: coverImage,
          imageAlt: '',
          buttonDisabled: false,
          sku,
        };
      });
    }, [result, currency]),
  };
};
