import { IntlShape } from 'react-intl';
import { BaseRadioItem } from '../../../components/atoms/Fields/Radio/types';
import flagUk from '../../../assets/images/flag-uk.png';
import flagArabic from '../../../assets/images/flag-arabic.png';
import SupportedLanguages from '../../../setup/intl/enums';
import { makeMutationFn } from '../../../utils/reactQuery';
import apiRoutes from '../../../setup/consts/apiRoutes';

export const languageRadioItems = (
  intl: IntlShape
): BaseRadioItem<SupportedLanguages>[] => [
  {
    value: SupportedLanguages.English,
    label: intl.formatMessage({
      id: 'languages.english',
      defaultMessage: 'English',
    }),
    decor: flagUk,
  },
  {
    value: SupportedLanguages.Arabic,
    label: intl.formatMessage({
      id: 'languages.arabic',
      defaultMessage: 'Arabic',
    }),
    decor: flagArabic,
  },
];

export const UPDATE_ACCOUNT_MUTATION_FN = makeMutationFn<FormData>(
  apiRoutes.ACCOUNT,
  {
    method: 'put',
  }
);
