import styled from 'styled-components';

export const StyledLoginLayoutWrapper = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.background.dark};
  display: flex;
  &::after {
    content: '';
    min-height: inherit;
    font-size: 0;
  }
`;
