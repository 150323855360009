import { useHistory } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import subMonths from 'date-fns/subMonths';
import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Range } from 'react-date-range';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';
import ButtonLink from '../../components/atoms/Button/buttonLink';
import Datepicker from '../../components/atoms/Fields/Datepicker';
import { DatepickerPosition } from '../../components/atoms/Fields/Datepicker/enums';
import StatusSelect from '../../components/atoms/Fields/Select/templates/StatusSelect';
import DataTable from '../../components/molecules/Table/dataTable';
import { StyledBox, StyledGridBox } from '../../components/styles/Grid/styles';
import useHeaderTitleContext from '../../hooks/useHeaderTitleContext';
import { GetOrdersResponseSingleItem } from '../../setup/apiTypes/orders';
import apiRoutes from '../../setup/consts/apiRoutes';
import routes from '../../setup/consts/routes';
import { ordersTableColumns } from './consts';
import {
  useExportOrders,
  usePlaceholderProps,
  useStatusOptions,
  useUploadOrdersFormSubmitHandler,
} from './hooks';
import { StyledForm, StyledTableWrapper } from './styles';
import OrderSummaryModal from '../../components/organisms/Modal/templates/OrderSummaryModal';
import UploadOrderModal from '../../components/organisms/Modal/templates/UploadOrderModal';
import useToggle from '../../hooks/useToggle';
import Button from '../../components/atoms/Button';
import { DataTableAPI } from '../../components/molecules/Table/types';
import UploadOrderStatusModal from '../../components/organisms/Modal/templates/UploadOrderStatusModal';
import { utcToLocal } from '../../utils/date';
import ConfirmationModal from '../../components/organisms/Modal/templates/ConfirmationModal';
import { missingProfileFeesModalMessages } from './orderFormPage/components/OrderForm/consts';
import { ButtonMode } from '../../components/atoms/Button/enums';
import Input from '../../components/atoms/Fields/Input';
import { FieldMode } from '../../components/atoms/Fields/enums';
import { IconClose, IconDownload, IconSearch } from '../../assets/svg/icons';
import Spinner from '../../components/atoms/Spinner';
import { SpinnerMode, SpinnerSize } from '../../components/atoms/Spinner/enums';
import { StyledClearButton } from '../../components/atoms/Fields/Autocomplete/styles';
import { StatusSelectProps } from '../../components/atoms/Fields/Select/templates/StatusSelect/types';

const defaultRange = {
  startDate: subMonths(new Date(), 1),
  endDate: new Date(),
};
const OrdersPage: React.FC = () => {
  // States
  const [range, setRange] = useState<Range>(defaultRange);
  const [detailsOrderId, setDetailsOrderId] = useState<string>();
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<string[]>(
    []
  );
  const [ordersTableAPI, setOrdersTableAPI] = useState<DataTableAPI>();
  const [searchQuery, setSearchQuery] = useState<string>('');

  // Hooks
  const history = useHistory();
  const { setHeaderTitle } = useHeaderTitleContext();
  const intl = useIntl();
  const tableColumns = useMemo(() => ordersTableColumns(intl), [intl]);
  const { options: statusSelectItems } = useStatusOptions();
  const { title, description, buttonLabel, Icon } = usePlaceholderProps();
  const goToCreateOrderPage = useCallback(() => {
    history.push(routes.PLACE_ORDER);
  }, [history]);
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const [
    isUploadStatusModalOpen,
    {
      toggleOn: toggleUploadStatusModalOn,
      toggleOff: toggleUploadStatusModalOff,
    },
  ] = useToggle();
  const [
    isMissingProfileFeesModalOpen,
    { toggleOn: toggleMissingProfileFeesModalOn },
  ] = useToggle();
  const { handleUploadOrders, taskId } = useUploadOrdersFormSubmitHandler(
    toggleOff,
    toggleUploadStatusModalOn
  );
  const [debouncedQuery] = useDebounce(searchQuery, 3000);
  const { handleDownloadOrdersExcelFile, isLoading: exportLoading } =
    useExportOrders();
  const [
    exportConfirmationModalIsOpen,
    { toggle: toggleExportConfirmationModal },
  ] = useToggle();

  // Handlers
  const handleEntityHasNoProfileFees = useCallback(() => {
    toggleOff();
    toggleMissingProfileFeesModalOn();
  }, [toggleOff, toggleMissingProfileFeesModalOn]);

  useEffect(() => {
    setHeaderTitle(
      intl.formatMessage({
        id: 'mainNavigation.orders',
        defaultMessage: 'Orders',
      })
    );
  }, [intl, setHeaderTitle]);

  const handleSearchQueryChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchQuery(e.target.value);
  };

  const clearSearchQuery = () => {
    setSearchQuery('');
  };

  const onClearFiltersButtonClick = () => {
    clearSearchQuery();
    setRange(defaultRange);
  };

  const onChangeSelectedStatusFilter: StatusSelectProps['onChange'] = (
    value
  ) => {
    let newValues = [];

    if (selectedStatusFilter.includes(value)) {
      newValues = selectedStatusFilter.filter((item) => item !== value);
    } else {
      newValues = [...selectedStatusFilter, value];
    }

    setSelectedStatusFilter(newValues);
  };

  const onExport = () => {
    if (exportConfirmationModalIsOpen) {
      toggleExportConfirmationModal();
    }

    handleDownloadOrdersExcelFile({
      statuses: selectedStatusFilter,
      from: range.startDate && utcToLocal(range.startDate).toISOString(),
      to: range.endDate && utcToLocal(range.endDate).toISOString(),
      page: ordersTableAPI?.page || 1,
      pageSize: ordersTableAPI?.pageSize || 10,
      search: debouncedQuery,
    });
  };

  return (
    <>
      {exportConfirmationModalIsOpen ? (
        <ConfirmationModal
          title={intl.formatMessage({ id: 'exportConfirmation' })}
          confirmButtonLabel={intl.formatMessage({ id: 'export' })}
          description={intl.formatMessage({
            id: 'ordersExportConfirmationDescription',
          })}
          cancelButtonLabel={intl.formatMessage({ id: 'cancel' })}
          withCloseButton
          onConfirmButtonClick={onExport}
          onClose={toggleExportConfirmationModal}
          loadingConfirm={exportLoading}
        />
      ) : null}

      <StyledBox mb='2rem' justifyContent='space-between'>
        {/* Search Input */}
        <StyledForm onSubmit={(e) => e.preventDefault()}>
          <Input
            mode={FieldMode.Tertiary}
            placeholder={intl.formatMessage({
              id: 'search.searchOrders',
              defaultMessage: 'Search Orders',
            })}
            isRounded
            prefixComponent={<IconSearch />}
            suffixComponent={
              (ordersTableAPI?.loading && searchQuery) ||
              (debouncedQuery && !searchQuery) ? (
                <Spinner
                  mode={SpinnerMode.Dark}
                  size={SpinnerSize.ExtraSmall}
                />
              ) : debouncedQuery ? (
                <StyledClearButton type='button' onClick={clearSearchQuery}>
                  <IconClose />
                </StyledClearButton>
              ) : null
            }
            value={searchQuery}
            onChange={handleSearchQueryChange}
            type='input'
            disabled={ordersTableAPI?.loading}
          />
        </StyledForm>

        <StyledBox justifyContent='flex-end' alignItems='center' flexShrink={0}>
          <Button
            icon={IconDownload}
            mode={ButtonMode.Secondary}
            style={{
              marginInlineEnd: '1rem',
            }}
            onClick={() => {
              if (
                ordersTableAPI?.totalCount &&
                ordersTableAPI.totalCount > 2000
              ) {
                toggleExportConfirmationModal();

                return;
              }

              onExport();
            }}
            disabled={exportLoading || ordersTableAPI?.isEmpty}
          >
            <StyledBox gap='0.75rem'>
              {intl.formatMessage({
                id: 'downloadXl',
                defaultMessage: 'Download (xl)',
              })}

              {exportLoading ? (
                <Spinner
                  mode={SpinnerMode.Dark}
                  size={SpinnerSize.ExtraSmall}
                />
              ) : null}
            </StyledBox>
          </Button>

          <StyledBox>
            <ButtonLink to={routes.PLACE_ORDER}>
              <FormattedMessage
                id='orders.createNewOrder'
                defaultMessage='Create new order'
              />
            </ButtonLink>
          </StyledBox>
          <StyledBox
            style={{
              marginInlineStart: '1rem',
            }}
          >
            <Button onClick={toggleOn}>
              <FormattedMessage
                id='orders.uploadOrders'
                defaultMessage='Upload Orders'
              />
            </Button>
          </StyledBox>
        </StyledBox>
      </StyledBox>
      <StyledBox mb='1rem'>
        <StyledGridBox
          gridTemplateColumns='12rem auto auto'
          gridGap='1.5rem'
          style={{ justifyContent: 'flex-end' }}
        >
          <StatusSelect
            placeholder={intl.formatMessage({
              id: 'status.filterByStatus',
              defaultMessage: 'Filter by status',
            })}
            options={statusSelectItems}
            value={selectedStatusFilter}
            onChange={onChangeSelectedStatusFilter}
          />
          <StyledBox alignItems='flex-start'>
            <Datepicker
              value={range}
              onChange={(item) => setRange(item)}
              position={DatepickerPosition.Right}
            />
          </StyledBox>
          <StyledBox>
            <Button
              mode={ButtonMode.Secondary}
              onClick={() => {
                setSelectedStatusFilter([]);
                onClearFiltersButtonClick();
              }}
            >
              <FormattedMessage
                id='search.clearFilters'
                defaultMessage='Clear filters'
              />
            </Button>
          </StyledBox>
        </StyledGridBox>
      </StyledBox>
      <StyledTableWrapper>
        <DataTable<GetOrdersResponseSingleItem>
          endpoint={apiRoutes.ORDERS}
          tableAPIRef={setOrdersTableAPI}
          placeholderProps={{
            title,
            description,
            buttonLabel,
            icon: Icon,
            onClick: goToCreateOrderPage,
          }}
          columns={tableColumns}
          query={{
            ...(range.startDate
              ? { from: utcToLocal(range.startDate).toISOString() }
              : {}),
            ...(range.endDate
              ? { to: utcToLocal(range.endDate).toISOString() }
              : {}),
            ...(debouncedQuery ? { search: debouncedQuery } : {}),
            statuses: selectedStatusFilter,
          }}
          onRowClick={({ original }) => setDetailsOrderId(original.id)}
          queryOptions={{
            cacheTime: 0,
          }}
        />
        <AnimatePresence>
          {detailsOrderId ? (
            <OrderSummaryModal
              orderId={detailsOrderId}
              refetchOrders={ordersTableAPI?.refetch}
              onClose={() => setDetailsOrderId(undefined)}
            />
          ) : null}
        </AnimatePresence>

        <AnimatePresence>
          {isOpen && (
            <UploadOrderModal
              uploadOrders={handleUploadOrders}
              isPopup
              onClose={toggleOff}
              onEntityHasNoProfileFees={handleEntityHasNoProfileFees}
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isMissingProfileFeesModalOpen && (
            <ConfirmationModal
              title={intl.formatMessage(missingProfileFeesModalMessages.title)}
              description={intl.formatMessage(
                missingProfileFeesModalMessages.description
              )}
              confirmButtonLabel={intl.formatMessage(
                missingProfileFeesModalMessages.confirmButtonLabel
              )}
              onConfirmButtonClick={() => history.push(routes.CONTACT_US)}
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isUploadStatusModalOpen && (
            <UploadOrderStatusModal
              {...{ taskId }}
              onClose={toggleUploadStatusModalOff}
              onUploadCompleted={ordersTableAPI?.refetch}
              closeButtonLabel={intl.formatMessage({
                id: 'forms.buttons.close',
                defaultMessage: 'Close',
              })}
            />
          )}
        </AnimatePresence>
      </StyledTableWrapper>
    </>
  );
};

export default OrdersPage;
