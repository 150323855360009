import { rgba } from 'polished';
import styled from 'styled-components';
import Typography from '../../../components/styles/Typography';
import { StyledLoginInnerLayoutProps } from './types';

export const StyledLoginInnerLayoutWrapper = styled.div<StyledLoginInnerLayoutProps>`
  width: 100%;
  display: flex;
  justify-content: ${({ isBig }) => (isBig ? 'flex-start' : 'center')};
  align-items: ${({ isBig }) => (isBig ? 'flex-start' : 'center')};
  padding: ${({ isBig }) =>
    isBig ? '4rem 4rem 0 7.5rem' : '4rem 4rem 4rem 7.5rem'};
  max-width: ${({ theme }) => theme.dimensions.signUpWrapper.maxWidth};
  margin: 0 auto;
`;

export const StyledLoginInnerLayoutBox = styled.div<StyledLoginInnerLayoutProps>`
  position: relative;
  width: 100%;
  max-width: ${({ theme, isBig }) =>
    isBig
      ? theme.dimensions.signUpBox.maxWidth
      : theme.dimensions.signInBox.maxWidth};
  padding: ${({ isBig }) =>
    isBig ? '6.25rem 8.5rem 3.5rem' : '3.5rem 4rem 3.5rem 8.5rem'};
  border-radius: ${({ isBig }) => (isBig ? '1.5rem 1.5rem 0 0' : '1.5rem')};
  background-color: ${({ theme }) => theme.colors.background.default};
  min-height: ${({ isBig }) => (isBig ? 'calc(100vh - 4rem)' : '24rem')};
`;

export const StyledLoginInnerLayoutLogo = styled.div`
  position: absolute;
  top: 6.25rem;
  left: -3.5rem;
`;

export const StyledLoginInnerLayoutHeadingWrapper = styled.div`
  width: 100%;
  max-width: 20rem;
`;

export const StyledLoginInnerLayoutHeading = styled(Typography).attrs({
  forwardedAs: 'h2',
  variant: 'h1',
})`
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 1.5rem;
`;

export const StyledLoginInnerLayoutSubtitle = styled(Typography).attrs({
  forwardedAs: 'span',
  variant: 'caption',
})``;

export const StyledLoginInnerLayoutDescription = styled(Typography).attrs({
  forwardedAs: 'p',
  variant: 'body',
})`
  color: ${({ theme }) => rgba(theme.colors.text.primary, 0.54)};
  margin-top: 1rem;
  margin-bottom: 2.375rem;
`;

export const StyledLoginInnerLayoutDecor = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(80%, -50%);
`;
