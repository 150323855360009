export enum DatepickerPosition {
  Left = 'left',
  Right = 'right',
}

export enum SupportedStaticRange {
  Today = 'Today',
  Yesterday = 'Yesterday',
  LastWeek = 'Last Week',
  LastSevenDays = 'Last 7 Days',
  ThisWeek = 'This Week',
  ThisMonth = 'This Month',
  LastMonth = 'Last Month',
}
