import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { SeoProps } from './types';

const Seo: React.FC<SeoProps> = ({ title }) => {
  const intl = useIntl();

  return (
    <Helmet>
      <title>
        {intl.formatMessage({
          id: 'app.name',
          defaultMessage: 'Sandoog',
        })}{' '}
        | {title}
      </title>
    </Helmet>
  );
};

export default Seo;
