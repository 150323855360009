import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { FormattedMessage } from 'react-intl';
import { MenuDropdownProps } from './types';
import {
  StyledMenuDropdown,
  StyledMenuDropdownButton,
  StyledMenuDropdownContent,
  StyledMenuDropdownContentWrapper,
  StyledMenuDropdownLink,
  StyledMenuDropdownLinkButton,
  StyledMenuDropdownList,
  StyledMenuDropdownListItem,
  StyledMenuDropdownTitle,
} from './styles';
import { IconSettings } from '../../../assets/svg/icons';
import useToggle from '../../../hooks/useToggle';

const MenuDropdown: React.FC<MenuDropdownProps> = ({
  title,
  items,
  onLogout,
}) => {
  const [isOpen, { toggle, registerContainerRef }] = useToggle();

  return (
    <StyledMenuDropdown ref={registerContainerRef}>
      <StyledMenuDropdownButton onClick={toggle}>
        <IconSettings />
      </StyledMenuDropdownButton>
      <AnimatePresence>
        {isOpen && (
          <StyledMenuDropdownContentWrapper>
            <StyledMenuDropdownContent>
              {title && (
                <StyledMenuDropdownTitle>{title}</StyledMenuDropdownTitle>
              )}
              <StyledMenuDropdownList>
                {items.map(({ label, to, key, isActive }) => (
                  <StyledMenuDropdownListItem {...{ key }}>
                    <StyledMenuDropdownLink {...{ to, isActive }}>
                      {label}
                    </StyledMenuDropdownLink>
                  </StyledMenuDropdownListItem>
                ))}
                <StyledMenuDropdownListItem>
                  <StyledMenuDropdownLinkButton onClick={onLogout}>
                    <FormattedMessage
                      id='menu.logout'
                      defaultMessage='Logout'
                    />
                  </StyledMenuDropdownLinkButton>
                </StyledMenuDropdownListItem>
              </StyledMenuDropdownList>
            </StyledMenuDropdownContent>
          </StyledMenuDropdownContentWrapper>
        )}
      </AnimatePresence>
    </StyledMenuDropdown>
  );
};

export default MenuDropdown;
