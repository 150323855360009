import { rgba } from 'polished';
import styled from 'styled-components';
import Typography from '../../styles/Typography';

export const StyledBalanceBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.5rem;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background.light};
  border-radius: 1rem;
  padding: 2rem 1.5rem;
  box-shadow: ${({ theme }) => theme.shadows.mid};
`;

export const StyledBalanceBoxIconWrapper = styled.div`
  display: inline-flex;
  margin-bottom: 0.75rem;
`;

export const StyledBalanceBoxTitle = styled(Typography).attrs({
  variant: 'caption',
})`
  color: ${({ theme }) => rgba(theme.colors.text.primary, 0.54)};
`;
