import React from 'react';
import { LogoProps } from './types';
import { StyledLogo, StyledLogoLink, StyledLogoWrapper } from './styles';
import { LogoSvg } from '../../../assets/svg/images';

const Logo: React.FC<LogoProps> = ({ to, isBig }) => (
  <StyledLogoWrapper {...{ isBig }}>
    {to ? (
      <StyledLogoLink {...{ to }}>
        <LogoSvg />
      </StyledLogoLink>
    ) : (
      <StyledLogo>
        <LogoSvg />
      </StyledLogo>
    )}
  </StyledLogoWrapper>
);

export default Logo;
