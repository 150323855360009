import { FORM_ERROR } from 'final-form';
import { useCallback } from 'react';
import { SubmitHandler } from 'react-final-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { CLIENT_SELECT_VALUE_COOKIE_NAME } from '../../../components/layouts/LayoutBase/consts';
import {
  AUTH_TOKENS_LOCAL_STORAGE_KEY,
  MFA_TOKENS_LOCAL_STORAGE_KEY,
  ROLE_TYPE,
} from '../../../contexts/User/consts';
import { AuthTokensShape } from '../../../contexts/User/types';
import useCookiesContext from '../../../hooks/useCookiesContext';
import { setLocalStorageValue } from '../../../hooks/useLocalStorageValue/utils';
import { getTokens } from '../../../utils/auth';
import { ClientSelectValueCookieShape } from '../../../utils/auth/types';
import { SIGN_IN_MUTATION_FN } from './consts';
import { SignInFormValues } from './types';
import routes from '../../../setup/consts/routes';

export const useSignInSubmitHandler = (): SubmitHandler<SignInFormValues> => {
  const { mutateAsync: signIn } = useMutation(SIGN_IN_MUTATION_FN);
  const cookies = useCookiesContext();
  const history = useHistory();

  return useCallback(
    async ({ email, password }) => {
      try {
        const { response } = await signIn({
          data: {
            password,
            username: email,
          },
        });

        const { token, sdgUserToken, clientId, entities } = response;
        const { roleType, mfa_token, oob_code } = token;

        if (mfa_token && oob_code) {
          setLocalStorageValue(MFA_TOKENS_LOCAL_STORAGE_KEY, {
            mfa_token,
            oob_code,
          });
          history.push(routes.VERIFY);

          return undefined;
        }

        const authTokens: AuthTokensShape = getTokens(token, sdgUserToken);
        const clientSelectValueCookie: ClientSelectValueCookieShape = {
          clientId,
          entities,
        };

        cookies.set(CLIENT_SELECT_VALUE_COOKIE_NAME, clientSelectValueCookie, {
          path: '/',
        });

        setLocalStorageValue(AUTH_TOKENS_LOCAL_STORAGE_KEY, authTokens);
        setLocalStorageValue(ROLE_TYPE, roleType, true);

        return undefined;
      } catch (_) {
        return {
          [FORM_ERROR]: 'Sign in failed',
        };
      }
    },
    [signIn, cookies, history]
  );
};
