import styled from 'styled-components';
import Typography from '../../../styles/Typography';

export const StyledAutocompleteWrapper = styled.div`
  width: 100%;
  .react-autosuggest__container {
    position: relative;
  }
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0.5rem 0.5rem;
    transition: ${({ theme }) => theme.transitions.default};
    border-radius: 14px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.background.medium};
    }
  }
  .react-autosuggest__suggestion--highlighted {
    background-color: ${({ theme }) => theme.colors.background.default};
  }
`;

export const StyledAutocompleteContent = styled.div`
  width: 100%;
  padding: 3.875rem 0.625rem 0.875rem;
  background-color: ${({ theme }) => theme.colors.background.light};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.autocomplete.border};
  box-shadow: ${({ theme }) => theme.shadows.mid};
`;

export const StyledAutocompleteContentAction = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  padding: 0 0.5rem;
  button {
    width: 100%;
  }
`;

export const StyledAutocompleteContentWrapper = styled.div`
  position: absolute;
  top: -0.875rem;
  left: -1.123rem;
  right: -1.123rem;
  display: none;
  &.react-autosuggest__suggestions-container--open {
    display: block;
  }
`;

export const StyledAutocompleteInput = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  &.react-autosuggest__input--focused + ${StyledAutocompleteContentWrapper} {
    display: block;
  }
`;

export const StyledAutocompleteText = styled(Typography).attrs({
  forwardedAs: 'span',
  variant: 'body',
})`
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledAutocompleteLoader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledClearButton = styled.button`
  display: inline-flex;
  border: 0;
  padding: 0.25rem 0.5rem;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.secondary};

  svg {
    width: 1em;
    height: 1em;
    font-size: 0.9rem;
    color: currentColor;
  }
`;
