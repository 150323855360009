import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CashFlowWrapper, StatsBox, StatsChartsWrapper } from '../styles';
import {
  IconBankGood,
  IconBankInfo,
  IconFinance,
} from '../../../../assets/svg/icons';
import { StyledBox } from '../../../styles/Grid/styles';
import Typography from '../../../styles/Typography';
import SalesChart from '../../SalesChart';
import OrdersChart from '../../OrdersChart';
import PriceWithCurrency from '../../../atoms/PriceWithCurrency';
import { PriceWithCurrencySize } from '../../../atoms/PriceWithCurrency/enums';
import { StyledBalanceBoxTitle } from '../../BalanceBox/styles';
import Link from '../../../atoms/Link';
import colors from '../../../../theme/config/colors';

const FinancialsStats: React.FC = () => {
  const intl = useIntl();

  return (
    <StatsBox>
      {/* Icon */}
      <IconFinance height='30px' />

      <StatsChartsWrapper>
        <StyledBox flexDirection='column' gap='10px'>
          {/* Title */}
          <StyledBox alignItems='center' gap='1rem' mb={4}>
            <Typography variant='bodyBold'>
              <FormattedMessage
                id='dashboard.financials'
                defaultMessage='Financials'
              />
            </Typography>

            <Typography variant='caption'>
              <Link to='/financials' color={colors.text.secondary}>
                <FormattedMessage id='seeAll' defaultMessage='seeAll' />
              </Link>
            </Typography>
          </StyledBox>

          {/* Sales Chart */}
          <SalesChart />
        </StyledBox>

        {/* Orders Chart */}
        <StyledBox>
          <OrdersChart />
        </StyledBox>

        {/* Cash Flow */}
        <CashFlowWrapper>
          {/* Current Balance */}
          <StyledBox gap='2rem' alignItems='center'>
            <IconBankGood />

            <StyledBox flexDirection='column' gap='0.5rem'>
              <PriceWithCurrency
                size={PriceWithCurrencySize.Big}
                price={8017000}
                currency='IQD'
              />
              <StyledBalanceBoxTitle>
                {intl.formatMessage({
                  id: 'financials.currentBalance',
                  defaultMessage: 'Current Balance',
                })}
              </StyledBalanceBoxTitle>
            </StyledBox>
          </StyledBox>

          {/* Pending Balance */}
          <StyledBox gap='2rem' alignItems='center'>
            <IconBankInfo />

            <StyledBox flexDirection='column' gap='0.5rem'>
              <PriceWithCurrency
                size={PriceWithCurrencySize.Big}
                price={2682000}
                currency='IQD'
              />
              <StyledBalanceBoxTitle>
                {intl.formatMessage({
                  id: 'financials.pendingBalance',
                  defaultMessage: 'Pending Balance',
                })}
              </StyledBalanceBoxTitle>
            </StyledBox>
          </StyledBox>
        </CashFlowWrapper>
      </StatsChartsWrapper>
    </StatsBox>
  );
};

export default FinancialsStats;
