import styled from 'styled-components';
import Typography from '../../styles/Typography';

export const StyledAvatarUpload = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  transition: ${({ theme }) => theme.transitions.default};
  &:hover {
    opacity: 0.9;
  }
`;

export const StyledAvatarUploadLabel = styled(Typography).attrs({
  variant: 'caption',
  forwardedAs: 'span',
})`
  text-align: center;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: 0.5rem;
`;

export const StyledAvatarUploadInput = styled.input.attrs({
  type: 'file',
})`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  width: 100%;
`;
