import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { variant } from 'styled-system';
import { textStyles } from '../../../theme/config/typography';
import { ButtonMode, ButtonSize } from './enums';
import { StyledButtonProps } from './types';

const ButtonStyles = css<StyledButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: ${({ theme, size = ButtonSize.Big }) =>
    theme.dimensions.button.height[size]};
  border-radius: ${({ size }) =>
    size === ButtonSize.Big ? '0.875rem' : '2rem'};
  border: 0;
  padding: ${({ size }) =>
    size === ButtonSize.Big ? '0.625rem 1.25rem' : '0.375rem 1.125rem'};
  ${variant({
    variants: {
      ...textStyles,
    },
  })};
  outline: none;
  transition: ${({ theme }) => theme.transitions.default};
  background-color: ${({ theme, mode = ButtonMode.Primary }) =>
    theme.colors.button[mode].background};
  color: ${({ theme, mode = ButtonMode.Primary }) =>
    theme.colors.button[mode].color};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, mode = ButtonMode.Primary }) =>
      theme.colors.button[mode].color};
    color: ${({ theme, mode = ButtonMode.Primary }) =>
      theme.colors.button[mode].background};
  }
  &:disabled,
  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }
  svg {
    width: 1em;
    height: 1em;
    font-size: 1.5rem;
    fill: currentColor;
    margin-right: 0.625rem;
  }
`;

export const StyledButton = styled.button<StyledButtonProps>`
  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      width: 100%;
    `}
  ${ButtonStyles}
`;

export const StyledButtonLink = styled(Link)<StyledButtonProps>`
  ${ButtonStyles}
  text-decoration: none;
`;

export const StyledButtonExternalLink = styled.a<StyledButtonProps>`
  ${ButtonStyles}
  text-decoration: none;
`;

export const StyledButtonClean = styled.button`
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  &:disabled,
  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }
`;
