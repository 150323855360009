import styled from 'styled-components';
import Button from '../../../components/atoms/Button';
import Typography from '../../../components/styles/Typography';

export const StyledForm = styled.form`
  width: 100%;
`;

export const StyledParagraph = styled(Typography).attrs({
  forwardedAs: 'p',
  variant: 'body',
})`
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const SignInButton = styled(Button)`
  margin-inline-end: 1rem;
`;
