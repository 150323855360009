import React from 'react';
import { FormattedMessage, FormattedNumber, IntlShape } from 'react-intl';
import { ColumnDef } from '@tanstack/react-table';
import { generatePath } from 'react-router-dom';
import { GetCustomersResponseSingleItem } from '../../setup/apiTypes/customers';
import { ButtonMode } from '../../components/atoms/Button/enums';
import ButtonLink from '../../components/atoms/Button/buttonLink';
import routes from '../../setup/consts/routes';

export const customersTableFakeData: GetCustomersResponseSingleItem[] = [
  {
    id: Math.random().toString(),
    name: 'Abas Hanoon',
    phone: '96403458981',
    lastOrderDate: new Date('01/02/2020'),
    city: 'Baghdad',
    totalOrders: 320,
    value: 260000,
    rating: 3.5,
  },
  {
    id: Math.random().toString(),
    name: 'Abas Hanoon',
    phone: '96403458982',
    lastOrderDate: new Date('01/02/2020'),
    city: 'Baghdad',
    totalOrders: 320,
    value: 260000,
    rating: 4,
  },
  {
    id: Math.random().toString(),
    name: 'Abas Hanoon',
    phone: '96403458981',
    lastOrderDate: new Date('01/02/2020'),
    city: 'Baghdad',
    totalOrders: 320,
    value: 260000,
    rating: 4,
  },
  {
    id: Math.random().toString(),
    name: 'Abas Hanoon',
    phone: '96403458982',
    lastOrderDate: new Date('01/02/2020'),
    city: 'Baghdad',
    totalOrders: 320,
    value: 260000,
    rating: 4,
  },
  {
    id: Math.random().toString(),
    name: 'Abas Hanoon',
    phone: '96403458981',
    lastOrderDate: new Date('01/02/2020'),
    city: 'Baghdad',
    totalOrders: 320,
    value: 260000,
    rating: 2,
  },
  {
    id: Math.random().toString(),
    name: 'Abas Hanoon',
    phone: '96403458982',
    lastOrderDate: new Date('01/02/2020'),
    city: 'Baghdad',
    totalOrders: 320,
    value: 260000,
    rating: 1,
  },
];

export const customersTableColumns = (
  intl: IntlShape
): ColumnDef<GetCustomersResponseSingleItem>[] => [
  {
    header: intl.formatMessage({
      id: 'customers.customerName',
      defaultMessage: 'Customer Name',
    }),
    accessorKey: 'name',
  },
  {
    header: intl.formatMessage({
      id: 'customers.customerPhone',
      defaultMessage: 'Customer Phone',
    }),
    accessorKey: 'phone',
  },
  {
    header: intl.formatMessage({
      id: 'customers.city',
      defaultMessage: 'City',
    }),
    accessorKey: 'city',
  },
  {
    header: intl.formatMessage({
      id: 'customers.totalOrders',
      defaultMessage: 'Total Orders',
    }),
    accessorKey: 'totalOrders',
  },
  {
    header: intl.formatMessage({
      id: 'customers.valueIQD',
      defaultMessage: 'Value IQD',
    }),
    accessorKey: 'totalPrice',
    cell: ({ cell: { getValue } }) => (
      <FormattedNumber value={getValue() as number} />
    ),
  },

  // {
  //   header: () => (
  //     <StyledBox gap='0.8rem' alignItems='center'>
  //       {intl.formatMessage({
  //         id: 'customers.rating',
  //         defaultMessage: 'Rating',
  //       })}

  //       <StyledBox
  //         data-tip={intl.formatMessage({
  //           id: 'customers.ratingColumnTooltip',
  //           defaultMessage:
  //             'Rating based on number of customer delivered vs failed orders',
  //         })}
  //       >
  //         <IconInfo />
  //         <ReactTooltip multiline />
  //       </StyledBox>
  //     </StyledBox>
  //   ),
  //   accessorKey: 'rating',
  //   cell: ({ cell: { getValue } }) => (
  //     <Rating
  //       initialRating={getValue() as number}
  //       readonly
  //       fullSymbol={<RatingFullStar />}
  //       emptySymbol={<RatingEmptyStar />}
  //     />
  //   ),
  // },

  {
    header: intl.formatMessage({
      id: 'customers.orders',
      defaultMessage: 'Orders',
    }),
    size: 60,
    cell: ({ row: { original } }) => (
      <ButtonLink
        mode={ButtonMode.Secondary}
        onClick={(e) => {
          e.stopPropagation();
        }}
        to={generatePath(routes.PLACE_CUSTOMER_ORDER, {
          customerId: original.id,
        })}
      >
        <FormattedMessage
          id='customers.createOrder'
          defaultMessage='Create Order'
        />
      </ButtonLink>
    ),
  },
];
