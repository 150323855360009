import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Switch, Route, useLocation } from 'react-router-dom';
import Autocomplete from '../../../components/atoms/Fields/Autocomplete';
import { BaseAutocompleteItem } from '../../../components/atoms/Fields/Autocomplete/types';
import TabsNavigation from '../../../components/molecules/TabsNavigation';
import { StyledBox } from '../../../components/styles/Grid/styles';
import useHeaderTitleContext from '../../../hooks/useHeaderTitleContext';
import useToggle from '../../../hooks/useToggle';
import { GetEntitiesResponseSingleItem } from '../../../setup/apiTypes/entities';
import { GetUsersResponseSingleItem } from '../../../setup/apiTypes/users';
import apiRoutes from '../../../setup/consts/apiRoutes';
import routes from '../../../setup/consts/routes';
import { tabsNavigationItems } from './consts';
import Entities from './Entities';
import {
  useAutocompletePlaceholderValues,
  useSearchFormHandlers,
} from './hooks';
import { StyledForm } from './styles';
import Users from './Users';

const ROUTE_TO_ENDPOINT_MAPPING = {
  [routes.ENTITIES]: apiRoutes.ENTITIES,
  [routes.USERS]: apiRoutes.USERS,
};

const EntitiesAndUsersPage: React.FC = () => {
  const {
    autocompleteRefGetter,
    onClearButtonClick,
    onInputChange,
    onSearchFormSubmit,
    onSuggestionClick,
    searchQuery,
  } = useSearchFormHandlers();

  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { pathname } = useLocation();
  const { setHeaderTitle } = useHeaderTitleContext();
  const intl = useIntl();
  const navigationItems = useMemo(() => tabsNavigationItems(intl), [intl]);
  const {
    buttonLabel,
    placeholderTitle,
    placeholderDescription,
    placeholderIcon,
  } = useAutocompletePlaceholderValues();

  useEffect(() => {
    setHeaderTitle(
      intl.formatMessage({
        id: 'sidebar.settings.entitiesAndUsers',
        defaultMessage: 'Entities and Users',
      })
    );
  }, [intl, setHeaderTitle]);

  return (
    <>
      <StyledBox mb='0.5rem'>
        <StyledForm onSubmit={onSearchFormSubmit}>
          <Autocomplete<
            string,
            {
              items: (
                | GetEntitiesResponseSingleItem
                | GetUsersResponseSingleItem
              )[];
            }
          >
            ref={autocompleteRefGetter}
            endpoint={ROUTE_TO_ENDPOINT_MAPPING[pathname]}
            mapApiResponseToSuggestions={({ items }) =>
              items.map<BaseAutocompleteItem<string>>(({ id, name }) => ({
                label: name,
                value: id,
              }))
            }
            placeholder={intl.formatMessage({
              id: 'search.searchSandoog',
              defaultMessage: 'Search Sandoog',
            })}
            onButtonClick={toggleOn}
            placeholderProps={{
              icon: placeholderIcon,
              title: placeholderTitle,
              description: placeholderDescription,
            }}
            {...{
              buttonLabel,
              onSuggestionClick,
              onInputChange,
              onClearButtonClick,
            }}
          />
        </StyledForm>
      </StyledBox>
      <StyledBox mb='0.875rem'>
        <TabsNavigation items={navigationItems} />
      </StyledBox>

      <Switch>
        <Route exact path={routes.ENTITIES}>
          <Entities {...{ searchQuery, isOpen, toggleOn, toggleOff }} />
        </Route>
        <Route exact path={routes.USERS}>
          <Users {...{ searchQuery, isOpen, toggleOn, toggleOff }} />
        </Route>
      </Switch>
    </>
  );
};

export default EntitiesAndUsersPage;
