import styled from 'styled-components';
import Typography from '../../../components/styles/Typography';

export const StyledForm = styled.form`
  width: 100%;
`;

export const StyledParagraph = styled(Typography).attrs({
  forwardedAs: 'p',
  variant: 'body',
})`
  color: ${({ theme }) => theme.colors.text.primary};
`;
