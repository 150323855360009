import React from 'react';
import { StyledRadioInput, StyledRadioLabel } from './styles';

import { BaseRadioItem, RadioProps } from './types';

function Radio<V, T extends BaseRadioItem<V>>({
  renderRadioContent,
  disabled,
  value,
  checked,
  radioItem,
  allowUnselect,
  ...props
}: RadioProps<V, T>): JSX.Element {
  return (
    <StyledRadioLabel {...{ disabled }}>
      <StyledRadioInput
        type={allowUnselect ? 'checkbox' : 'radio'}
        {...{ ...props, disabled, value, checked }}
      />
      {renderRadioContent({
        ...radioItem,
        disabled,
        checked,
      })}
    </StyledRadioLabel>
  );
}

export default Radio;
