import { AnimatePresence } from 'framer-motion';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, useFormState } from 'react-final-form';
import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import Button from '../../../../../components/atoms/Button';
import { ButtonMode } from '../../../../../components/atoms/Button/enums';
import { FormInput } from '../../../../../components/atoms/Fields/Input';
import { StyledBox } from '../../../../../components/styles/Grid/styles';
import Typography from '../../../../../components/styles/Typography';
import { OrderFormProps, ProductsTableItem } from './types';
import PlaceOrderProductsModal from '../../../../../components/organisms/Modal/templates/PlaceOrderProductsModal';
import useToggle from '../../../../../hooks/useToggle';
import OrderApprovalBar from '../../../../../components/organisms/OrderApprovalBar';
import { FormSimpleSelect } from '../../../../../components/atoms/Fields/Select/templates/SimpleSelect';
import { useEntitySelectItems, useFeesProfileData } from './hooks';
import routes from '../../../../../setup/consts/routes';
import { OrderFormValues } from '../../types';

import { StyledForm } from './styles';
import ProductsTable from './ProductsTable';
import useGeocodeAddress from '../../../../../hooks/useGeocodeAddress';
import { currencyOptions, missingProfileFeesModalMessages } from './consts';
import ConfirmationModal from '../../../../../components/organisms/Modal/templates/ConfirmationModal';
import { useCitiesList } from '../../../../../hooks/useCitiesList';
import apiRoutes from '../../../../../setup/consts/apiRoutes';
import { GetProductDetailsResponse } from '../../../../../setup/apiTypes/warehouse';
import { GetCustomerResponse } from '../../../../../setup/apiTypes/customers';

const OrderForm: React.FC<OrderFormProps> = ({
  isEdit,
  loadingSubmit,
  handleSubmit,
}) => {
  const history = useHistory();
  const entitySelectItems = useEntitySelectItems();
  const intl = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const {
    values: {
      entity,
      city,
      address,
      products = [],
      total,
      currency,
      customerId,
    },
  } = useFormState<OrderFormValues>();
  const { change, mutators } = useForm();
  const { productId, customerId: customerIdParam } =
    useParams<{ productId: string; customerId: string }>();
  const { data: fetchedProductData } = useQuery<
    UseQueryResponse<GetProductDetailsResponse>
  >(apiRoutes.PRODUCT_DETAILS(productId || ''), {
    enabled: !!productId,
  });
  const { data: fetchedCustomerData } = useQuery<
    UseQueryResponse<GetCustomerResponse>
  >(apiRoutes.GET_CUSTOMER(customerIdParam || ''), {
    enabled: !!customerIdParam,
  });

  const handleProductAdd = (newProduct: ProductsTableItem) =>
    mutators.push('products', newProduct);

  const {
    currency: preferredCurrency,
    deliveryChargeOptions,
    deliveryFee,
    entityHasNoFeesProfile,
  } = useFeesProfileData();

  const { list: citiesOptions } = useCitiesList();

  const coords = useGeocodeAddress({
    city,
    address,
  });

  useEffect(() => {
    change('latitude', coords.lat);
    change('longitude', coords.lng);
  }, [change, coords]);

  useEffect(() => {
    if (!currency) {
      change('currency', preferredCurrency);
    }
  }, [change, preferredCurrency, currency]);

  useEffect(() => {
    const product = fetchedProductData?.response.product;

    if (productId && product) {
      handleProductAdd({
        idCol: product.id.toString(),
        imageCol: product.imageUrl,
        priceCol: product.sellingPriceIqd,
        productCol: product.name,
        quantityCol: 1,
        totalCol: {
          currency: 'IQD',
          price: product.sellingPriceIqd,
        },
        sku: product.sku,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedProductData, productId]);

  useEffect(() => {
    const customer = fetchedCustomerData?.response;

    if (customerIdParam && customer) {
      change('name', customer.name);
      change('phoneNumber', customer.phone);
      change('address', customer.address);
      change('secondPhoneNumber', customer.secondPhone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedCustomerData, customerIdParam, citiesOptions]);

  const productsTotal = useMemo(() => {
    if (!products || products.length <= 0) {
      return total ?? 0;
    }

    return products.reduce(
      (acc, { priceCol, quantityCol }) => acc + priceCol * quantityCol,
      0
    );
  }, [products, total]);

  useEffect(() => {
    change('total', productsTotal);
  }, [change, productsTotal]);

  return (
    <>
      <StyledForm onSubmit={handleSubmit}>
        <Row>
          <Col md={3}>
            <StyledBox mb='1.5rem'>
              <Typography variant='bodyBold' as='h3'>
                <FormattedMessage
                  id='orders.orderDetails'
                  defaultMessage='Order Details'
                />
              </Typography>
            </StyledBox>
            <StyledBox mb='1rem'>
              <FormSimpleSelect
                name='entity'
                label={intl.formatMessage({
                  id: 'forms.fields.entity',
                  defaultMessage: 'Entity',
                })}
                options={entitySelectItems}
              />
            </StyledBox>
            <StyledBox mb='1.5rem'>
              <Typography variant='bodyBold' as='h3'>
                <FormattedMessage
                  id='orders.customerInformation'
                  defaultMessage='Customer information'
                />
              </Typography>
            </StyledBox>
            <FormInput
              name='phoneNumber'
              label={intl.formatMessage({
                id: 'forms.fields.phoneNumber',
                defaultMessage: 'Phone Number',
              })}
              type='tel'
            />
            <FormInput
              name='secondPhoneNumber'
              label={intl.formatMessage({
                id: 'forms.fields.secondPhoneNumber',
                defaultMessage: 'Second Phone Number',
              })}
              type='tel'
            />
            <FormInput
              name='externalReference'
              label={intl.formatMessage({
                id: 'forms.fields.receiptNumber',
                defaultMessage: 'Receipt Number',
              })}
              type='email'
            />
            <FormInput
              name='name'
              label={intl.formatMessage({
                id: 'forms.fields.customer',
                defaultMessage: 'Customer',
              })}
            />
            <FormSimpleSelect
              name='currency'
              label={intl.formatMessage({
                id: 'forms.fields.currency',
                defaultMessage: 'Currency',
              })}
              options={currencyOptions}
              disabled={!entity || !!customerId || products.length > 0}
            />
            <FormSimpleSelect
              disabled={!entity || entityHasNoFeesProfile}
              name='city'
              label={intl.formatMessage({
                id: 'forms.fields.city',
                defaultMessage: 'City',
              })}
              options={citiesOptions}
            />
            <FormInput
              name='address'
              label={intl.formatMessage({
                id: 'forms.fields.address',
                defaultMessage: 'Address',
              })}
              placeholder={intl.formatMessage({
                id: 'forms.placeholders.typeAddress',
                defaultMessage: 'Type address',
              })}
            />
            <FormInput
              name='notes'
              label={intl.formatMessage({
                id: 'forms.fields.addressNotes',
                defaultMessage: 'Address Notes',
              })}
            />
          </Col>
          <Col md={9}>
            <StyledBox mb='1.5rem'>
              <Typography variant='bodyBold' as='h3'>
                <FormattedMessage
                  id='orders.products'
                  defaultMessage='Products'
                />
              </Typography>
            </StyledBox>
            <StyledBox mb='2.5rem'>
              <Button
                type='button'
                onClick={toggleOn}
                mode={ButtonMode.Secondary}
                disabled={!entity || entity.length <= 0 || !currency}
              >
                +{' '}
                <FormattedMessage
                  id='orders.addProductsToOrder'
                  defaultMessage='Add products to order'
                />
              </Button>
            </StyledBox>
            <ProductsTable data={products} {...{ currency }} />
          </Col>
        </Row>
      </StyledForm>

      <AnimatePresence>
        {isOpen && (
          <PlaceOrderProductsModal
            productsInOrder={products}
            onProductAdd={handleProductAdd}
            entityId={entity}
            withCloseButton
            onClose={toggleOff}
            {...{ currency }}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        <OrderApprovalBar
          deliveryChargeFieldDisabled={!city}
          selectOptions={deliveryChargeOptions || entityHasNoFeesProfile}
          deliveryCharge={deliveryFee}
          grandTotal={total + (deliveryFee ?? 0)}
          onCancelClick={() => history.push(routes.ORDERS)}
          onSubmit={handleSubmit}
          deliveryChargeFieldName='deliveryRegion'
          submitButtonContent={
            isEdit ? (
              <FormattedMessage
                id='orders.saveOrder'
                defaultMessage='Save order'
              />
            ) : (
              <FormattedMessage
                id='orders.placeOrderForApproval'
                defaultMessage='Place order for approval'
              />
            )
          }
          {...{ currency }}
          loading={loadingSubmit}
        />
      </AnimatePresence>

      <AnimatePresence>
        {entityHasNoFeesProfile && (
          <ConfirmationModal
            title={intl.formatMessage(missingProfileFeesModalMessages.title)}
            description={intl.formatMessage(
              missingProfileFeesModalMessages.description
            )}
            confirmButtonLabel={intl.formatMessage(
              missingProfileFeesModalMessages.confirmButtonLabel
            )}
            onConfirmButtonClick={() => history.push(routes.CONTACT_US)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default OrderForm;
