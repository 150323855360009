import React, { useMemo, useState } from 'react';
import noop from 'lodash/noop';

export interface HeaderTitleContextValue {
  title: string;
  setHeaderTitle: (title: string) => void;
}

export const HeaderTitleContext = React.createContext<HeaderTitleContextValue>({
  title: '',
  setHeaderTitle: noop,
});

export const { Provider: HeaderTitleProvider } = HeaderTitleContext;

export const HeaderTitleContextProvider: React.FC = ({ children }) => {
  const [title, setHeaderTitle] = useState('');
  const HeaderTitleContextValue = useMemo(
    () => ({
      title,
      setHeaderTitle,
    }),
    [title]
  );

  return (
    <HeaderTitleProvider value={HeaderTitleContextValue}>
      {children}
    </HeaderTitleProvider>
  );
};
