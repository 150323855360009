import apiRoutes from '../../../setup/consts/apiRoutes';
import { makeMutationFn } from '../../../utils/reactQuery';
import { SignInFormValues, SignInRequestBody, SignInResponse } from './types';

export const SIGN_IN_FORM_INITIAL_VALUES: Partial<SignInFormValues> = {
  email: '',
  password: '',
};

export const SIGN_IN_MUTATION_FN = makeMutationFn<
  SignInRequestBody,
  SignInResponse
>(apiRoutes.SIGN_IN);
