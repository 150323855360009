import { rgba } from 'polished';
import styled from 'styled-components';
import Typography from '../../../../styles/Typography';

export const StyledTableWrapper = styled.div``;

export const StyledSearchForm = styled.form`
  width: 100%;
  max-width: ${({ theme }) => theme.dimensions.searchForm.width};
  z-index: ${({ theme }) => theme.zindexes.search};
  ${({ theme }) =>
    theme.direction === 'rtl' ? 'margin-right: 2rem;' : 'margin-left: 2rem;'}
`;

export const StyledForm = styled.form`
  width: 100%;
`;

export const StyledTextareaDescription = styled(Typography).attrs({
  variant: 'caption',
})`
  color: ${({ theme }) => rgba(theme.colors.text.secondary, 0.54)};
`;
