import React, { ChangeEventHandler, useCallback } from 'react';
import { Field } from 'react-final-form';

import { FormInputProps, InputProps } from './types';
import {
  StyledInput,
  StyledInputPrefix,
  StyledInputSuffix,
  StyledInputWrapper,
} from './styles';
import { FieldMode, FieldSize } from '../enums';
import FieldWrapper from '../FieldWrapper';

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      mode = FieldMode.Primary,
      inputSize = FieldSize.Small,
      isError,
      isRounded,
      prefixComponent,
      suffixComponent,
      label,
      errorMessage,
      type,
      onChange,
      ...props
    },
    ref
  ) => {
    const textVariant = mode === FieldMode.Tertiary ? 'caption' : 'body';

    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        if (!onChange) {
          return undefined;
        }

        if (type === 'number') {
          return onChange(event.target.valueAsNumber);
        }

        return onChange(event);
      },
      [onChange, type]
    );

    const input = (
      <StyledInput
        {...{
          mode,
          inputSize,
          isError,
          isRounded,
          ref,
          type,
          ...props,
          onChange: handleChange,
        }}
        variant={textVariant}
        hasPrefix={!!prefixComponent}
        hasSuffix={!!suffixComponent}
      />
    );

    const content =
      prefixComponent || suffixComponent ? (
        <StyledInputWrapper {...{ inputSize }}>
          {prefixComponent && (
            <StyledInputPrefix {...{ mode }}>
              {prefixComponent}
            </StyledInputPrefix>
          )}
          {input}
          {suffixComponent && (
            <StyledInputSuffix {...{ mode }}>
              {suffixComponent}
            </StyledInputSuffix>
          )}
        </StyledInputWrapper>
      ) : (
        input
      );

    return label ? (
      <FieldWrapper
        error={isError ? errorMessage : ''}
        {...{ label }}
        control={content}
      />
    ) : (
      content
    );
  }
);

export const FormInput: React.FC<FormInputProps> = ({ name, ...props }) => (
  <Field<string> {...{ name }}>
    {({ input, meta: { error, submitError } }) => (
      <Input
        {...props}
        isError={!!(error || submitError)}
        errorMessage={error || submitError}
        {...input}
      />
    )}
  </Field>
);

export default Input;
