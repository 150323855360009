import styled from 'styled-components';
import Typography from '../../../styles/Typography';

export const StyledFieldWrapper = styled.div`
  width: 100%;
  display: block;
`;

export const StyledFieldWrapperLabel = styled(Typography).attrs({
  variant: 'caption',
  forwardedAs: 'span',
})`
  display: block;
  margin-bottom: 0.625rem;
`;
