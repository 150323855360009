import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { menuItems, sideMenuAdditionalItems } from './consts';
import LayoutBase from '../LayoutBase';
import { useSideMenuItems } from '../hooks';
import { SideMenuItem } from '../../molecules/SideMenu/types';

const SettingsLayout: React.FC = ({ children }) => {
  const intl = useIntl();
  const menuAdditionalItems = useMemo(
    () => sideMenuAdditionalItems(intl),
    [intl]
  );

  const items = useSideMenuItems(menuItems as unknown as SideMenuItem[]);

  return (
    <LayoutBase
      {...{ items }}
      settingsItems={items}
      additionalItems={menuAdditionalItems}
    >
      {children}
    </LayoutBase>
  );
};

export default SettingsLayout;
