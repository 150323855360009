import { PreferredCurrency } from '../setup/apiTypes/enums';
import apiRoutes from '../setup/consts/apiRoutes';
import { makeApiUrl } from './reactQuery';

export const getShipmentLabelSrc = (): string =>
  makeApiUrl(apiRoutes.ORDER_SHIPPING_LABEL);

const currencyToCurrencyCodeMap = new Map<string, string>([
  ['IraqDinar', PreferredCurrency.IQD],
  ['Dollar', PreferredCurrency.USD],
]);

const currencyCodeToCurrencyMap = (() => {
  const map = new Map<string, string>();
  currencyToCurrencyCodeMap.forEach((value, key) => map.set(value, key));

  return map;
})();

export const mapCurrencyToCurrencyCode = (currency: string): string =>
  currencyToCurrencyCodeMap.get(currency) ?? currency;

export const mapCurrencyCodeToCurrency = (currencyCode: string): string =>
  currencyCodeToCurrencyMap.get(currencyCode) ?? currencyCode;

export const getEnumValueByStringValue = <T>(
  enumObj: T,
  value: string
): T[keyof T] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anyEnumObj = enumObj as any;
  const result = Object.entries(anyEnumObj).find(
    ([_, enumValue]) => String(enumValue) === value
  );
  const [key] = result || [];

  return key ? anyEnumObj[key] : undefined;
};
