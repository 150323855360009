import React, { useContext } from 'react';
import { ChartProps, Line } from 'react-chartjs-2';
import { ThemeContext } from 'styled-components';
import { OrdersChartProps } from './types';
import Typography from '../../styles/Typography';
import { StyledOrdersChart } from './styles';

const OrdersChart: React.FC<OrdersChartProps> = () => {
  const theme = useContext(ThemeContext);
  const data: ChartProps['data'] = {
    labels: ['25/10/2020', '25/10/2020', '25/10/2020', '25/10/2020'],
    datasets: [
      {
        data: [100, 200, 599, 400],
        borderWidth: 3,
        fill: {
          target: 'origin',
          above: theme.colors.lineChart.background,
        },
        borderColor: theme.colors.lineChart.lineBorder,
      },
    ],
  };
  const options: ChartProps['options'] = {
    maintainAspectRatio: true,
    scales: {
      y: {
        display: false,
      },
      x: {
        ticks: { display: false },
        grid: {
          color: theme.colors.lineChart.lineBorder,
          lineWidth: 1.5,
          drawTicks: false,
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        onClick: () => null,
        display: false,
      },
      tooltip: {
        displayColors: false,
        padding: 10,
        bodyFont: {
          family: theme.fonts.default,
          size: theme.fontSizes[1],
          lineHeight: 'normal',
        },
        backgroundColor: theme.colors.lineChart.tooltipBackground,
        bodyColor: theme.colors.text.primary,
        titleColor: theme.colors.text.primary,
      },
    },
  };

  return (
    <StyledOrdersChart>
      {/* @ts-ignore */}
      <Line data={data} options={options} />

      <Typography variant='h2'>220</Typography>

      <Typography variant='caption'>Orders (20% Last Week)</Typography>
    </StyledOrdersChart>
  );
};

export default OrdersChart;
