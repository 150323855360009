import React from 'react';
import { Field } from 'react-final-form';
import { FormTextareaProps, TextareaProps } from './types';
import { StyledTextarea } from './styles';
import FieldWrapper from '../FieldWrapper';

const Textarea: React.FC<TextareaProps> = ({
  children,
  label,
  isError,
  errorMessage,
  ...props
}) => {
  const textarea = (
    <StyledTextarea {...{ isError, ...props }}>{children}</StyledTextarea>
  );

  return (
    <FieldWrapper
      error={isError ? errorMessage : ''}
      {...{ label }}
      control={textarea}
    />
  );
};

export const FormTextarea: React.FC<FormTextareaProps> = ({
  name,
  ...props
}) => (
  <Field<string> {...{ name }}>
    {({ input, meta: { error, submitError } }) => (
      <Textarea
        {...props}
        isError={!!(error || submitError)}
        errorMessage={error || submitError}
        {...input}
      />
    )}
  </Field>
);

export default Textarea;
