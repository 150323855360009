import styled from 'styled-components';
import { variant } from 'styled-system';
import { textStyles } from '../../../../theme/config/typography';
import { StyledInputProps } from '../Input/types';

export const StyledTextarea = styled.textarea.attrs({
  variant: 'body',
})<StyledInputProps>`
  width: 100%;
  height: ${({ theme }) => theme.dimensions.textarea.height};
  padding: 0.5rem 0.875rem;
  background-color: ${({ theme }) => theme.colors.field.primary.background};
  color: ${({ theme }) => theme.colors.field.primary.color};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, isError }) =>
    isError
      ? theme.colors.field.primary.border.error
      : theme.colors.field.primary.border.default};
  border-radius: 0.5rem;
  ${variant({
    variants: {
      ...textStyles,
    },
  })};
  resize: none;
  outline: none;
  transition: ${({ theme }) => theme.transitions.default};
  &:focus {
    border-color: ${({ theme }) => theme.colors.field.primary.border.focus};
  }
  &:disabled,
  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
`;
