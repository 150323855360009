import { rgba } from 'polished';
import baseColors from './base';

export default {
  accent: baseColors.sandPrimaryGreen,
  background: {
    dark: baseColors.midBlue,
    medium: baseColors.blueGrayMid,
    light: baseColors.white,
    default: baseColors.backgroundGray,
    modal: rgba(baseColors.black, 0.5),
    placeholder: baseColors.gray,
  },
  text: {
    primary: baseColors.black,
    secondary: baseColors.midBlue,
    tertiary: baseColors.sandPrimaryGreen,
    light: baseColors.white,
    error: baseColors.red,
  },
  link: baseColors.sandPrimaryGreen,
  button: {
    primary: {
      background: baseColors.sandPrimaryGreen,
      color: baseColors.white,
    },
    secondary: {
      background: baseColors.blueGrayMid,
      color: baseColors.midBlue,
    },
    tertiary: {
      background: baseColors.red,
      color: baseColors.white,
    },
    info: {
      background: baseColors.blue,
      color: baseColors.white,
    },
  },
  iconButton: {
    background: baseColors.white,
    color: rgba(baseColors.midBlue, 0.54),
    border: rgba(baseColors.midBlue, 0.22),
  },
  checkbox: {
    background: {
      default: rgba(baseColors.white, 0.38),
      checked: baseColors.white,
    },
    border: {
      default: rgba(baseColors.midBlue, 0.38),
      checked: baseColors.sandPrimaryGreen,
    },
    icon: baseColors.midBlue,
  },
  field: {
    primary: {
      background: baseColors.white,
      color: baseColors.midBlue,
      icon: baseColors.black,
      border: {
        default: baseColors.blueGrayMid,
        focus: baseColors.sandPrimaryGreen,
        error: baseColors.red,
      },
    },
    secondary: {
      background: baseColors.blueGrayMid,
      color: baseColors.midBlue,
      icon: baseColors.black,
      border: {
        default: baseColors.blueGrayMid,
        focus: baseColors.sandPrimaryGreen,
        error: baseColors.red,
      },
    },
    tertiary: {
      background: rgba(baseColors.white, 0.2),
      color: baseColors.midBlue,
      icon: baseColors.black,
      border: {
        default: rgba(baseColors.midBlue, 0.2),
        focus: baseColors.blueGrayMid2,
        error: baseColors.red,
      },
    },
  },
  select: {
    background: baseColors.white,
    selected: baseColors.blueGrayMid,
    color: baseColors.midBlue,
    icon: baseColors.black,
    default: {
      border: baseColors.blueGrayMid,
      menuBorder: rgba(baseColors.black, 0.13),
    },
    notSeparated: {
      border: baseColors.white,
      menuBorder: baseColors.white,
    },
  },
  tag: {
    success: {
      background: baseColors.sandPrimaryGreen,
      color: baseColors.backgroundGray,
    },
    error: { background: baseColors.red, color: baseColors.backgroundGray },
    warning: {
      background: baseColors.yellow,
      color: baseColors.sandPrimaryBlue,
    },
    info: { background: baseColors.blue, color: baseColors.sandPrimaryBlue },
  },
  badge: {
    success: {
      background: baseColors.sandPrimaryGreen,
      color: baseColors.backgroundGray,
    },
    error: { background: baseColors.red, color: baseColors.backgroundGray },
    info: {
      background: baseColors.lightBlue,
      color: baseColors.sandPrimaryBlue,
    },
  },
  notification: {
    dark: {
      background: baseColors.darkBlue,
      color: baseColors.backgroundGray,
    },
  },
  navigation: {
    background: baseColors.blueGrayMid,
    border: rgba(baseColors.black, 0.1),
    item: {
      activeBorder: baseColors.sandPrimaryGreen,
    },
  },
  placeholder: {
    background: baseColors.blueGrayMid,
    color: baseColors.midBlue,
  },
  pagination: {
    bullet: {
      color: baseColors.midBlue,
      background: baseColors.blueGrayMid,
    },
    arrow: {
      color: baseColors.midBlue,
    },
  },
  autocomplete: {
    border: rgba(baseColors.black, 0.13),
  },
  spinner: {
    light: baseColors.white,
    dark: baseColors.sandPrimaryGreen,
  },
  radio: {
    border: rgba(baseColors.black, 0.15),
    mark: {
      active: baseColors.sandPrimaryGreen,
      inActive: rgba(baseColors.midBlue, 0.38),
    },
    line: baseColors.blueGrayMid,
  },
  multiSelect: {
    background: baseColors.white,
  },
  loader: {
    background: baseColors.blueGrayMid,
    foreground: baseColors.backgroundLightGray,
  },
  productOrderCard: {
    background: baseColors.white,
    imagePlaceholder: {
      background: baseColors.blueGrayMid,
    },
  },
  productThumbnail: {
    placeholder: {
      background: baseColors.blueGrayMid,
    },
  },
  orderApprovalBar: {
    background: baseColors.white,
  },
  datepicker: {
    background: baseColors.white,
    dayBackground: baseColors.sandPrimaryGreen,
    dayColor: {
      default: baseColors.midBlue,
      active: baseColors.white,
      muted: rgba(baseColors.black, 0.38),
    },
    weekDaysColor: rgba(baseColors.black, 0.54),
    border: rgba(baseColors.black, 0.1),
    arrowBorder: baseColors.midBlue,
    selectBorder: baseColors.blueGrayMid,
    decor: baseColors.sandPrimaryGreen,
  },
  deliveryTimeline: {
    decorColor: baseColors.midBlue,
    status: {
      background: baseColors.white,
      border: baseColors.sandPrimaryGray,
    },
  },
  shoppingLabelCard: {
    background: baseColors.white,
  },
  orderSummaryModalContactInfoWrapper: {
    border: rgba(baseColors.black, 0.1),
  },
  orderSummaryModalTableExpandButton: {
    color: baseColors.midBlue,
    background: baseColors.backgroundGray,
    border: rgba(baseColors.midBlue, 0.38),
  },
  confirmationModalIconWrapper: {
    primary: {
      background: baseColors.blueGrayMid,
      color: baseColors.midBlue,
    },
    warning: {
      background: baseColors.pink,
      color: baseColors.red,
    },
  },
  upload: {
    background: baseColors.white,
    color: rgba(baseColors.black, 0.4),
  },
  uploadedImage: {
    background: baseColors.white,
    border: baseColors.blueGrayMid,
  },
  progressBar: {
    background: baseColors.grayMid,
    foreground: baseColors.sandPrimaryGreen,
    returnsBar: baseColors.red,
    disputesBar: '#305ED2',
  },
  doughnutChart: {
    first: baseColors.midPink,
    second: baseColors.dodgerBlue,
    third: baseColors.lightOrange,
    fourth: baseColors.turquoise,
  },
  lineChart: {
    lineBorder: baseColors.sandPrimaryGreen,
    background: baseColors.lightGreen,
    tooltipBackground: baseColors.lightGrey,
  },
  accordionHeader: {
    background: baseColors.blueGrayMid,
    color: baseColors.midBlue,
  },
};
