import React from 'react';
import { ProgressBarProps } from './types';
import {
  StyledProgressBar,
  StyledProgressBarItem,
  StyledProgressBarItemWrapper,
} from './styles';
import Typography from '../../styles/Typography';

const ProgressBar: React.FC<ProgressBarProps> = ({
  percent,
  label,
  barColor,
}) => (
  <StyledProgressBar>
    <Typography variant='h2'>{percent}%</Typography>

    <StyledProgressBarItemWrapper>
      <StyledProgressBarItem {...{ percent, barColor }} />
      <Typography variant='caption'>{label}</Typography>
    </StyledProgressBarItemWrapper>
  </StyledProgressBar>
);

export default ProgressBar;
