import styled from 'styled-components';
import { StyledIndicatorsContainerProps } from './types';

export const StyledSelectIndicatorsContainerArrow = styled.span<StyledIndicatorsContainerProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  flex-shrink: 0;
  transition: 250ms ease-in-out;
  transform: rotate(
    ${({ menuIsOpen, withoutIndicatorRotation }) =>
      menuIsOpen && !withoutIndicatorRotation ? '180deg' : '0deg'}
  );
`;
