import { SimpleSelectOption } from '../../../../../../../../components/atoms/Fields/Select/templates/SimpleSelect/types';

export const BUSINESS_TYPE_SELECT_ITEMS: SimpleSelectOption[] = [
  {
    label: 'Electronics',
    value: 'Electronics',
  },
  {
    label: 'Computers & Accessories',
    value: 'Computers & Accessories',
  },
  {
    label: 'Industrial & Scientific',
    value: 'Industrial & Scientific',
  },
  {
    label: 'DIY & Tools',
    value: 'DIY & Tools',
  },
  {
    label: 'Home & Kitchen',
    value: 'Home & Kitchen',
  },
  {
    label: 'Stationery & Office Supplies',
    value: 'Stationery & Office Supplies',
  },
  {
    label: 'Car & Motorbike',
    value: 'Car & Motorbike',
  },
  {
    label: 'Video Games',
    value: 'Video Games',
  },
  {
    label: 'Luggage',
    value: 'Luggage',
  },
  {
    label: 'Musical Instruments & DJ Equipment',
    value: 'Musical Instruments & DJ Equipment',
  },
  {
    label: 'Sports & Outdoors',
    value: 'Sports & Outdoors',
  },
  {
    label: 'Health & Personal Care',
    value: 'Health & Personal Care',
  },
  {
    label: 'Garden & Outdoors',
    value: 'Garden & Outdoors',
  },
  {
    label: 'Toys & Games',
    value: 'Toys & Games',
  },
  {
    label: 'Lighting',
    value: 'Lighting',
  },
  {
    label: 'Grocery',
    value: 'Grocery',
  },
  {
    label: 'Clothing',
    value: 'Clothing',
  },
  {
    label: 'Baby',
    value: 'Baby',
  },
  {
    label: 'Beauty',
    value: 'Beauty',
  },
  {
    label: 'Jewellery',
    value: 'Jewellery',
  },
  {
    label: 'Pet Supplies',
    value: 'Pet Supplies',
  },
  {
    label: 'Shoes & Bags',
    value: 'Shoes & Bags',
  },
  {
    label: 'Watches',
    value: 'Watches',
  },
  {
    label: 'Vouchers & Gift Cards',
    value: 'Vouchers & Gift Cards',
  },
];
