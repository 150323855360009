import React from 'react';
import { Container, Row } from 'react-grid-system';
import ModalBase from '../..';
import Table from '../../../../molecules/Table';
import { useTableColumns } from './hooks';
import { WithdrawnBalancesModalProps } from './types';

const WithdrawnBalancesModal: React.FC<WithdrawnBalancesModalProps> = ({
  withdrawnBalances,
  onClose,
  ...props
}) => {
  const { tableColumns } = useTableColumns();

  return (
    <ModalBase {...props} onClose={onClose}>
      <Container>
        <Row>
          <Table
            data={withdrawnBalances}
            columns={tableColumns}
            disabledPagination
          />
        </Row>
      </Container>
    </ModalBase>
  );
};

export default WithdrawnBalancesModal;
