import styled from 'styled-components';

export const StyledLayoutBaseWrapper = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background.default};
  display: flex;
  &::after {
    content: '';
    min-height: inherit;
    font-size: 0;
  }
`;

export const StyledLayoutBaseSidebar = styled.div`
  width: ${({ theme }) => theme.dimensions.sidebar.width};
  background-color: ${({ theme }) => theme.colors.background.light};
  flex-shrink: 0;
  padding: 2rem 1rem;
`;

export const StyledLayoutBaseLogo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
`;

export const StyledLayoutBaseDropdown = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

export const StyledLayoutBaseMain = styled.main`
  width: calc(100% - ${({ theme }) => theme.dimensions.sidebar.width});
  max-height: 100vh;
  overflow: auto;
  padding: 2rem 2.75rem;
`;

export const StyledSwitcherBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 1rem;
`;
