import styled, { css } from 'styled-components';
import { variant } from 'styled-system';
import { textStyles } from '../../../../theme/config/typography';
import { DatepickerPosition } from './enums';
import { StyledDatepickerProps } from './types';

export const StyledDatepicker = styled.div<StyledDatepickerProps>`
  position: relative;
  display: flex;
  align-items: flex-start;
  ${({ position }) =>
    position === DatepickerPosition.Right &&
    css`
      justify-content: flex-end;
    `};
  .datepicker {
    position: absolute;
    top: 100%;
    margin-top: 0.5rem;
    flex-direction: row-reverse;
    background-color: ${({ theme }) => theme.colors.datepicker.background};
    overflow: hidden;
    box-shadow: ${({ theme }) => theme.shadows.mid};
    border-radius: 1rem;
    z-index: ${({ theme }) => theme.zindexes.autocomplete};
    ${({ position, theme }) =>
      theme.direction === 'rtl'
        ? css`
            ${position === DatepickerPosition.Left
              ? css`
                  right: 0;
                `
              : css`
                  left: 0;
                `}
          `
        : css`
            ${position === DatepickerPosition.Left
              ? css`
                  left: 0;
                `
              : css`
                  right: 0;
                `}
          `};
  }
  .rdrMonthAndYearWrapper {
    border-bottom: 1px solid ${({ theme }) => theme.colors.datepicker.border};
    padding: 1rem;
    height: auto;
  }
  .rdrNextPrevButton {
    margin: 0;
    border-radius: 100%;
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.colors.datepicker.arrowBorder};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rdrNextButton i {
    margin: 0;
  }
  .rdrMonth:not(:last-child) {
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? css`
            border-left: 1px solid ${theme.colors.datepicker.border};
          `
        : css`
            border-right: 1px solid ${theme.colors.datepicker.border};
          `};
  }
  .rdrDefinedRangesWrapper {
    flex-shrink: 0;
    border-right: 0;
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? css`
            border-right: 1px solid ${theme.colors.datepicker.border};
          `
        : css`
            border-left: 1px solid ${theme.colors.datepicker.border};
          `};

    width: auto;
    padding: 1rem 0;
  }
  .rdrStaticRange {
    border-bottom: 0;
  }
  .rdrStaticRangeLabel {
    font-family: ${({ theme }) => theme.fonts.default};
    font-weight: ${({ theme }) => theme.fontWeights[0]};
    font-size: ${({ theme }) => `${theme.fontSizes[1]}px`};
    line-height: ${({ theme }) => theme.lineHeights[1]};
    padding: 0.75rem 1.5rem;
    color: ${({ theme }) => theme.colors.datepicker.dayColor.default};
    white-space: nowrap;
  }
  .rdrMonthAndYearPickers select {
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.datepicker.background};
    border: 1px solid ${({ theme }) => theme.colors.datepicker.selectBorder};
    height: 2.5rem;
    padding: 0.625rem 1.625rem 0.625rem 0.875rem;
    font-family: ${({ theme }) => theme.fonts.default};
    font-weight: ${({ theme }) => theme.fontWeights[0]};
    font-size: 1rem;
    line-height: ${({ theme }) => theme.lineHeights[1]};
  }
  .rdrInputRanges {
    display: none;
  }
  .rdrDayToday .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
    display: none;
  }
  .rdrMonth {
    width: 20.5rem;
    padding: 1rem 1rem calc(1rem - 0.375rem);
  }
  .rdrDay {
    height: auto;
    margin-bottom: 0.375rem;
    &::after {
      content: '';
      padding-top: 100%;
      display: block;
    }
  }

  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    background-color: transparent;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: currentColor;
    }
    &::before {
      opacity: 0.2;
      border-radius: inherit;
    }
    &::after {
      border-radius: 100%;
    }
  }

  .rdrStartEdge.rdrEndEdge {
    border-radius: 100%;
  }

  .rdrInRange {
    opacity: 0.2;
  }

  .rdrEndEdge {
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? css`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 100%;
            border-bottom-left-radius: 100%;
          `
        : css`
            border-top-right-radius: 100%;
            border-bottom-right-radius: 100%;
          `};
  }

  .rdrStartEdge {
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 100%;
            border-bottom-right-radius: 100%;
          `
        : css`
            border-top-left-radius: 100%;
            border-bottom-left-radius: 100%;
          `};
  }

  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfWeek .rdrInRange {
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? css`
            border-top-left-radius: 100%;
            border-bottom-left-radius: 100%;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          `
        : css`
            border-top-right-radius: 100%;
            border-bottom-right-radius: 100%;
          `};
  }

  .rdrDayEndOfMonth .rdrStartEdge,
  .rdrDayEndOfWeek .rdrStartEdge {
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? css`
            border-top-left-radius: 100%;
            border-bottom-left-radius: 100%;
            border-top-right-radius: 100%;
            border-bottom-right-radius: 100%;
          `
        : css`
            border-top-right-radius: 100%;
            border-bottom-right-radius: 100%;
          `};
  }

  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfWeek .rdrInRange {
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? css`
            border-top-right-radius: 100%;
            border-bottom-right-radius: 100%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          `
        : css`
            border-top-left-radius: 100%;
            border-bottom-left-radius: 100%;
            left: 2px;
          `};
  }

  .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDayStartOfWeek .rdrEndEdge {
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? css`
            border-top-right-radius: 100%;
            border-bottom-right-radius: 100%;
            border-top-left-radius: 100%;
            border-bottom-left-radius: 100%;
          `
        : css`
            border-top-left-radius: 100%;
            border-bottom-left-radius: 100%;
          `};
  }

  .rdrMonthName,
  .rdrWeekDays {
    margin-bottom: 1rem;
  }

  .rdrMonthName {
    color: ${({ theme }) => theme.colors.datepicker.dayColor.default};
  }

  .rdrWeekDays {
    color: ${({ theme }) => theme.colors.datepicker.weekDaysColor};
  }

  .rdrMonthName,
  .rdrWeekDay,
  .rdrDayNumber {
    font-family: ${({ theme }) => theme.fonts.default};
    font-weight: ${({ theme }) => theme.fontWeights[0]};
    font-size: ${({ theme }) => `${theme.fontSizes[1]}px`};
    line-height: ${({ theme }) => theme.lineHeights[1]};
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDayNumber span {
    color: ${({ theme }) => theme.colors.datepicker.dayColor.default};
  }

  .rdrDayPassive .rdrDayNumber span {
    color: ${({ theme }) => theme.colors.datepicker.dayColor.muted};
  }
`;

export const StyledDatepickerButton = styled.button.attrs({
  type: 'button',
  variant: 'body',
})`
  background-color: ${({ theme }) => theme.colors.datepicker.background};
  padding: 0.75rem 0.875rem;

  box-shadow: ${({ theme }) => theme.shadows.small};
  border-radius: 0.75rem;
  color: ${({ theme }) => theme.colors.datepicker.dayColor.default};
  display: flex;
  align-items: center;
  border: 0;
  outline: 0;
  min-height: 3.125rem;
  cursor: pointer;
  &:disabled,
  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }
  ${variant({
    variants: {
      ...textStyles,
    },
  })};
`;

export const StyledDatepickerButtonDecor = styled.span`
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.datepicker.dayColor.default};
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? css`
          padding-right: 2rem;
        `
      : css`
          padding-left: 2rem;
        `};
  svg {
    height: 1em;
    width: 1em;
    fill: currentColor;
    font-size: 1.375rem;
  }
`;

export const StyledDatepickerDecor = styled.span`
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.datepicker.decor};
  padding: 0 1rem;
  svg {
    height: 1em;
    width: 1em;
    fill: currentColor;
    font-size: 1rem;
    ${({ theme }) =>
      theme.direction === 'rtl' &&
      css`
        transform: rotate(180deg);
      `};
  }
`;
