import styled from 'styled-components';

export const StyledImageUploader = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 6.5rem);
  grid-auto-rows: 6.5rem;
  grid-gap: 1rem;

  &.disabled {
    opacity: 0.4;
  }
`;

export const StyledCoverImageWrapper = styled.div`
  grid-column: 1 / 3;
  grid-row: 1 / 3;
`;

export const StyledUploadedImageWrapper = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.uploadedImage.border};
  background-color: ${({ theme }) => theme.colors.uploadedImage.background};
`;

export const StyledUploadedImage = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
