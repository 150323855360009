import { defineMessages, IntlShape } from 'react-intl';

import logoNike from '../../../assets/images/logo-nike.png';
import logoAdidas from '../../../assets/images/logo-adidas.png';
import { RadioItemWithCheckboxesProps } from '../../atoms/Fields/Radio/content/withCheckboxes/types';
import routes from '../../../setup/consts/routes';
import { SideMenuItem } from '../../molecules/SideMenu/types';
import { SideMenuItemDefinition } from '../types';

const {
  ACCOUNT_DETAILS,
  ENTITIES,
  USERS,
  BILLING_AND_PAYMENTS,
  SUPPORT_AND_FAQ,
  CONTACT_US,
} = routes;

const menuItemsIntl = defineMessages({
  [ACCOUNT_DETAILS]: {
    id: 'sidebar.settings.accountDetails',
    defaultMessage: 'Account details',
  },
  [ENTITIES]: {
    id: 'sidebar.settings.entitiesAndUsers',
    defaultMessage: 'Entities and Users',
  },
  [USERS]: {
    id: 'mainNavigation.warehouse',
    defaultMessage: 'Warehouse',
  },
  [BILLING_AND_PAYMENTS]: {
    id: 'sidebar.settings.billingAndPayments',
    defaultMessage: 'Billing \u0026 Payments',
  },
  [SUPPORT_AND_FAQ]: {
    id: 'sidebar.settings.supportAndFaq',
    defaultMessage: 'Support \u0026 FAQ',
  },
});

export const menuItems: SideMenuItemDefinition[] = [
  {
    label: menuItemsIntl[ACCOUNT_DETAILS],
    to: ACCOUNT_DETAILS,
    key: '1',
  },
  {
    label: menuItemsIntl[ENTITIES],
    to: ENTITIES,
    key: '2',
    isActive: (_: unknown, { pathname }: { pathname: string }): boolean =>
      [ENTITIES, USERS].includes(pathname),
  },
  {
    label: menuItemsIntl[BILLING_AND_PAYMENTS],
    to: BILLING_AND_PAYMENTS,
    key: '3',
  },
  {
    label: menuItemsIntl[SUPPORT_AND_FAQ],
    to: SUPPORT_AND_FAQ,
    key: '4',
  },
];

export const sideMenuAdditionalItems = (intl: IntlShape): SideMenuItem[] => [
  {
    label: intl.formatMessage({
      id: 'sidebar.common.contactUs',
      defaultMessage: 'Contact Us',
    }),
    to: CONTACT_US,
    key: '1',
  },
];

export const multiSelectDemoItems: RadioItemWithCheckboxesProps[] = [
  {
    value: 'Nike',
    label: 'Nike',
    decor: logoNike,
    checkboxes: [
      {
        label: 'Nike - US/NY',
        value: '1',
      },
      {
        label: 'Nike - US/CA',
        value: '2',
      },
      {
        label: 'Nike - US/PA',
        value: '3',
      },
    ],
  },
  {
    value: 'Adidas',
    label: 'Adidas',
    decor: logoAdidas,
    checkboxes: [
      {
        label: 'Adidas - US/NY',
        value: '1',
      },
      {
        label: 'Adidas - US/CA',
        value: '2',
      },
      {
        label: 'Adidas - US/PA',
        value: '3',
      },
    ],
  },
];
