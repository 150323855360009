import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Doughnut } from 'react-chartjs-2';
import { SalesByCityBoxProps } from './types';
import {
  StyledSalesByCityBox,
  StyledSalesByCityBoxChartWrapper,
  StyledSalesByCityBoxLabel,
} from './styles';

const SalesByCityBox: React.FC<SalesByCityBoxProps> = ({
  label,
  dataChartLabels,
  dataChartNumbers,
}) => {
  const theme = useContext(ThemeContext);
  const data = {
    labels: dataChartLabels,
    datasets: [
      {
        data: dataChartNumbers,
        backgroundColor: [
          theme.colors.doughnutChart.first,
          theme.colors.doughnutChart.second,
          theme.colors.doughnutChart.third,
          theme.colors.doughnutChart.fourth,
          '#d63031',
          '#6c5ce7',
          '#fdcb6e',
          '#00cec9',
          '#2d3436',
          '#e84393',
          '#34495e',
          '#e67e22',
          '#1abc9c',
          '#9b59b6',
          '#f1c40f',
          '#2ecc71',
          '#e74c3c',
          '#3498db',
        ],
        borderWidth: 0,
      },
    ],
  };
  const options = {
    maintainAspectRatio: true,
    cutout: '25%',
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        onClick: null,
        labels: {
          padding: 20,
          boxWidth: 12,
          boxHeight: 12,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            family: theme.fonts.default,
            size: theme.fontSizes[2],
            weight: theme.fontWeights[0],
          },
          color: theme.colors.text.primary,
        },
      },
      tooltip: {
        boxWidth: 16,
        boxHeight: 16,
        usePointStyle: true,
        pointStyle: 'circle',
        padding: 8,
        bodyFont: {
          family: theme.fonts.default,
          size: theme.fontSizes[2],
          weight: theme.fontWeights[1],
          lineHeight: 'normal',
        },
        callbacks: {
          label: (context: any) =>
            ` ${Number(context.raw).toFixed(2)}% ${context.label}`,
        },
      },
    },
  };

  return (
    <StyledSalesByCityBox>
      <StyledSalesByCityBoxLabel>{label}</StyledSalesByCityBoxLabel>
      <StyledSalesByCityBoxChartWrapper>
        {/* React-chart doesn't expose prop types, so we can't properly type the options object */}
        {/* @ts-ignore */}
        <Doughnut {...{ data, options }} />
      </StyledSalesByCityBoxChartWrapper>
    </StyledSalesByCityBox>
  );
};

export default SalesByCityBox;
