import styled from 'styled-components';
import { motion } from 'framer-motion';
import Typography from '../../styles/Typography';

export const StyledOrderApprovalBarWrapper = styled(motion.div).attrs(() => ({
  initial: { opacity: 0, y: '100%' },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: '100%' },
  transition: { duration: 0.2 },
}))`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zindexes.bar};
  padding: 0 1rem;
  background-color: ${({ theme }) => theme.colors.orderApprovalBar.background};
  box-shadow: ${({ theme }) => theme.shadows.bar};
`;

export const StyledOrderApprovalBar = styled.div`
  display: grid;
  grid-template-columns: 17rem 1fr 1fr 1fr auto;
  grid-template-areas: 'delivery-charge-select total delivery-charge  grand-total action';
  grid-column-gap: 4rem;
  width: 100%;
  max-width: ${({ theme }) => theme.dimensions.orderApprovalBar.maxWidth};
  margin: 0 auto;
  padding: 1.5rem 0 1.2rem;
`;

export const SyledDeliveryChargeSelectWrapper = styled.div`
  grid-area: delivery-charge-select;
`;

export const StyledDeliveryChargeWrapper = styled.div`
  grid-area: delivery-charge;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledTotalWrapper = styled.div`
  grid-area: total;
`;

export const StyledGrandTotalWrapper = styled.div`
  grid-area: grand-total;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledOrderApprovalBarAction = styled.div`
  grid-area: action;
  display: flex;
  align-items: center;
`;

export const StyledOrderApprovalBarLabel = styled(Typography).attrs({
  forwardedAs: 'span',
  variant: 'caption',
})`
  margin-bottom: 1.15rem;
`;

export const StyledPriceWithCurrencyWrapper = styled.div`
  color: ${({ theme }) => theme.colors.text.tertiary};
`;
