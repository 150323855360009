import styled, { css } from 'styled-components';
import { StyledRadioProps } from './types';

export const StyledRadioListWrapper = styled.div`
  width: 100%;
`;

export const StyledRadioList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const StyledRadioListItem = styled.li`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const StyledRadioLabel = styled.label<StyledRadioProps>`
  position: relative;

  display: flex;

  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

export const StyledRadioInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;

  overflow: hidden;

  width: 0;
  height: 0;

  opacity: 0;
`;
