import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalBase from '../..';
import { IconSearch } from '../../../../../assets/svg/icons';
import { FieldMode, FieldSize } from '../../../../atoms/Fields/enums';
import Input from '../../../../atoms/Fields/Input';
import Loader from '../../../../atoms/Loader';
import { LoaderMode } from '../../../../atoms/Loader/enums';
import ProductOrderCardList from '../../../../molecules/ProductOrderCardList';
import { ProductOrderCardListItem } from '../../../../molecules/ProductOrderCardList/types';
import { StyledBox } from '../../../../styles/Grid/styles';
import Typography from '../../../../styles/Typography';
import { createProductsTableItem } from './consts';
import { useProductSearch } from './hooks';
import { StyledForm, StyledSearchWrapper } from './styles';
import { PlaceOrderProductsModalProps } from './types';

const PlaceOrderProductsModal: React.FC<PlaceOrderProductsModalProps> = ({
  entityId,
  currency,
  onProductAdd,
  productsInOrder,
  ...modalBaseProps
}) => {
  const intl = useIntl();

  const {
    products,
    query,
    handleQueryChange,
    fetchMore,
    hasMore,
    isLoading,
    isFetchingNextPage,
  } = useProductSearch(currency, entityId);

  const items: ProductOrderCardListItem[] = useMemo(
    () =>
      products.map((product) => {
        const productInOrder = !!productsInOrder?.some(
          (p) => p.idCol === product.key
        );

        return {
          ...product,
          productNameLabel: 'Name',
          orderedQuantityLabel: 'Ordered',
          dateCreatedLabel: 'Created',
          priceLabel: 'Price',
          buttonLabel: productInOrder ? 'Added' : 'Add to Order',
          buttonDisabled: productInOrder,
          onButtonClick: () => onProductAdd(createProductsTableItem(product)),
        };
      }),
    [products, onProductAdd, productsInOrder]
  );

  const productOrderCardList =
    items.length > 0 ? (
      <ProductOrderCardList {...{ fetchMoreData: fetchMore, hasMore, items }} />
    ) : (
      <Typography textAlign='center'>
        <FormattedMessage id='search.noResults' defaultMessage='No results' />
      </Typography>
    );

  return (
    <ModalBase {...modalBaseProps}>
      <StyledForm onSubmit={(e) => e.preventDefault()}>
        <StyledBox mb='3.125rem' justifyContent='center' alignItems='center'>
          <StyledSearchWrapper>
            <Input
              value={query}
              onChange={handleQueryChange}
              mode={FieldMode.Secondary}
              inputSize={FieldSize.Big}
              placeholder={intl.formatMessage({
                id: 'search.searchProducts',
                defaultMessage: 'Search Products',
              })}
              isRounded
              prefixComponent={<IconSearch />}
            />
          </StyledSearchWrapper>
        </StyledBox>
      </StyledForm>

      {isLoading && !isFetchingNextPage ? (
        <StyledBox justifyContent='center'>
          <Loader mode={LoaderMode.ProductOrderCardList} />
        </StyledBox>
      ) : (
        productOrderCardList
      )}
    </ModalBase>
  );
};

export default PlaceOrderProductsModal;
