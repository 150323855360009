import styled from 'styled-components';
import Typography from '../../styles/Typography';
import { StyledUploadProps } from './types';

export const StyledUpload = styled.div<StyledUploadProps>`
  position: relative;
  width: ${({ isBig }) => (isBig ? '14rem' : '6.5rem')};
  height: ${({ isBig }) => (isBig ? '14rem' : '6.5rem')};
  background-color: ${({ theme }) => theme.colors.upload.background};
  color: ${({ theme }) => theme.colors.upload.color};
  border-radius: 1rem;
  border-width: 1px;
  border-color: currentColor;
  border-style: dashed;
  overflow: hidden;
  padding: 1rem;
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    opacity: 0.8;
  }
`;

export const StyledIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 1em;
    height: 1em;
    font-size: 1.5rem;
    fill: currentColor;
  }
`;

export const StyledUploadLabel = styled(Typography)`
  position: relative;
  z-index: 1;
`;

export const StyledUploadCoverImage = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledUploadInput = styled.input.attrs({
  type: 'file',
})`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
  width: 100%;
`;
