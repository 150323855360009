import React from 'react';
import { StyledBox } from '../../../../../components/styles/Grid/styles';
import Typography from '../../../../../components/styles/Typography';
import {
  StyledFaqList,
  StyledFaqListItem,
  StyledHeading,
  StyledQuestionList,
  StyledQuestionListAnswer,
  StyledQuestionListItem,
} from './styles';
import { FaqListProps } from './types';

const FaqList: React.FC<FaqListProps> = ({ items }) => (
  <StyledFaqList>
    {items.map(({ key, heading, description, questions }) => (
      <StyledFaqListItem {...{ key }}>
        <StyledHeading>- {heading}</StyledHeading>
        <StyledBox mb='1.5rem'>
          <Typography variant='body' as='p'>
            {description}
          </Typography>
        </StyledBox>
        <StyledQuestionList>
          {questions.map(({ key: itemKey, question, answer }) => (
            <StyledQuestionListItem key={itemKey}>
              <StyledBox mb='0.5rem'>
                <Typography as='h4' variant='bodyBold'>
                  {question}
                </Typography>
              </StyledBox>
              <StyledQuestionListAnswer>{answer}</StyledQuestionListAnswer>
            </StyledQuestionListItem>
          ))}
        </StyledQuestionList>
      </StyledFaqListItem>
    ))}
  </StyledFaqList>
);

export default FaqList;
