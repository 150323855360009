import React from 'react';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useDebouncedCallback } from 'use-debounce';
import Button from '../../../../../components/atoms/Button';
import { ButtonMode } from '../../../../../components/atoms/Button/enums';
import { FormInput } from '../../../../../components/atoms/Fields/Input';
import { FormTextarea } from '../../../../../components/atoms/Fields/Textarea';
import ImageUploader from '../../../../../components/molecules/ImageUploader';
import { StyledBox } from '../../../../../components/styles/Grid/styles';
import Typography from '../../../../../components/styles/Typography';
import { StyledAction, StyledForm } from './styles';
import { ProductFormProps } from '../../types';
import routes from '../../../../../setup/consts/routes';
import { useCategoriesList } from '../../../../../hooks/useCategoriesList';
import { FormSimpleAsyncSelect } from '../../../../../components/atoms/Fields/Select/templates/SimpleSelect';
import { makeApiUrl } from '../../../../../utils/reactQuery';
import { GetCategoryListResponseSingleItem } from '../../../../../hooks/useCategoriesList/types';
import apiRoutes from '../../../../../setup/consts/apiRoutes';

const ProductForm: React.FC<ProductFormProps> = ({
  handleSubmit,
  isEdit,
  productData,
  coverImage,
  setCoverImage,
  uploadedImages,
  setUploadedImages,
  loadingSubmit,
}) => {
  const intl = useIntl();
  const hasUploadedImages = uploadedImages.length > 0;
  const coverImageLabelText = intl.formatMessage({
    id: 'warehouse.coverImage',
    defaultMessage: 'Cover Image',
  });
  const history = useHistory();
  const { list: categoriesOptions, isLoading: categoriesIsLoading } =
    useCategoriesList();

  const coverImageSrc = coverImage
    ? { src: coverImage.src }
    : productData?.imageUrl
    ? { src: productData?.imageUrl }
    : undefined;

  const debouncedLoadCategoriesOptions = useDebouncedCallback(
    (inputValue: string, resolve) =>
      axios
        .get(makeApiUrl(apiRoutes.CATEGORIES_LIST), {
          params: { pageSize: 20, name: inputValue },
        })
        .then((res) => {
          const categories = res?.data?.categories.map(
            (c: GetCategoryListResponseSingleItem) => ({
              label: c.name,
              value: c.id.toString(),
            })
          );

          resolve(categories);
        }),

    1500
  );

  const handleLoadMoreOptions = (inputValue: string) =>
    new Promise<{ value: string; label: string }[]>((resolve) => {
      debouncedLoadCategoriesOptions(inputValue, resolve);
    });

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledBox justifyContent='flex-end' mb='2rem'>
        <StyledAction>
          <Button
            mode={ButtonMode.Secondary}
            onClick={() => history.push(routes.WAREHOUSE)}
            type='button'
            disabled={loadingSubmit}
          >
            <FormattedMessage
              id='forms.buttons.cancel'
              defaultMessage='Cancel'
            />
          </Button>
          <Button type='submit' disabled={loadingSubmit}>
            <FormattedMessage id='forms.buttons.save' defaultMessage='Save' />
          </Button>
        </StyledAction>
      </StyledBox>

      <Row>
        <Col md={6}>
          <FormInput
            label={intl.formatMessage({
              id: 'warehouse.productName',
              defaultMessage: 'Product Name',
            })}
            name='name'
            disabled={loadingSubmit}
          />
          <FormInput
            label={intl.formatMessage({
              id: 'warehouse.productPrice',
              defaultMessage: 'Product Price',
            })}
            name='price'
            type='number'
            min={0}
            disabled={loadingSubmit}
          />
          <FormTextarea
            label={intl.formatMessage({
              id: 'warehouse.productDetails',
              defaultMessage: 'Product Details',
            })}
            name='description'
            disabled={loadingSubmit}
          />
          <FormSimpleAsyncSelect
            name='categoryId'
            label={intl.formatMessage({
              id: 'warehouse.category',
              defaultMessage: 'Category',
            })}
            options={categoriesOptions}
            disabled={categoriesIsLoading || loadingSubmit}
            loadOptions={handleLoadMoreOptions}
          />
        </Col>
        <Col md={6}>
          <StyledBox mb='0.625rem'>
            <Typography variant='caption'>
              <FormattedMessage id='warehouse.images' defaultMessage='Images' />
            </Typography>
          </StyledBox>
          <StyledBox mb='2.5rem'>
            <ImageUploader
              coverImageLabel={coverImageLabelText}
              coverImage={coverImageSrc}
              uploadedImages={hasUploadedImages ? uploadedImages : undefined}
              onChangeCoverImage={(file) => {
                const data = file
                  ? { file, src: URL.createObjectURL(file) }
                  : null;

                setCoverImage(data);
              }}
              onChangeUploadedImages={(files) => {
                const data = files.length
                  ? Object.values(files).map((f) => ({
                      file: f,
                      src: URL.createObjectURL(f),
                    }))
                  : [];

                setUploadedImages([...uploadedImages, ...data]);
              }}
              disabled={loadingSubmit}
            />
          </StyledBox>
          {/* Hidden */}
          <StyledBox mb='1rem' style={{ display: 'none' }}>
            <Typography variant='caption'>
              <FormattedMessage
                id='warehouse.fulfillment'
                defaultMessage='Fulfillment'
              />
            </Typography>
          </StyledBox>
          {/* <RadioList<string>
            items={secondaryRadioItems}
            value={currentValue}
            onChange={(value): void => setCurrentValue(value || '')}
            renderRadioContent={renderSecondaryRadioItem}
            disabled={loadingSubmit}
          /> */}
        </Col>
      </Row>
    </StyledForm>
  );
};

export default ProductForm;
