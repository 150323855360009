import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ColumnDef } from '@tanstack/react-table';
import PriceWithCurrency from '../../../../atoms/PriceWithCurrency';
import { PriceWithCurrencySize } from '../../../../atoms/PriceWithCurrency/enums';
import { UseTableColumnsReturnType } from './types';
import { SingleWithdrawnBalanceObject } from '../../../../../setup/apiTypes/settlements';

export const useTableColumns = (): UseTableColumnsReturnType => {
  const intl = useIntl();

  const tableColumns = useMemo<ColumnDef<SingleWithdrawnBalanceObject>[]>(
    () => [
      {
        header: intl.formatMessage({
          id: 'notes',
          defaultMessage: 'Notes',
        }),
        accessorKey: 'notes',
      },
      {
        header: intl.formatMessage({
          id: 'financials.withdrawnBalance',
          defaultMessage: 'Withdrawn Balance',
        }),
        accessorKey: 'balance',
        cell: ({ cell: { getValue, row } }) => {
          const { getValue: getCellValue } = row;
          const currency = getCellValue('currency');

          return (
            <PriceWithCurrency
              size={PriceWithCurrencySize.Big}
              price={getValue() as number}
              currency={currency as string}
            />
          );
        },
      },
    ],
    [intl]
  );

  return {
    tableColumns,
  };
};
