import axios from 'axios';
import { FORM_ERROR } from 'final-form';
import noop from 'lodash/noop';
import startCase from 'lodash/startCase';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import qs from 'qs';
import { makeApiUrl } from '../../utils/reactQuery/index';

import { IconOrders } from '../../assets/svg/icons';
import { StatusSelectOption } from '../../components/atoms/Fields/Select/templates/StatusSelect/types';
import { UPLOAD_ORDERS_MUTATION_FN } from '../../components/organisms/Modal/templates/UploadOrderModal/consts';
import apiRoutes from '../../setup/consts/apiRoutes';
import SupportedLanguages from '../../setup/intl/enums';
import { transformObjectToFormData } from '../../utils/api';
import { mapApiErrorsToFormErrors } from '../../utils/finalForm';
import {
  GetOrderStatusListResponse,
  OrdersGridFilters,
  UsePlaceholderPropsReturnType,
  UseUploadOrdersFormSubmitHandlerReturnType,
} from './types';
import { downloadFile } from '../../utils';
import { formatDateDashes } from '../../components/atoms/Fields/Datepicker/utils';

export const usePlaceholderProps = (): UsePlaceholderPropsReturnType => {
  const intl = useIntl();

  const title = intl.formatMessage({
    id: 'orders.addOrders',
    defaultMessage: 'Add Orders',
  });

  const description = intl.formatMessage({
    id: 'orders.ordersPlaceholderDescription',
    defaultMessage: 'Orders Placeholder Description',
  });

  const buttonLabel = intl.formatMessage({
    id: 'orders.createNewOrder',
    defaultMessage: 'Create new order',
  });

  const Icon = IconOrders;

  return {
    title,
    description,
    buttonLabel,
    Icon,
  };
};

export const useUploadOrdersFormSubmitHandler = (
  onClose: () => void = noop,
  openOrderUploadStatusModal: () => void = noop
): UseUploadOrdersFormSubmitHandlerReturnType => {
  const intl = useIntl();
  const { addToast } = useToasts();

  const [taskId, setTaskId] = useState<string>();
  const { mutateAsync: uploadOrders } = useMutation(UPLOAD_ORDERS_MUTATION_FN);

  const handleUploadOrders = useCallback(
    async ({ entity: entityId, file }) => {
      try {
        const formData = transformObjectToFormData({
          entityId,
          file,
        });

        const { response } = await uploadOrders({
          data: formData,
        });

        addToast(
          intl.formatMessage({
            id: 'enitiesAndUsers.orderCreatedMessage',
            defaultMessage: 'Order has been created',
          }),
          {
            appearance: 'success',
          }
        );

        setTaskId(response.taskId);
        openOrderUploadStatusModal();
        onClose();

        return undefined;
      } catch (error) {
        const { errors, generalError } = mapApiErrorsToFormErrors(error, intl);

        if (errors) {
          return errors;
        }

        const defaultError = intl.formatMessage({
          id: 'orders.uploadOrdersFailed',
          defaultMessage: 'Failed to upload orders.',
        });

        addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
          appearance: 'error',
        });

        return {
          [FORM_ERROR]: defaultError,
        };
      }
    },
    [uploadOrders, openOrderUploadStatusModal, addToast, intl, onClose]
  );

  return {
    handleUploadOrders,
    taskId,
  };
};

export const useStatusOptions = () => {
  const { locale } = useIntl();
  const { data, isLoading } = useQuery<
    UseQueryResponse<GetOrderStatusListResponse>
  >(apiRoutes.ORDER_STATUS_LIST);

  return {
    options: useMemo(() => {
      if (!data || !data.response) {
        return [];
      }

      const {
        response: { statuses },
      } = data;

      const isEnglish = locale === SupportedLanguages.English;

      return [
        ...statuses
          .filter((s) => s.status !== 'Default')
          .map<StatusSelectOption>(
            ({ status, enStatusName, arStatusName, color }) => ({
              value: status,
              label: isEnglish ? startCase(enStatusName) : arStatusName,
              color,
            })
          ),
      ];
    }, [data, locale]),
    isLoading,
  };
};

export const useExportOrders = (): {
  isLoading: boolean;
  handleDownloadOrdersExcelFile: (filters: OrdersGridFilters) => Promise<any>;
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { addToast } = useToasts();
  const intl = useIntl();

  const handleDownloadOrdersExcelFile = async (filters: OrdersGridFilters) => {
    try {
      setIsLoading(true);

      const res = await axios
        .get(makeApiUrl(apiRoutes.ORDERS_EXPORT), {
          params: filters,
          paramsSerializer: (params) => qs.stringify(params),
          responseType: 'blob',
        })
        .finally(() => setIsLoading(false));

      if (res.status === 200 && res.data) {
        const file = new Blob([res.data]);

        downloadFile(file, `orders - ${formatDateDashes(new Date())}.xlsx`);
      } else {
        addToast(intl.formatMessage({ id: 'failed' }), {
          appearance: 'error',
        });
      }
    } catch (error) {
      addToast(intl.formatMessage({ id: 'failed' }), {
        appearance: 'error',
      });
    }
  };

  return {
    isLoading,
    handleDownloadOrdersExcelFile,
  };
};
