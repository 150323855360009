import { rgba } from 'polished';
import styled, { keyframes } from 'styled-components';
import Typography from '../../styles/Typography';
import { SpinnerMode, SpinnerSize } from './enums';
import { SpinnerProps } from './types';

const rotate = keyframes`
   0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledSpinner = styled.div<SpinnerProps>`
  border-radius: 50%;
  width: 10em;
  height: 10em;
  overflow: hidden;

  font-size: ${({ theme, size = SpinnerSize.Small }) =>
    theme.dimensions.spinner[size]};
  position: relative;
  text-indent: -9999em;
  border-top: 1.5em solid
    ${({ theme, mode = SpinnerMode.Light }) =>
      rgba(theme.colors.spinner[mode], 0.2)};
  border-right: 1.5em solid
    ${({ theme, mode = SpinnerMode.Light }) =>
      rgba(theme.colors.spinner[mode], 0.2)};
  border-bottom: 1.5em solid
    ${({ theme, mode = SpinnerMode.Light }) =>
      rgba(theme.colors.spinner[mode], 0.2)};
  border-left: 1.5em solid
    ${({ theme, mode = SpinnerMode.Light }) => theme.colors.spinner[mode]};

  transform: translateZ(0);
  animation: ${rotate} 1.1s infinite linear;
`;

export const StyledSpinnerOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  z-index: ${({ theme }) => theme.zindexes.loaderOverlay};
  background-color: ${({ theme }) => theme.colors.background.modal};
`;

export const StyledSpinnerDescription = styled(Typography).attrs({
  variant: 'body',
})`
  color: ${({ theme }) => theme.colors.text.light};
  text-align: center;
  margin-top: 1rem;
  max-width: 20rem;
  width: 100%;
`;
