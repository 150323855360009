import React from 'react';
import Checkbox from '../../../Checkbox';
import { MultiSelectValue } from '../../../MultiSelect/types';

import { RenderRadioContent } from '../../types';
import {
  StyledRadioMark,
  StyledRadioControlWrapper,
  StyledRadioWrapper,
  StyledRadioLabel,
  StyledRadioDecor,
  StyledRadioDecorWrapper,
  StyledRadioCheckboxesList,
  StyledRadioCheckboxesListItem,
} from './styles';
import { RadioItemWithCheckboxesProps } from './types';

const renderRadioItemWithCheckboxes =
  (
    selectedValue: MultiSelectValue | undefined,
    withoutDisableFirstOption?: boolean,
    onChangeCheckedCheckboxes?: (value: string) => void
  ): RenderRadioContent<RadioItemWithCheckboxesProps> =>
  ({ value, label, decor, checked, disabled, checkboxes }) =>
    (
      <StyledRadioWrapper>
        <StyledRadioControlWrapper {...{ disabled }}>
          <StyledRadioMark {...{ checked }} />
          {decor && (
            <StyledRadioDecorWrapper>
              <StyledRadioDecor src={decor} alt={label} />
            </StyledRadioDecorWrapper>
          )}
          <StyledRadioLabel>{label || value}</StyledRadioLabel>
        </StyledRadioControlWrapper>
        <StyledRadioCheckboxesList>
          {checkboxes.map(({ value: checkBoxValue, color, ...props }, idx) => {
            const checkedSubItems = selectedValue?.checkedSubItems || [];
            const [firstCheckedSubItem] = checkedSubItems;
            const isCheckboxDisabled = withoutDisableFirstOption
              ? false
              : firstCheckedSubItem === checkBoxValue &&
                checkedSubItems.length === 1;

            return (
              <StyledRadioCheckboxesListItem key={idx}>
                <Checkbox
                  disabled={isCheckboxDisabled}
                  checked={checkedSubItems.includes(checkBoxValue)}
                  onChange={() =>
                    onChangeCheckedCheckboxes &&
                    onChangeCheckedCheckboxes(checkBoxValue)
                  }
                  tagColor={color}
                  {...props}
                />
              </StyledRadioCheckboxesListItem>
            );
          })}
        </StyledRadioCheckboxesList>
      </StyledRadioWrapper>
    );

export default renderRadioItemWithCheckboxes;
