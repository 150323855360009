import styled from 'styled-components';

export const StyledProgressBar = styled.div`
  display: flex;
  gap: 1.5rem;
  width: 100%;
`;

export const StyledProgressBarItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.2rem;
`;

export const StyledProgressBarItem = styled.div<{
  percent: number;
  barColor: string;
}>`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 1rem;
  background-color: ${({ theme }) => theme.colors.progressBar.background};
  border-radius: 1rem;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${({ percent }) => percent}%;
    background-color: ${({ barColor }) => barColor};
  }
`;
