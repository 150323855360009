export enum LoaderMode {
  Table = 'table',
  PlaceOrder = 'placeOrder',
  ProductOrderCardList = 'productOrderCardList',
  FinancialBalanceBoxes = 'financialBalanceBoxes',
  OrderSummary = 'orderSummary',
  WarehouseGridList = 'warehouseGridList',
  WarehouseAddProduct = 'warehouseAddProduct',
  WarehouseShowProduct = 'warehouseShowProduct',
  WarehouseReport = 'warehouseReport',
  CashoutBox = 'cashoutBox',
  CustomerSummary = 'customerSummary',
}
