import React, { ChangeEvent, useMemo } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useIntl } from 'react-intl';
import { IconCustomer2 } from '../../../../../../../../assets/svg/icons';
import Autocomplete from '../../../../../../../../components/atoms/Fields/Autocomplete';
import { BaseAutocompleteItem } from '../../../../../../../../components/atoms/Fields/Autocomplete/types';
import CheckboxList from '../../../../../../../../components/atoms/Fields/CheckboxList';

import Placeholder from '../../../../../../../../components/molecules/Placeholder';
import { PlaceholderSize } from '../../../../../../../../components/molecules/Placeholder/enums';
import { StyledBox } from '../../../../../../../../components/styles/Grid/styles';
import { GetUsersResponseSingleItem } from '../../../../../../../../setup/apiTypes/users';
import apiRoutes from '../../../../../../../../setup/consts/apiRoutes';
import {
  StyledAssignedUsersWrapper,
  StyledPlaceholderWrapper,
  StyledSearchWrapper,
} from './styles';
import {
  AssignedUsersFieldProps,
  AssignedUsersProps,
  UserFormItem,
} from './types';

const AssignedUsers: React.FC<AssignedUsersProps> = ({
  fields,
  openUsersModal,
}) => {
  const intl = useIntl();
  const checkboxItems = useMemo(
    () =>
      fields.value.map(({ label, checked, value }, index) => ({
        key: value,
        label,
        checked,
        onChange: (event: ChangeEvent<HTMLInputElement>) => {
          fields.update(index, {
            label,
            value,
            checked: event.target.checked,
          });
        },
      })),
    [fields]
  );

  return (
    <StyledAssignedUsersWrapper>
      <StyledSearchWrapper>
        <Autocomplete<string, { items: GetUsersResponseSingleItem[] }>
          endpoint={apiRoutes.USERS}
          onSuggestionClick={(item) =>
            fields.push({
              ...item,
              checked: true,
            })
          }
          mapApiResponseToSuggestions={({ items }) =>
            items.reduce<BaseAutocompleteItem<string>[]>(
              (result, { id, name, email }) => {
                const isItemSelected = fields.value.find(
                  ({ value }) => value === id
                );

                if (isItemSelected) {
                  return result;
                }

                return [...result, { label: `${name} (${email})`, value: id }];
              },
              []
            )
          }
          placeholder={intl.formatMessage({
            id: 'search.searchUsers',
            defaultMessage: 'Search users',
          })}
          buttonLabel={intl.formatMessage({
            id: 'enitiesAndUsers.createNewUsers',
            defaultMessage: 'Create New User',
          })}
          onButtonClick={openUsersModal}
          placeholderProps={{
            title: intl.formatMessage({
              id: 'enitiesAndUsers.usersPlaceholderTitle',
              defaultMessage: 'You have not added any users yet',
            }),
            description: intl.formatMessage({
              id: 'enitiesAndUsers.usersPlaceholderDescription',
              defaultMessage:
                'You can add and assign roles to users and associate them with one or more of your business entities.',
            }),
            icon: IconCustomer2,
          }}
          clearInputOnSuggestionClick
        />
      </StyledSearchWrapper>
      <StyledBox>
        {!checkboxItems.length ? (
          <StyledPlaceholderWrapper>
            <Placeholder
              title={intl.formatMessage({
                id: 'enitiesAndUsers.usersPlaceholderTitle',
                defaultMessage: 'You have not added any users yet',
              })}
              description={intl.formatMessage({
                id: 'enitiesAndUsers.usersPlaceholderDescription',
                defaultMessage:
                  'You can add and assign roles to users and associate them with one or more of your business entities.',
              })}
              icon={IconCustomer2}
              buttonLabel={intl.formatMessage({
                id: 'enitiesAndUsers.createNewUsers',
                defaultMessage: 'Create New User',
              })}
              onClick={openUsersModal}
              size={PlaceholderSize.Small}
            />
          </StyledPlaceholderWrapper>
        ) : (
          <CheckboxList items={checkboxItems} />
        )}
      </StyledBox>
    </StyledAssignedUsersWrapper>
  );
};

const AssignedUsersField: React.FC<AssignedUsersFieldProps> = ({
  name,
  ...rest
}) => (
  <FieldArray<UserFormItem> {...{ name }}>
    {({ fields }) => <AssignedUsers {...{ fields, ...rest }} />}
  </FieldArray>
);

export default AssignedUsersField;
