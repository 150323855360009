import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import reset from 'styled-reset';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${reset}
  html {
    box-sizing: border-box;
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: "Neo Sans W23", sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #3A3F60;
    overflow: hidden;
  }
  *,
  *:before,
  *:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: inherit;
  }
  svg {
    fill: currentColor;
  }
`;

export default GlobalStyle;
