import isAfter from 'date-fns/isAfter';
import { useEffect } from 'react';
import { useLocalStorageValue } from '../../hooks/useLocalStorageValue';
import { removeLocalStorageValue } from '../../hooks/useLocalStorageValue/utils';
import { AUTH_TOKENS_LOCAL_STORAGE_KEY } from './consts';
import { AuthTokensShape } from './types';

export const useAuthTokens = (): AuthTokensShape | null => {
  const tokens = useLocalStorageValue<AuthTokensShape>(
    AUTH_TOKENS_LOCAL_STORAGE_KEY
  );

  useEffect(() => {
    if (tokens && isAfter(new Date(), new Date(tokens.expirationDate))) {
      removeLocalStorageValue(AUTH_TOKENS_LOCAL_STORAGE_KEY);
    }
  }, [tokens]);

  return tokens;
};
