import noop from 'lodash/noop';

export const flattenObject = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  obj: object,
  parent: null | string = null,
  res: { [key: string]: string } = {}
): { [key: string]: string } => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(obj)) {
    const propName = parent ? `${parent}.${key}` : key;

    if (typeof value === 'object') {
      flattenObject(value, propName, res);
    } else {
      res[propName] = value;
    }
  }

  return res;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const asyncNoop = async (...args: any[]): Promise<any> => noop(...args);

export const uuidv4 = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });
