import React, { useState } from 'react';
import { subMonths } from 'date-fns';
import { Range } from 'react-date-range';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { KeyPerformanceStatsProps } from './types';
import {
  CitySelectWrapper,
  StyledKeyPerformanceStats,
  StyledKeyPerformanceStatsHeader,
} from './styles';
import { StyledBox } from '../../styles/Grid/styles';
import Typography from '../../styles/Typography';
import Datepicker from '../../atoms/Fields/Datepicker';
import { DatepickerPosition } from '../../atoms/Fields/Datepicker/enums';
import FinancialsStats from './FinancialsStats';
import OrdersStats from './OrdersStats';
import { IconLocation } from '../../../assets/svg/icons';
import SelectBase from '../../atoms/Fields/Select/base';

const KeyPerformanceStats: React.FC<KeyPerformanceStatsProps> = () => {
  const [range, setRange] = useState<Range>({
    startDate: subMonths(new Date(), 1),
    endDate: new Date(),
  });

  return (
    <StyledKeyPerformanceStats>
      <StyledKeyPerformanceStatsHeader>
        <Typography variant='h2'>
          <FormattedMessage
            id='keyPerformanceStats'
            defaultMessage='Key Performance Stats'
          />
        </Typography>

        {/* Filters */}
        <StyledBox alignItems='center' gap='1rem'>
          {/* City Filter */}
          <CitySelectWrapper>
            <SelectBase
              placeholder='Select filter...'
              icon={IconLocation}
              options={[
                { value: 'baghdad', label: 'Baghdad' },
                { value: 'erbil', label: 'Erbil' },
                { value: 'najaf', label: 'Najaf' },
                { value: 'anbar', label: 'Anbar' },
              ]}
              notSeparated
              value='baghdad'
              onChange={noop}
              withoutIndicatorRotation
              hideErrorMessageWrapper
            />
          </CitySelectWrapper>

          <Datepicker
            value={range}
            onChange={(item) => setRange(item)}
            position={DatepickerPosition.Right}
          />
        </StyledBox>
      </StyledKeyPerformanceStatsHeader>

      {/* Financials Stats */}
      <FinancialsStats />

      {/* Orders Stats */}
      <OrdersStats />
    </StyledKeyPerformanceStats>
  );
};

export default KeyPerformanceStats;
