import React from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  IconArrowForward,
  IconDocument,
  IconMail,
} from '../../../assets/svg/icons';
import Button from '../../../components/atoms/Button';
import { FormInput } from '../../../components/atoms/Fields/Input';
import { StyledBox } from '../../../components/styles/Grid/styles';
import LoginInnerLayout from '../Layout';
import { RESET_PASSWORD_FORM_INITIAL_VALUES } from './consts';
import { useResetPasswordFormSubmitHandler } from './hooks';
import {
  StyledResetPasswordCaption,
  StyledResetPasswordDecor,
  StyledResetPasswordDecorSeparator,
  StyledResetPasswordDescription,
  StyledResetPasswordForm,
  StyledResetPasswordInfoButton,
} from './styles';
import { ResetPasswordFormValues } from './types';

const ResetPasswordPage: React.FC = () => {
  const intl = useIntl();
  const submitHandler = useResetPasswordFormSubmitHandler();

  return (
    <Form<ResetPasswordFormValues>
      onSubmit={submitHandler}
      initialValues={RESET_PASSWORD_FORM_INITIAL_VALUES}
    >
      {({ handleSubmit, submitSucceeded, submitting, form: { reset } }) => (
        <LoginInnerLayout
          pageTitle={intl.formatMessage({
            id: 'login.resetPassword',
            defaultMessage: 'Reset password',
          })}
          title={
            submitSucceeded
              ? intl.formatMessage({
                  id: 'login.checkYourEmail',
                  defaultMessage: 'Check your email',
                })
              : intl.formatMessage({
                  id: 'login.resetPassword',
                  defaultMessage: 'Reset password',
                })
          }
        >
          {submitSucceeded ? (
            <>
              <StyledBox mb='1.5rem'>
                <StyledResetPasswordDecor>
                  <IconDocument />
                  <StyledResetPasswordDecorSeparator>
                    <IconArrowForward />
                  </StyledResetPasswordDecorSeparator>
                  <IconMail />
                </StyledResetPasswordDecor>
              </StyledBox>
              <StyledBox mb='2rem'>
                <StyledResetPasswordDescription>
                  <FormattedMessage
                    id='login.passwordResetInstructions'
                    defaultMessage='We have sent a password reset instructions to your email.'
                  />
                </StyledResetPasswordDescription>
              </StyledBox>
              <StyledResetPasswordCaption>
                <FormattedMessage
                  id='login.didntReceiveTheEmail'
                  defaultMessage='Didn’t receive the email? Check your spam filter, or try {link}.'
                  values={{
                    link: (
                      <StyledResetPasswordInfoButton onClick={() => reset()}>
                        <FormattedMessage
                          id='login.anotherEmailAddress'
                          defaultMessage='another email address'
                        />
                      </StyledResetPasswordInfoButton>
                    ),
                  }}
                />
              </StyledResetPasswordCaption>
            </>
          ) : (
            <>
              <StyledResetPasswordCaption>
                <FormattedMessage
                  id='login.resetPasswordDescription'
                  defaultMessage='Enter the email associated with your account and we’ll send an email with instructions to rest your password'
                />
              </StyledResetPasswordCaption>
              <StyledResetPasswordForm onSubmit={handleSubmit}>
                <FormInput
                  name='email'
                  label={intl.formatMessage({
                    id: 'forms.fields.emailAddress',
                    defaultMessage: 'Email Address',
                  })}
                  disabled={submitting}
                  type='email'
                />
                <Button type='submit' disabled={submitting}>
                  <FormattedMessage
                    id='login.sendInstructions'
                    defaultMessage='Send Instructions'
                  />
                </Button>
              </StyledResetPasswordForm>
            </>
          )}
        </LoginInnerLayout>
      )}
    </Form>
  );
};

export default ResetPasswordPage;
