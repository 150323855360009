import { rgba } from 'polished';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { variant } from 'styled-system';
import { NavLink } from 'react-router-dom';
import Typography from '../../styles/Typography';
import { textStyles } from '../../../theme/config/typography';

export const StyledMenuDropdown = styled.div`
  position: relative;
  z-index: 3;
`;

export const StyledMenuDropdownButton = styled.button`
  background-color: transparent;
  border: 0;
  padding: 0;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => rgba(theme.colors.text.secondary, 0.54)};
  transition: ${({ theme }) => theme.transitions.default};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => rgba(theme.colors.text.secondary, 0.8)};
  }
  svg {
    height: 1em;
    width: 1em;
    font-size: 1.5rem;
    fill: currentColor;
  }
`;

export const StyledMenuDropdownContentWrapper = styled(motion.div).attrs({
  key: 'menu-dropdown',
  initial: { opacity: 0, y: '-16px' },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: '-16px' },
  transition: { duration: 0.2, type: 'tween' },
})`
  min-width: ${({ theme }) => theme.dimensions.menuDropdown.minWidth};
  position: absolute;
  top: calc(100% + 0.5rem);

  ${({ theme }) => (theme.direction === 'rtl' ? 'left: 0;' : 'right: 0;')}
`;

export const StyledMenuDropdownContent = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.light};
  border-radius: 1rem;
  box-shadow: ${({ theme }) => theme.shadows.mid};
  border: 1px solid ${({ theme }) => rgba(theme.colors.text.primary, 0.13)};
  padding: 0.875rem 0;
`;

export const StyledMenuDropdownTitle = styled(Typography).attrs({
  forwardedAs: 'h3',
  variant: 'body',
})`
  text-transform: uppercase;
  color: ${({ theme }) => rgba(theme.colors.text.secondary, 0.54)};
  margin-bottom: 0.5rem;
  padding: 0 1.125rem;
`;

export const StyledMenuDropdownList = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const StyledMenuDropdownListItem = styled.li`
  width: 100%;
`;

export const StyledMenuDropdownLink = styled(NavLink).attrs({
  variant: 'body',
  activeClassName: 'is-active',
})`
  transition: ${({ theme }) => theme.transitions.default};
  color: ${({ theme }) => theme.colors.text.secondary};
  background-color: transparent;
  padding: 0.5rem 1.125rem;
  text-decoration: none;
  display: block;
  ${variant({
    variants: {
      ...textStyles,
    },
  })};
  &.is-active {
    background-color: ${({ theme }) =>
      rgba(theme.colors.navigation.background, 0.9)};
  }
  &:hover {
    background-color: ${({ theme }) =>
      rgba(theme.colors.navigation.background, 0.5)};
  }
`;

export const StyledMenuDropdownLinkButton = styled.button.attrs({
  variant: 'body',
})`
  border: 0;
  outline: none;
  cursor: pointer;
  padding: 0.5rem 1.125rem;
  transition: ${({ theme }) => theme.transitions.default};
  color: ${({ theme }) => theme.colors.text.secondary};
  background-color: transparent;
  display: block;
  width: 100%;
  ${({ theme }) =>
    theme.direction === 'rtl' ? 'text-align: right;' : 'text-align: left;'}
  ${variant({
    variants: {
      ...textStyles,
    },
  })};
  &:hover {
    background-color: ${({ theme }) =>
      rgba(theme.colors.navigation.background, 0.5)};
  }
`;
