import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ProductOrderCardListProps } from './types';
import {
  StyledProductOrderCardList,
  StyledProductOrderCardListItem,
} from './styles';
import ProductOrderCard from '../ProductOrderCard';
import Spinner from '../../atoms/Spinner';
import { StyledBox } from '../../styles/Grid/styles';
import { SpinnerMode } from '../../atoms/Spinner/enums';

const ProductOrderCardList: React.FC<ProductOrderCardListProps> = ({
  items,
  fetchMoreData,
  hasMore,
}) => {
  const handleFetchMoreData = useCallback(() => {
    fetchMoreData();
  }, [fetchMoreData]);

  return (
    <InfiniteScroll
      dataLength={items.length}
      next={handleFetchMoreData}
      loader={
        <StyledBox justifyContent='center' alignItems='center' p='2rem'>
          <Spinner mode={SpinnerMode.Dark} />
        </StyledBox>
      }
      hasChildren
      scrollableTarget='modal-content'
      {...{ hasMore }}
    >
      <StyledProductOrderCardList>
        {items.map(({ key, ...item }) => (
          <StyledProductOrderCardListItem {...{ key }}>
            <ProductOrderCard {...item} />
          </StyledProductOrderCardListItem>
        ))}
      </StyledProductOrderCardList>
    </InfiniteScroll>
  );
};

export default ProductOrderCardList;
