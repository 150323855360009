import React from 'react';
import { components } from 'react-select';
import { SelectPlaceholderProps } from './types';

function Placeholder<T = string>({
  children,
  renderPlaceholderContent,
  selectProps,
  ...rest
}: SelectPlaceholderProps<T>): ReactComponentReturnType {
  const { placeholder } = selectProps;

  return (
    <components.Placeholder {...{ selectProps, ...rest }}>
      {(renderPlaceholderContent &&
        placeholder &&
        typeof placeholder === 'string' &&
        renderPlaceholderContent(placeholder)) ||
        children}
    </components.Placeholder>
  );
}

export default Placeholder;
