import React from 'react';

import { RenderRadioContent, BaseRadioItem } from '../../types';
import {
  StyledRadioMark,
  StyledRadioControlWrapper,
  StyledRadioWrapper,
  StyledRadioLabel,
  StyledRadioInfo,
  StyledRadioInfoWrapper,
  StyledRadioDecor,
  StyledRadioDecorWrapper,
} from './styles';

const renderDefaultRadioItem =
  (): RenderRadioContent<BaseRadioItem<string>> =>
  ({ value, label, info, decor, checked, disabled }) =>
    (
      <StyledRadioWrapper>
        <StyledRadioControlWrapper {...{ disabled }}>
          <StyledRadioMark {...{ checked }} />
          <StyledRadioLabel>{label || value}</StyledRadioLabel>
          {decor && (
            <StyledRadioDecorWrapper>
              <StyledRadioDecor src={decor} alt={label || value} />
            </StyledRadioDecorWrapper>
          )}
        </StyledRadioControlWrapper>
        {info && checked && (
          <StyledRadioInfoWrapper>
            <StyledRadioInfo>{info}</StyledRadioInfo>
          </StyledRadioInfoWrapper>
        )}
      </StyledRadioWrapper>
    );

export default renderDefaultRadioItem;
