import styled from 'styled-components';
import Typography from '../../styles/Typography';
import colors from '../../../theme/config/colors';

export const StyledCustomerOrdersTable = styled.div`
  width: 100%;
  overflow: auto;
`;

export const StyledDeliveryLabelLink = styled(Typography)`
  a {
    color: ${colors.link};
  }
`;
