import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalBase from '../..';
import { IconUpload } from '../../../../../assets/svg/icons';
import { useEntitySelectItems } from '../../../../../pages/orders/orderFormPage/components/OrderForm/hooks';
import Avatar from '../../../../atoms/Avatar';
import { AvatarSize } from '../../../../atoms/Avatar/enums';
import Button from '../../../../atoms/Button';
import { ButtonMode } from '../../../../atoms/Button/enums';
import Input from '../../../../atoms/Fields/Input';
import { FormSimpleSelect } from '../../../../atoms/Fields/Select/templates/SimpleSelect';
import { StyledBox } from '../../../../styles/Grid/styles';
import Typography from '../../../../styles/Typography';
import { useEntityHasProfileFees } from './hooks';
import {
  StyledForm,
  StyledUploadInputWrapper,
  StyledUploadModalAction,
} from './styles';
import {
  OrdersUploadFormData,
  UploadOrderModalProps,
  UploadOrderModalWrapperProps,
} from './types';

const UploadOrderModal: React.FC<UploadOrderModalProps> = ({
  onClose,
  handleSubmit,
  submitting,
  values,
  onEntityHasNoProfileFees,
}) => {
  const intl = useIntl();
  const options = useEntitySelectItems();

  const entityHasNoFeesProfile = useEntityHasProfileFees();

  useEffect(() => {
    if (entityHasNoFeesProfile) {
      onEntityHasNoProfileFees();
    }
  }, [entityHasNoFeesProfile, onEntityHasNoProfileFees]);

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledBox mb='1rem'>
        <Avatar
          size={AvatarSize.Medium}
          noBorder
          placeholder={<IconUpload />}
        />
      </StyledBox>
      <StyledBox mb='0.5rem'>
        <Typography as='h2' variant='h2' textAlign='center'>
          <FormattedMessage
            id='orders.areYouSure'
            defaultMessage='Are you sure?'
          />
        </Typography>
      </StyledBox>
      <StyledBox mb='1.5rem'>
        <Typography textAlign='center'>
          <FormattedMessage
            id='orders.doYouWantToUploadOrders'
            defaultMessage='Do you want to upload orders?'
          />
        </Typography>
      </StyledBox>
      <StyledUploadInputWrapper>
        <Field<File | string | undefined> name='file'>
          {({ input }) => (
            <Input
              name={input.name}
              onChange={(event) => {
                const { files } = event.target;

                if (!files) {
                  return;
                }

                input.onChange(files[0]);
              }}
              disabled={submitting}
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              type='file'
            />
          )}
        </Field>
      </StyledUploadInputWrapper>

      <FormSimpleSelect
        name='entity'
        disabled={submitting}
        label={intl.formatMessage({
          id: 'forms.fields.entity',
          defaultMessage: 'Entity',
        })}
        options={options}
        placeholder={intl.formatMessage({
          id: 'forms.fields.select',
          defaultMessage: 'Select...',
        })}
      />

      <StyledUploadModalAction>
        <Button
          type='submit'
          disabled={!values.entity || !values.file || submitting}
        >
          <FormattedMessage id='forms.buttons.upload' defaultMessage='Upload' />
        </Button>
        <Button
          mode={ButtonMode.Secondary}
          onClick={onClose}
          disabled={submitting}
          type='button'
        >
          <FormattedMessage id='forms.buttons.cancel' defaultMessage='Cancel' />
        </Button>
      </StyledUploadModalAction>
    </StyledForm>
  );
};

const UploadOrderModalWrapper: React.FC<UploadOrderModalWrapperProps> = ({
  onClose,
  uploadOrders,
  onEntityHasNoProfileFees,
  ...props
}) => (
  <ModalBase {...props}>
    <Form<OrdersUploadFormData> onSubmit={uploadOrders}>
      {({ handleSubmit, submitting, values }) => (
        <UploadOrderModal
          {...{
            uploadOrders,
            onClose,
            handleSubmit,
            submitting,
            values,
            onEntityHasNoProfileFees,
          }}
        />
      )}
    </Form>
  </ModalBase>
);

export default UploadOrderModalWrapper;
