import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import useHeaderTitleContext from '../hooks/useHeaderTitleContext';
import KeyPerformanceStats from '../components/molecules/KeyPerformanceStats';
import OrderResolution from '../components/molecules/OrderResolution';
import { StyledBox } from '../components/styles/Grid/styles';

const DashboardPage: React.FC = () => {
  const { setHeaderTitle } = useHeaderTitleContext();
  const intl = useIntl();

  useEffect(() => {
    setHeaderTitle(
      intl.formatMessage({
        id: 'mainNavigation.dashboard',
        defaultMessage: 'Dashboard',
      })
    );
  }, [intl, setHeaderTitle]);

  return (
    <StyledBox flexDirection='column' gap='1rem'>
      <KeyPerformanceStats />
      <OrderResolution />
    </StyledBox>
  );
};

export default DashboardPage;
