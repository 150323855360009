import { rgba } from 'polished';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { textStyles } from '../../../theme/config/typography';

export const StyledPaginationWrapper = styled.div.attrs({
  variant: 'bodyBold',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  ${variant({
    variants: {
      ...textStyles,
    },
  })};
  .pagination {
    display: flex;
    align-items: center;
    li a {
      height: 1.75rem;
      min-width: 1.75rem;
      display: flex;
      text-align: center;
      padding: 0.25rem;
      justify-content: center;
      align-items: center;
      color: ${({ theme }) => theme.colors.pagination.bullet.color};
      opacity: 0.87;
      transition: ${({ theme }) => theme.transitions.default};
      background-color: transparent;
      cursor: pointer;
      border-radius: 100%;
      outline: none;
      &:hover {
        background-color: ${({ theme }) =>
          rgba(theme.colors.pagination.bullet.background, 0.6)};
      }
    }
    li.previous a,
    li.next a {
      height: auto;
      width: auto;
      padding: 0;
      opacity: 1;
      color: ${({ theme }) => rgba(theme.colors.pagination.arrow.color, 0.54)};
      border-radius: 0;
      svg {
        height: 1em;
        width: 1em;
        font-size: 0.875rem;
      }
      &:hover {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.pagination.arrow.color};
      }
    }
    li.disabled a {
      opacity: 0.5;
      pointer-events: none;
    }
    li.active a {
      background-color: ${({ theme }) =>
        theme.colors.pagination.bullet.background};
    }
  }
  .previous {
    margin-right: 1.5rem;
  }
  .next {
    margin-left: 1.5rem;
  }
`;
