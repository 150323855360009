import React from 'react';
import { StyledErrorText, StyledErrorWrapper } from './styles';
import { ErrorMessageProps } from './types';

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => (
  <StyledErrorWrapper>
    {error && <StyledErrorText>{error}</StyledErrorText>}
  </StyledErrorWrapper>
);

export default ErrorMessage;
