import * as finalForm from 'final-form';
import { useCallback } from 'react';
import { SubmitHandler } from 'react-final-form';
import { useMutation } from 'react-query';
import { parseJson } from '../../../utils/index';
import {
  getLocalStorageValue,
  removeLocalStorageValue,
  setLocalStorageValue,
} from '../../../hooks/useLocalStorageValue/utils';
import { CLIENT_SELECT_VALUE_COOKIE_NAME } from '../../../components/layouts/LayoutBase/consts';
import {
  AUTH_TOKENS_LOCAL_STORAGE_KEY,
  MFA_TOKENS_LOCAL_STORAGE_KEY,
  ROLE_TYPE,
} from '../../../contexts/User/consts';
import { AuthTokensShape } from '../../../contexts/User/types';
import useCookiesContext from '../../../hooks/useCookiesContext';

import { getTokens } from '../../../utils/auth';
import { ClientSelectValueCookieShape } from '../../../utils/auth/types';
import { VERIFY_MUTATION_FN } from './consts';
import { VerifyFormValues } from './types';

export const useVerifySubmitHandler = (): SubmitHandler<VerifyFormValues> => {
  const { mutateAsync: verify } = useMutation(VERIFY_MUTATION_FN);
  const cookies = useCookiesContext();
  const storedMfaToken = parseJson<{
    mfa_token: string;
    oob_code: string;
  }>(getLocalStorageValue(MFA_TOKENS_LOCAL_STORAGE_KEY));

  return useCallback(
    async ({ code }) => {
      try {
        const { response } = await verify({
          data: {
            mfaToken: storedMfaToken?.mfa_token || '',
            oobCode: storedMfaToken?.oob_code || '',
            bindingCode: code,
          },
        });

        removeLocalStorageValue(MFA_TOKENS_LOCAL_STORAGE_KEY);

        const { token, sdgUserToken, clientId, entities } = response;
        const { roleType } = token;
        const authTokens: AuthTokensShape = getTokens(token, sdgUserToken);
        const clientSelectValueCookie: ClientSelectValueCookieShape = {
          clientId,
          entities,
        };
        cookies.set(CLIENT_SELECT_VALUE_COOKIE_NAME, clientSelectValueCookie, {
          path: '/',
        });

        setLocalStorageValue(AUTH_TOKENS_LOCAL_STORAGE_KEY, authTokens);
        setLocalStorageValue(ROLE_TYPE, roleType, true);

        return undefined;
      } catch (_) {
        return {
          [finalForm.FORM_ERROR]: 'Sign in failed',
        };
      }
    },
    [verify, storedMfaToken?.mfa_token, storedMfaToken?.oob_code, cookies]
  );
};
