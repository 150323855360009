import styled from 'styled-components';

export const StyledCashoutBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.25rem;
  background-color: ${({ theme }) => theme.colors.background.light};
  padding: 2.8125rem 1rem;
  box-shadow: ${({ theme }) => theme.shadows.mid};
`;
