import { Row } from 'react-grid-system';
import styled from 'styled-components';

export const StyledCheckboxWrapper = styled.div`
  padding-inline-start: 1rem;
  box-sizing: border-box;
  max-width: 2.25rem;
`;

export const StyledHeaderRow = styled(Row)`
  display: flex;
  align-items: center !important;
  height: ${({ theme }) => theme.dimensions.buttonClose.height};
  margin-bottom: 2rem;
  margin-top: ${({ theme }) => theme.dimensions.buttonClose.height};
`;

export const StyledCashOutInfoCellWrapper = styled.div``;
