import React, { useContext } from 'react';
import range from 'lodash/range';
import ContentLoader from 'react-content-loader';
import { ThemeContext } from 'styled-components';

const RECT_WIDTH = 1110;
const RECT_HEIGHT = 113;
const RECT_Y_SPACING = 18;
const ROWS_NUMBER = 5;

const ProductOrderCardListLoaderTemplate: React.FC = () => {
  const theme = useContext(ThemeContext);
  const rows = range(ROWS_NUMBER);
  const viewBoxWidth = RECT_WIDTH;
  const viewBoxHeight =
    ROWS_NUMBER * RECT_HEIGHT + (ROWS_NUMBER - 1) * RECT_Y_SPACING;

  return (
    <ContentLoader
      speed={2}
      width={viewBoxWidth}
      height={viewBoxHeight}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      backgroundColor={theme.colors.loader.background}
      foregroundColor={theme.colors.loader.foreground}
    >
      {rows.map((row) => (
        <React.Fragment key={row}>
          <rect
            x='0'
            y={row * (RECT_HEIGHT + RECT_Y_SPACING)}
            rx='16'
            ry='16'
            width={RECT_WIDTH}
            height={RECT_HEIGHT}
          />
        </React.Fragment>
      ))}
    </ContentLoader>
  );
};

export default ProductOrderCardListLoaderTemplate;
