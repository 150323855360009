import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { OrderApprovalBarProps } from './types';
import {
  StyledDeliveryChargeWrapper,
  StyledGrandTotalWrapper,
  StyledOrderApprovalBar,
  StyledOrderApprovalBarAction,
  StyledOrderApprovalBarLabel,
  StyledOrderApprovalBarWrapper,
  StyledPriceWithCurrencyWrapper,
  StyledTotalWrapper,
  SyledDeliveryChargeSelectWrapper,
} from './styles';
import Button from '../../atoms/Button';
import { ButtonMode } from '../../atoms/Button/enums';
import { StyledBox } from '../../styles/Grid/styles';
import PriceWithCurrency from '../../atoms/PriceWithCurrency';
import { PriceWithCurrencySize } from '../../atoms/PriceWithCurrency/enums';
import SimpleSelect, {
  FormSimpleSelect,
} from '../../atoms/Fields/Select/templates/SimpleSelect';
import { FormInput } from '../../atoms/Fields/Input';
import Typography from '../../styles/Typography';

const OrderApprovalBar: React.FC<OrderApprovalBarProps> = ({
  deliveryCharge,
  grandTotal,
  currency,
  onCancelClick,
  selectOptions,
  onSubmit,
  deliveryChargeFieldName,
  deliveryChargeFieldDisabled,
  submitButtonContent,
  loading,
}) => {
  const intl = useIntl();
  return (
    <StyledOrderApprovalBarWrapper>
      <form {...{ onSubmit }}>
        <StyledOrderApprovalBar>
          <SyledDeliveryChargeSelectWrapper>
            {deliveryChargeFieldName ? (
              <FormSimpleSelect
                disabled={deliveryChargeFieldDisabled || loading}
                label={intl.formatMessage({
                  id: 'delivery.deliveryCharge',
                  defaultMessage: 'Delivery charge',
                })}
                options={selectOptions}
                name={deliveryChargeFieldName}
              />
            ) : (
              <SimpleSelect
                disabled={deliveryChargeFieldDisabled || loading}
                label={intl.formatMessage({
                  id: 'delivery.deliveryCharge',
                  defaultMessage: 'Delivery charge',
                })}
                options={selectOptions}
              />
            )}
          </SyledDeliveryChargeSelectWrapper>
          <StyledTotalWrapper>
            <StyledBox flexDirection='column'>
              <FormInput
                name='total'
                label={intl.formatMessage({
                  id: 'products.total',
                  defaultMessage: 'Total',
                })}
                suffixComponent={
                  <Typography variant='captionBold'>{currency}</Typography>
                }
                type='number'
                min={0}
                step={1}
                disabled={loading}
              />
            </StyledBox>
          </StyledTotalWrapper>

          <StyledDeliveryChargeWrapper>
            <StyledBox flexDirection='column'>
              <StyledOrderApprovalBarLabel>
                <FormattedMessage
                  id='delivery.deliveryCharge'
                  defaultMessage='Delivery charge'
                />
              </StyledOrderApprovalBarLabel>
              <PriceWithCurrency
                size={PriceWithCurrencySize.Big}
                price={deliveryCharge}
                {...{ currency }}
              />
            </StyledBox>
          </StyledDeliveryChargeWrapper>
          <StyledGrandTotalWrapper>
            <StyledBox flexDirection='column'>
              <StyledOrderApprovalBarLabel>
                <FormattedMessage
                  id='products.grandTotal'
                  defaultMessage='Grand Total'
                />
              </StyledOrderApprovalBarLabel>
              <StyledPriceWithCurrencyWrapper>
                <PriceWithCurrency
                  size={PriceWithCurrencySize.Big}
                  price={grandTotal}
                  {...{ currency }}
                />
              </StyledPriceWithCurrencyWrapper>
            </StyledBox>
          </StyledGrandTotalWrapper>

          <StyledOrderApprovalBarAction>
            <StyledBox>
              <Button
                type='button'
                mode={ButtonMode.Secondary}
                disabled={loading}
                onClick={onCancelClick}
              >
                <FormattedMessage
                  id='forms.buttons.cancel'
                  defaultMessage='Cancel'
                />
              </Button>
            </StyledBox>
            <StyledBox ml='1rem'>
              <Button disabled={loading} type='submit'>
                {submitButtonContent}
              </Button>
            </StyledBox>
          </StyledOrderApprovalBarAction>
        </StyledOrderApprovalBar>
      </form>
    </StyledOrderApprovalBarWrapper>
  );
};

export default OrderApprovalBar;
