import React, { useContext } from 'react';
import range from 'lodash/range';
import { ThemeContext } from 'styled-components';
import { StyledLoaderTableContent, StyledLoaderTableWrapper } from './styles';
import { TableBaseLoaderTemplateProps } from './types';

const RECT_WIDTH = 117;
const RECT_HEIGHT = 20;
const RECT_X_SPACING = 61;
const RECT_Y_SPACING = 43;

const TableBaseLoaderTemplate: React.FC<TableBaseLoaderTemplateProps> = ({
  columnsNumber = 8,
  rowsNumber = 9,
}) => {
  const theme = useContext(ThemeContext);
  const columns = range(columnsNumber);
  const rows = range(rowsNumber);
  const viewBoxWidth =
    columnsNumber * RECT_WIDTH + (columnsNumber - 1) * RECT_X_SPACING;
  const viewBoxHeight =
    rowsNumber * RECT_HEIGHT + (rowsNumber - 1) * RECT_Y_SPACING;

  return (
    <StyledLoaderTableWrapper ratio={viewBoxHeight / viewBoxWidth}>
      <StyledLoaderTableContent
        speed={2}
        viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        backgroundColor={theme.colors.loader.background}
        foregroundColor={theme.colors.loader.foreground}
      >
        {rows.map((row) => (
          <React.Fragment key={row}>
            {columns.map((column) => (
              <React.Fragment key={column}>
                <rect
                  x={column * (RECT_WIDTH + RECT_X_SPACING)}
                  y={row * (RECT_HEIGHT + RECT_Y_SPACING)}
                  rx='10'
                  ry='10'
                  width={RECT_WIDTH}
                  height={RECT_HEIGHT}
                />
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </StyledLoaderTableContent>
    </StyledLoaderTableWrapper>
  );
};

export default TableBaseLoaderTemplate;
