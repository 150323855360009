import styled from 'styled-components';

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledUploadModalAction = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  grid-column-gap: 1rem;
`;

export const StyledUploadInputWrapper = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
`;
