import React from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderSummaryTotalsProps } from './types';
import {
  StyledOrderSummaryTotals,
  StyledOrderSummaryTotalsBox,
  StyledOrderSummaryTotalsGrandTotalWrapper,
  StyledOrderSummaryTotalsLabel,
} from './styles';
import PriceWithCurrency from '../../atoms/PriceWithCurrency';
import { PriceWithCurrencySize } from '../../atoms/PriceWithCurrency/enums';

const OrderSummaryTotals: React.FC<OrderSummaryTotalsProps> = ({
  total,
  deliveryCharge,
  grandTotal,
  currency,
}) => (
  <StyledOrderSummaryTotals>
    <StyledOrderSummaryTotalsBox>
      <StyledOrderSummaryTotalsLabel>
        <FormattedMessage id='products.total' defaultMessage='Total' />
      </StyledOrderSummaryTotalsLabel>
      <PriceWithCurrency
        size={PriceWithCurrencySize.Big}
        price={total}
        {...{ currency }}
      />
    </StyledOrderSummaryTotalsBox>
    <>
      <StyledOrderSummaryTotalsBox>
        <StyledOrderSummaryTotalsLabel>
          <FormattedMessage
            id='delivery.deliveryCharge'
            defaultMessage='Delivery charge'
          />
        </StyledOrderSummaryTotalsLabel>
        <PriceWithCurrency
          size={PriceWithCurrencySize.Big}
          price={deliveryCharge}
          {...{ currency }}
        />
      </StyledOrderSummaryTotalsBox>
      <StyledOrderSummaryTotalsBox>
        <StyledOrderSummaryTotalsLabel>
          <FormattedMessage
            id='products.grandTotal'
            defaultMessage='Grand Total'
          />
        </StyledOrderSummaryTotalsLabel>
        <StyledOrderSummaryTotalsGrandTotalWrapper>
          <PriceWithCurrency
            size={PriceWithCurrencySize.Big}
            price={grandTotal}
            {...{ currency }}
          />
        </StyledOrderSummaryTotalsGrandTotalWrapper>
      </StyledOrderSummaryTotalsBox>
    </>
  </StyledOrderSummaryTotals>
);

export default OrderSummaryTotals;
