import React, { useMemo, useState } from 'react';
import noop from 'lodash/noop';
import { GlobalLoaderContextValue } from './types';
import Spinner from '../../components/atoms/Spinner';

export const GlobalLoaderContext = React.createContext<GlobalLoaderContextValue>(
  {
    hideLoader: noop,
    showLoader: noop,
  }
);

export const GlobalLoaderProvider: React.FC = ({ children }) => {
  const [loaderState, setLoaderState] = useState<{
    loaderShown: boolean;
    text?: string;
  }>({ loaderShown: false });
  const contextValue: GlobalLoaderContextValue = useMemo(
    () => ({
      hideLoader: () => setLoaderState({ loaderShown: false, text: undefined }),
      showLoader: (text?: string) =>
        setLoaderState({ loaderShown: true, text }),
    }),
    []
  );

  return (
    <>
      {loaderState.loaderShown ? (
        <Spinner withOverlay description={loaderState.text} />
      ) : null}
      <GlobalLoaderContext.Provider value={contextValue}>
        {children}
      </GlobalLoaderContext.Provider>
    </>
  );
};
