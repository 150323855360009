import React, { useContext } from 'react';
import ContentLoader from 'react-content-loader';
import { ThemeContext } from 'styled-components';

const CustomerSummaryTemplate: React.FC = () => {
  const theme = useContext(ThemeContext);

  return (
    <ContentLoader
      speed={2}
      width={1346}
      height={727}
      viewBox='0 0 1346 727'
      backgroundColor={theme.colors.loader.background}
      foregroundColor={theme.colors.loader.foreground}
    >
      <path d='M 2 448.5 h 736' />
      <rect x='3' y='0' rx='10' ry='10' width='143' height='20' />
      <rect x='3' y='89' rx='10' ry='10' width='199' height='20' />
      <rect x='3' y='157' rx='10' ry='10' width='199' height='20' />
      <rect x='3' y='225' rx='10' ry='10' width='199' height='20' />
      <rect x='316' y='89' rx='10' ry='10' width='199' height='20' />

      <rect x='316' y='157' rx='10' ry='10' width='199' height='20' />
      <rect x='316' y='225' rx='10' ry='10' width='199' height='20' />
      <rect x='546' y='89' rx='10' ry='10' width='83' height='20' />
      <rect x='546' y='157' rx='10' ry='10' width='83' height='20' />
      <rect x='546' y='225' rx='10' ry='10' width='83' height='20' />

      <rect x='655' y='89' rx='10' ry='10' width='83' height='20' />
      <rect x='655' y='157' rx='10' ry='10' width='83' height='20' />
      <rect x='655' y='225' rx='10' ry='10' width='83' height='20' />
      <rect x='237' y='89' rx='10' ry='10' width='34' height='20' />
      <rect x='237' y='157' rx='10' ry='10' width='34' height='20' />
      <rect x='237' y='225' rx='10' ry='10' width='34' height='20' />
      <path d='M 7 129.5 h 731 M 7 201.5 h 731' />

      <rect x='0' y='359' rx='10' ry='10' width='125' height='20' />
      <rect x='3' y='492' rx='10' ry='10' width='125' height='20' />
      <rect x='3' y='526' rx='10' ry='10' width='125' height='20' />
      <rect x='3' y='560' rx='10' ry='10' width='125' height='20' />
      <rect x='3' y='594' rx='10' ry='10' width='125' height='20' />

      <rect x='153' y='492' rx='10' ry='10' width='125' height='20' />
      <rect x='153' y='526' rx='10' ry='10' width='125' height='20' />
      <rect x='153' y='560' rx='10' ry='10' width='125' height='20' />
      <rect x='153' y='594' rx='10' ry='10' width='125' height='20' />
      <rect x='151' y='359' rx='10' ry='10' width='125' height='20' />
      <rect x='302' y='359' rx='10' ry='10' width='125' height='20' />
      <rect x='0' y='388' rx='10' ry='10' width='125' height='20' />
    </ContentLoader>
  );
};

export default CustomerSummaryTemplate;
