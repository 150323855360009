import { defineMessage, IntlShape } from 'react-intl';
import { OrderTaskStatus } from '../../../../../setup/apiTypes/enums';
import { UploadModalStatus } from './enums';

const taskStatusToUploadStatusMap = new Map([
  [OrderTaskStatus.Running, UploadModalStatus.Loading],
  [OrderTaskStatus.Completed, UploadModalStatus.Completed],
  [OrderTaskStatus.Failed, UploadModalStatus.Failed],
]);

export const mapTaskStatusToUploadStatus = (
  taskStatus: OrderTaskStatus | undefined
): UploadModalStatus => {
  if (!taskStatus) {
    return UploadModalStatus.Loading;
  }

  return (
    taskStatusToUploadStatusMap.get(taskStatus) ?? UploadModalStatus.Loading
  );
};

const messages = defineMessage({
  uploadCompleted: {
    id: 'orders.uploadStatusModal.completed',
    defaultMessage: '{ordersCount} orders uploaded',
  },
  uploadRunning: {
    id: 'orders.uploadStatusModal.loading',
    defaultMessage: 'Please wait, we are uploading your orders...',
  },
  uploadFailed: {
    id: 'orders.uploadStatusModal.failed',
    defaultMessage: 'Uploading orders failed',
  },
});

export const getMessageForStatus = (
  status: UploadModalStatus,
  ordersCount: number,
  intl: IntlShape
): string => {
  const statusToMessageMap: MappedObject<string, UploadModalStatus> = {
    [UploadModalStatus.Loading]: intl.formatMessage(messages.uploadRunning),
    [UploadModalStatus.Completed]: intl.formatMessage(
      messages.uploadCompleted,
      {
        ordersCount,
      }
    ),
    [UploadModalStatus.Failed]: intl.formatMessage(messages.uploadFailed),
  };

  return statusToMessageMap[status];
};
