import apiRoutes from '../../../setup/consts/apiRoutes';
import { makeMutationFn } from '../../../utils/reactQuery';
import { ResetPasswordFormValues, ResetPasswordRequestBody } from './types';

export const RESET_PASSWORD_FORM_INITIAL_VALUES: Partial<ResetPasswordFormValues> = {
  email: '',
};

export const RESET_PASSWORD_MUTATION_FN = makeMutationFn<ResetPasswordRequestBody>(
  apiRoutes.REST_PASSWORD
);
