import React, { useCallback } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';
import ModalBase from '../..';
import { useEntityContext } from '../../../../../pages/financials/EntityContext/hooks';
import Button from '../../../../atoms/Button';
import Loader from '../../../../atoms/Loader';
import { LoaderMode } from '../../../../atoms/Loader/enums';
import Table from '../../../../molecules/Table';
import { useSettlementDetails, useTableColumns } from './hooks';
import { StyledHeaderRow } from './styles';
import { FinancialsEntitySelectModalProps } from './types';

const FinancialsEntitySelectModal: React.FC<FinancialsEntitySelectModalProps> =
  ({ onCashOut, onClose, ...props }) => {
    const { data, isLoading } = useSettlementDetails();
    const { hasSelectedEntities, tableColumns } = useTableColumns();
    const { clearSelection } = useEntityContext();

    const onModalClose = useCallback(
      (event?: React.MouseEvent<HTMLButtonElement>) => {
        if (onClose) {
          clearSelection();
          onClose(event);
        }
      },
      [clearSelection, onClose]
    );

    return (
      <ModalBase {...props} onClose={onModalClose}>
        <Container>
          <StyledHeaderRow>
            <Col md={12}>
              <Button
                type='button'
                onClick={onCashOut}
                disabled={!hasSelectedEntities}
              >
                <FormattedMessage
                  id='financials.cashOut'
                  defaultMessage='Cash Out'
                />
              </Button>
            </Col>
          </StyledHeaderRow>

          <Row>
            {isLoading ? (
              <Loader mode={LoaderMode.Table} />
            ) : (
              <Table data={data} columns={tableColumns} disabledPagination />
            )}
          </Row>
        </Container>
      </ModalBase>
    );
  };

export default FinancialsEntitySelectModal;
