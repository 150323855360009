import React from 'react';
import { StyledButtonLink } from './styles';
import { ButtonLinkProps } from './types';
import { ButtonMode, ButtonSize } from './enums';

const ButtonLink: React.FC<ButtonLinkProps> = ({
  mode = ButtonMode.Primary,
  size = ButtonSize.Big,
  icon: Icon,
  children,
  ...props
}) => {
  const buttonTextVariant = size === ButtonSize.Big ? 'body' : 'caption';

  return (
    <StyledButtonLink variant={buttonTextVariant} {...{ mode, size, ...props }}>
      {Icon && <Icon />}
      {children}
    </StyledButtonLink>
  );
};

export default ButtonLink;
