import { CreateOrderRequestBody, UpdateOrderRequestBody } from './types';

import { makeMutationFn } from '../../../utils/reactQuery';
import apiRoutes from '../../../setup/consts/apiRoutes';
import { MutationFn } from '../../../utils/reactQuery/types';

export const CREATE_ORDER_MUTATION_FN = makeMutationFn<CreateOrderRequestBody>(
  apiRoutes.ORDERS
);

export const MAKE_UPDATE_ORDER_MUTATION_FN = (
  orderId: string
): MutationFn<UpdateOrderRequestBody> =>
  makeMutationFn<UpdateOrderRequestBody>(apiRoutes.ORDER_DETAILS(orderId), {
    method: 'put',
  });
