import React from 'react';
import { useQuery } from 'react-query';
import apiRoutes from '../../setup/consts/apiRoutes';
import { ProxyVersionResponseType } from './types';

const Version: React.FC = () => {
  const { data, isLoading } = useQuery<
    UseQueryResponse<ProxyVersionResponseType>
  >(apiRoutes.PROXY_VERSION);

  return (
    <div>
      <p>APP VERSION: {process.env.REACT_APP_VERSION || '-'}</p>
      <p>
        PROXY_APP_VERSION:{' '}
        {isLoading ? 'Loading...' : data?.response.version ?? '-'}
      </p>
    </div>
  );
};

export default Version;
