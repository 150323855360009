import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';
import {
  useProductFormSubmitHandler,
  useProductInitialValues,
} from './components/ProductForm/hooks';
import useHeaderTitleContext from '../../../hooks/useHeaderTitleContext';
import Typography from '../../../components/styles/Typography';
import Loader from '../../../components/atoms/Loader';
import { LoaderMode } from '../../../components/atoms/Loader/enums';
import { CoverImage, ProductFormValues, UploadedProductImage } from './types';
import ProductForm from './components/ProductForm';

const ProductFormPage: React.FC = () => {
  const { productId } = useParams<{ productId: string }>();
  const { loading, initialValues, productData, error } =
    useProductInitialValues(productId);
  const [coverImage, setCoverImage] = useState<CoverImage>(null);
  const [uploadedImages, setUploadedImages] = useState<UploadedProductImage[]>(
    []
  );
  const { submitHandler, isLoading } = useProductFormSubmitHandler({
    productId: productData?.id.toString(),
    coverImage: coverImage?.file,
    images: [],
  });
  const { setHeaderTitle } = useHeaderTitleContext();
  const intl = useIntl();

  useEffect(() => {
    setHeaderTitle(
      intl.formatMessage({
        id: 'mainNavigation.warehouse',
        defaultMessage: 'Warehouse',
      })
    );
  }, [intl, setHeaderTitle]);

  if (error) {
    return <Typography>Failed to load product</Typography>;
  }

  return loading ? (
    <Loader mode={LoaderMode.WarehouseAddProduct} />
  ) : (
    <Form<ProductFormValues> onSubmit={submitHandler} {...{ initialValues }}>
      {({ handleSubmit }) => (
        <ProductForm
          isEdit={!!productId}
          productData={productData}
          coverImage={coverImage}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
          setCoverImage={setCoverImage}
          loadingSubmit={isLoading}
          {...{ handleSubmit }}
        />
      )}
    </Form>
  );
};

export default ProductFormPage;
