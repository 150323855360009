import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { GetOrderTaskResponse } from '../../../../../setup/apiTypes/orders';
import apiRoutes from '../../../../../setup/consts/apiRoutes';
import { mapTaskStatusToUploadStatus } from './consts';
import { UploadModalStatus } from './enums';
import { UsePollOrderTaskStatus } from './types';

const POLLING_INTERVAL_MILLISECONDS = 500;

export const useOrderUploadStatus = (
  taskId: string | undefined,
  onSuccess: () => void
): UsePollOrderTaskStatus => {
  const [refetchEnabled, setRefetchEnabled] = useState(true);
  const { data, isError } = useQuery<UseQueryResponse<GetOrderTaskResponse>>(
    apiRoutes.ORDER_TASK(taskId ?? ''),
    {
      enabled: !!taskId && refetchEnabled,
      keepPreviousData: false,
      refetchInterval: POLLING_INTERVAL_MILLISECONDS,
      onSuccess: (orderTaskData) => {
        const taskStatus = mapTaskStatusToUploadStatus(
          orderTaskData.response.task.taskStatus
        );

        if (
          taskStatus === UploadModalStatus.Completed ||
          taskStatus === UploadModalStatus.Failed
        ) {
          setRefetchEnabled(false);

          if (taskStatus === UploadModalStatus.Completed) {
            onSuccess();
          }
        }
      },
    }
  );

  const status = useMemo(
    () => mapTaskStatusToUploadStatus(data?.response.task.taskStatus),
    [data?.response.task.taskStatus]
  );

  return {
    status: !isError ? status : UploadModalStatus.Failed,
    ordersCount: data?.response.task.ordersCount ?? 0,
    message: data?.response.task.message,
  };
};
