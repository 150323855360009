import React from 'react';
import { StyledButton } from './styles';
import { ButtonProps } from './types';
import { ButtonMode, ButtonSize } from './enums';

const Button: React.FC<ButtonProps> = ({
  mode = ButtonMode.Primary,
  size = ButtonSize.Big,
  isFullWidth,
  icon: Icon,
  children,
  ...props
}) => {
  const buttonTextVariant = size === ButtonSize.Big ? 'body' : 'caption';

  return (
    <StyledButton
      variant={buttonTextVariant}
      {...{ mode, size, isFullWidth, ...props }}
    >
      {Icon && <Icon />}
      {children}
    </StyledButton>
  );
};

export default Button;
