import styled from 'styled-components';
import { rgba } from 'polished';

export const StyledItemsPopup = styled.div`
  .items-popup-tooltip {
    background-color: white;
    width: 810px;
    border-radius: 1rem;
    color: ${({ theme }) => theme.colors.text.primary};
    &:after {
      display: none;
    }
  }
`;

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  :not(:last-child) {
    border-bottom: 1px solid
      ${({ theme }) => rgba(theme.colors.text.primary, 0.15)};
  }
  padding: 0.5rem 0;
`;
