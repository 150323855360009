import { AUTH_TOKENS_LOCAL_STORAGE_KEY } from '../../contexts/User/consts';
import { AuthTokensShape } from '../../contexts/User/types';
import { getLocalStorageValue } from '../../hooks/useLocalStorageValue/utils';
import { AuthUserType } from '../../setup/axios/interceptors/enums';
import { ClientSelectValueCookieShape, Token } from './types';

export const getExpirationDate = (expiresInSeconds: number): Date =>
  new Date(Date.now() + expiresInSeconds * 1000);

export const getTokens = (
  { accessToken, refreshToken, expiresIn }: Token,
  sdgToken: Token | null,
  userType: AuthUserType = AuthUserType.UNKNOWN
): AuthTokensShape => {
  const expirationDate = getExpirationDate(expiresIn);

  if (userType === AuthUserType.UNKNOWN) {
    return {
      authToken: accessToken,
      ...(sdgToken ? { sdgAuthToken: sdgToken.accessToken } : {}),
      refreshToken,
      expirationDate,
    };
  }

  const currentTokens = JSON.parse(
    getLocalStorageValue(AUTH_TOKENS_LOCAL_STORAGE_KEY) as string
  ) as AuthTokensShape;

  return {
    ...currentTokens,
    ...(userType === AuthUserType.CLIENT ? { authToken: accessToken } : {}),
    ...(userType === AuthUserType.SDG ? { sdgAuthToken: accessToken } : {}),
    refreshToken,
    expirationDate,
  };
};

export const createClientSelectValueCookie = (
  clientId: string,
  entities: string[]
): ClientSelectValueCookieShape => ({
  clientId,
  entities,
});
