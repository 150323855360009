import styled from 'styled-components';

export const StyledForm = styled.form`
  width: 100%;
`;

export const StyledSearchWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.dimensions.searchForm.width};
`;

export const StyledFaqTitleDescriptionWrapper = styled.div`
  margin-bottom: 2rem;
`;
