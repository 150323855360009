import apiRoutes from '../../../../../setup/consts/apiRoutes';
import { makeMutationFn } from '../../../../../utils/reactQuery';
import { MutationFn } from '../../../../../utils/reactQuery/types';
import { CreateProductRequestBody, UpdateProductRequestBody } from '../../types';

export const CREATE_PRODUCT_MUTATION_FN =
  makeMutationFn<CreateProductRequestBody>(apiRoutes.PRODUCTS);

export const MAKE_UPDATE_PRODUCT_MUTATION_FN = (
  productId: string
): MutationFn<UpdateProductRequestBody> =>
  makeMutationFn<UpdateProductRequestBody>(
    apiRoutes.UPDATE_PRODUCT(productId),
    {
      method: 'put',
    }
  );
