import { useState, useEffect } from 'react';
import { CookieChangeOptions } from 'universal-cookie';

import useCookiesContext from './useCookiesContext';

const useCookieValue = <T>(cookieName: string): T | undefined => {
  const cookies = useCookiesContext();
  const [cookieValue, setCookieValue] = useState<T | undefined>(
    cookies.get(cookieName, {
      doNotParse: false,
    })
  );

  useEffect(() => {
    const handler = ({ name }: CookieChangeOptions): void => {
      if (name !== cookieName) {
        return;
      }

      setCookieValue(
        cookies.get<T | undefined>(cookieName, {
          doNotParse: false,
        })
      );
    };

    cookies.addChangeListener(handler);

    return (): void => {
      cookies.removeChangeListener(handler);
    };
  }, [cookieName, cookies]);

  return cookieValue;
};

export default useCookieValue;
