import { flattenObject } from '../../utils/lodash';
import SupportedLanguages from './enums';
import en from './languages/en.json';
import ar from './languages/ar.json';

export const languages = {
  [SupportedLanguages.English]: en,
  [SupportedLanguages.Arabic]: ar,
};

const AppLocale: { [key: string]: { [key: string]: string } } = {
  ...Object.entries(SupportedLanguages).reduce(
    (all, [, value]) => ({
      ...all,
      [value]: flattenObject(languages[value]),
    }),
    {}
  ),
};

export default AppLocale;
