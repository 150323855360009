import styled from 'styled-components';
import { variant } from 'styled-system';
import { textStyles } from '../../../theme/config/typography';
import { BadgeStatus } from './enums';
import { StyledBadgeProps } from './types';

export const StyledBadge = styled.div<StyledBadgeProps>`
  padding: ${({ isRound }) => (isRound ? '0.25rem 0.4rem' : '0.375rem 0.5rem')};
  color: ${({ theme, status = BadgeStatus.Success }) =>
    theme.colors.badge[status].color};
  background-color: ${({ theme, status = BadgeStatus.Success }) =>
    theme.colors.badge[status].background};
  border-radius: ${({ isRound }) => (isRound ? '1.25rem' : '0.5625rem')};
  ${variant({
    variants: {
      ...textStyles,
    },
  })};
`;
