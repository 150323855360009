import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import useLanguageContext from '../../hooks/useLanguageContext';
import AppLocale from './AppLocale';
import SupportedLanguages from './enums';

const IntlProviderWrapper: React.FC = ({ children }) => {
  const { language } = useLanguageContext();
  const direction = language === SupportedLanguages.Arabic ? 'rtl' : 'ltr';

  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  return (
    <IntlProvider locale={language} messages={AppLocale[language]}>
      {children}
    </IntlProvider>
  );
};

export default IntlProviderWrapper;
