import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { StyledTableBaseLoaderTemplateProps } from './types';

export const StyledLoaderTableWrapper = styled.div<StyledTableBaseLoaderTemplateProps>`
  width: 100%;
  padding-top: ${({ ratio }) => `calc(${ratio * 100}% + 1rem)`};
  position: relative;
`;

export const StyledLoaderTableContent = styled(ContentLoader)`
  width: 100%;
  height: calc(100% - 1rem);
  top: 1rem;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
`;
