import React from 'react';
import { useIntl } from 'react-intl';
import { Cell } from '@tanstack/react-table';
import CashoutInfo from '../../../../../../../pages/financials/partials/CashoutInfo';
import PriceWithCurrency from '../../../../../../atoms/PriceWithCurrency';
import { PriceWithCurrencySize } from '../../../../../../atoms/PriceWithCurrency/enums';
import { StyledCashOutInfoCellWrapper } from '../../styles';
import { EntityListTableItem, StatementDetails } from '../../types';

const PendingBalanceCell: React.FC<
  Cell<EntityListTableItem, number | StatementDetails>
> = ({ getValue, row }) => {
  const intl = useIntl();

  if (typeof getValue() === 'object') {
    const value = getValue() as any;

    return (
      <StyledCashOutInfoCellWrapper>
        <CashoutInfo
          price={value.pendingBalance ?? 0}
          currency={value.currency}
          date={new Date(value.createdDate)}
          status={intl.formatMessage({
            id: 'financials.cashoutRequested',
            defaultMessage: 'Cashout Requested',
          })}
          href={value.pdfUrlAr}
          buttonLabel={`Invoice ${value.id} (PDF)`}
        />
      </StyledCashOutInfoCellWrapper>
    );
  }

  const { getValue: getCellValue } = row;
  const currency = getCellValue('currency');

  return (
    <PriceWithCurrency
      size={PriceWithCurrencySize.Big}
      price={getValue() as number}
      currency={currency as string}
    />
  );
};

export default PendingBalanceCell;
