import styled from 'styled-components';
import { BackgroundProps, background } from 'styled-system';

export const DashboardSalesByCityChartStyledWrapper = styled.div`
  width: 200px;
`;

export const CitySalesLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const CitySalesCircle = styled.div<BackgroundProps>`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: red;
  ${background};
`;
