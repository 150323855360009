import styled from 'styled-components';

export const StyledStatusSelectWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    height: 100%;
  }
`;
