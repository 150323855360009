import { FORM_ERROR, Mutator } from 'final-form';
import snakeCase from 'lodash/snakeCase';
import upperFirst from 'lodash/upperFirst';
import get from 'lodash/get';
import { IntlShape } from 'react-intl';
import arrayMutators from 'final-form-arrays';

import { ApiError } from './types';

export const mapApiErrorsToFormErrors = (
  apiError: unknown,
  intl: IntlShape,
  messageKeyPrefix?: string
): {
  errors: MappedObject<string> | undefined;
  generalError: string | undefined;
} => {
  const apiErrors = get(apiError, 'response.data.errors');
  const generalErrorValue = get(apiError, 'response.data.message');
  const generalError =
    typeof generalErrorValue === 'string' ? generalErrorValue : undefined;

  if (!Array.isArray(apiErrors)) {
    return {
      generalError,
      errors: undefined,
    };
  }

  const typedApiErrors = apiErrors as ApiError[];

  return {
    errors: typedApiErrors.reduce(
      (result, { name, property, argument, message }) => {
        const fieldKey =
          property.replace('instance.body', '').replace('.', '') || argument;

        if (!fieldKey) {
          return {
            ...result,
            [FORM_ERROR]: message,
          };
        }

        const fieldName = upperFirst(snakeCase(fieldKey).split('_').join(' '));
        const intlMessage = [
          ...(messageKeyPrefix
            ? [`${messageKeyPrefix}.${fieldKey}.${name}`]
            : []),
          ...(messageKeyPrefix ? [`${messageKeyPrefix}.${name}`] : []),
          `${fieldKey}.${name}`,
          name,
        ].reduce<string | undefined>((intlMessageResult, messageKey) => {
          if (intlMessageResult) {
            return intlMessageResult;
          }

          const fullMessageKey = `forms.fieldErrors.${messageKey}`;

          if (intl.messages[fullMessageKey]) {
            return intl.formatMessage(
              {
                id: fullMessageKey,
              },
              {
                fieldName,
              }
            );
          }

          return undefined;
        }, undefined);

        return {
          ...result,
          [fieldKey]: intlMessage || message,
        };
      },
      {}
    ),
    generalError,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const COMMON_MUTATORS: MappedObject<Mutator<any, any>, string> = {
  ...arrayMutators,
  resetValues: (_, state, utils) => {
    const { formState, fields } = state;
    const { initialValues = {} } = formState;

    Object.keys(fields).forEach((fieldKey) => {
      const initialValue = utils.getIn(initialValues, fieldKey);

      utils.changeValue(state, fieldKey, () => initialValue);
    });
  },
  setValue: ([field, value], state, { changeValue }) => {
    changeValue(state, field, () => value);
  },
};
