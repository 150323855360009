import styled from 'styled-components';

export const CautionIconWrapper = styled.div`
  svg {
    fill: transparent !important;
  }
`;
export const StyledOrderResolution = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.light};
  border-radius: 1rem;
  padding: 2rem 1.5rem;
  display: flex;
  gap: 1.5rem;
  box-shadow: ${({ theme }) => theme.shadows.mid};
`;
