import styled from 'styled-components';
import { StyledCheckboxLabelProps } from './types';

export const StyledCheckboxLabel = styled.label<StyledCheckboxLabelProps>`
  display: flex;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: pointer;
`;

export const StyledCheckboxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  color: transparent;
  background-color: ${({ theme }) => theme.colors.checkbox.background.default};
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.checkbox.border.default};
  border-radius: 6px;
  transition: ${({ theme }) => theme.transitions.default};
  flex-shrink: 0;
  svg {
    width: 1em;
    height: 1em;
    font-size: 0.75rem;
    fill: currentColor;
  }
`;

export const StyledCheckbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  :checked + ${StyledCheckboxIcon} {
    background-color: ${({ theme }) =>
      theme.colors.checkbox.background.checked};
    border-color: ${({ theme }) => theme.colors.checkbox.border.checked};
    color: ${({ theme }) => theme.colors.checkbox.icon};
  }
`;

export const StyledCheckboxLabelText = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 10px;
`;

export const StyledColorTag = styled.div<{ tagColor: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.tagColor};
`;
