import React, { useContext } from 'react';
import ContentLoader from 'react-content-loader';
import { ThemeContext } from 'styled-components';

const PlaceOrderLoaderTemplate: React.FC = () => {
  const theme = useContext(ThemeContext);

  return (
    <ContentLoader
      speed={2}
      width={273}
      height={811}
      viewBox='0 0 273 811'
      backgroundColor={theme.colors.loader.background}
      foregroundColor={theme.colors.loader.foreground}
    >
      <rect x='0' y='0' rx='10' ry='10' width='166' height='20' />
      <rect x='1' y='41' rx='10' ry='10' width='93' height='20' />
      <rect x='1' y='130' rx='10' ry='10' width='93' height='20' />
      <rect x='1' y='219' rx='10' ry='10' width='93' height='20' />
      <rect x='1' y='308' rx='10' ry='10' width='93' height='20' />
      <rect x='1' y='397' rx='10' ry='10' width='93' height='20' />
      <rect x='1' y='486' rx='10' ry='10' width='93' height='20' />
      <rect x='1' y='71' rx='16' ry='16' width='272' height='40' />
      <rect x='1' y='160' rx='16' ry='16' width='272' height='40' />
      <rect x='1' y='249' rx='16' ry='16' width='272' height='40' />
      <rect x='1' y='338' rx='16' ry='16' width='272' height='40' />
      <rect x='1' y='427' rx='16' ry='16' width='272' height='40' />
      <rect x='1' y='516' rx='16' ry='16' width='272' height='40' />
      <rect x='1' y='582' rx='16' ry='16' width='272' height='229' />
    </ContentLoader>
  );
};

export default PlaceOrderLoaderTemplate;
