import { useEffect, useState } from 'react';
import { parseJson } from '../../utils';
import { LOCAL_STORAGE_CHANGE } from './consts';
import { getLocalStorageValue } from './utils';

export const useLocalStorageValue = <T>(key: string): T | null => {
  const [value, setValue] = useState<T | null>(() =>
    parseJson(getLocalStorageValue(key))
  );

  useEffect(() => {
    setValue(parseJson(getLocalStorageValue(key)));
  }, [key]);

  useEffect(() => {
    const handleDifferentContextChange = (event: StorageEvent): void => {
      if (event.storageArea === localStorage) {
        setValue(parseJson(getLocalStorageValue(key)));
      }
    };

    const handleSameContextChange = (): void => {
      setValue(parseJson(getLocalStorageValue(key)));
    };

    window.addEventListener(LOCAL_STORAGE_CHANGE, handleSameContextChange);
    window.addEventListener('storage', handleDifferentContextChange);

    return (): void => {
      window.removeEventListener(LOCAL_STORAGE_CHANGE, handleSameContextChange);
      window.removeEventListener('storage', handleDifferentContextChange);
    };
  }, [key, value]);

  return value;
};
