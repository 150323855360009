import { IntlShape } from 'react-intl';
import { BaseRadioItem } from '../../../components/atoms/Fields/Radio/types';
import flagIraq from '../../../assets/images/flag-iraq.png';
import flagUsa from '../../../assets/images/flag-usa.png';
import {
  PreferredCurrency,
  PreferredPayment,
} from '../../../setup/apiTypes/enums';
import apiRoutes from '../../../setup/consts/apiRoutes';
import { makeMutationFn } from '../../../utils/reactQuery';
import { PreferredCurrencyPutBody, PreferredPaymentPutBody } from './types';

export const paymentMethodRadioItems = (
  intl: IntlShape
): BaseRadioItem<PreferredPayment>[] => [
  {
    value: PreferredPayment.BankTransfer,
    label: intl.formatMessage({
      id: 'billingAndPayments.bankTransfer',
      defaultMessage: 'Bank Transfer',
    }),
    info: intl.formatMessage({
      id: 'billingAndPayments.bankTransferInfo',
      defaultMessage: 'Sandoog staff will contact you with further details',
    }),
  },
  {
    value: PreferredPayment.MoneyTransfer,
    label: intl.formatMessage({
      id: 'billingAndPayments.moneyTransfer',
      defaultMessage: 'Money Transfer',
    }),
    info: intl.formatMessage({
      id: 'billingAndPayments.moneyTransferInfo',
      defaultMessage: 'Sandoog staff will contact you with further details',
    }),
  },
  {
    value: PreferredPayment.Cash,
    label: intl.formatMessage({
      id: 'billingAndPayments.cash',
      defaultMessage: 'Cash',
    }),
    info: intl.formatMessage({
      id: 'billingAndPayments.cashInfo',
      defaultMessage: 'Sandoog staff will contact you with further details',
    }),
  },
  {
    value: PreferredPayment.Check,
    label: intl.formatMessage({
      id: 'billingAndPayments.check',
      defaultMessage: 'Check',
    }),
    info: intl.formatMessage({
      id: 'billingAndPayments.checkInfo',
      defaultMessage: 'Sandoog staff will contact you with further details',
    }),
  },
];

export const currencyRadioItems: BaseRadioItem<PreferredCurrency>[] = [
  {
    value: PreferredCurrency.IQD,
    label: 'IQD',
    decor: flagIraq,
  },
  {
    value: PreferredCurrency.USD,
    label: 'USD',
    decor: flagUsa,
  },
];

export const SET_PREFERRED_PAYMENT_METHOD_MUTATION_FN = makeMutationFn<PreferredPaymentPutBody>(
  apiRoutes.SET_PAYMENT_METHOD,
  {
    method: 'put',
  }
);

export const SET_PREFERRED_CURRENCY_MUTATION_FN = makeMutationFn<PreferredCurrencyPutBody>(
  apiRoutes.SET_PREFERRED_CURRENCY,
  {
    method: 'put',
  }
);
