import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { IconUnpack, IconWarehouseCart } from '../../../assets/svg/icons';
import Loader from '../../../components/atoms/Loader';
import { LoaderMode } from '../../../components/atoms/Loader/enums';
import PercentIconBox from '../../../components/molecules/PercentIconBox';
import SalesByCityBox from '../../../components/molecules/SalesByCityBox';
import TopSellingBox from '../../../components/molecules/TopSellingBox';
import { StyledBox } from '../../../components/styles/Grid/styles';
import useHeaderTitleContext from '../../../hooks/useHeaderTitleContext';
import {
  StyledForPackingWrapper,
  StyledPackedWrapper,
  StyledReportBoxesWrapper,
  StyledSalesByCityWrapper,
  StyledTopSellingItemsWrapper,
} from './styles';
import { StyledPercentIconBox } from '../../../components/molecules/PercentIconBox/styles';
import Typography from '../../../components/styles/Typography';
import { useWarehouseReport } from './hooks';

const ReportPage: React.FC = () => {
  const intl = useIntl();
  const { data, isLoading } = useWarehouseReport();
  const { setHeaderTitle } = useHeaderTitleContext();

  const topSalesChartCities = data?.ordersStatePercentage.map((o) => o.state);
  const topSalesChartNumbers = data?.ordersStatePercentage.map(
    (o) => `${o.ordersPercentage}`
  );

  useEffect(() => {
    setHeaderTitle(
      intl.formatMessage({
        id: 'mainNavigation.warehouse',
        defaultMessage: 'Warehouse',
      })
    );
  }, [intl, setHeaderTitle]);

  return (
    <>
      {/* <StyledBox mb='2rem' justifyContent='flex-end'>
        <ButtonLink to={routes.ADD_PRODUCT}>
          <FormattedMessage
            id='warehouse.addNewProduct'
            defaultMessage='Add new product'
          />
        </ButtonLink>
      </StyledBox> */}
      <StyledReportBoxesWrapper>
        {isLoading ? (
          <Loader mode={LoaderMode.WarehouseReport} />
        ) : (
          <>
            <StyledBox flexDirection='column' gap='2rem' width='50%'>
              {/* Last 7 Days */}
              <StyledPercentIconBox>
                <Typography variant='caption'>
                  {intl.formatMessage({
                    id: 'report.lastSevenDays',
                    defaultMessage: 'Last 7 Days',
                  })}
                </Typography>
                <StyledBox justifyContent='space-around'>
                  <StyledPackedWrapper>
                    <PercentIconBox
                      label={intl.formatMessage({
                        id: 'report.packed',
                        defaultMessage: 'Packed',
                      })}
                      percent={data?.packedPercentage.toFixed(2) || '0'}
                      icon={IconWarehouseCart}
                    />
                  </StyledPackedWrapper>
                  <StyledForPackingWrapper>
                    <PercentIconBox
                      label={intl.formatMessage({
                        id: 'report.pendingPacking',
                        defaultMessage: 'Pending Packing',
                      })}
                      percent={data?.forPackingPercentage.toFixed(2) || '0'}
                      icon={IconUnpack}
                    />
                  </StyledForPackingWrapper>
                </StyledBox>
              </StyledPercentIconBox>

              <StyledTopSellingItemsWrapper>
                <TopSellingBox
                  label={intl.formatMessage({
                    id: 'report.topSellingItems',
                    defaultMessage: 'Top selling items',
                  })}
                  items={data?.ordersGroupingByItem}
                />
              </StyledTopSellingItemsWrapper>
            </StyledBox>

            <StyledSalesByCityWrapper>
              <SalesByCityBox
                label={intl.formatMessage({
                  id: 'report.salesByCity',
                  defaultMessage: 'Sales by city',
                })}
                dataChartLabels={topSalesChartCities || []}
                dataChartNumbers={topSalesChartNumbers || []}
              />
            </StyledSalesByCityWrapper>

            {/* <StyledInventoryLevelsWrapper>
            <InventoryLevelsBox
              label={intl.formatMessage({
                id: 'report.inventoryLevelsByMonth',
                defaultMessage: 'Inventory levels by month - Last 6 Months',
              })}
              items={demoInventoryLevelsBoxItems}
            />
          </StyledInventoryLevelsWrapper> */}
          </>
        )}
      </StyledReportBoxesWrapper>
    </>
  );
};

export default ReportPage;
