import React from 'react';
import { useIntl } from 'react-intl';
import ButtonExternalLink from '../../../../components/atoms/Button/buttonExternalLink';
import { ButtonMode } from '../../../../components/atoms/Button/enums';
import PriceWithCurrency from '../../../../components/atoms/PriceWithCurrency';
import { PriceWithCurrencySize } from '../../../../components/atoms/PriceWithCurrency/enums';
import Typography from '../../../../components/styles/Typography';
import { StyledCashoutInfoWrapper, StyledDate } from './styles';
import { CashoutInfoProps } from './types';

const CashoutInfo: React.FC<CashoutInfoProps> = ({
  price,
  currency,
  status,
  date,
  href,
  buttonLabel,
}) => {
  const intl = useIntl();

  return (
    <StyledCashoutInfoWrapper>
      <PriceWithCurrency
        size={PriceWithCurrencySize.Big}
        {...{ price, currency }}
      />
      <Typography variant='bodyBold'>{status}</Typography>
      <Typography variant='caption'>
        <StyledDate dateTime={date.toString()}>
          {intl.formatDate(date)}
        </StyledDate>
      </Typography>
      <ButtonExternalLink mode={ButtonMode.Secondary} {...{ href }} download>
        {buttonLabel}
      </ButtonExternalLink>
    </StyledCashoutInfoWrapper>
  );
};

export default CashoutInfo;
