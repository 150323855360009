import styled from 'styled-components';
import Typography from '../../styles/Typography';

export const StyledHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.75rem;
`;

export const StyledHeaderAction = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;

  ${({ theme }) =>
    theme.direction === 'rtl' ? 'padding-right: 2rem;' : 'padding-left: 2rem;'}
`;

export const StyledHeaderActionColumn = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  &:not(:last-child) {
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? 'margin-left: 1.5rem;'
        : 'margin-right: 1.5rem;'}
  }
`;

export const StyledHeaderTitle = styled(Typography).attrs({
  forwardedAs: 'h1',
  variant: 'h2',
})`
  color: ${({ theme }) => theme.colors.text.secondary};
  text-transform: uppercase;
`;
