import styled from 'styled-components';
import Typography from '../../styles/Typography';

export const StyledTopSellingBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.background.light};
  box-shadow: ${({ theme }) => theme.shadows.mid};
`;

export const StyledTopSellingBoxLabel = styled(Typography).attrs({
  variant: 'bodyBold',
})`
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 1rem;
`;

export const StyledTopSellingBoxItems = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 2rem;
`;

export const StyledTopSellingBoxItem = styled.div``;

export const StyledTopSellingBoxItemImageWrapper = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 0.5rem;
`;

export const StyledTopSellingBoxItemImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
