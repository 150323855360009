import React from 'react';
import { Col, Row } from 'react-grid-system';
import { useIntl } from 'react-intl';
import { FormInput } from '../../../../../../../../components/atoms/Fields/Input';
import { StyledBox } from '../../../../../../../../components/styles/Grid/styles';
import { FormSimpleSelect } from '../../../../../../../../components/atoms/Fields/Select/templates/SimpleSelect';
import { ROLES_SELECT_ITEMS } from './consts';
import { InformationProps } from './types';

const Information: React.FC<InformationProps> = ({ disabled, isEdit }) => {
  const intl = useIntl();

  return (
    <>
      <StyledBox>
        <FormInput
          {...{ disabled }}
          name='fullName'
          label={intl.formatMessage({
            id: 'forms.fields.fullName',
            defaultMessage: 'Full Name',
          })}
        />
      </StyledBox>
      <StyledBox>
        <FormInput
          {...{ disabled }}
          name='phoneNumber'
          label={intl.formatMessage({
            id: 'forms.fields.phone',
            defaultMessage: 'Phone',
          })}
          type='tel'
        />
      </StyledBox>
      <StyledBox>
        <FormInput
          disabled={disabled || isEdit}
          name='email'
          label={intl.formatMessage({
            id: 'forms.fields.email',
            defaultMessage: 'Email',
          })}
          type='email'
        />
      </StyledBox>
      <Row gutterWidth={18}>
        <Col>
          <FormInput
            disabled={disabled || isEdit}
            name='password'
            label={intl.formatMessage({
              id: 'forms.fields.password',
              defaultMessage: 'Password',
            })}
            type='password'
          />
        </Col>
        <Col>
          <FormInput
            disabled={disabled || isEdit}
            name='repeatPassword'
            label={intl.formatMessage({
              id: 'forms.fields.confirmPassword',
              defaultMessage: 'Confirm Password',
            })}
            type='password'
          />
        </Col>
      </Row>
      <StyledBox>
        <FormSimpleSelect
          {...{ disabled }}
          name='role'
          options={ROLES_SELECT_ITEMS}
          label={intl.formatMessage({
            id: 'forms.fields.role',
            defaultMessage: 'Role',
          })}
        />
      </StyledBox>
    </>
  );
};

export default Information;
