import React from 'react';
import { useIntl } from 'react-intl';
import { ProductThumbnailProps } from './types';
import { StyledProductImage, StyledProductThumbnail } from './styles';
import imageAddImage from '../../../assets/images/add-image.png';

const ProductThumbnail: React.FC<ProductThumbnailProps> = ({
  src,
  alt,
  isBig,
}) => {
  const intl = useIntl();
  const imgAlt = src
    ? alt
    : intl.formatMessage({
        id: 'orders.addImage',
        defaultMessage: 'Add image',
      });

  return (
    <StyledProductThumbnail {...{ isBig }}>
      <StyledProductImage src={src || imageAddImage} alt={imgAlt} />
    </StyledProductThumbnail>
  );
};

export default ProductThumbnail;
