import styled from 'styled-components';

export const StyledForm = styled.form`
  width: 100%;
  padding-bottom: 6rem;
`;

export const StyledOrderFormMapWrapper = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.dimensions.googleMap.height};
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 1rem;
`;
