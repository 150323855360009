import React, { useEffect } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getCoreRowModel,
  Row,
  flexRender,
} from '@tanstack/react-table';
import { StyledBox } from '../../styles/Grid/styles';
import Typography from '../../styles/Typography';
import Pagination from '../Pagination';
import {
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableCellContent,
  StyledTableHead,
  StyledTableHeadText,
  StyledTableRow,
} from './styles';
import { TableProps } from './types';

// eslint-disable-next-line @typescript-eslint/ban-types
function Table<T extends object>({
  data,
  columns,
  onRowClick,
  pageSize = data.length,
  disabledPagination,
  pageCount,
  onPageChange,
  currentPage,
  columnVisibility,
  totalCount,
  rowSelection,
  getRowId,
  setSelectedRows,
}: TableProps<T>): ReactComponentReturnType {
  const tableInstance = useReactTable({
    columns,
    data,
    enableRowSelection: true,
    getRowId,
    initialState: {
      columnVisibility,
      pagination: { pageSize },
    },
    onRowSelectionChange: setSelectedRows,
    state: {
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: disabledPagination
      ? undefined
      : getPaginationRowModel(),
  });

  const { getRowModel, setPageSize, getHeaderGroups } = tableInstance;

  useEffect(() => {
    if (setPageSize) {
      setPageSize(pageSize);
    }
  }, [pageSize, setPageSize]);

  const dataSrc = getRowModel().rows;

  return (
    <>
      <StyledTable>
        <colgroup>
          {getHeaderGroups().map(({ headers }, i) => (
            <React.Fragment key={i as number}>
              {headers.map(({ id }) => (
                <col key={id} />
              ))}
            </React.Fragment>
          ))}
        </colgroup>

        {/* Headers */}
        <StyledTableHead>
          {getHeaderGroups().map((headerGroup) => (
            <StyledTableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <StyledTableCell key={header.id}>
                  <StyledTableHeadText>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </StyledTableHeadText>
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </StyledTableHead>

        {/* Rows */}
        <StyledTableBody>
          {dataSrc.map((row: Row<T>, i: number) => (
            <StyledTableRow
              key={row.id}
              clickable={!!onRowClick}
              onClick={onRowClick ? () => onRowClick(row) : undefined}
            >
              {row.getVisibleCells().map((cell) => (
                <StyledTableCell key={cell.id}>
                  <StyledTableCellContent>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </StyledTableCellContent>
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </StyledTableBody>
      </StyledTable>

      {/* Pagination */}
      {!disabledPagination &&
        pageCount !== undefined &&
        onPageChange &&
        currentPage !== undefined && (
          <>
            <StyledBox mt='2rem'>
              <Typography variant='caption'>Total: {totalCount}</Typography>
            </StyledBox>
            <StyledBox mt='1rem' justifyContent='center'>
              <StyledBox>
                <Pagination
                  forcePage={currentPage - 1}
                  {...{ pageCount }}
                  pageRangeDisplayed={5}
                  marginPagesDisplayed={3}
                  onPageChange={({ selected }) => onPageChange(selected + 1)}
                />
              </StyledBox>
            </StyledBox>
          </>
        )}
    </>
  );
}

export default Table;
