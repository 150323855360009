import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RowSelectionState } from '@tanstack/react-table';
import { utils, write } from 'xlsx';
import { saveAs } from 'file-saver';
import { CustomerOrdersTableProps } from './types';
import { StyledCustomerOrdersTable } from './styles';
import { customerOrdersTableColumns } from './consts';
import Table from '../Table';
import { StyledBox } from '../../styles/Grid/styles';
import Button from '../../atoms/Button';
import { IconDownload } from '../../../assets/svg/icons';
import { ButtonMode, ButtonSize } from '../../atoms/Button/enums';
import { DeliveryItem } from '../../../pages/orders/orderFormPage/types';

const CustomerOrdersTable: React.FC<CustomerOrdersTableProps> = (props) => {
  // Props
  const { orders } = props;

  // States
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  // Hooks
  const intl = useIntl();
  const tableColumns = useMemo(() => customerOrdersTableColumns(intl), [intl]);

  // Vars
  const selectedOrdersIds = Object.keys(rowSelection);
  const selectedOrders = orders.filter((order) =>
    selectedOrdersIds.includes(order.id)
  );

  // Handlers
  const handleDownload = () => {
    const parsedOrders = [...selectedOrders].map((order) => ({
      Products: order.delivery?.deliveryItems
        ? order.delivery.deliveryItems
            .map((i: DeliveryItem) => i.name)
            .join(', ')
        : '',
      Customer: order.customer.name,
      Date: new Date(order.createdOn).toLocaleDateString(),
      'Receipt Number': order.externalReference,
      'Order Status': order.status,
      'Delivery Label': order.deliveryLabel,
      'Value IQD': order.totalPrice,
      Note: order.notes,
    }));

    const worksheet = utils.json_to_sheet(parsedOrders);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `orders.xlsx`);
  };

  return (
    <>
      {/* Download Button */}
      <StyledBox mt={4} mb={3}>
        <Button
          icon={IconDownload}
          mode={ButtonMode.Secondary}
          size={ButtonSize.Small}
          onClick={handleDownload}
          disabled={!selectedOrdersIds.length}
        >
          <FormattedMessage id='downloadXl' defaultMessage='Download (xl)' />
        </Button>
      </StyledBox>

      <StyledCustomerOrdersTable>
        <Table
          disabledPagination
          columns={tableColumns}
          data={orders}
          getRowId={(r) => r.id}
          rowSelection={rowSelection}
          setSelectedRows={setRowSelection}
        />
      </StyledCustomerOrdersTable>
    </>
  );
};

export default CustomerOrdersTable;
