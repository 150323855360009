import { AxiosRequestConfig } from 'axios';
import { CLIENT_SELECT_VALUE_COOKIE_NAME } from '../../../components/layouts/LayoutBase/consts';

import { cookies } from '../../../contexts/Cookies';
import { ClientSelectValueCookieShape } from '../../../utils/auth/types';

const selectedEntitiesInterceptor = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const clientSelectValue = cookies.get<
    ClientSelectValueCookieShape | undefined
  >(CLIENT_SELECT_VALUE_COOKIE_NAME, {
    doNotParse: false,
  });

  if (clientSelectValue) {
    const { entities = [] } = clientSelectValue;
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...(config.headers || {}),
      ...(entities.length
        ? { SelectedEntities: JSON.stringify(entities) }
        : {}),
    };
  }

  return config;
};

export default selectedEntitiesInterceptor;
