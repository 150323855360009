import React, { useContext } from 'react';
import ContentLoader from 'react-content-loader';
import { ThemeContext } from 'styled-components';

const OrderSummaryTemplate: React.FC = () => {
  const theme = useContext(ThemeContext);

  return (
    <ContentLoader
      speed={2}
      width={1346}
      height={727}
      viewBox='0 0 1346 727'
      backgroundColor={theme.colors.loader.background}
      foregroundColor={theme.colors.loader.foreground}
    >
      <path d='M 2 448.5 h 736' />
      <rect x='3' y='0' rx='10' ry='10' width='143' height='20' />
      <rect x='3' y='89' rx='10' ry='10' width='199' height='20' />
      <rect x='3' y='157' rx='10' ry='10' width='199' height='20' />
      <rect x='3' y='225' rx='10' ry='10' width='199' height='20' />
      <rect x='316' y='89' rx='10' ry='10' width='199' height='20' />
      <rect x='316' y='157' rx='10' ry='10' width='199' height='20' />
      <rect x='316' y='225' rx='10' ry='10' width='199' height='20' />
      <rect x='546' y='89' rx='10' ry='10' width='83' height='20' />
      <rect x='546' y='157' rx='10' ry='10' width='83' height='20' />
      <rect x='546' y='225' rx='10' ry='10' width='83' height='20' />
      <rect x='655' y='89' rx='10' ry='10' width='83' height='20' />
      <rect x='655' y='157' rx='10' ry='10' width='83' height='20' />
      <rect x='655' y='225' rx='10' ry='10' width='83' height='20' />
      <rect x='237' y='89' rx='10' ry='10' width='34' height='20' />
      <rect x='237' y='157' rx='10' ry='10' width='34' height='20' />
      <rect x='237' y='225' rx='10' ry='10' width='34' height='20' />
      <path d='M 7 129.5 h 731 M 7 201.5 h 731' />
      <rect x='811' y='88' rx='16' ry='16' width='535' height='182' />
      <rect x='961' y='312' rx='10' ry='10' width='125' height='20' />
      <rect x='811' y='312' rx='10' ry='10' width='83' height='20' />
      <rect x='811' y='411' rx='10' ry='10' width='83' height='20' />
      <rect x='811' y='514' rx='10' ry='10' width='83' height='20' />
      <rect x='811' y='615' rx='10' ry='10' width='83' height='20' />
      <rect x='838' y='340' rx='6' ry='6' width='56' height='11' />
      <rect x='838' y='439' rx='6' ry='6' width='56' height='11' />
      <rect x='838' y='542' rx='6' ry='6' width='56' height='11' />
      <rect x='838' y='643' rx='6' ry='6' width='56' height='11' />
      <rect x='961' y='413' rx='10' ry='10' width='125' height='20' />
      <rect x='961' y='514' rx='10' ry='10' width='125' height='20' />
      <rect x='961' y='615' rx='10' ry='10' width='125' height='20' />
      <rect x='961' y='339' rx='10' ry='10' width='376' height='20' />
      <rect x='961' y='440' rx='10' ry='10' width='376' height='20' />
      <rect x='961' y='541' rx='10' ry='10' width='376' height='20' />
      <rect x='961' y='642' rx='10' ry='10' width='376' height='20' />
      <rect x='961' y='364' rx='10' ry='10' width='222' height='20' />
      <rect x='961' y='465' rx='10' ry='10' width='348' height='20' />
      <rect x='961' y='566' rx='10' ry='10' width='299' height='20' />
      <rect x='961' y='667' rx='10' ry='10' width='311' height='20' />
      <rect x='0' y='359' rx='10' ry='10' width='125' height='20' />
      <rect x='3' y='492' rx='10' ry='10' width='125' height='20' />
      <rect x='3' y='526' rx='10' ry='10' width='125' height='20' />
      <rect x='3' y='560' rx='10' ry='10' width='125' height='20' />
      <rect x='3' y='594' rx='10' ry='10' width='125' height='20' />
      <rect x='153' y='492' rx='10' ry='10' width='125' height='20' />
      <rect x='153' y='526' rx='10' ry='10' width='125' height='20' />
      <rect x='153' y='560' rx='10' ry='10' width='125' height='20' />
      <rect x='153' y='594' rx='10' ry='10' width='125' height='20' />
      <rect x='151' y='359' rx='10' ry='10' width='125' height='20' />
      <rect x='302' y='359' rx='10' ry='10' width='125' height='20' />
      <rect x='0' y='388' rx='10' ry='10' width='125' height='20' />
      <rect x='151' y='388' rx='10' ry='10' width='125' height='20' />
      <rect x='302' y='388' rx='10' ry='10' width='125' height='20' />
      <rect x='596' y='359' rx='16' ry='16' width='142' height='31' />
      <rect x='1204' y='4' rx='16' ry='16' width='142' height='38' />
      <rect x='1046' y='4' rx='16' ry='16' width='142' height='38' />
      <rect x='455' y='490' rx='16' ry='16' width='283' height='237' />
    </ContentLoader>
  );
};

export default OrderSummaryTemplate;
