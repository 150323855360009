import { ProductsTableItem } from '../../../../../pages/orders/orderFormPage/components/OrderForm/types';
import { ProductOrderCardListItem } from '../../../../molecules/ProductOrderCardList/types';

export const createProductsTableItem = ({
  key,
  productName,
  imageUrl,
  price,
  currency,
  sku,
}: ProductOrderCardListItem): ProductsTableItem => ({
  idCol: key,
  productCol: productName,
  imageCol: imageUrl ?? '',
  priceCol: price,
  quantityCol: 1,
  totalCol: {
    price,
    currency,
  },
  sku,
});
