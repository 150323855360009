import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { IntlShape } from 'react-intl';
import { IconEdit } from '../../../../assets/svg/icons';
import UsersBadgeList from '../partials/UsersBadgeList';
import { StyledBox } from '../../../../components/styles/Grid/styles';
import IconButton from '../../../../components/atoms/IconButton';
import { GetEntitiesResponseSingleItem } from '../../../../setup/apiTypes/entities';
import { makeDateCellRenderer } from '../../../../utils/tableRenderers';

export const useEntitiesTableColumns = (
  intl: IntlShape,
  setEditEntityId: (entityId: string) => void
): ColumnDef<GetEntitiesResponseSingleItem>[] =>
  useMemo(
    () => [
      {
        header: intl.formatMessage({
          id: 'enitiesAndUsers.entitiesTable.title',
          defaultMessage: 'Title',
        }),
        accessorKey: 'name',
      },
      {
        header: intl.formatMessage({
          id: 'enitiesAndUsers.entitiesTable.dateAdded',
          defaultMessage: 'Date Added',
        }),
        accessorKey: 'createdAt',
        cell: makeDateCellRenderer(),
      },
      {
        header: intl.formatMessage({
          id: 'enitiesAndUsers.entitiesTable.phoneNumber',
          defaultMessage: 'Phone',
        }),
        accessorKey: 'phoneNumber',
      },
      {
        header: intl.formatMessage({
          id: 'enitiesAndUsers.entitiesTable.email',
          defaultMessage: 'Email',
        }),
        accessorKey: 'email',
      },
      {
        header: intl.formatMessage({
          id: 'enitiesAndUsers.entitiesTable.users',
          defaultMessage: 'Users',
        }),
        accessorKey: 'users',
        cell: ({ getValue }) => {
          const users = getValue() as GetEntitiesResponseSingleItem['users'];

          return users.length ? (
            <UsersBadgeList items={users.map(({ name }) => name)} />
          ) : (
            '-'
          );
        },
      },
      {
        header: intl.formatMessage({
          id: 'enitiesAndUsers.entitiesTable.city',
          defaultMessage: 'City',
        }),
        accessorKey: 'city',
      },
      {
        header: intl.formatMessage({
          id: 'enitiesAndUsers.entitiesTable.businessType',
          defaultMessage: 'Business Type',
        }),
        accessorKey: 'businessType',
      },
      {
        header: '',
        id: 'actions',
        accessorKey: 'id',
        cell: ({ getValue }): ReactComponentReturnType => (
          <StyledBox justifyContent='flex-end'>
            <IconButton
              icon={IconEdit}
              onClick={() => setEditEntityId(getValue() as string)}
            />
          </StyledBox>
        ),
        width: 40,
        align: 'right',
      },
    ],
    [setEditEntityId, intl]
  );
