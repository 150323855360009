import React from 'react';
import { IllustrationSvg } from '../../../assets/svg/images';
import Logo from '../../../components/atoms/Logo';
import Seo from '../../../components/Seo';
import {
  StyledLoginInnerLayoutWrapper,
  StyledLoginInnerLayoutBox,
  StyledLoginInnerLayoutLogo,
  StyledLoginInnerLayoutHeading,
  StyledLoginInnerLayoutDecor,
  StyledLoginInnerLayoutHeadingWrapper,
  StyledLoginInnerLayoutSubtitle,
} from './styles';

import { LoginInnerLayoutProps } from './types';

const LoginInnerLayout: React.FC<LoginInnerLayoutProps> = ({
  title,
  subtitle,
  pageTitle,
  isBig,
  withDecor,
  children,
}) => (
  <>
    <Seo title={pageTitle || title || ''} />
    <StyledLoginInnerLayoutWrapper {...{ isBig }}>
      <StyledLoginInnerLayoutBox {...{ isBig }}>
        <StyledLoginInnerLayoutLogo>
          <Logo isBig />
        </StyledLoginInnerLayoutLogo>
        {withDecor && (
          <StyledLoginInnerLayoutDecor>
            <IllustrationSvg />
          </StyledLoginInnerLayoutDecor>
        )}
        {title && (
          <StyledLoginInnerLayoutHeadingWrapper>
            {title && (
              <StyledLoginInnerLayoutHeading>
                {title}
              </StyledLoginInnerLayoutHeading>
            )}
          </StyledLoginInnerLayoutHeadingWrapper>
        )}
        {subtitle && (
          <StyledLoginInnerLayoutHeadingWrapper>
            {subtitle && (
              <StyledLoginInnerLayoutSubtitle>
                {subtitle}
              </StyledLoginInnerLayoutSubtitle>
            )}
          </StyledLoginInnerLayoutHeadingWrapper>
        )}
        {children}
      </StyledLoginInnerLayoutBox>
    </StyledLoginInnerLayoutWrapper>
  </>
);

export default LoginInnerLayout;
