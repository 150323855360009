import React, { useCallback, useState } from 'react';
import { IEntityContext } from './types';

export const EntityContext = React.createContext<IEntityContext>({
  onChange: () => null,
  clearSelection: () => null,
  cutOffDate: null,
  setCutOffDate: () => null,
  selectedEntities: [],
});

export const EntityContextProvider: React.FC = ({ children }) => {
  const [selectedEntities, setSelectedEntities] = useState<string[]>([]);
  const [cutOffDate, setCutOffDate] = useState<Date | null>(null);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
      const {
        target: { checked },
      } = event;

      if (checked) {
        return setSelectedEntities((currentEntities) =>
          !(id in currentEntities) ? [...currentEntities, id] : currentEntities
        );
      }

      return setSelectedEntities((currentEntities) =>
        currentEntities.filter((entityId) => entityId !== id)
      );
    },
    []
  );

  const clearSelection = useCallback(() => setSelectedEntities([]), []);

  return (
    <EntityContext.Provider
      value={{
        selectedEntities,
        cutOffDate,
        setCutOffDate,
        onChange,
        clearSelection,
      }}
    >
      {children}
    </EntityContext.Provider>
  );
};
