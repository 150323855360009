import React from 'react';
import { components } from 'react-select';
import { SelectValueContainerProps } from './types';

function ValueContainer<T>({
  children,
  renderControlContent,
  getValue,
  ...rest
}: SelectValueContainerProps<T>): ReactComponentReturnType {
  const [value] = getValue();

  return (
    <components.ValueContainer {...{ getValue, ...rest }}>
      {(renderControlContent && value && renderControlContent(value)) ||
        children}
    </components.ValueContainer>
  );
}

export default ValueContainer;
