import React, { useRef, useState } from 'react';
import { AccordionProps } from './types';
import {
  AccordionContainer,
  AccordionContent,
  AccordionHeader,
  AccordionIcon,
} from './styles';
import { IconChevronDown } from '../../../assets/svg/icons';

const Accordion: React.FC<AccordionProps> = (props) => {
  const { children, title } = props;

  const contentRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState('0px');

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setHeight(isOpen ? '0px' : `${contentRef.current?.scrollHeight}px`);
  };

  return (
    <AccordionContainer>
      <AccordionHeader isOpen={isOpen} onClick={toggleAccordion}>
        {title}
        <AccordionIcon isOpen={isOpen}>
          <IconChevronDown />
        </AccordionIcon>
      </AccordionHeader>
      <AccordionContent
        ref={contentRef}
        style={{ maxHeight: height }}
        isOpen={isOpen}
      >
        <div>{children}</div>
      </AccordionContent>{' '}
    </AccordionContainer>
  );
};

export default Accordion;
