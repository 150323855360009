import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

const usePathMatchesRoute = (
  routeOrRoutes: string | string[],
  pathname?: string,
  exact = true
): boolean => {
  const location = useLocation();
  const pathnameToCheck = pathname || location.pathname;

  return useMemo(() => {
    const routes = Array.isArray(routeOrRoutes)
      ? routeOrRoutes
      : [routeOrRoutes];

    return routes.some((route) => {
      if (route === undefined) {
        console.warn( // eslint-disable-line
          'One of routes passed to pathMatchesRoute method is undefined. This may lead to unexpected results'
        );

        return false;
      }

      return (
        matchPath(pathnameToCheck, {
          path: route,
          exact,
        }) !== null
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exact, pathnameToCheck, JSON.stringify(routeOrRoutes)]);
};

export default usePathMatchesRoute;
