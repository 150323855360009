import styled from 'styled-components';
import { variant } from 'styled-system';
import { textStyles } from '../../../theme/config/typography';
import colors from '../../../theme/config/colors';

export const StyledOrderSummaryContactList = styled.dl`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: flex-start;
  grid-gap: 1.125rem 2.75rem;
`;

export const StyledOrderSummaryContactListLabel = styled.dt`
  padding-top: 2px;
`;

export const StyledOrderSummaryContactListContent = styled.dd.attrs({
  variant: 'bodyBold',
})<{ isClickable: boolean }>`
  ${variant({
    variants: {
      ...textStyles,
    },
  })};
  color: ${({ isClickable }) => (isClickable ? colors.link : '')};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : '')};
`;
