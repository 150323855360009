import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { CLIENT_SELECT_VALUE_COOKIE_NAME } from '../../../components/layouts/LayoutBase/consts';
import { cookies } from '../../../contexts/Cookies';

import { AUTH_TOKENS_LOCAL_STORAGE_KEY } from '../../../contexts/User/consts';
import { AuthTokensShape } from '../../../contexts/User/types';
import {
  getLocalStorageValue,
  removeLocalStorageValue,
  setLocalStorageValue,
} from '../../../hooks/useLocalStorageValue/utils';
import { parseJson } from '../../../utils';
import { createClientSelectValueCookie, getTokens } from '../../../utils/auth';
import { REFRESHED_TOKEN_HEADER } from './consts';
import { AuthUserType } from './enums';
import { TokenData } from './types';

const createAuthHeaders = (
  configHeaders: Record<string, string>,
  authToken: string,
  sdgAuthToken: string | undefined,
  refreshToken: string
) => ({
  ...(configHeaders || {}),
  ...(sdgAuthToken ? { SdgAuthorization: `Bearer ${sdgAuthToken}` } : {}),
  refreshToken,
  Authorization: `Bearer ${authToken}`,
});

const authRequestInterceptor = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const authTokens = parseJson<AuthTokensShape>(
    getLocalStorageValue(AUTH_TOKENS_LOCAL_STORAGE_KEY)
  );

  if (authTokens) {
    const { authToken, sdgAuthToken, refreshToken } = authTokens;
    // eslint-disable-next-line no-param-reassign
    config.headers = createAuthHeaders(
      config.headers,
      authToken,
      sdgAuthToken,
      refreshToken
    );
  }

  return config;
};

const authResponseInterceptor = async (
  response: AxiosResponse
): Promise<AxiosResponse> => {
  const refreshedToken = response.headers[REFRESHED_TOKEN_HEADER];
  if (refreshedToken) {
    try {
      const tokenData = JSON.parse(refreshedToken) as TokenData;

      const { token, clientId, entities, sdgUser } = tokenData;

      const authTokens = getTokens(
        token,
        null,
        sdgUser ? AuthUserType.SDG : AuthUserType.CLIENT
      );

      const clientSelectValueCookie = createClientSelectValueCookie(
        clientId,
        entities
      );

      cookies.set(CLIENT_SELECT_VALUE_COOKIE_NAME, clientSelectValueCookie, {
        path: '/',
      });

      setLocalStorageValue(AUTH_TOKENS_LOCAL_STORAGE_KEY, authTokens);
    } catch {
      removeLocalStorageValue(AUTH_TOKENS_LOCAL_STORAGE_KEY);
      return response;
    }
  }

  return response;
};

const authErrorResponseInterceptor = async (
  error: AxiosError
): Promise<AxiosError> => {
  if (error.isAxiosError && error?.response?.status === 401) {
    removeLocalStorageValue(AUTH_TOKENS_LOCAL_STORAGE_KEY);
  }

  return Promise.reject(error);
};

export {
  authRequestInterceptor,
  authResponseInterceptor,
  authErrorResponseInterceptor,
};
