import React from 'react';
import ReactTooltip from 'react-tooltip';
import { ItemsPopupProps } from './types';
import { StyledItem, StyledItemsPopup } from './styles';
import ProductThumbnail from '../../atoms/ProductThumbnail';
import Typography from '../../styles/Typography';
import PriceWithCurrency from '../../atoms/PriceWithCurrency';

const ItemsPopup: React.FC<ItemsPopupProps> = ({ tooltipId, products }) => (
  <StyledItemsPopup>
    <ReactTooltip
      multiline
      id={tooltipId}
      className='items-popup-tooltip'
      place='right'
    >
      {products.map((product) => (
        <StyledItem key={product.id}>
          <ProductThumbnail src={product.coverImage} alt={product.name} />
          <Typography>{product.name}</Typography>
          <Typography>{product.quantity}</Typography>
          <Typography>{product.description}</Typography>
          <PriceWithCurrency price={product.sellingPrice} />
          <PriceWithCurrency price={product.sellingPrice * product.quantity} />
        </StyledItem>
      ))}
    </ReactTooltip>
  </StyledItemsPopup>
);

export default ItemsPopup;
