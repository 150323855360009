import { FORM_ERROR } from 'final-form';
import { useCallback } from 'react';
import { SubmitHandler } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useToasts } from 'react-toast-notifications';

import { mapApiErrorsToFormErrors } from '../../../utils/finalForm';
import { RESET_PASSWORD_MUTATION_FN } from './consts';

import { ResetPasswordFormValues } from './types';

export const useResetPasswordFormSubmitHandler = (): SubmitHandler<ResetPasswordFormValues> => {
  const intl = useIntl();
  const { addToast } = useToasts();
  const { mutateAsync: resetPassword } = useMutation(
    RESET_PASSWORD_MUTATION_FN
  );

  return useCallback(
    async ({ email }) => {
      try {
        await resetPassword({
          data: {
            email,
          },
        });

        return undefined;
      } catch (error) {
        const { errors, generalError } = mapApiErrorsToFormErrors(error, intl);

        if (errors) {
          return errors;
        }

        const defaultError = intl.formatMessage({
          id: 'login.resetPasswordFailed',
          defaultMessage: 'Failed to send reset password request',
        });

        addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
          appearance: 'error',
        });

        return {
          [FORM_ERROR]: defaultError,
        };
      }
    },
    [intl, resetPassword, addToast]
  );
};
