import styled from 'styled-components';
import Typography from '../../styles/Typography';

export const StyledContactLink = styled.a`
  display: inline-flex;
  align-items: center;
  width: 100%;
  max-width: ${({ theme }) => theme.dimensions.contactLink.maxWidth};
  padding: 1.5rem 1.375rem 1.875rem;
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: ${({ theme }) => theme.colors.background.light};
  border-radius: 1rem;
  text-decoration: none;
  transition: ${({ theme }) => theme.transitions.default};
  box-shadow: ${({ theme }) => theme.shadows.mid};
  svg {
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    font-size: 3.25rem;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const StyledLabel = styled(Typography).attrs({
  forwardedAs: 'span',
  variant: 'h2',
})`
  margin-left: 2.875rem;
`;
