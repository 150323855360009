import React from 'react';
import { useIntl } from 'react-intl';

import { TopSellingBoxProps } from './types';
import {
  StyledTopSellingBox,
  StyledTopSellingBoxItem,
  StyledTopSellingBoxItemImage,
  StyledTopSellingBoxItemImageWrapper,
  StyledTopSellingBoxItems,
  StyledTopSellingBoxLabel,
} from './styles';
import Typography from '../../styles/Typography';
import { StyledBox } from '../../styles/Grid/styles';
import placeholderImage from '../../../assets/images/placeholder-image.jpeg';

const TopSellingBox: React.FC<TopSellingBoxProps> = ({ label, items }) => {
  const intl = useIntl();

  return (
    <StyledTopSellingBox>
      <StyledTopSellingBoxLabel>{label}</StyledTopSellingBoxLabel>
      {items && (
        <StyledTopSellingBoxItems>
          {items.map(({ key, name, quantity, coverImage }) => (
            <StyledTopSellingBoxItem {...{ key }}>
              <StyledTopSellingBoxItemImageWrapper>
                <StyledTopSellingBoxItemImage
                  src={coverImage || placeholderImage}
                  alt={name}
                />
              </StyledTopSellingBoxItemImageWrapper>
              <StyledBox mb='0.5rem'>
                <Typography variant='bodyHeavy'>{name}</Typography>
              </StyledBox>
              <Typography variant='caption'>
                {intl.formatNumber(quantity)} pcs
              </Typography>
            </StyledTopSellingBoxItem>
          ))}
        </StyledTopSellingBoxItems>
      )}
    </StyledTopSellingBox>
  );
};

export default TopSellingBox;
