import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { getAppRoutes } from './consts';
import NotFoundPage from '../not-found';
import { useRedirectContent } from './hooks';
import LoginLayout from '../../components/layouts/LoginLayout';
import useUserContext from '../../hooks/useUserContext';

const AppRoutesSwitch: React.FC = () => {
  const redirectContent = useRedirectContent();
  const { user } = useUserContext();

  const appRoutes = getAppRoutes({
    isAdvancedUser: Boolean(user?.isAdvancedUser),
    isSandoogUser: Boolean(user?.isSandoogUser),
  });

  if (redirectContent) {
    return redirectContent;
  }

  return (
    <Switch>
      {appRoutes.map(({ path, component: Component, layout: Layout }) => (
        <Route {...{ path }} exact key={path}>
          {Layout ? (
            <Layout>
              <Component />
            </Layout>
          ) : (
            <Component />
          )}
        </Route>
      ))}
      <Route>
        <LoginLayout>
          <NotFoundPage />
        </LoginLayout>
      </Route>
    </Switch>
  );
};
const AppRoutes: React.FC = () => (
  <Router>
    <AppRoutesSwitch />
  </Router>
);
export default AppRoutes;
