import styled from 'styled-components';
import Typography from '../../styles/Typography';

export const StyledPriceWithCurrency = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const StyledCurrencyWrapper = styled.div`
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? 'margin-right: 0.5rem;'
      : 'margin-left: 0.5rem;'}
`;
