import { FORM_ERROR } from 'final-form';
import { useCallback, useMemo } from 'react';
import { SubmitHandler } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { GetUserResponse } from '../../../../../../setup/apiTypes/users';
import apiRoutes from '../../../../../../setup/consts/apiRoutes';
import { transformObjectToFormData } from '../../../../../../utils/api';
import { mapApiErrorsToFormErrors } from '../../../../../../utils/finalForm';
import {
  CREATE_USER_MUTATION_FN,
  MAKE_UPDATE_USER_MUTATION_FN,
} from './consts';
import { UserFormValues, UseUserDetailsReturnType } from './types';

export const useUserFormSubmitHandler = (
  refetchUsers: (() => Promise<void>) | undefined,
  closeModal: () => void,
  userId: string | undefined
): SubmitHandler<UserFormValues> => {
  const { mutateAsync: createUser } = useMutation(CREATE_USER_MUTATION_FN);
  const { mutateAsync: updateUser } = useMutation(
    MAKE_UPDATE_USER_MUTATION_FN(userId || '')
  );
  const intl = useIntl();
  const { addToast } = useToasts();

  return useCallback(
    async (values) => {
      const { repeatPassword, password } = values;

      if (repeatPassword !== password) {
        return {
          repeatPassword: intl.formatMessage({
            id: 'enitiesAndUsers.passwordsNotEqualError',
            defaultMessage: 'Passwords are not equal',
          }),
        };
      }

      const { entities, avatar, fullName, phoneNumber, role, email } = values;
      const dataToSend = {
        entities: entities
          .filter(({ checked }) => checked)
          .map(({ value }) => value),
        ...(avatar && typeof avatar !== 'string' ? { avatar } : {}),
        fullName,
        phoneNumber,
        role,
        ...(userId
          ? {}
          : {
              email,
              password,
            }),
      };
      const formData = transformObjectToFormData(dataToSend);

      try {
        const mutationFn = userId ? updateUser : createUser;

        await mutationFn({
          data: formData,
        });

        if (refetchUsers) {
          refetchUsers();
        }

        closeModal();

        const successMessageDescriptior = userId
          ? {
              id: 'enitiesAndUsers.userUpdatedMessage',
              defaultMessage: 'User updated successfuly',
            }
          : {
              id: 'enitiesAndUsers.userCreatedMessage',
              defaultMessage: 'User has been created',
            };

        addToast(intl.formatMessage(successMessageDescriptior), {
          appearance: 'success',
        });

        return undefined;
      } catch (error) {
        const { errors, generalError } = mapApiErrorsToFormErrors(error, intl);

        if (errors) {
          return errors;
        }

        const errorMessageDescriptior = userId
          ? {
              id: 'enitiesAndUsers.updateUserFailed',
              defaultMessage: 'Failed to update user',
            }
          : {
              id: 'enitiesAndUsers.createUserFailed',
              defaultMessage: 'Failed to create user',
            };
        const defaultError = intl.formatMessage(errorMessageDescriptior);

        addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
          appearance: 'error',
        });

        return {
          [FORM_ERROR]: defaultError,
        };
      }
    },
    [intl, userId, updateUser, createUser, refetchUsers, closeModal, addToast]
  );
};

export const useUserDetails = (
  userId: string | undefined
): UseUserDetailsReturnType => {
  const { isLoading, data } = useQuery<UseQueryResponse<GetUserResponse>>(
    apiRoutes.USER_DETAILS(userId || ''),
    {
      enabled: !!userId,
    }
  );

  return {
    isLoading,
    user: useMemo(() => {
      if (!data) {
        return undefined;
      }

      const {
        email,
        role,
        phoneNumber,
        entities,
        name: fullName,
        picture,
      } = data.response;

      return {
        entities: entities.map(({ name, id }) => ({
          value: id,
          label: name,
          checked: true,
        })),
        fullName,
        phoneNumber,
        email,
        password: '********',
        repeatPassword: '********',
        role,
        avatar: picture || undefined,
      };
    }, [data]),
  };
};
