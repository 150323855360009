import { FORM_ERROR } from 'final-form';
import { useCallback, useMemo } from 'react';
import { SubmitHandler } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { GetOrderDetailsResponse } from '../../../setup/apiTypes/orders';
import apiRoutes from '../../../setup/consts/apiRoutes';

import routes from '../../../setup/consts/routes';
import { mapApiErrorsToFormErrors } from '../../../utils/finalForm';
import {
  mapCurrencyCodeToCurrency,
  mapCurrencyToCurrencyCode,
} from '../../../utils/misc';
import {
  CREATE_ORDER_MUTATION_FN,
  MAKE_UPDATE_ORDER_MUTATION_FN,
} from './consts';
import { OrderFormValues, UseOrderInitialValuesReturnType } from './types';

export const useOrderFormSubmitHandler = (
  orderId: string | undefined
): {
  submitHandler: SubmitHandler<OrderFormValues>;
  loadingSubmit: boolean;
} => {
  const history = useHistory();
  const { mutateAsync: createOrder, isLoading: createLoading } = useMutation(
    CREATE_ORDER_MUTATION_FN
  );
  const { mutateAsync: updateOrder, isLoading: updateLoading } = useMutation(
    MAKE_UPDATE_ORDER_MUTATION_FN(orderId || '')
  );
  const intl = useIntl();
  const { addToast } = useToasts();

  const submitHandler: SubmitHandler<OrderFormValues> = useCallback(
    async ({ products = [], currency, ...values }) => {
      try {
        const mutateOrderFn = orderId ? updateOrder : createOrder;

        await mutateOrderFn({
          data: {
            ...values,
            currency: mapCurrencyCodeToCurrency(currency),
            products: products.map(
              ({
                idCol,
                quantityCol,
                priceCol,
                imageCol,
                productCol,
                sku,
              }) => ({
                id: parseInt(idCol, 10),
                quantity: quantityCol,
                price: priceCol,
                imageUrl: imageCol,
                name: productCol,
                sku,
              })
            ),
          },
        });

        const successMessageDescriptior = orderId
          ? {
              id: 'enitiesAndUsers.orderUpdatedMessage',
              defaultMessage: 'Order updated successfuly',
            }
          : {
              id: 'enitiesAndUsers.orderCreatedMessage',
              defaultMessage: 'Order has been created',
            };

        addToast(intl.formatMessage(successMessageDescriptior), {
          appearance: 'success',
        });
        history.push(routes.ORDERS);

        return undefined;
      } catch (error) {
        const { errors, generalError } = mapApiErrorsToFormErrors(error, intl);

        if (errors) {
          return errors;
        }

        const errorMessageDescriptior = orderId
          ? {
              id: 'enitiesAndUsers.updateOrderFailed',
              defaultMessage: 'Failed to update order',
            }
          : {
              id: 'enitiesAndUsers.createOrderFailed',
              defaultMessage: 'Failed to create order',
            };
        const defaultError = intl.formatMessage(errorMessageDescriptior);

        addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
          appearance: 'error',
        });

        return {
          [FORM_ERROR]: defaultError,
        };
      }
    },
    [orderId, updateOrder, createOrder, addToast, intl, history]
  );

  return {
    loadingSubmit: createLoading || updateLoading,
    submitHandler,
  };
};

export const useOrderInitialValues = (
  orderId: string | undefined
): UseOrderInitialValuesReturnType => {
  const { data, isLoading, error } = useQuery<
    UseQueryResponse<GetOrderDetailsResponse>
  >(apiRoutes.ORDER_DETAILS(orderId || ''), {
    enabled: !!orderId,
  });

  return {
    initialValues: useMemo((): OrderFormValues | undefined => {
      if (!data || !data.response) {
        return undefined;
      }

      const {
        entity,
        customerId,
        customerAddress,
        customerName,
        customerPhone,
        secondCustomerPhone,
        externalReference,
        deliveryFee,
        notes,
        state,
        latitude,
        longitude,
        currency: _currency,
        deliveryRegion,
        totalPrice,
        deliveryItems = [],
      } = data.response;

      const currency = mapCurrencyToCurrencyCode(_currency);

      return {
        entity,
        phoneNumber: customerPhone,
        secondPhoneNumber: secondCustomerPhone,
        externalReference,
        name: customerName,
        city: state,
        address: customerAddress,
        deliveryCharge: deliveryFee.toString(),
        deliveryRegion: deliveryRegion.toLocaleLowerCase(),
        products: deliveryItems.map(
          ({ id, name: productName, quantity, price, imageUrl, sku }) => ({
            idCol: id?.toString(),
            productCol: productName ?? '',
            quantityCol: quantity,
            imageCol: imageUrl,
            priceCol: price,
            totalCol: {
              price: quantity * price,
              currency,
            },
            name: String(productName),
            sku,
          })
        ),
        latitude,
        longitude,
        customerId,
        currency,
        total: totalPrice,
        ...(notes ? { notes } : {}),
      };
    }, [data]),
    loading: isLoading,
    error: !!error,
  };
};
