import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import Table from '../../../../../../components/molecules/Table';
import { productsTableColumns } from '../consts';
import { ProductsTableProps } from './types';

const ProductsTable: React.FC<ProductsTableProps> = ({ data, currency }) => {
  const intl = useIntl();
  const tableColumns = useMemo(
    () => productsTableColumns(currency, intl),
    [currency, intl]
  );

  return (
    <Table
      data={data ?? []}
      columns={tableColumns}
      columnVisibility={{ idCol: false }}
      disabledPagination
    />
  );
};

export default ProductsTable;
