import React from 'react';
import { SpinnerProps } from './types';
import {
  StyledSpinner,
  StyledSpinnerDescription,
  StyledSpinnerOverlay,
} from './styles';
import { SpinnerMode, SpinnerSize } from './enums';

const Spinner: React.FC<SpinnerProps> = ({
  size = SpinnerSize.Small,
  mode = SpinnerMode.Light,
  withOverlay,
  description,
}) => {
  const spinner = <StyledSpinner {...{ size, mode }}>Loading...</StyledSpinner>;

  return withOverlay ? (
    <StyledSpinnerOverlay>
      {spinner}
      {description && (
        <StyledSpinnerDescription>{description}</StyledSpinnerDescription>
      )}
    </StyledSpinnerOverlay>
  ) : (
    spinner
  );
};

export default Spinner;
