import React from 'react';
import { useIntl } from 'react-intl';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { ImageSliderProps } from './types';
import { StyledImage, StyledImageSlider, StyledImageWrapper } from './styles';
import imageAddImage from '../../../assets/images/add-image.png';
import placeholderImage from '../../../assets/images/placeholder-image.jpeg';

SwiperCore.use([Pagination]);

const ImageSlider: React.FC<ImageSliderProps> = ({ images }) => {
  const intl = useIntl();

  return (
    <StyledImageSlider>
      {images ? (
        <Swiper pagination={{ clickable: true }} spaceBetween={10}>
          {images.map(({ src, alt, key }) => (
            <SwiperSlide {...{ key }}>
              <StyledImageWrapper>
                <StyledImage
                  {...{ src, alt }}
                  onError={(e) => {
                    // @ts-ignore
                    e.target.onerror = null;

                    // @ts-ignore
                    e.target.src = placeholderImage;
                  }}
                />
              </StyledImageWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <StyledImageWrapper>
          <StyledImage
            src={imageAddImage}
            alt={intl.formatMessage({
              id: 'orders.addImage',
              defaultMessage: 'Add image',
            })}
          />
        </StyledImageWrapper>
      )}
    </StyledImageSlider>
  );
};

export default ImageSlider;
