import React from 'react';
import { FormattedNumber } from 'react-intl';
import { PriceWithCurrencyProps } from './types';
import { StyledCurrencyWrapper, StyledPriceWithCurrency } from './styles';
import Typography from '../../styles/Typography';
import { PriceWithCurrencySize } from './enums';
import { PreferredCurrency } from '../../../setup/apiTypes/enums';

const PriceWithCurrency: React.FC<PriceWithCurrencyProps> = ({
  price,
  currency = PreferredCurrency.IQD,
  size,
}) => {
  const textPriceVariant =
    (size === PriceWithCurrencySize.Large && 'priceLarge') ||
    (size === PriceWithCurrencySize.Big && 'h2') ||
    'body';
  const textCurrencyVariant =
    (size === PriceWithCurrencySize.Large && 'captionBold') ||
    (size === PriceWithCurrencySize.Big && 'captionBold') ||
    'currencyBold';

  return (
    /* TODO
    Fix the style='currency' problem: [https://github.com/formatjs/formatjs/issues/785]
  */
    <StyledPriceWithCurrency>
      <Typography as='span' variant={textPriceVariant}>
        {price !== undefined ? (
          <FormattedNumber
            /* style='currency' */ {...{ currency }}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={price}
          />
        ) : (
          '-'
        )}
      </Typography>
      <StyledCurrencyWrapper>
        <Typography as='span' variant={textCurrencyVariant}>
          {currency}
        </Typography>
      </StyledCurrencyWrapper>
    </StyledPriceWithCurrency>
  );
};

export default PriceWithCurrency;
