import isSameDay from 'date-fns/isSameDay';
import subDays from 'date-fns/subDays';
import { Range, StaticRange } from 'react-date-range';
import { defineMessages } from 'react-intl';
import { SupportedStaticRange } from './enums';

export const messages = defineMessages({
  [SupportedStaticRange.Today]: {
    id: 'staticRange.today',
    defaultMessage: 'Today',
  },
  [SupportedStaticRange.Yesterday]: {
    id: 'staticRange.yesterday',
    defaultMessage: 'Yesterday',
  },
  [SupportedStaticRange.ThisWeek]: {
    id: 'staticRange.thisWeek',
    defaultMessage: 'This Week',
  },
  [SupportedStaticRange.ThisMonth]: {
    id: 'staticRange.thisMonth',
    defaultMessage: 'This Month',
  },
  [SupportedStaticRange.LastMonth]: {
    id: 'staticRange.lastMonth',
    defaultMessage: 'Last Month',
  },
  [SupportedStaticRange.LastWeek]: {
    id: 'staticRange.lastWeek',
    defaultMessage: 'Last Week',
  },
  [SupportedStaticRange.LastSevenDays]: {
    id: 'staticRange.lastSevenDays',
    defaultMessage: 'Last 7 days',
  },
});

const lastSevenDaysRange: Range = {
  startDate: subDays(new Date(), 7),
  endDate: new Date(),
};

export const lastSevenDaysStaticRange: StaticRange = {
  label: SupportedStaticRange.LastSevenDays,
  isSelected: (currentRange: Range): boolean => {
    if (!currentRange.startDate || !currentRange.endDate) {
      return false;
    }

    return (
      isSameDay(currentRange.startDate, lastSevenDaysRange.startDate as Date) &&
      isSameDay(currentRange.endDate, lastSevenDaysRange.endDate as Date)
    );
  },
  range: () => lastSevenDaysRange,
};
