import styled, { css } from 'styled-components';
import { variant } from 'styled-system';
import { textStyles } from '../../../../theme/config/typography';
import { FieldMode, FieldSize } from '../enums';
import { StyledInputProps } from './types';

export const StyledInput = styled.input<StyledInputProps>`
  width: 100%;
  height: ${({ theme, inputSize = FieldSize.Small }) =>
    theme.dimensions.input.height[inputSize]};
  border-radius: ${({ isRounded, inputSize = FieldSize.Small }) =>
    isRounded
      ? (inputSize === FieldSize.Small && '1.25rem') ||
        (inputSize === FieldSize.Big && '3.125rem')
      : '0.5rem'};
  background-color: ${({ theme, mode = FieldMode.Primary }) =>
    theme.colors.field[mode].background};
  color: ${({ theme, mode = FieldMode.Primary }) =>
    theme.colors.field[mode].color};
  border-color: ${({ theme, mode = FieldMode.Primary, isError }) =>
    isError
      ? theme.colors.field[mode].border.error
      : theme.colors.field[mode].border.default};
  border-width: 1px;
  border-style: solid;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  ${({ theme, hasPrefix, hasSuffix, inputSize = FieldSize.Small }) =>
    theme.direction === 'rtl'
      ? css`
          padding-right: ${hasPrefix
            ? theme.dimensions.input.prefixWidth[inputSize]
            : '0.875rem'};
          padding-left: ${hasSuffix
            ? theme.dimensions.input.suffixWidth[inputSize]
            : '0.875rem'};
        `
      : css`
          padding-left: ${hasPrefix
            ? theme.dimensions.input.prefixWidth[inputSize]
            : '0.875rem'};
          padding-right: ${hasSuffix
            ? theme.dimensions.input.suffixWidth[inputSize]
            : '0.875rem'};
        `};

  ${variant({
    variants: {
      ...textStyles,
    },
  })};
  outline: none;
  transition: ${({ theme }) => theme.transitions.default};
  &:focus {
    border-color: ${({ theme, mode = FieldMode.Primary }) =>
      theme.colors.field[mode].border.focus};
    ${({ theme, mode = FieldMode.Primary }) =>
      mode === FieldMode.Tertiary &&
      css`
        background-color: ${theme.colors.field[mode].border.focus};
      `}
  }
  &:disabled,
  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const StyledInputPrefix = styled.div<StyledInputProps>`
  position: absolute;
  top: 0;
  ${({ theme }) => (theme.direction === 'rtl' ? 'right: 0;' : 'left: 0;')}
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ inputSize = FieldSize.Small, theme }) =>
    theme.dimensions.input.prefixWidth[inputSize]};
  z-index: 1;
  color: ${({ theme, mode = FieldMode.Primary }) =>
    theme.colors.field[mode].color};
`;

export const StyledInputSuffix = styled.div<StyledInputProps>`
  position: absolute;
  top: 0;
  ${({ theme }) => (theme.direction === 'rtl' ? 'left: 0;' : 'right: 0;')}
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ inputSize = FieldSize.Small, theme }) =>
    theme.dimensions.input.suffixWidth[inputSize]};
  z-index: 1;
  color: ${({ theme, mode = FieldMode.Primary }) =>
    theme.colors.field[mode].color};
`;

export const StyledInputWrapper = styled.div<StyledInputProps>`
  width: 100%;
  position: relative;
`;
