import apiRoutes from '../../../../../../setup/consts/apiRoutes';
import { makeMutationFn } from '../../../../../../utils/reactQuery';
import { MutationFn } from '../../../../../../utils/reactQuery/types';
import { ROLES_SELECT_ITEMS } from './partials/Information/consts';
import { UserFormValues } from './types';

export const USER_FORM_INITIAL_VALUES: Partial<UserFormValues> = {
  entities: [],
  fullName: '',
  phoneNumber: '',
  email: '',
  password: '',
  repeatPassword: '',
  role: ROLES_SELECT_ITEMS[0].value,
};

export const CREATE_USER_MUTATION_FN = makeMutationFn<FormData>(
  apiRoutes.USERS
);

export const MAKE_UPDATE_USER_MUTATION_FN = (
  userId: string
): MutationFn<FormData> =>
  makeMutationFn<FormData>(apiRoutes.USER_DETAILS(userId), { method: 'put' });
