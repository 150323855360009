import apiRoutes from '../../../../../../setup/consts/apiRoutes';
import { makeMutationFn } from '../../../../../../utils/reactQuery';
import { MutationFn } from '../../../../../../utils/reactQuery/types';
import { BUSINESS_TYPE_SELECT_ITEMS } from './partials/Information/consts';
import { EntityFormValues } from './types';

export const ENTITY_FORM_INITIAL_VALUES: Partial<EntityFormValues> = {
  name: '',
  phoneNumber: '',
  email: '',
  users: [],
  city: '',
  businessType: BUSINESS_TYPE_SELECT_ITEMS[0].value,
};

export const CREATE_ENTITY_MUTATION_FN = makeMutationFn<FormData>(
  apiRoutes.ENTITIES
);

export const MAKE_UPDATE_ENTITY_MUTATION_FN = (
  entityId: string
): MutationFn<FormData> =>
  makeMutationFn<FormData>(apiRoutes.ENTITY_DETAILS(entityId), {
    method: 'put',
  });
