export enum SpinnerSize {
  ExtraSmall = 'extraSmall',
  Small = 'small',
  Big = 'big',
}

export enum SpinnerMode {
  Light = 'light',
  Dark = 'dark',
}
