import React from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import routes from '../../../setup/consts/routes';
import { FormInput } from '../../../components/atoms/Fields/Input';
import Link from '../../../components/atoms/Link';
import { StyledBox } from '../../../components/styles/Grid/styles';
import { SIGN_IN_FORM_INITIAL_VALUES } from './consts';
import { useSignInSubmitHandler } from './hooks';
import { SignInFormValues } from './types';
import Typography from '../../../components/styles/Typography';
import LoginInnerLayout from '../Layout';
import { SignInButton, StyledForm, StyledParagraph } from './styles';
import useUserContext from '../../../hooks/useUserContext';

const SignInPage: React.FC = () => {
  const intl = useIntl();
  const { isUserLoading } = useUserContext();
  const signInSubmitHandler = useSignInSubmitHandler();

  return (
    <LoginInnerLayout
      title={intl.formatMessage({
        id: 'login.signIn',
        defaultMessage: 'Sign In',
      })}
    >
      <StyledBox>
        <Form<SignInFormValues>
          onSubmit={signInSubmitHandler}
          initialValues={SIGN_IN_FORM_INITIAL_VALUES}
        >
          {({ handleSubmit, submitting, submitError }) => (
            <StyledForm onSubmit={handleSubmit}>
              {submitError ? (
                <StyledBox mb='1rem' style={{ color: 'red' }}>
                  <Typography variant='body' as='p'>
                    {submitError}
                  </Typography>
                </StyledBox>
              ) : null}
              <StyledBox>
                <FormInput
                  disabled={submitting || isUserLoading}
                  label={intl.formatMessage({
                    id: 'forms.fields.emailAddress',
                    defaultMessage: 'Email Address',
                  })}
                  type='email'
                  name='email'
                />
              </StyledBox>
              <StyledBox>
                <FormInput
                  disabled={submitting || isUserLoading}
                  label={intl.formatMessage({
                    id: 'forms.fields.password',
                    defaultMessage: 'Password',
                  })}
                  type='password'
                  name='password'
                />
              </StyledBox>
              <StyledBox alignItems='center' justifyContent='space-between'>
                <SignInButton
                  type='submit'
                  disabled={submitting || isUserLoading}
                >
                  <FormattedMessage
                    id='login.signIn'
                    defaultMessage='Sign in'
                  />
                </SignInButton>
                <StyledParagraph>
                  <FormattedMessage
                    id='login.forgotPassword'
                    defaultMessage='Forgot password?'
                  />{' '}
                  <Link to={routes.RESET_PASSWORD}>
                    <FormattedMessage
                      id='login.resetPassword'
                      defaultMessage='Reset Password'
                    />{' '}
                  </Link>
                </StyledParagraph>
              </StyledBox>
            </StyledForm>
          )}
        </Form>
      </StyledBox>
      <StyledBox mt='3.5rem'>
        <StyledParagraph>
          <FormattedMessage
            id='login.noAccount'
            defaultMessage='You don’t have an account?'
          />{' '}
          <Link to={routes.SIGN_UP}>
            <FormattedMessage id='login.signUp' defaultMessage='Sign up' />
          </Link>
        </StyledParagraph>
      </StyledBox>
    </LoginInnerLayout>
  );
};

export default SignInPage;
