import styled from 'styled-components';

export const StyledPercentIconBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  text-align: end;
  justify-content: space-around;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.background.light};
  box-shadow: ${({ theme }) => theme.shadows.mid};
  grid-area: last-seven-days;
  > p {
    margin-bottom: 0.5rem;
  }
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  margin-bottom: 0.75rem;

  svg {
    width: 1em;
    height: 1em;
    font-size: 3rem;
    fill: currentColor;
  }
`;

export const StyledPercentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
`;

export const StyledPercentValue = styled.div`
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? 'margin-left: 0.75rem;'
      : 'margin-right: 0.75rem;'}
`;
