const apiRoutes = {
  ME: '/auth/me',
  SIGN_IN: '/auth/login',
  VERIFY: '/auth/mfa-login',
  SIGN_UP: '/auth/register',
  CLIENTS: '/auth/clients',
  REFRESH_TOKEN: '/auth/refresh-token',
  CLIENT_TOKEN: (clientId: string): string => `/auth/clients/${clientId}`,
  REST_PASSWORD: '/auth/reset-password',
  ENTITIES: '/entities',
  USERS: '/users',
  USER_DETAILS: (userId: string): string => `/users/${userId}`,
  ENTITY_DETAILS: (entityId: string): string => `/entities/${entityId}`,
  ACCOUNT: '/account',
  SET_PAYMENT_METHOD: '/account/set-payment-method',
  SET_PREFERRED_CURRENCY: '/account/set-preferred-currency',
  ORDERS: '/orders',
  ORDERS_EXPORT: '/orders/export',
  ORDERS_UPLOAD: '/orders/upload',
  ORDER_DETAILS: (orderId: string): string => `/orders/${orderId}`,
  UPDATE_PRODUCT: (productId: string): string => `/products/${productId}`,
  ORDER_SUMMARY_ORDER_DETAILS: (orderId: string): string =>
    `/orders/${orderId}/details`,
  GET_WAREHOUSE_PRODUCT: (productId: number): string =>
    `/products/${productId}`,
  ORDER_SHIPPING_LABEL: `/orders/shipping-label`,
  CANCEL_ORDER: (orderId: string): string => `/orders/${orderId}/cancel`,
  FEES_PROFILE_BY_ENTITY_ID: (entityId: string): string =>
    `/fees-profiles/entity/${entityId}`,
  PRODUCT_SEARCH: '/products',
  PRODUCTS: '/products',
  UPLOAD_PRODUCT_IMAGES: (productId: number): string =>
    `/products/${productId}/upload-images`,
  ORDER_TASK: (taskId: string): string => `/orders/order-task/${taskId}`,
  STATEMENTS: '/statements',
  AVAILABLE_BALANCE: '/settlements/available-balance',
  WITHDRAWN_BALANCE: '/settlements/withdrawn-balance',
  PENDING_BALANCE: '/settlements/pending-balance',
  PENDING_STATEMENT: '/statements/pending-statement',
  SETTLEMENT_DETAILS: '/settlements/settlement-details',
  SETTLEMENTS: '/settlements',
  SUBMIT_CASH_OUT: '/statements/cash-out',
  SUBMIT_DISPUTE: '/statements/submit-dispute',
  PROXY_VERSION: '/_system/version',
  CITIES_LIST: '/lists/city-codes',
  ORDER_STATUS_LIST: '/lists/order-statuses-list',
  CUSTOMERS: '/customers/search',
  GET_CUSTOMER: (customerId: string): string => `/customers/${customerId}`,
  CUSTOMER_SUMMARY: '/customers/summary',
  WEBHOOK_LOGS: (orderId: string): string => `/orders/${orderId}/webhook-logs`,
  CATEGORIES_LIST: '/categories',
  DAMAGED_PRODUCTS_TRANSACTIONS: '/transactions/damaged',
  PRODUCT_DETAILS: (productId: string): string => `/products/${productId}`,
  DELETE_PRODUCT: (productId: string): string => `/products/${productId}`,
  WAREHOUSE_REPORT: '/analytics/orders',
};

export default apiRoutes;
