import { useContext } from 'react';
import {
  HeaderTitleContext,
  HeaderTitleContextValue,
} from '../contexts/HeaderTitle';

const useHeaderTitleContext = (): HeaderTitleContextValue =>
  useContext(HeaderTitleContext);

export default useHeaderTitleContext;
