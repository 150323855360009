import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { FC } from 'react';
import Typography from '../../styles/Typography';
import { StyledWarehouseProductCardProps } from './types';
import { TypographyProps } from '../../styles/Typography/types';

export const StyledWarehouseProductCard = styled.div<StyledWarehouseProductCardProps>`
  ${({ isHorizontal }) =>
    isHorizontal
      ? css`
          display: grid;
          grid-template-columns: 4rem 2fr 1fr 1fr 1fr 2fr auto;
          grid-template-areas: 'image name ordered remaining damaged created price action';
          align-items: center;
          grid-column-gap: 4rem;
          padding: 0.625rem;
          min-height: ${({ theme }) =>
            theme.dimensions.warehouseProductCard.minHeight};
          box-shadow: ${({ theme }) => theme.shadows.small};
        `
      : css`
          display: flex;
          flex-direction: column;
          padding: 1rem;
          box-shadow: ${({ theme }) => theme.shadows.mid};
        `};
  width: 100%;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.productOrderCard.background};
  border-radius: 1rem;
`;

export const StyledWarehouseProductCardImageWrapper = styled.div<StyledWarehouseProductCardProps>`
  ${({ isHorizontal }) =>
    isHorizontal &&
    css`
      grid-area: image;
    `}
`;

export const StyledWarehouseProductLabel = styled(Typography).attrs({
  forwardedAs: 'span',
  variant: 'caption',
})`
  display: block;
  color: ${({ theme }) => rgba(theme.colors.text.primary, 0.54)};
  margin-bottom: 0.75rem;
  margin-top: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledWarehouseProductNameLabel = styled<FC<TypographyProps>>(
  Typography
).attrs({
  forwardedAs: 'span',
  variant: 'caption',
})<StyledWarehouseProductCardProps>`
  display: block;
  color: ${({ theme }) => rgba(theme.colors.text.primary, 0.54)};
  margin-bottom: 0.75rem;
  margin-top: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ isHorizontal }) =>
    isHorizontal
      ? css`
          width: 80px;
        `
      : css`
          width: 100%;
        `}
`;

export const StyledWarehouseCategoryNameLabel = styled<FC<TypographyProps>>(
  Typography
).attrs({
  forwardedAs: 'span',
  variant: 'caption',
})<StyledWarehouseProductCardProps>`
  display: block;
  color: ${({ theme }) => rgba(theme.colors.text.primary, 0.54)};
  margin-bottom: 0.75rem;
  margin-top: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ isHorizontal }) =>
    isHorizontal
      ? css`
          width: 80px;
        `
      : css`
          width: 150px;
        `}
`;

export const StyledWarehouseProductCardNameWrapper = styled.div<StyledWarehouseProductCardProps>`
  ${({ isHorizontal }) =>
    isHorizontal
      ? css`
          grid-area: name;
        `
      : css`
          order: 1;
          margin-bottom: 0.625rem;
        `}
`;

export const StyledWarehouseProductCardOrderedWrapper = styled.div<StyledWarehouseProductCardProps>`
  ${({ isHorizontal }) =>
    isHorizontal &&
    css`
      grid-area: ordered;
    `}
`;

export const StyledWarehouseProductCardRemainingUnitsWrapper = styled.div<StyledWarehouseProductCardProps>`
  ${({ isHorizontal }) =>
    isHorizontal &&
    css`
      grid-area: remaining;
    `}
`;

export const StyledWarehouseProductCardDamagedUnitsWrapper = styled.div<StyledWarehouseProductCardProps>`
  ${({ isHorizontal }) =>
    isHorizontal &&
    css`
      grid-area: damaged;
    `}
`;

export const StyledWarehouseProductCardDateCreatedWrapper = styled.div<StyledWarehouseProductCardProps>`
  ${({ isHorizontal }) =>
    isHorizontal
      ? css`
          grid-area: created;
        `
      : css`
          grid-column: span 2;
        `}
`;

export const StyledWarehouseProductCardInfoGroup = styled.div<StyledWarehouseProductCardProps>`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 1.5rem;
  margin-bottom: 1.625rem;
  order: 3;
`;

export const StyledWarehouseProductPrice = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const StyledWarehouseProductCardPriceWrapper = styled.div<StyledWarehouseProductCardProps>`
  ${({ isHorizontal }) =>
    isHorizontal
      ? css`
          grid-area: price;
        `
      : css`
          order: 2;
          margin-bottom: 1rem;
        `}
`;

export const StyledWarehouseProductCurrency = styled(Typography).attrs({
  forwardedAs: 'span',
  variant: 'currencyBold',
})`
  text-transform: uppercase;
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? 'margin-right: 0.75rem;'
      : 'margin-left: 0.75rem;'}
`;

export const StyledWarehouseProductCardAction = styled.div<StyledWarehouseProductCardProps>`
  ${({ isHorizontal }) =>
    isHorizontal
      ? css`
          grid-area: action;
        `
      : css`
          order: 5;
        `}
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 0.625rem;
`;
