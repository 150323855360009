import React from 'react';
import { ProductOrderCardProps } from './types';
import {
  StyledProductCardAction,
  StyledProductCurrency,
  StyledProductLabel,
  StyledProductDateWrapper,
  StyledProductImageWrapper,
  StyledProductNameWrapper,
  StyledProductOrderCard,
  StyledProductPrice,
  StyledProductPriceWrapper,
  StyledProductQuantityWrapper,
} from './styles';
import Button from '../../atoms/Button';
import Typography from '../../styles/Typography';
import ProductThumbnail from '../../atoms/ProductThumbnail';

const ProductOrderCard: React.FC<ProductOrderCardProps> = ({
  imageUrl,
  imageAlt,
  productNameLabel,
  productName,
  orderedQuantityLabel,
  orderedQuantity,
  dateCreatedLabel,
  dateCreated,
  priceLabel,
  price,
  currency,
  buttonLabel,
  onButtonClick,
  buttonDisabled,
}) => {
  const formattedDate = new Date(dateCreated).toLocaleDateString();
  const formattedPrice = Intl.NumberFormat().format(price);

  return (
    <StyledProductOrderCard>
      <StyledProductImageWrapper>
        <ProductThumbnail isBig src={imageUrl} alt={imageAlt} />
      </StyledProductImageWrapper>
      <StyledProductNameWrapper>
        <StyledProductLabel>{productNameLabel}</StyledProductLabel>
        <Typography as='span'>{productName}</Typography>
      </StyledProductNameWrapper>
      <StyledProductQuantityWrapper>
        <StyledProductLabel>{orderedQuantityLabel}</StyledProductLabel>
        <Typography variant='h2' as='span'>
          {orderedQuantity}
        </Typography>
      </StyledProductQuantityWrapper>
      <StyledProductDateWrapper>
        <StyledProductLabel>{dateCreatedLabel}</StyledProductLabel>
        <Typography as='span'>{formattedDate}</Typography>
      </StyledProductDateWrapper>
      <StyledProductPriceWrapper>
        <StyledProductLabel>{priceLabel}</StyledProductLabel>
        <StyledProductPrice>
          <Typography as='span'>{formattedPrice}</Typography>
          <StyledProductCurrency>{currency}</StyledProductCurrency>
        </StyledProductPrice>
      </StyledProductPriceWrapper>
      <StyledProductCardAction>
        <Button isFullWidth disabled={buttonDisabled} onClick={onButtonClick}>
          {buttonLabel}
        </Button>
      </StyledProductCardAction>
    </StyledProductOrderCard>
  );
};

export default ProductOrderCard;
