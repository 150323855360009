import React from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { IconArrowBack, IconArrowForward } from '../../../assets/svg/icons';
import { StyledPaginationWrapper } from './styles';

const Pagination: React.FC<ReactPaginateProps> = (props) => (
  <StyledPaginationWrapper>
    <ReactPaginate
      previousLabel={<IconArrowBack />}
      nextLabel={<IconArrowForward />}
      breakLabel='...'
      breakClassName='separator'
      containerClassName='pagination'
      activeClassName='active'
      {...props}
    />
  </StyledPaginationWrapper>
);

export default Pagination;
