import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconSearch } from '../../../assets/svg/icons';
import { FieldMode } from '../../../components/atoms/Fields/enums';
import Input from '../../../components/atoms/Fields/Input';
import { StyledBox } from '../../../components/styles/Grid/styles';
import Typography from '../../../components/styles/Typography';
import useHeaderTitleContext from '../../../hooks/useHeaderTitleContext';
import { FAQ_ITEMS } from './consts';
import FaqList from './partials/FaqList';
import {
  StyledForm,
  StyledSearchWrapper,
  StyledFaqTitleDescriptionWrapper,
} from './styles';

const SupportAndFaqPage: React.FC = () => {
  const { setHeaderTitle } = useHeaderTitleContext();
  const [search, setSearch] = useState<string>('');
  const intl = useIntl();

  useEffect(() => {
    setHeaderTitle(
      intl.formatMessage({
        id: 'sidebar.settings.supportAndFaq',
        defaultMessage: 'Support \u0026 FAQ',
      })
    );
  }, [intl, setHeaderTitle]);

  return (
    <>
      <StyledFaqTitleDescriptionWrapper>
        <Row>
          <Col md={6}>
            <StyledBox mb='1rem'>
              <Typography as='h2' variant='h2'>
                <FormattedMessage
                  id='faq.sandoogFrequentlyAskedQuestions'
                  defaultMessage='Sandoog Frequently Asked Questions'
                />
              </Typography>
            </StyledBox>
            <StyledBox>
              <Typography>
                <FormattedMessage
                  id='faq.description'
                  defaultMessage='Below you’ll find help with common issues - for anything else, our Client Relations team [Hyperlink to contact page] is there to help!'
                />
              </Typography>
            </StyledBox>
          </Col>
        </Row>
      </StyledFaqTitleDescriptionWrapper>
      <StyledBox mb='3.25rem'>
        <StyledForm>
          <StyledSearchWrapper>
            <Input
              mode={FieldMode.Tertiary}
              placeholder={intl.formatMessage({
                id: 'search.searchFaq',
                defaultMessage: 'Search FAQ Topics',
              })}
              isRounded
              prefixComponent={<IconSearch />}
              onChange={({ target }) => setSearch(target.value)}
              value={search}
            />
          </StyledSearchWrapper>
        </StyledForm>
      </StyledBox>
      <Row>
        <Col md={6}>
          <FaqList
            items={
              search
                ? FAQ_ITEMS.filter(
                    ({ heading, description }) =>
                      heading.toLowerCase().includes(search.toLowerCase()) ||
                      description.toLowerCase().includes(search.toLowerCase())
                  )
                : FAQ_ITEMS
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default SupportAndFaqPage;
