import { FORM_ERROR } from 'final-form';
import { useCallback } from 'react';
import { SubmitHandler } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import useUserContext from '../../../hooks/useUserContext';
import { mapApiErrorsToFormErrors } from '../../../utils/finalForm';
import {
  SET_PREFERRED_CURRENCY_MUTATION_FN,
  SET_PREFERRED_PAYMENT_METHOD_MUTATION_FN,
} from './consts';
import {
  PreferredCurrencyFormValues,
  PreferredPaymentFormValues,
} from './types';

export const useSetPreferredPaymentMethodSubmitHandler = (): SubmitHandler<PreferredPaymentFormValues> => {
  const { refetchUserData } = useUserContext();
  const { mutateAsync: setPreferredPaymentMethod } = useMutation(
    SET_PREFERRED_PAYMENT_METHOD_MUTATION_FN
  );
  const intl = useIntl();
  const { addToast } = useToasts();

  return useCallback(
    async ({ preferredPayment }) => {
      try {
        if (!preferredPayment) {
          return {
            preferredPayment: intl.formatMessage({
              id: 'billingAndPayments.paymentMethodRequired',
              defaultMessage: 'Preferred payment method is required',
            }),
          };
        }

        await setPreferredPaymentMethod({
          data: {
            preferredPayment,
          },
        });

        await refetchUserData();

        return undefined;
      } catch (error) {
        const { errors, generalError } = mapApiErrorsToFormErrors(error, intl);

        if (errors) {
          return errors;
        }

        const defaultError = intl.formatMessage({
          id: 'billingAndPayments.updatePaymentMethodFailed',
          defaultMessage: 'Failed to update payment method',
        });

        addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
          appearance: 'error',
        });

        return {
          [FORM_ERROR]: defaultError,
        };
      }
    },
    [addToast, intl, refetchUserData, setPreferredPaymentMethod]
  );
};

export const useSetPreferredCurrencySubmitHandler = (): SubmitHandler<PreferredCurrencyFormValues> => {
  const { refetchUserData } = useUserContext();
  const { mutateAsync: setPreferredPaymentMethod } = useMutation(
    SET_PREFERRED_CURRENCY_MUTATION_FN
  );
  const intl = useIntl();
  const { addToast } = useToasts();

  return useCallback(
    async ({ preferredCurrency }) => {
      try {
        if (!preferredCurrency) {
          return {
            preferredCurrency: intl.formatMessage({
              id: 'billingAndPayments.currencyRequired',
              defaultMessage: 'Preferred currency is required',
            }),
          };
        }

        await setPreferredPaymentMethod({
          data: {
            preferredCurrency,
          },
        });

        await refetchUserData();

        return undefined;
      } catch (error) {
        const { errors, generalError } = mapApiErrorsToFormErrors(error, intl);

        if (errors) {
          return errors;
        }

        const defaultError = intl.formatMessage({
          id: 'billingAndPayments.updateCurrencyFailed',
          defaultMessage: 'Failed to update preferred currency',
        });

        addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
          appearance: 'error',
        });

        return {
          [FORM_ERROR]: defaultError,
        };
      }
    },
    [addToast, intl, refetchUserData, setPreferredPaymentMethod]
  );
};
