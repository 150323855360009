import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Container, Row } from 'react-grid-system';
import { DamagedProductTransactionsProps } from './types';
import { StyledDamagedProductTransactions } from './styles';
import ModalBase from '../../organisms/Modal';
import DataTable from '../Table/dataTable';
import { GetDamagedProductTransactionsResponseSingleItem } from '../../../setup/apiTypes/products';
import apiRoutes from '../../../setup/consts/apiRoutes';
import { damagedProductTransactionsTableColumns } from './consts';
import Typography from '../../styles/Typography';
import { StyledBox } from '../../styles/Grid/styles';

const DamagedProductTransactions: React.FC<DamagedProductTransactionsProps> = (
  props
) => {
  const { productId, onClose } = props;
  const intl = useIntl();

  const tableColumns = useMemo(
    () => damagedProductTransactionsTableColumns(intl),
    [intl]
  );

  return (
    <ModalBase {...{ ...props, onClose }} withCloseButton>
      <Container>
        <Row>
          <Col md={12}>
            <StyledBox marginBottom='2rem'>
              <Typography variant='h2' as='h2'>
                <FormattedMessage
                  id='damagedTransactions'
                  defaultMessage='Damaged Transactions'
                />
              </Typography>
            </StyledBox>
          </Col>

          <Col md={12}>
            <StyledDamagedProductTransactions>
              <DataTable<GetDamagedProductTransactionsResponseSingleItem>
                endpoint={apiRoutes.DAMAGED_PRODUCTS_TRANSACTIONS}
                columns={tableColumns}
                queryOptions={{
                  cacheTime: 0,
                }}
                query={{
                  productId,
                }}
                placeholderProps={{
                  title: intl.formatMessage({
                    id: 'customers.noData',
                    defaultMessage: 'No Data Found',
                  }),
                }}
              />
            </StyledDamagedProductTransactions>
          </Col>
        </Row>
      </Container>
    </ModalBase>
  );
};

export default DamagedProductTransactions;
