import React from 'react';
import Cookies from 'universal-cookie';

export const cookies = new Cookies();

export const CookiesContext = React.createContext(cookies);

const { Provider } = CookiesContext;

export const CookieContextProvider: React.FC = ({ children }) => (
  <Provider value={cookies}>{children}</Provider>
);
