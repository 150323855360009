import { rgba } from 'polished';
import styled from 'styled-components';
import Typography from '../../styles/Typography';
import { PlaceholderSize } from './enums';
import { StyledPlaceholderProps } from './types';

export const StyledPlaceholder = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.dimensions.placeholder.maxWidth};
`;

export const StyledPlaceholderDecor = styled.div<StyledPlaceholderProps>`
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto
    ${({ size = PlaceholderSize.Big }) =>
      (size === PlaceholderSize.Big && '1.6875rem') ||
      (size === PlaceholderSize.Small && '1rem')};
  background-color: ${({ theme }) => theme.colors.placeholder.background};
  color: ${({ theme }) => rgba(theme.colors.placeholder.color, 0.6)};
  height: ${({ theme, size = PlaceholderSize.Big }) =>
    theme.dimensions.placeholder.decorWrapper[size]};
  width: ${({ theme, size = PlaceholderSize.Big }) =>
    theme.dimensions.placeholder.decorWrapper[size]};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 1em;
    width: 1em;
    fill: currentColor;
    font-size: ${({ theme, size = PlaceholderSize.Big }) =>
      theme.dimensions.placeholder.icon[size]};
  }
`;

export const StyledPlaceholderTitle = styled(
  Typography
)<StyledPlaceholderProps>`
  text-align: center;
  margin-bottom: ${({ size = PlaceholderSize.Big }) =>
    (size === PlaceholderSize.Big && '1rem') ||
    (size === PlaceholderSize.Small && '0.5rem')};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledPlaceholderDescription = styled(Typography).attrs({
  forwardedAs: 'p',
})<StyledPlaceholderProps>`
  text-align: center;
  margin-bottom: ${({ size = PlaceholderSize.Big }) =>
    (size === PlaceholderSize.Big && '2.25rem') ||
    (size === PlaceholderSize.Small && '1.6875rem')};
  color: ${({ theme }) => rgba(theme.colors.text.primary, 0.54)};
`;

export const StyledPlaceholderAction = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
