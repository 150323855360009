import React from 'react';
import { IconButtonProps } from './types';
import { StyledIconButton } from './styles';

const IconButton: React.FC<IconButtonProps> = ({
  icon: Icon,
  isActive,
  isSecondary,
  ...props
}) => (
  <StyledIconButton {...{ isActive, isSecondary, ...props }}>
    <Icon />
  </StyledIconButton>
);

export default IconButton;
