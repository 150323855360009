import React from 'react';
import { BadgeProps } from './types';
import { StyledBadge } from './styles';
import { BadgeStatus } from './enums';

const Badge: React.FC<BadgeProps> = ({
  status = BadgeStatus.Success,
  isRound,
  children,
}) => {
  const textVariant = isRound ? 'bodyBold' : 'caption';

  return (
    <StyledBadge {...{ status, isRound }} variant={textVariant}>
      {children}
    </StyledBadge>
  );
};

export default Badge;
