import styled from 'styled-components';

export const StyledProductOrderCardList = styled.ul`
  width: 100%;
  max-width: ${({ theme }) => theme.dimensions.productOrderCardList.maxWidth};
  margin: 0 auto;
  padding: 0.25rem;
`;

export const StyledProductOrderCardListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 1.125rem;
  }
`;
