import { SimpleSelectOption } from '../../../../../../../../components/atoms/Fields/Select/templates/SimpleSelect/types';
import { UserRole } from '../../../../../../../../contexts/User/enums';

export const ROLES_SELECT_ITEMS: SimpleSelectOption[] = [
  {
    label: UserRole.Admin,
    value: UserRole.Admin,
  },
  {
    label: UserRole.Supervisor,
    value: UserRole.Supervisor,
  },
  {
    label: UserRole.Agent,
    value: UserRole.Agent,
  },
  {
    label: UserRole.Limited,
    value: UserRole.Limited,
  },
];
