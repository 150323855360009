import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import qs from 'qs';
import urljoin from 'url-join';

import {
  ListingEndpointResponse,
  UseTableDataQueryOptions,
  UseTableDataReturnType,
} from './types';

export const useTableData = <T>(
  endpoint: string,
  page: number,
  pageSize: number,
  query: MappedObject<unknown>,
  pageParamKey: string,
  options?: UseTableDataQueryOptions<T>
): UseTableDataReturnType<T> => {
  const queryAsString = qs.stringify(
    {
      ...query,
      [pageParamKey]: page,
      pageSize,
    },
    {
      addQueryPrefix: true,
    }
  );
  const { isLoading, isFetching, data, refetch } = useQuery<
    UseQueryResponse<ListingEndpointResponse<T>>
  >(urljoin(endpoint, queryAsString), {
    refetchOnMount: 'always',
    ...options,
  });

  return {
    loading: isLoading || isFetching,
    refetch: useCallback(async () => {
      await refetch();
    }, [refetch]),
    data: useMemo(() => {
      if (!data) {
        return [];
      }

      const { items } = data.response;

      return items;
    }, [data]),
    pageCount: data?.response.pagesCount || 1,
    totalCount: data?.response.total,
  };
};
