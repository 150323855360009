import { AnimatePresence } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconGlobalLocation } from '../../../../assets/svg/icons';
import Button from '../../../../components/atoms/Button';
import DataTable from '../../../../components/molecules/Table/dataTable';
import { DataTableAPI } from '../../../../components/molecules/Table/types';
import { StyledBox } from '../../../../components/styles/Grid/styles';
import { GetEntitiesResponseSingleItem } from '../../../../setup/apiTypes/entities';
import apiRoutes from '../../../../setup/consts/apiRoutes';
import AddEntitiesModal from './components/Modal';
import { useEntitiesTableColumns } from './hooks';
import { EntitiesProps } from './types';

const Entities: React.FC<EntitiesProps> = ({
  searchQuery,
  isOpen,
  toggleOn,
  toggleOff,
}) => {
  const intl = useIntl();
  const [editEntityId, setEditEntityId] = useState<string>();
  const entitiesTableColumns = useEntitiesTableColumns(intl, setEditEntityId);
  const [entitiesTableAPI, setEntitiesTableAPI] = useState<DataTableAPI>();
  const onCloseClick = useCallback(() => {
    setEditEntityId(undefined);
    toggleOff();
  }, [toggleOff]);
  const filterEntities = useCallback(
    ({ name }: GetEntitiesResponseSingleItem) => {
      if (!searchQuery) {
        return true;
      }

      return name.toLowerCase().includes(searchQuery.toLowerCase());
    },
    [searchQuery]
  );

  return (
    <>
      <StyledBox mb='2.25rem' justifyContent='flex-end'>
        <Button onClick={toggleOn}>
          <FormattedMessage
            id='enitiesAndUsers.createEntity'
            defaultMessage='Create Entity'
          />
        </Button>
      </StyledBox>
      <DataTable<GetEntitiesResponseSingleItem>
        tableAPIRef={setEntitiesTableAPI}
        endpoint={apiRoutes.ENTITIES}
        columns={entitiesTableColumns}
        placeholderProps={{
          title: intl.formatMessage({
            id: 'enitiesAndUsers.addEntities',
            defaultMessage: 'Add Entities',
          }),
          description: intl.formatMessage({
            id: 'enitiesAndUsers.entitiesPlaceholderDescription',
            defaultMessage:
              'You can add multiple Entities to your business, these entities can represent sub-brands, locations, etc.',
          }),
          buttonLabel: intl.formatMessage({
            id: 'enitiesAndUsers.createNewEntity',
            defaultMessage: 'Create New Entity',
          }),
          icon: IconGlobalLocation,
          onClick: toggleOn,
        }}
        filterItems={filterEntities}
      />
      <AnimatePresence>
        {(isOpen || editEntityId) && (
          <AddEntitiesModal
            entityId={editEntityId}
            refetchEntities={entitiesTableAPI?.refetch}
            {...{ onCloseClick }}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Entities;
