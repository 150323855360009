import apiRoutes from '../../../../setup/consts/apiRoutes';
import { makeMutationFn } from '../../../../utils/reactQuery';
import { MutationFn } from '../../../../utils/reactQuery/types';

export const MAKE_DELETE_USER_MUTATION_FN = (
  userId: string
): MutationFn<FormData> =>
  makeMutationFn<FormData>(apiRoutes.USER_DETAILS(userId), {
    method: 'delete',
  });
