import React, { useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import { ThemeContext } from 'styled-components';
import Badge from '../../../../../components/atoms/Badge';
import { StyledBox } from '../../../../../components/styles/Grid/styles';
import Typography from '../../../../../components/styles/Typography';
import { UsersBadgeListProps } from './types';

const UsersBadgeList: React.FC<UsersBadgeListProps> = ({
  items,
  itemsToShow = 2,
}) => {
  const theme = useContext(ThemeContext);

  return items ? (
    <StyledBox as='ul' alignItems='center'>
      {items.slice(0, itemsToShow).map((user, index, data) => (
        <StyledBox
          as='li'
          key={user}
          mr={itemsToShow > 1 && index === 0 ? '0.25rem' : 0}
        >
          {user}
          {index !== data.length - 1 ? ',' : null}
        </StyledBox>
      ))}
      {items.length > itemsToShow && (
        <StyledBox
          ml={theme.direction === 'ltr' ? '0.625rem' : 0}
          mr={theme.direction === 'rtl' ? '0.625rem' : 0}
          flexShrink={0}
          as='li'
        >
          <div data-tip={items.join('<br/>')}>
            <Badge isRound>+{items.length - itemsToShow}</Badge>
            <ReactTooltip multiline />
          </div>
        </StyledBox>
      )}
    </StyledBox>
  ) : (
    <Typography variant='body'>-</Typography>
  );
};

export default UsersBadgeList;
