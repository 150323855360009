import { rgba } from 'polished';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { textStyles } from '../../../theme/config/typography';

export const StyledTabsNavigation = styled.nav`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.navigation.border};
`;

export const StyledTabsNavigationList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
`;

export const StyledTabsNavigationListItem = styled.li`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  &:not(:first-child) {
    margin-left: 2.75rem;
  }
`;

export const StyledTabsNavigationLink = styled(NavLink).attrs({
  variant: 'bodyBold',
  activeClassName: 'is-active',
})`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-decoration: none;
  transition: ${({ theme }) => theme.transitions.default};
  color: ${({ theme }) => theme.colors.text.secondary};
  border-width: 4px 0;
  border-style: solid;
  border-color: transparent;
  padding: 0.75rem;
  ${variant({
    variants: {
      ...textStyles,
    },
  })};
  &.is-active {
    border-bottom-color: ${({ theme }) =>
      theme.colors.navigation.item.activeBorder};
  }
  &:hover {
    border-bottom-color: ${({ theme }) =>
      rgba(theme.colors.navigation.item.activeBorder, 0.8)};
  }
`;
