import styled from 'styled-components';
import { StyledConfirmationModalProps } from './types';

export const StyledConfirmationModalIconWrapper = styled.div<StyledConfirmationModalProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.dimensions.modalPopup.iconWrapper.width};
  height: ${({ theme }) => theme.dimensions.modalPopup.iconWrapper.height};
  background-color: ${({ isWarning, theme }) =>
    isWarning
      ? theme.colors.confirmationModalIconWrapper.warning.background
      : theme.colors.confirmationModalIconWrapper.primary.background};
  color: ${({ isWarning, theme }) =>
    isWarning
      ? theme.colors.confirmationModalIconWrapper.warning.color
      : theme.colors.confirmationModalIconWrapper.primary.color};
  border-radius: 50%;
  svg {
    width: 1em;
    height: 1em;
    font-size: 1rem;
    fill: currentColor;
  }
`;

export const StyledConfirmationModalAction = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  grid-column-gap: 1rem;

  & > button:only-child {
    margin-right: -1rem;
  }
`;
