import React, { useContext } from 'react';
import ContentLoader from 'react-content-loader';
import { ThemeContext } from 'styled-components';

const WarehouseAddProductTemplate: React.FC = () => {
  const theme = useContext(ThemeContext);

  return (
    <ContentLoader
      speed={2}
      width={1189}
      height={578}
      viewBox='0 0 1189 578'
      backgroundColor={theme.colors.loader.background}
      foregroundColor={theme.colors.loader.foreground}
    >
      <rect x='2' y='0' rx='10' width='125' height='20' />
      <rect x='611' y='0' rx='10' ry='10' width='125' height='20' />
      <rect x='611' y='288' rx='10' ry='10' width='125' height='20' />
      <rect x='611' y='324' rx='10' ry='10' width='125' height='20' />
      <rect x='611' y='401' rx='10' ry='10' width='125' height='20' />
      <rect x='611' y='355' rx='10' ry='10' width='498' height='20' />
      <rect x='611' y='432' rx='10' ry='10' width='498' height='20' />
      <rect x='2' y='90' rx='10' ry='10' width='125' height='20' />
      <rect x='2' y='419' rx='10' ry='10' width='125' height='20' />
      <rect x='2' y='510' rx='10' ry='10' width='125' height='20' />
      <rect x='2' y='184' rx='10' ry='10' width='125' height='20' />
      <rect x='0' y='30' rx='16' ry='16' width='553' height='38' />
      <rect x='0' y='120' rx='16' ry='16' width='553' height='38' />
      <rect x='0' y='449' rx='16' ry='16' width='553' height='38' />
      <rect x='0' y='540' rx='16' ry='16' width='553' height='38' />
      <rect x='0' y='211' rx='16' ry='16' width='448' height='186' />
      <rect x='611' y='31' rx='16' ry='16' width='225' height='226' />
      <rect x='846' y='31' rx='16' ry='16' width='107' height='107' />
      <rect x='846' y='147' rx='16' ry='16' width='107' height='107' />
      <rect x='964' y='31' rx='16' ry='16' width='107' height='107' />
      <rect x='964' y='147' rx='16' ry='16' width='107' height='107' />
      <rect x='1082' y='31' rx='16' ry='16' width='107' height='107' />
      <rect x='1082' y='147' rx='16' ry='16' width='107' height='107' />
    </ContentLoader>
  );
};

export default WarehouseAddProductTemplate;
