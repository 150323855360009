import React, { useContext } from 'react';
import ContentLoader from 'react-content-loader';
import { ThemeContext } from 'styled-components';

const WarehouseGridListTemplate: React.FC = () => {
  const theme = useContext(ThemeContext);

  return (
    <ContentLoader
      speed={2}
      width={1188}
      height={1045}
      viewBox='0 0 1188 1045'
      backgroundColor={theme.colors.loader.background}
      foregroundColor={theme.colors.loader.foreground}
    >
      <rect x='0' y='0' rx='16' ry='16' width='279' height='511' />
      <rect x='0' y='534' rx='16' ry='16' width='279' height='511' />
      <rect x='303' y='0' rx='16' ry='16' width='279' height='511' />
      <rect x='303' y='534' rx='16' ry='16' width='279' height='511' />
      <rect x='606' y='0' rx='16' ry='16' width='279' height='511' />
      <rect x='606' y='534' rx='16' ry='16' width='279' height='511' />
      <rect x='909' y='0' rx='16' ry='16' width='279' height='511' />
      <rect x='909' y='534' rx='16' ry='16' width='279' height='511' />
    </ContentLoader>
  );
};

export default WarehouseGridListTemplate;
