import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ltrTheme, rtlTheme } from '.';
import useLanguageContext from '../hooks/useLanguageContext';
import SupportedLanguages from '../setup/intl/enums';
import GlobalStyle from './GlobalStyles';

const ThemeProvider: React.FC = ({ children }) => {
  const { language } = useLanguageContext();
  const theme = language === SupportedLanguages.Arabic ? rtlTheme : ltrTheme;

  return (
    <StyledThemeProvider {...{ theme }}>
      <GlobalStyle />
      {children}
    </StyledThemeProvider>
  );
};

export default ThemeProvider;
