import React, { useEffect, useMemo } from 'react';
import { Form } from 'react-final-form';
import { Row, Col } from 'react-grid-system';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../../components/atoms/Button';
import { FormRadioList } from '../../../components/atoms/Fields/Radio';
import renderDefaultRadioItem from '../../../components/atoms/Fields/Radio/content/default';
import { StyledBox } from '../../../components/styles/Grid/styles';
import Typography from '../../../components/styles/Typography';
import useHeaderTitleContext from '../../../hooks/useHeaderTitleContext';
import useUserContext from '../../../hooks/useUserContext';
import {
  PreferredCurrency,
  PreferredPayment,
} from '../../../setup/apiTypes/enums';
import { currencyRadioItems, paymentMethodRadioItems } from './consts';
import {
  useSetPreferredPaymentMethodSubmitHandler,
  useSetPreferredCurrencySubmitHandler,
} from './hooks';
import { StyledRadioListWrapper } from './styles';
import {
  PreferredCurrencyFormValues,
  PreferredPaymentFormValues,
} from './types';

const BillingAndPaymentsPage: React.FC = () => {
  const { user } = useUserContext();
  const { setHeaderTitle } = useHeaderTitleContext();
  const intl = useIntl();
  const paymentMethodItems = useMemo(() => paymentMethodRadioItems(intl), [
    intl,
  ]);
  const setPreferredPaymentMethodSubmitHandler = useSetPreferredPaymentMethodSubmitHandler();
  const setPreferredCurrencySubmitHandler = useSetPreferredCurrencySubmitHandler();

  useEffect(() => {
    setHeaderTitle(
      intl.formatMessage({
        id: 'sidebar.settings.billingAndPayments',
        defaultMessage: 'Billing \u0026 Payments',
      })
    );
  }, [intl, setHeaderTitle]);

  return (
    <>
      <Row>
        <Col md={3}>
          <Form<PreferredPaymentFormValues>
            onSubmit={setPreferredPaymentMethodSubmitHandler}
            initialValues={
              user
                ? {
                    preferredPayment:
                      user.billingAndPayment.preferredPayment || undefined,
                  }
                : undefined
            }
          >
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <StyledBox mb='2.625rem'>
                  <Typography variant='body' as='h3'>
                    <FormattedMessage
                      id='billingAndPayments.selectYourPreferredPaymentMethod'
                      defaultMessage='Select your preferred payment method'
                    />
                  </Typography>
                </StyledBox>
                <StyledRadioListWrapper>
                  <FormRadioList<PreferredPayment>
                    disabled={submitting}
                    name='preferredPayment'
                    items={paymentMethodItems}
                    renderRadioContent={renderDefaultRadioItem}
                  />
                </StyledRadioListWrapper>
                <StyledBox mt='1.5rem'>
                  <Button type='submit' disabled={submitting}>
                    <FormattedMessage
                      id='forms.buttons.save'
                      defaultMessage='Save'
                    />
                  </Button>
                </StyledBox>
              </form>
            )}
          </Form>
        </Col>
        <Col md={3}>
          <Form<PreferredCurrencyFormValues>
            onSubmit={setPreferredCurrencySubmitHandler}
            initialValues={
              user
                ? {
                    preferredCurrency:
                      user.billingAndPayment.preferredCurrency || undefined,
                  }
                : undefined
            }
          >
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <StyledBox mb='2.625rem'>
                  <Typography variant='body' as='h3'>
                    <FormattedMessage
                      id='billingAndPayments.selectYourPreferredCurrency'
                      defaultMessage='Select your preferred currency'
                    />
                  </Typography>
                </StyledBox>
                <StyledRadioListWrapper>
                  <FormRadioList<PreferredCurrency>
                    disabled={submitting}
                    name='preferredCurrency'
                    items={currencyRadioItems}
                    renderRadioContent={renderDefaultRadioItem}
                  />
                </StyledRadioListWrapper>
                <StyledBox mt='1.5rem'>
                  <Button type='submit' disabled={submitting}>
                    <FormattedMessage
                      id='forms.buttons.save'
                      defaultMessage='Save'
                    />
                  </Button>
                </StyledBox>
              </form>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default BillingAndPaymentsPage;
