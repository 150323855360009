import jwtDecode from 'jwt-decode';
import { GetMeResponse } from '../../setup/apiTypes/auth';
import apiRoutes from '../../setup/consts/apiRoutes';
import SupportedLanguages from '../../setup/intl/enums';
import { defaultQueryFunction } from '../../utils/reactQuery';
import { AuthTokensShape, JWTTokenData, User } from './types';

const languageStringToLanguageMapping: Partial<
  MappedObject<SupportedLanguages>
> = {
  English: SupportedLanguages.English,
  Arabic: SupportedLanguages.Arabic,
};

export const fetchUser = async (authTokens: AuthTokensShape): Promise<User> => {
  const { authToken, sdgAuthToken } = authTokens;
  const {
    'https://sdg.iq.auth/sdg_roles': roles,
    permissions,
    'https://sdg.iq.auth/sdg_user': isSandoogUser,
    'https://sdg.iq.auth/sdg_client_permission_type': userType,
  } = jwtDecode<JWTTokenData>(sdgAuthToken || authToken);
  const { response: user } = await defaultQueryFunction<GetMeResponse>({
    queryKey: [apiRoutes.ME],
  });
  const {
    accountDetails: { language, ...accountDetails },
  } = user;
  const userLangauge =
    languageStringToLanguageMapping[language || ''] ||
    SupportedLanguages.English;

  return {
    permissions,
    roles,
    ...user,
    isSandoogUser,
    isAdvancedUser: userType === 'Advanced',
    accountDetails: {
      ...accountDetails,
      language: userLangauge,
    },
  };
};
