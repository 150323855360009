import { AnimatePresence } from 'framer-motion';
import noop from 'lodash/noop';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconCustomer2 } from '../../../../assets/svg/icons';
import Button from '../../../../components/atoms/Button';
import DataTable from '../../../../components/molecules/Table/dataTable';
import { DataTableAPI } from '../../../../components/molecules/Table/types';
import ConfirmationModal from '../../../../components/organisms/Modal/templates/ConfirmationModal';
import { StyledBox } from '../../../../components/styles/Grid/styles';
import useToggle from '../../../../hooks/useToggle';
import { GetUsersResponseSingleItem } from '../../../../setup/apiTypes/users';
import apiRoutes from '../../../../setup/consts/apiRoutes';
import AddUsersModal from './components/Modal';
import { useOnDeleteUserClickHandler, useUsersTableColumns } from './hooks';
import { UsersProps } from './types';

const Users: React.FC<UsersProps> = ({
  searchQuery,
  isOpen,
  toggleOn,
  toggleOff,
}) => {
  const [
    isConfirmationModalOpen,
    {
      toggleOn: toggleConfirmationModalOn,
      toggleOff: toggleConfirmationModalOff,
    },
  ] = useToggle();
  const intl = useIntl();
  const [userId, setUserId] = useState<string>();
  const [usersTableAPI, setUsersTableAPI] = useState<DataTableAPI>();
  const usersTableColumns = useUsersTableColumns(
    intl,
    (id) => {
      setUserId(id);
      toggleOn();
    },
    (id) => {
      setUserId(id);
      toggleConfirmationModalOn();
    }
  );

  const deleteUser = useOnDeleteUserClickHandler(
    userId,
    usersTableAPI?.refetch || noop,
    toggleConfirmationModalOff
  );
  const onCloseClick = useCallback(() => {
    setUserId(undefined);
    toggleOff();
  }, [toggleOff]);

  const onConfirmationModalCloseClick = useCallback(() => {
    setUserId(undefined);
    toggleConfirmationModalOff();
  }, [toggleConfirmationModalOff]);

  const filterUsers = useCallback(
    ({ name }: GetUsersResponseSingleItem) => {
      if (!searchQuery) {
        return true;
      }

      return name.toLowerCase().includes(searchQuery.toLowerCase());
    },
    [searchQuery]
  );

  return (
    <>
      <StyledBox mb='2.25rem' justifyContent='flex-end'>
        <Button onClick={toggleOn}>
          <FormattedMessage
            id='enitiesAndUsers.createUser'
            defaultMessage='Create User'
          />
        </Button>
      </StyledBox>
      <DataTable<GetUsersResponseSingleItem>
        endpoint={apiRoutes.USERS}
        columns={usersTableColumns}
        placeholderProps={{
          title: intl.formatMessage({
            id: 'enitiesAndUsers.addUsers',
            defaultMessage: 'Add Users',
          }),
          description: intl.formatMessage({
            id: 'enitiesAndUsers.usersPlaceholderDescription',
            defaultMessage:
              'You can add and assign roles to users and associate them with one or more of your business entities.',
          }),
          buttonLabel: intl.formatMessage({
            id: 'enitiesAndUsers.createNewUsers',
            defaultMessage: 'Create New User',
          }),
          icon: IconCustomer2,
          onClick: toggleOn,
        }}
        tableAPIRef={setUsersTableAPI}
        filterItems={filterUsers}
      />
      <AnimatePresence>
        {isOpen && (
          <AddUsersModal
            {...{ onCloseClick }}
            refetchUsers={usersTableAPI?.refetch}
            userId={userId}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isConfirmationModalOpen && (
          <ConfirmationModal
            title={intl.formatMessage({
              id: 'enitiesAndUsers.deleteUserModal.title',
              defaultMessage: 'Delete user',
            })}
            description={intl.formatMessage({
              id: 'enitiesAndUsers.Cancel.description',
              defaultMessage:
                'Are you sure that you want to delete this user? This action is irreversible.',
            })}
            confirmButtonLabel={intl.formatMessage({
              id: 'forms.buttons.delete',
              defaultMessage: 'Delete',
            })}
            cancelButtonLabel={intl.formatMessage({
              id: 'forms.buttons.cancel',
              defaultMessage: 'Cancel',
            })}
            onConfirmButtonClick={deleteUser}
            onClose={onConfirmationModalCloseClick}
            isWarning
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Users;
