import { AnimatePresence } from 'framer-motion';
import { noop } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  IconBankGood,
  IconBankInfo,
  IconBankMoney,
  IconFinance,
} from '../../assets/svg/icons';
import Button from '../../components/atoms/Button';
import SimpleSelect from '../../components/atoms/Fields/Select/templates/SimpleSelect';
import Loader from '../../components/atoms/Loader';
import { LoaderMode } from '../../components/atoms/Loader/enums';
import BalanceBox from '../../components/molecules/BalanceBox';
import Placeholder from '../../components/molecules/Placeholder';
import { PlaceholderSize } from '../../components/molecules/Placeholder/enums';
import Table from '../../components/molecules/Table';
import FinancialsEntitySelectModal from '../../components/organisms/Modal/templates/FinancialsEntitySelectModal';
import TransactionSummaryModal from '../../components/organisms/Modal/templates/TransactionsSummaryModal';
import { StyledBox, StyledGridBox } from '../../components/styles/Grid/styles';
import Typography from '../../components/styles/Typography';
import useHeaderTitleContext from '../../hooks/useHeaderTitleContext';
import useToggle from '../../hooks/useToggle';
import { statementsTableColumns, yearDropdownOptions } from './consts';
import { EntityContextProvider } from './EntityContext';
import {
  useAvailableBalance,
  usePendingBalance,
  useStatementList,
  useWithdrawnBalance,
  useYearDropdownHandlers,
} from './hooks';
import WithdrawnBalancesModal from '../../components/organisms/Modal/templates/WithdrawnBalancesModal';

const FinancialsPage: React.FC = () => {
  const intl = useIntl();

  const { setHeaderTitle } = useHeaderTitleContext();
  const [selectedYear, handleYearChange] = useYearDropdownHandlers();
  const { statements, loading } = useStatementList(selectedYear);

  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const [
    isEntitySelectModalOpen,
    {
      toggleOn: toggleEntitySelectModalOn,
      toggleOff: toggleEntitySelectModalOff,
    },
  ] = useToggle();
  const [
    viewWithdrawnDetailsModal,
    {
      toggleOn: toggleViewWithdrawnDetailsModalOn,
      toggleOff: toggleViewWithdrawnDetailsModalOff,
    },
  ] = useToggle();

  const {
    pendingBalance,
    isLoading: pendingBalanceLoading,
    currency: pendingBalanceCurrency,
  } = usePendingBalance();
  const {
    availableBalance,
    isLoading: availableBalanceLoading,
    currency: availableBalanceCurrency,
  } = useAvailableBalance();
  const {
    withdrawnBalance,
    isLoading: withdrawnBalanceLoading,
    currency: withdrawnBalanceCurrency,
    withdrawnBalances,
  } = useWithdrawnBalance();

  const tableColumns = useMemo(() => statementsTableColumns(intl), [intl]);

  useEffect(() => {
    setHeaderTitle(
      intl.formatMessage({
        id: 'mainNavigation.financials',
        defaultMessage: 'Financials',
      })
    );
  }, [intl, setHeaderTitle]);

  const balanceLoading =
    availableBalanceLoading || pendingBalanceLoading || withdrawnBalanceLoading;

  return (
    <EntityContextProvider>
      <StyledBox mb='3.75rem'>
        {balanceLoading ? (
          <Loader mode={LoaderMode.FinancialBalanceBoxes} />
        ) : (
          <StyledGridBox gridTemplateColumns='1fr 1fr 1fr' gridGap='1.375rem'>
            <BalanceBox
              icon={IconBankGood}
              price={availableBalance}
              currency={availableBalanceCurrency}
              title={intl.formatMessage({
                id: 'financials.currentBalance',
                defaultMessage: 'Current Balance',
              })}
            >
              <Button onClick={toggleEntitySelectModalOn}>
                <FormattedMessage
                  id='financials.cashOut'
                  defaultMessage='Cash Out'
                />
              </Button>
            </BalanceBox>
            <BalanceBox
              icon={IconBankInfo}
              price={pendingBalance}
              currency={pendingBalanceCurrency}
              title={intl.formatMessage({
                id: 'financials.pendingBalance',
                defaultMessage: 'Pending Balance',
              })}
            />
            <BalanceBox
              icon={IconBankMoney}
              price={withdrawnBalance}
              currency={withdrawnBalanceCurrency}
              title={intl.formatMessage({
                id: 'financials.withdrawnBalance',
                defaultMessage: 'Withdrawn Balance',
              })}
            >
              <Button onClick={toggleViewWithdrawnDetailsModalOn}>
                <FormattedMessage
                  id='financials.viewDetails'
                  defaultMessage='View Details'
                />
              </Button>
            </BalanceBox>
          </StyledGridBox>
        )}
      </StyledBox>
      <StyledGridBox
        mb='2rem'
        gridTemplateColumns='auto 128px'
        css={{
          alignItems: 'center',
        }}
      >
        <Typography as='h2' variant='h2'>
          <FormattedMessage
            id='financials.previousStatments'
            defaultMessage='Previous Statements'
          />
        </Typography>

        <SimpleSelect
          disabled={loading}
          value={selectedYear}
          onChange={handleYearChange}
          options={yearDropdownOptions}
        />
      </StyledGridBox>
      {statements.length ? (
        <>
          {loading ? (
            <Loader mode={LoaderMode.Table} />
          ) : (
            <Table columns={tableColumns} {...{ data: statements }} />
          )}
        </>
      ) : (
        <StyledBox justifyContent='center'>
          <Placeholder
            icon={IconFinance}
            title={intl.formatMessage({
              id: 'search.noResults',
              defaultMessage: 'No results',
            })}
            size={PlaceholderSize.Big}
            onClick={noop}
          />
        </StyledBox>
      )}
      <AnimatePresence>
        {isOpen && (
          <TransactionSummaryModal
            onClose={() => {
              toggleEntitySelectModalOff();
              toggleOff();
            }}
            withCloseButton
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isEntitySelectModalOpen && (
          <FinancialsEntitySelectModal
            onClose={toggleEntitySelectModalOff}
            onCashOut={toggleOn}
            withCloseButton
          />
        )}
        {viewWithdrawnDetailsModal && (
          <WithdrawnBalancesModal
            onClose={toggleViewWithdrawnDetailsModalOff}
            withdrawnBalances={withdrawnBalances}
            withCloseButton
          />
        )}
      </AnimatePresence>
    </EntityContextProvider>
  );
};

export default FinancialsPage;
