import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { StyledLogoProps } from './types';

export const StyledLogoWrapper = styled.div<StyledLogoProps>`
  svg {
    height: 1em;
    width: 0.7em;
    font-size: ${({ isBig }) => (isBig ? '8.6875rem' : '5.75rem')};
  }
`;

export const StyledLogo = styled.div`
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.background.light};
  border-radius: 1rem;
  overflow: hidden;
`;

export const StyledLogoLink = styled(Link)`
  outline: none;
  text-decoration: none;
  transition: ${({ theme }) => theme.transitions.default};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.9;
  }
`;
