import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { StyledModalProps } from './types';

export const StyledModal = styled(motion.div)<StyledModalProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zindexes.modal};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.modal};
  padding-top: 5.875rem;
  display: flex;
  flex-direction: column;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  > div {
    box-shadow: ${({ theme }) => theme.shadows.modal};
  }
`;

export const StyledModalContent = styled(motion.div)<StyledModalProps>`
  flex-grow: 1;
  background-color: ${({ isPopup, theme }) =>
    isPopup ? theme.colors.background.light : theme.colors.background.default};
  overflow: ${({ isPopup }) => (isPopup ? 'visible' : 'auto')};
  padding: ${({ isPopup }) => (isPopup ? '1.5rem' : '3rem 0')};
  ${({ isPopup }) =>
    isPopup &&
    css`
      border-radius: 1rem;
      box-shadow: ${({ theme }) => theme.shadows.small};
    `};
`;

export const StyledModalPopupInner = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.dimensions.modalPopup.maxWidth};
  height: 100%;
  margin: 0 auto;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
`;

export const StyledModalPopupContentBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 5.875rem;
  overflow-y: auto;
`;

export const StyledModalPopupContent = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

export const StyledModalAction = styled.div`
  position: sticky;
  margin-right: 3rem;
  top: 0;
  width: 3.5rem;
  left: 100vw;
  z-index: 999;
  :dir(rtl) {
    left: 0;
    right: 100vw;
    margin-right: 0;
    margin-left: 3rem;
  }
`;

export const StyledButtonClose = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: ${({ theme }) => theme.dimensions.buttonClose.width};
  height: ${({ theme }) => theme.dimensions.buttonClose.height};
  background-color: ${({ theme }) => theme.colors.button.secondary.background};
  color: ${({ theme }) => theme.colors.button.secondary.color};
  cursor: pointer;
  border: 0;
  border-radius: 100%;
  outline: none;
  transition: ${({ theme }) => theme.transitions.default};
  &:hover {
    opacity: 0.7;
  }
  svg {
    width: 1em;
    height: 1em;
    font-size: 0.875rem;
    fill: currentColor;
  }
`;
