import axios, { AxiosRequestConfig } from 'axios';
import { first } from 'lodash';
import merge from 'lodash/merge';
import { QueryFunctionContext } from 'react-query';
import urlJoin from 'url-join';

import { MutationFn } from './types';

const {
  NODE_ENV,
  REACT_APP_API_URL = NODE_ENV === 'development'
    ? 'http://localhost:1337/sandoog-center-api'
    : '',
} = process.env;

export const makeApiUrl = (url: string): string =>
  urlJoin(REACT_APP_API_URL, url);

const mutationFunctionsCache: Partial<MappedObject<MutationFn<unknown>>> = {};

export const makeMutationFn = <T, P = unknown>(
  url: string,
  config: Omit<AxiosRequestConfig, 'url' | 'data'> = {}
): MutationFn<T, P> => {
  const requestMethod = config.method || 'post';
  const cacheKey = `${url}|${requestMethod}`;
  const cachedMutationFunction = mutationFunctionsCache[cacheKey];

  if (cachedMutationFunction) {
    return cachedMutationFunction as MutationFn<T, P>;
  }

  const mutationFnBody: MutationFn<T, P> = async (
    callConfig = {}
  ): Promise<UseMutationResponse<P>> => {
    const { data } = callConfig;
    const mergedConfig = merge(config, callConfig);
    const { data: responseData, headers } = await axios({
      ...mergedConfig,
      url: makeApiUrl(callConfig.url || url),
      method: config.method || 'post',
      data,
    });

    return {
      response: responseData as P,
      headers,
    };
  };

  mutationFunctionsCache[cacheKey] = mutationFnBody as MutationFn<unknown>;

  return mutationFnBody;
};

export const defaultQueryFunction = async <T>({
  queryKey,
}: QueryFunctionContext): Promise<UseQueryResponse<T>> => {
  const requestPath = Array.isArray(queryKey) ? first(queryKey) : queryKey;
  const { data, headers } = await axios.get(
    makeApiUrl(typeof requestPath === 'string' ? requestPath : '')
  );

  return {
    response: data as T,
    headers,
  };
};
