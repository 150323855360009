import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { HeaderTitleContextProvider } from '../../../contexts/HeaderTitle';
import MultiSelect from '../../atoms/Fields/MultiSelect';
import Logo from '../../atoms/Logo';
import Header from '../../organisms/Header';
import SideMenu from '../../molecules/SideMenu';
import {
  StyledLayoutBaseDropdown,
  StyledLayoutBaseLogo,
  StyledLayoutBaseMain,
  StyledLayoutBaseSidebar,
  StyledLayoutBaseWrapper,
  StyledSwitcherBox,
} from './styles';
import {
  useClientsDropdownItems,
  useClientSelectValue,
  useOnClientSelectChange,
  useOnLogoutClick,
  useRefetchQueriesOnClientChange,
} from './hooks';
import useUserContext from '../../../hooks/useUserContext';
import { LayoutBaseProps } from './types';
import usePathMatchesRoute from '../../../hooks/usePathMatchesRoute';
import routes from '../../../setup/consts/routes';
import { useAuthTokens } from '../../../contexts/User/hooks';
import IconButtonLink from '../../atoms/IconButton/iconButtonLink';
import { IconStats, IconWarehouse2 } from '../../../assets/svg/icons';
import { StyledBox } from '../../styles/Grid/styles';
import Spinner from '../../atoms/Spinner';
import { SpinnerMode } from '../../atoms/Spinner/enums';
import renderRadioItemWithCheckboxes from '../../atoms/Fields/Radio/content/withCheckboxes';

const LayoutBase: React.FC<LayoutBaseProps> = ({
  children,
  items,
  settingsItems,
  ...props
}) => {
  const intl = useIntl();
  const onLogout = useOnLogoutClick();
  const { user } = useUserContext();
  const {
    items: clientSelectItems,
    fetchMore,
    hasNextPage,
  } = useClientsDropdownItems();
  const onClientSelectChange = useOnClientSelectChange();
  const value = useClientSelectValue();
  const isSettingsSection = usePathMatchesRoute(
    routes.SETTINGS_SECTION,
    undefined,
    false
  );
  const isWarehousePage = usePathMatchesRoute(routes.WAREHOUSE, undefined);
  const isReportPage = usePathMatchesRoute(routes.REPORT, undefined);
  const authTokens = useAuthTokens();

  useRefetchQueriesOnClientChange(value?.value, value?.checkedSubItems);

  const selectedClient = useMemo(
    () =>
      clientSelectItems.find(
        ({ value: clientId }) => clientId === value?.value
      ),
    [value?.value, clientSelectItems]
  );

  const renderSelectedItems = useCallback(
    (selected) =>
      selectedClient
        ? renderRadioItemWithCheckboxes(selected)({
            ...selectedClient,
            checked: true,
          })
        : null,
    [selectedClient]
  );

  const SwitcherComponent = (isReportPage || isWarehousePage) && (
    <StyledSwitcherBox>
      <IconButtonLink
        isActive={isWarehousePage}
        isSecondary
        icon={IconWarehouse2}
        to={routes.WAREHOUSE}
      />
      <IconButtonLink
        isActive={isReportPage}
        isSecondary
        icon={IconStats}
        to={routes.REPORT}
      />
    </StyledSwitcherBox>
  );

  return (
    <HeaderTitleContextProvider>
      <StyledLayoutBaseWrapper>
        <StyledLayoutBaseSidebar>
          <StyledLayoutBaseLogo>
            <Logo to={routes.ORDERS} />
          </StyledLayoutBaseLogo>
          {(!isSettingsSection || authTokens?.sdgAuthToken) && (
            <StyledLayoutBaseDropdown>
              <MultiSelect
                placeholder='Select channel'
                onChange={onClientSelectChange}
                items={clientSelectItems}
                infiniteScrollProps={{
                  dataLength: clientSelectItems.length,
                  hasMore: hasNextPage,
                  loader: (
                    <StyledBox
                      justifyContent='center'
                      alignItems='center'
                      p='2rem'
                    >
                      <Spinner mode={SpinnerMode.Dark} />
                    </StyledBox>
                  ),
                  next: fetchMore,
                  scrollableTarget: 'clients',
                }}
                {...{ value, renderSelectedItems }}
              />
            </StyledLayoutBaseDropdown>
          )}
          <SideMenu {...{ items, ...props }} />
        </StyledLayoutBaseSidebar>
        <StyledLayoutBaseMain id='main-content'>
          <Header
            menu={{
              items: settingsItems,
              title: intl.formatMessage({
                id: 'sidebar.settings.title',
                defaultMessage: 'Settings',
              }),
              onLogout,
            }}
            avatar={
              user && user.accountDetails.logo
                ? {
                    src: user.accountDetails.logo,
                    alt: user.name,
                  }
                : undefined
            }
            centerComponent={SwitcherComponent}
          />
          {children}
        </StyledLayoutBaseMain>
      </StyledLayoutBaseWrapper>
    </HeaderTitleContextProvider>
  );
};

export default LayoutBase;
