import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';

import {
  useBlockBodyWhenModalIsOpened,
  useModalContainer,
  useModalContextValue,
} from './hooks';
import {
  StyledButtonClose,
  StyledModal,
  StyledModalAction,
  StyledModalContent,
  StyledModalPopupContent,
  StyledModalPopupContentBox,
  StyledModalPopupInner,
} from './styles';
import { IconClose } from '../../../assets/svg/icons';
import { ModalBaseProps } from './types';
import { ModalContext } from './consts';
import { uuidv4 } from '../../../utils/lodash';

const ModalProvider: React.FC = ({ children }) => {
  const [modalContainerHasChildren, setModalContainerHasChildren] =
    useState(false);
  const { modalContextValue, modalContainerRef } = useModalContextValue();

  useBlockBodyWhenModalIsOpened(modalContainerRef.current);
  useEffect(() => {
    const modalNode = modalContainerRef.current;

    const observerCallback = (mutations: MutationRecord[]): void => {
      mutations.forEach((mutation) => {
        setModalContainerHasChildren(
          mutation.type === 'childList' && !!mutation.target.childNodes.length
        );
      });
    };

    const observer = new MutationObserver(observerCallback);

    if (modalNode) {
      observer.observe(modalNode, { childList: true });
    }

    return (): void => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const variants = {
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
      },
    },
    hidden: { opacity: 0 },
  };

  return (
    <>
      <StyledModal
        initial='hidden'
        animate={modalContainerHasChildren ? 'visible' : 'hidden'}
        transition={{ duration: 0.2, type: 'tween' }}
        isVisible={modalContainerHasChildren}
        {...{ variants }}
      >
        <div ref={modalContainerRef} />
      </StyledModal>

      <ModalContext.Provider value={modalContextValue}>
        {children}
      </ModalContext.Provider>
    </>
  );
};

const ModalBase: React.FC<ModalBaseProps> = ({
  children,
  onClose,
  withCloseButton,
  isPopup,
}) => {
  const focusTrapInnerWrapperIdRef = useRef<string>(
    `focus-trap-wrapper-${uuidv4()}`
  );
  const modalNode = useModalContainer();
  const modalContent = (
    <StyledModalContent
      key='modal-content'
      initial={isPopup ? { opacity: 0 } : { y: '100%' }}
      animate={
        isPopup
          ? { opacity: 1 }
          : {
              y: 0,
            }
      }
      exit={isPopup ? { opacity: 0 } : { y: '100%' }}
      transition={{ duration: 0.2, type: 'tween' }}
      id='modal-content'
      {...{ isPopup }}
    >
      <FocusTrap
        focusTrapOptions={{
          fallbackFocus: `#${focusTrapInnerWrapperIdRef.current}`,
        }}
      >
        <div id={focusTrapInnerWrapperIdRef.current}>
          {onClose && withCloseButton && (
            <StyledModalAction>
              <StyledButtonClose type='button' onClick={onClose}>
                <IconClose />
              </StyledButtonClose>
            </StyledModalAction>
          )}

          <div>{children}</div>
        </div>
      </FocusTrap>
    </StyledModalContent>
  );

  return modalNode
    ? ReactDOM.createPortal(
        isPopup ? (
          <StyledModalPopupInner>
            <StyledModalPopupContentBox>
              <StyledModalPopupContent>{modalContent}</StyledModalPopupContent>
            </StyledModalPopupContentBox>
          </StyledModalPopupInner>
        ) : (
          modalContent
        ),
        modalNode
      )
    : null;
};

export { ModalProvider, ModalBase as default };
