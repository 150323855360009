import styled from 'styled-components';

export const StyledKeyPerformanceStats = styled.div`
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background.medium};
  border-radius: 1rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  box-shadow: ${({ theme }) => theme.shadows.mid};
`;

export const StyledKeyPerformanceStatsHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const StatsBox = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.light};
  border-radius: 1rem;
  padding: 2rem 5rem 2rem 1.5rem;
  box-shadow: ${({ theme }) => theme.shadows.mid};
  display: flex;
  gap: 35px;
`;

export const StatsChartsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: end;
`;

export const CashFlowWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2.5rem;
`;

export const OrdersBarsWrapper = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const CitySelectWrapper = styled.div`
  min-width: 12rem;
  z-index: 2;
`;
