export default {
  black: '#000',
  white: '#fff',
  red: '#EB5757',
  pink: '#FAF0F0',
  midPink: '#FF82AC',
  orange: '#FFAA2A',
  lightOrange: '#FFBB38',
  yellow: '#F2C94C',
  blue: '#2D9CDB',
  lightBlue: '#51C9E3',
  dodgerBlue: '#396AFF',
  gray: '#606060',
  grayMid: '#E9EEF0',
  sandPrimaryBlue: '#090F37',
  sandPrimaryGreen: '#77B05C',
  sandPrimaryGray: '#CBCACA',
  midBlue: '#3A3F60',
  darkBlue: '#181818',
  backgroundGray: '#F5F6FA',
  backgroundLightGray: '#FBFBFB',
  blueGrayMid: '#E7EAF5',
  blueGrayMid2: '#e7eff5',
  turquoise: '#15DBCC',
  lightGreen: '#E7F2E2AB',
  lightGrey: '#ECEEF5',
};
