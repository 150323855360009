import { mapCurrencyToCurrencyCode } from '../../../../../utils/misc';
import { EntityListTableItem, SettlementDetailsSingleItem } from './types';

export const settlementDetailsToEntityListTableItemMapperFn = ({
  entityId,
  entityName,
  availableBalance,
  pendingBalance,
  statementDetails,
  currency,
}: SettlementDetailsSingleItem): EntityListTableItem => ({
  idCol: entityId,
  nameCol: entityName,
  currency,
  availableBalanceCol: availableBalance,
  pendingBalanceCol: statementDetails
    ? {
        ...statementDetails,
        currency: mapCurrencyToCurrencyCode(statementDetails.currency),
        pendingBalance,
      }
    : pendingBalance,
});
