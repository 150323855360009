import { FORM_ERROR } from 'final-form';
import { useCallback } from 'react';
import { SubmitHandler } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import useUserContext from '../../../hooks/useUserContext';
import apiRoutes from '../../../setup/consts/apiRoutes';
import SupportedLanguages from '../../../setup/intl/enums';
import { transformObjectToFormData } from '../../../utils/api';
import { mapApiErrorsToFormErrors } from '../../../utils/finalForm';
import { UPDATE_ACCOUNT_MUTATION_FN } from './consts';
import { AccountFormValues } from './types';

const languageToLanguageStringMapping: MappedObject<
  string,
  SupportedLanguages
> = {
  [SupportedLanguages.English]: 'English',
  [SupportedLanguages.Arabic]: 'Arabic',
};

export const useAccountFormSubmitHandler = (): SubmitHandler<AccountFormValues> => {
  const { mutateAsync: updateAccount } = useMutation(
    UPDATE_ACCOUNT_MUTATION_FN
  );
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { addToast } = useToasts();
  const { refetchUserData } = useUserContext();

  return useCallback(
    async (values) => {
      const { logo, language, ...data } = values;
      const formData = transformObjectToFormData({
        language: languageToLanguageStringMapping[language],
        ...data,
        ...(logo && typeof logo !== 'string' ? { logo } : {}),
      });

      try {
        await updateAccount({
          data: formData,
        });

        refetchUserData();
        queryClient.refetchQueries(apiRoutes.CLIENTS);
        addToast(
          intl.formatMessage({
            id: 'account.accountUpdatedMessage',
            defaultMessage: 'Account updated successfuly',
          }),
          {
            appearance: 'success',
          }
        );

        return undefined;
      } catch (error) {
        const { errors, generalError } = mapApiErrorsToFormErrors(error, intl);

        if (errors) {
          return errors;
        }

        const defaultError = intl.formatMessage({
          id: 'account.updateAccountFailed',
          defaultMessage: 'Failed to update account',
        });

        addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
          appearance: 'error',
        });

        return {
          [FORM_ERROR]: defaultError,
        };
      }
    },
    [addToast, intl, updateAccount, refetchUserData, queryClient]
  );
};
