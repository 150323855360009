import { useMemo } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import useUserContext from '../../hooks/useUserContext';
import routes from '../../setup/consts/routes';
import { SideMenuItem } from '../molecules/SideMenu/types';
import { menuItemPermissionsMap } from './MainLayout/consts';
import { SideMenuItemDefinition } from './types';

export const useSideMenuItems = (
  items: SideMenuItemDefinition[]
): SideMenuItem[] => {
  const intl = useIntl();
  const { user } = useUserContext();

  return useMemo(() => {
    const { permissions = [] } = user ?? {};

    const navItemsArray = items.filter((item) => {
      const requiredPermission =
        menuItemPermissionsMap[item.to as keyof typeof routes];

      return !requiredPermission || permissions.includes(requiredPermission);
    });

    return navItemsArray.map((item) => ({
      ...item,
      label: intl.formatMessage(item.label as MessageDescriptor),
    }));
  }, [intl, items, user]);
};
