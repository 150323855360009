import React from 'react';
import { QueryClientProvider } from 'react-query';
import { ScreenClassProvider, setConfiguration } from 'react-grid-system';
import { ToastProvider } from 'react-toast-notifications';

import AppRoutes from './AppRoutes';
import IntlProviderWrapper from '../../setup/intl/IntlProviderWrapper';
import { LanguageContextProvider } from '../../contexts/Language';
import { ModalProvider } from '../../components/organisms/Modal';
import reactQueryClient from './reactQueryClient';
import { CookieContextProvider } from '../../contexts/Cookies';
import { UserContextProvider } from '../../contexts/User';
import ThemeProvider from '../../theme/ThemeProvider';
import { GlobalLoaderProvider } from '../../contexts/GlobalLoader';

setConfiguration({
  gutterWidth: 64,
  containerWidths: [540, 740, 960, 1308, 1308],
});

const App: React.FC = () => (
  <ToastProvider autoDismiss>
    <CookieContextProvider>
      <QueryClientProvider client={reactQueryClient}>
        <UserContextProvider>
          {(isLoading) =>
            isLoading ? null : (
              <LanguageContextProvider>
                <IntlProviderWrapper>
                  <ScreenClassProvider>
                    <ThemeProvider>
                      <ModalProvider>
                        <GlobalLoaderProvider>
                          <AppRoutes />
                        </GlobalLoaderProvider>
                      </ModalProvider>
                    </ThemeProvider>
                  </ScreenClassProvider>
                </IntlProviderWrapper>
              </LanguageContextProvider>
            )
          }
        </UserContextProvider>
      </QueryClientProvider>
    </CookieContextProvider>
  </ToastProvider>
);

export default App;
