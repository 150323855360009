import React from 'react';
import { useIntl } from 'react-intl';
import { FormInput } from '../../../../../../../../components/atoms/Fields/Input';
import { FormSimpleSelect } from '../../../../../../../../components/atoms/Fields/Select/templates/SimpleSelect';
import { StyledBox } from '../../../../../../../../components/styles/Grid/styles';
import { useCitiesList } from '../../../../../../../../hooks/useCitiesList';
import { BUSINESS_TYPE_SELECT_ITEMS } from './consts';
import { InformationProps } from './types';

const Information: React.FC<InformationProps> = ({ disabled }) => {
  const intl = useIntl();

  const { list: options } = useCitiesList();

  return (
    <>
      <StyledBox>
        <FormInput
          name='name'
          label={intl.formatMessage({
            id: 'forms.fields.name',
            defaultMessage: 'Name',
          })}
          {...{ disabled }}
        />
      </StyledBox>
      <StyledBox>
        <FormInput
          name='phoneNumber'
          label={intl.formatMessage({
            id: 'forms.fields.phone',
            defaultMessage: 'Phone',
          })}
          type='tel'
          {...{ disabled }}
        />
      </StyledBox>
      <StyledBox>
        <FormInput
          name='email'
          label={intl.formatMessage({
            id: 'forms.fields.email',
            defaultMessage: 'Email',
          })}
          type='email'
          {...{ disabled }}
        />
      </StyledBox>
      <StyledBox>
        <FormSimpleSelect
          name='city'
          label={intl.formatMessage({
            id: 'forms.fields.city',
            defaultMessage: 'City',
          })}
          {...{ disabled, options }}
        />
      </StyledBox>
      <StyledBox>
        <FormSimpleSelect
          name='businessType'
          options={BUSINESS_TYPE_SELECT_ITEMS}
          label={intl.formatMessage({
            id: 'forms.fields.businessType',
            defaultMessage: 'Business Type',
          })}
          {...{ disabled }}
        />
      </StyledBox>
      {/* Idk what this is, commented for now */}
      {/* <StyledBox>
        <Select
          options={selectDemoItems}
          label={intl.formatMessage({
            id: 'forms.fields.productType',
            defaultMessage: 'Product Type',
          })}
        />
      </StyledBox> */}
    </>
  );
};

export default Information;
