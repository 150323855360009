import { useQuery } from 'react-query';
import { WebhookLogsResponse, UseWebhookLogsReturnType } from './types';
import apiRoutes from '../../../../../setup/consts/apiRoutes';

export const useWebhookLogs = (orderId: string): UseWebhookLogsReturnType => {
  const { data, isLoading } = useQuery<UseQueryResponse<WebhookLogsResponse>>(
    apiRoutes.WEBHOOK_LOGS(orderId),
    {
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      },
    }
  );

  return {
    data: data?.response || [],
    loading: isLoading,
  };
};
