import React from 'react';
import { FormattedNumber, IntlShape } from 'react-intl';
import { ColumnDef } from '@tanstack/react-table';
import Tag from '../../components/atoms/Tag';
import { TagStatus } from '../../components/atoms/Tag/enums';
import { GetOrdersResponseSingleItem } from '../../setup/apiTypes/orders';
import { makeDateCellRenderer } from '../../utils/tableRenderers';
import { useStatusOptions } from './hooks';
import { OrdersTableItem } from './types';

export const ORDER_STATUS_ALL = 'all';

export const ordersTableData: OrdersTableItem[] = [
  {
    productNameCol: '6 Products',
    dateCol: '01/02/2020',
    customerPhoneCol: '0345898',
    cityCol: 'Baghdad',
    receiptNoCol: '587458656',
    orderStatusCol: <Tag status={TagStatus.Success}>Placed</Tag>,
    valueIqdCol: 60000,
  },
  {
    productNameCol: '3 Products',
    dateCol: '01/02/2020',
    customerPhoneCol: '0345898',
    cityCol: 'Baghdad',
    receiptNoCol: '587458656',
    orderStatusCol: <Tag status={TagStatus.Warning}>Out For Delivery</Tag>,
    valueIqdCol: 25000,
  },
  {
    productNameCol: '3 Products',
    dateCol: '01/02/2020',
    customerPhoneCol: '0345898',
    cityCol: 'Baghdad',
    receiptNoCol: '587458656',
    orderStatusCol: <Tag status={TagStatus.Error}>Order Resolution</Tag>,
    valueIqdCol: 25000,
  },
  {
    productNameCol: '3 Products',
    dateCol: '01/02/2020',
    customerPhoneCol: '0345898',
    cityCol: 'Baghdad',
    receiptNoCol: '587458656',
    orderStatusCol: <Tag status={TagStatus.Error}>Order Resolution</Tag>,
    valueIqdCol: 25000,
  },
  {
    productNameCol: '3 Products',
    dateCol: '01/02/2020',
    customerPhoneCol: '0345898',
    cityCol: 'Baghdad',
    receiptNoCol: '587458656',
    orderStatusCol: <Tag status={TagStatus.Info}>Delivered</Tag>,
    valueIqdCol: 25000,
  },
  {
    productNameCol: '3 Products',
    dateCol: '01/02/2020',
    customerPhoneCol: '0345898',
    cityCol: 'Baghdad',
    receiptNoCol: '587458656',
    orderStatusCol: <Tag status={TagStatus.Success}>Placed</Tag>,
    valueIqdCol: 25000,
  },
  {
    productNameCol: '3 Products',
    dateCol: '01/02/2020',
    customerPhoneCol: '0345898',
    cityCol: 'Baghdad',
    receiptNoCol: '587458656',
    orderStatusCol: <Tag status={TagStatus.Success}>Placed</Tag>,
    valueIqdCol: 25000,
  },
  {
    productNameCol: '3 Products',
    dateCol: '01/02/2020',
    customerPhoneCol: '0345898',
    cityCol: 'Baghdad',
    receiptNoCol: '587458656',
    orderStatusCol: <Tag status={TagStatus.Success}>Placed</Tag>,
    valueIqdCol: 25000,
  },
  {
    productNameCol: '3 Products',
    dateCol: '01/02/2020',
    customerPhoneCol: '0345898',
    cityCol: 'Baghdad',
    receiptNoCol: '587458656',
    orderStatusCol: <Tag status={TagStatus.Warning}>Out For Delivery</Tag>,
    valueIqdCol: 25000,
  },
  {
    productNameCol: '3 Products',
    dateCol: '01/02/2020',
    customerPhoneCol: '0345898',
    cityCol: 'Baghdad',
    receiptNoCol: '587458656',
    orderStatusCol: <Tag status={TagStatus.Error}>Order Resolution</Tag>,
    valueIqdCol: 25000,
  },
  {
    productNameCol: '3 Products',
    dateCol: '01/02/2020',
    customerPhoneCol: '0345898',
    cityCol: 'Baghdad',
    receiptNoCol: '587458656',
    orderStatusCol: <Tag status={TagStatus.Error}>Order Resolution</Tag>,
    valueIqdCol: 25000,
  },
  {
    productNameCol: '3 Products',
    dateCol: '01/02/2020',
    customerPhoneCol: '0345898',
    cityCol: 'Baghdad',
    receiptNoCol: '587458656',
    orderStatusCol: <Tag status={TagStatus.Info}>Delivered</Tag>,
    valueIqdCol: 25000,
  },
  {
    productNameCol: '3 Products',
    dateCol: '01/02/2020',
    customerPhoneCol: '0345898',
    cityCol: 'Baghdad',
    receiptNoCol: '587458656',
    orderStatusCol: <Tag status={TagStatus.Success}>Placed</Tag>,
    valueIqdCol: 25000,
  },
  {
    productNameCol: '3 Products',
    dateCol: '01/02/2020',
    customerPhoneCol: '0345898',
    cityCol: 'Baghdad',
    receiptNoCol: '587458656',
    orderStatusCol: <Tag status={TagStatus.Success}>Placed</Tag>,
    valueIqdCol: 25000,
  },
  {
    productNameCol: '3 Products',
    dateCol: '01/02/2020',
    customerPhoneCol: '0345898',
    cityCol: 'Baghdad',
    receiptNoCol: '587458656',
    orderStatusCol: <Tag status={TagStatus.Success}>Placed</Tag>,
    valueIqdCol: 25000,
  },
];

export const ordersTableColumns = (
  intl: IntlShape
): ColumnDef<GetOrdersResponseSingleItem>[] => [
  {
    header: intl.formatMessage({
      id: 'orders.customerName',
      defaultMessage: 'Customer Name',
    }),
    accessorKey: 'name',
  },
  {
    header: intl.formatMessage({
      id: 'orders.date',
      defaultMessage: 'Date',
    }),
    accessorKey: 'createdAt',
    cell: makeDateCellRenderer(),
  },
  {
    header: intl.formatMessage({
      id: 'orders.lastUpdated',
      defaultMessage: 'Last Updated',
    }),
    accessorKey: 'lastUpdatedAt',
    cell: makeDateCellRenderer(),
  },
  {
    header: intl.formatMessage({
      id: 'orders.customerPhone',
      defaultMessage: 'Customer Phone',
    }),
    accessorKey: 'phoneNumber',
  },
  {
    header: intl.formatMessage({
      id: 'orders.city',
      defaultMessage: 'City',
    }),
    accessorKey: 'city',
  },
  {
    header: intl.formatMessage({
      id: 'orders.receiptNo',
      defaultMessage: 'Receipt No.',
    }),
    accessorKey: 'receiptNumber',
  },
  {
    header: intl.formatMessage({
      id: 'orders.orderStatus',
      defaultMessage: 'Order Status',
    }),
    accessorKey: 'status',
    cell: ({ cell: { getValue } }) => {
      const { options } = useStatusOptions();
      const { color, label } =
        options.find((option) => option.value === getValue()) ?? {};

      return <Tag {...{ color }}>{label}</Tag>;
    },
  },
  {
    header: intl.formatMessage({
      id: 'orders.valueIQD',
      defaultMessage: 'Value IQD',
    }),
    accessorKey: 'value',
    cell: ({ cell: { getValue } }) => (
      <FormattedNumber value={getValue() as number} />
    ),
  },
];
