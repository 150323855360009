import React, { ReactNode } from 'react';
import { IntlShape } from 'react-intl';
import { ColumnDef } from '@tanstack/react-table';
import { DashboardOrderTableItem } from './types';
import { StyledBox } from '../../styles/Grid/styles';
import { WarningIcon } from '../../../assets/svg/icons';
import Typography from '../../styles/Typography';

export const dashboardOrderTableColumns = (
  intl: IntlShape
): ColumnDef<DashboardOrderTableItem>[] => [
  {
    header: intl.formatMessage({
      id: 'orders.productName',
      defaultMessage: 'Product Name',
    }),
    accessorKey: 'productName',
    cell: ({ cell: { getValue } }) => (
      <StyledBox gap='1rem'>
        <WarningIcon />
        <Typography>{getValue() as ReactNode}</Typography>
      </StyledBox>
    ),
  },
  {
    header: intl.formatMessage({
      id: 'orders.customerName',
      defaultMessage: 'Customer Name',
    }),
    accessorKey: 'customerName',
  },
  {
    header: intl.formatMessage({
      id: 'orders.receiptNo',
      defaultMessage: 'Receipt No.',
    }),
    accessorKey: 'receiptNo',
  },
  {
    header: intl.formatMessage({
      id: 'products.price',
      defaultMessage: 'Price',
    }),
    accessorKey: 'price',
  },
];

export const tableDate: DashboardOrderTableItem[] = [
  {
    productName: 'Nike Air Force 1 "07"',
    customerName: 'Kareem Abas',
    receiptNo: 587458656,
    price: 587458656,
  },
  {
    productName: 'Nike Air Force 1 "07"',
    customerName: 'Kareem Abas',
    receiptNo: 587458656,
    price: 587458656,
  },
  {
    productName: 'Nike Air Force 1 "07"',
    customerName: 'Kareem Abas',
    receiptNo: 587458656,
    price: 587458656,
  },
  {
    productName: 'Nike Air Force 1 "07"',
    customerName: 'Kareem Abas',
    receiptNo: 587458656,
    price: 587458656,
  },
  {
    productName: 'Nike Air Force 1 "07"',
    customerName: 'Kareem Abas',
    receiptNo: 587458656,
    price: 587458656,
  },
];
