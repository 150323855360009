import React, { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';
import Avatar from '../../atoms/Avatar';
import { AvatarSize } from '../../atoms/Avatar/enums';
import { AvatarUploadProps, FormAvatarUploadProps } from './types';
import {
  StyledAvatarUpload,
  StyledAvatarUploadLabel,
  StyledAvatarUploadInput,
} from './styles';
import { IconUpload } from '../../../assets/svg/icons';

const AvatarUpload: React.FC<AvatarUploadProps> = ({
  isBig,
  label,
  value,
  onChange,
}) => {
  const imageUrl = useMemo(() => {
    if (!value) {
      return undefined;
    }

    return typeof value === 'string' ? value : URL.createObjectURL(value);
  }, [value]);

  const handleImageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      const { files } = event.target;

      if (!files || !onChange) {
        return;
      }

      const file = files[0];

      onChange(file);
    },
    [onChange]
  );

  return (
    <StyledAvatarUpload>
      <StyledAvatarUploadInput onChange={handleImageChange} />
      <Avatar
        noBorder
        src={imageUrl}
        size={isBig ? AvatarSize.Big : AvatarSize.Medium}
        placeholder={<IconUpload />}
      />
      <StyledAvatarUploadLabel>{label}</StyledAvatarUploadLabel>
    </StyledAvatarUpload>
  );
};

export default AvatarUpload;

export const FormAvatarUpload: React.FC<FormAvatarUploadProps> = ({
  name,
  ...props
}) => (
  <Field<File | string | undefined> {...{ name }}>
    {({ input }) => (
      <AvatarUpload
        {...props}
        value={input.value}
        onChange={(file) => input.onChange(file)}
      />
    )}
  </Field>
);
