export const dispatchGlobalEvent = (
  eventName: string,
  {
    bubbles = false,
    cancelable = false,
    detail = undefined,
    composed = false,
  }: StorageGlobalEvent = {}
): CustomEvent => {
  let event;
  if (typeof window.Event === 'function') {
    event = new window.CustomEvent(eventName, {
      bubbles,
      cancelable,
      composed,
      detail,
    });
  } else {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(eventName, bubbles, cancelable, detail);
  }

  window.dispatchEvent(event);
  return event;
};
