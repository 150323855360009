import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useQuery } from 'react-query';
import { SimpleSelectOption } from '../../../../../components/atoms/Fields/Select/templates/SimpleSelect/types';
import useDidUpdateEffect from '../../../../../hooks/useDidUpdateEffect';
import { GetEntitiesResponseSingleItem } from '../../../../../setup/apiTypes/entities';
import { GetFeesProfileByEntityIdResponse } from '../../../../../setup/apiTypes/feesProfiles';
import apiRoutes from '../../../../../setup/consts/apiRoutes';
import { OrderFormValues } from '../../types';
import { UseFeesProfileDataReturnType } from './types';

export const useEntitySelectItems = (): SimpleSelectOption[] => {
  const { data } = useQuery<
    UseQueryResponse<{ items: GetEntitiesResponseSingleItem[] }>
  >(apiRoutes.ENTITIES);

  return useMemo(() => {
    if (!data) {
      return [];
    }

    return data.response.items.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [data]);
};

export const useFeesProfileData = (): UseFeesProfileDataReturnType => {
  const {
    values: { entity, city, deliveryRegion },
  } = useFormState<OrderFormValues>();
  const { mutators } = useForm<OrderFormValues>();
  const { data, error } = useQuery<
    UseQueryResponse<GetFeesProfileByEntityIdResponse>
  >(apiRoutes.FEES_PROFILE_BY_ENTITY_ID(entity || ''), {
    enabled: !!entity,
    retry: false,
    keepPreviousData: false,
  });

  useDidUpdateEffect(() => {
    if (entity) {
      mutators.setValue('city', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);
  useDidUpdateEffect(() => {
    mutators.setValue('deliveryRegion', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  return {
    deliveryChargeOptions: useMemo(() => {
      if (!data || !city) {
        return [];
      }

      const { deliveryDestinationFees } = data.response;
      const deliveryDestinationFee = deliveryDestinationFees.find(
        ({ cityCode }) => cityCode === city
      );

      if (!deliveryDestinationFee) {
        return [];
      }

      return Object.entries(deliveryDestinationFee).reduce<
        SimpleSelectOption[]
      >((result, [key, value]) => {
        if (!key.endsWith('Fee') || typeof value !== 'number') {
          return result;
        }

        const optionValue = key.replace('Fee', '');

        return [
          ...result,
          {
            value: optionValue,
            label: startCase(optionValue),
          },
        ];
      }, []);
    }, [city, data]),
    currency: data?.response.currency,
    deliveryFee: useMemo(() => {
      if (!data || !city || !deliveryRegion) {
        return undefined;
      }

      const { deliveryDestinationFees } = data.response;
      const deliveryDestinationFee = deliveryDestinationFees.find(
        ({ cityCode }) => cityCode === city
      );

      if (!deliveryDestinationFee) {
        return undefined;
      }

      return get(deliveryDestinationFee, `${deliveryRegion}Fee`);
    }, [city, data, deliveryRegion]),
    entityHasNoFeesProfile: !!error,
  };
};
