import React, { Component } from 'react';
import noop from 'lodash/noop';
import set from 'lodash/set';
import Autosuggest, {
  ChangeEvent,
  RenderInputComponentProps,
  RenderSuggestion,
  RenderSuggestionsContainerParams,
  SuggestionsFetchRequestedParams,
  GetSuggestionValue,
} from 'react-autosuggest';
import { FormattedMessage } from 'react-intl';
import { IconClose, IconSearch } from '../../../../assets/svg/icons';
import reactQueryClient from '../../../../pages/app/reactQueryClient';
import Placeholder from '../../../molecules/Placeholder';
import { PlaceholderSize } from '../../../molecules/Placeholder/enums';
import Button from '../../Button';
import { ButtonMode } from '../../Button/enums';
import Spinner from '../../Spinner';
import { SpinnerMode } from '../../Spinner/enums';
import { FieldMode } from '../enums';
import Input from '../Input';
import {
  StyledAutocompleteContent,
  StyledAutocompleteContentAction,
  StyledAutocompleteContentWrapper,
  StyledAutocompleteInput,
  StyledAutocompleteLoader,
  StyledAutocompleteText,
  StyledAutocompleteWrapper,
  StyledClearButton,
} from './styles';
import { AutocompleteProps, BaseAutocompleteItem } from './types';

class Autocomplete<
  V,
  R,
  T extends BaseAutocompleteItem<V> = BaseAutocompleteItem<V>
> extends Component<
  AutocompleteProps<V, R, T>,
  { value: string; suggestions: T[]; isLoading: boolean }
> {
  inputRef: HTMLInputElement | null = null;

  constructor(props: AutocompleteProps<V, R, T>) {
    super(props);

    this.state = {
      value: '',
      isLoading: false,
      suggestions: [],
    };
  }

  onChange = (
    _: React.FormEvent<HTMLElement>,
    { newValue }: ChangeEvent
  ): void => {
    const { onInputChange } = this.props;

    this.setState(
      {
        value: newValue,
      },
      () => {
        onInputChange?.(newValue);
      }
    );
  };

  shouldRenderSuggestions = (): boolean => true;

  onSuggestionsFetchRequested = async ({
    value,
  }: SuggestionsFetchRequestedParams): Promise<void> => {
    const {
      mapApiResponseToSuggestions,
      endpoint,
      searchFieldKey = 'search',
    } = this.props;

    this.setState({
      isLoading: true,
    });

    try {
      const { response } = await reactQueryClient.fetchQuery<
        UseQueryResponse<R>
      >(`${endpoint}?${searchFieldKey}=${value}?pageSize=20`);

      this.setState({
        suggestions: mapApiResponseToSuggestions(response),
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  onSuggestionsClearRequested = (): void => {
    if (this.inputRef && this.inputRef.value.length) {
      this.inputRef.blur();
    }

    this.setState({
      suggestions: [],
    });
  };

  getSuggestionValue: GetSuggestionValue<T> = ({ label }) => label;

  render(): JSX.Element {
    const { value, suggestions, isLoading } = this.state;
    const {
      buttonLabel,
      onButtonClick,
      onClearButtonClick,
      placeholder = '',
      onSuggestionClick = noop,
    } = this.props;
    const inputProps = {
      placeholder,
      value: value || '',
      onChange: this.onChange,
    };

    const renderSuggestion: RenderSuggestion<T> = ({ label }) => (
      <StyledAutocompleteText>{label}</StyledAutocompleteText>
    );

    const renderSuggestionsContainer = ({
      containerProps,
      children,
    }: RenderSuggestionsContainerParams) => {
      const { placeholderProps } = this.props;

      return (
        <StyledAutocompleteContentWrapper {...containerProps}>
          <StyledAutocompleteContent>
            {suggestions.length < 1 &&
            !isLoading &&
            onButtonClick &&
            placeholderProps ? (
              <Placeholder
                onClick={(event) => {
                  if (this.inputRef) {
                    this.inputRef.disabled = true;
                    onButtonClick(event);
                    this.inputRef.disabled = false;
                  } else {
                    onButtonClick(event);
                  }
                }}
                size={PlaceholderSize.Small}
                {...{ buttonLabel, ...placeholderProps }}
              />
            ) : (
              <>
                {suggestions.length < 1 && !isLoading ? (
                  <Placeholder
                    size={PlaceholderSize.Small}
                    title={
                      <FormattedMessage
                        id='search.noResults'
                        defaultMessage='No Results'
                      />
                    }
                  />
                ) : (
                  <>
                    {isLoading ? (
                      <StyledAutocompleteLoader>
                        <Spinner mode={SpinnerMode.Dark} />
                      </StyledAutocompleteLoader>
                    ) : (
                      <>
                        {children}
                        {(() => {
                          setTimeout(() => {
                            if (this.inputRef && !children) {
                              this.inputRef.blur();
                            }
                          });
                        })()}
                        <StyledAutocompleteContentAction>
                          <Button
                            mode={ButtonMode.Secondary}
                            onClick={onButtonClick}
                            type='button'
                          >
                            {buttonLabel}
                          </Button>
                        </StyledAutocompleteContentAction>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </StyledAutocompleteContent>
        </StyledAutocompleteContentWrapper>
      );
    };

    const renderInputComponent = ({
      className,
      ref,
      ...props
    }: RenderInputComponentProps) => (
      <StyledAutocompleteInput {...{ className }}>
        <Input
          mode={FieldMode.Tertiary}
          isRounded
          prefixComponent={<IconSearch />}
          ref={(inputRef) => {
            if (ref) {
              if (typeof ref === 'function') {
                ref(inputRef);
              } else {
                set(ref, 'current', inputRef);
              }
            }

            this.inputRef = inputRef;
          }}
          {...props}
          suffixComponent={
            onClearButtonClick && (
              <StyledClearButton type='button' onClick={onClearButtonClick}>
                <IconClose />
              </StyledClearButton>
            )
          }
        />
      </StyledAutocompleteInput>
    );

    return (
      <StyledAutocompleteWrapper>
        <Autosuggest<T>
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          multiSection={false}
          onSuggestionSelected={(_, { suggestion }) => {
            onSuggestionClick(suggestion);

            setTimeout(() => {
              const { clearInputOnSuggestionClick = false } = this.props;

              if (this.inputRef) {
                this.inputRef.blur();
              }

              if (clearInputOnSuggestionClick) {
                this.setState({
                  value: '',
                });
              }
            });
          }}
          getSuggestionValue={this.getSuggestionValue}
          {...{
            inputProps,
            suggestions,
            renderSuggestion,
            renderSuggestionsContainer,
            renderInputComponent,
          }}
        />
      </StyledAutocompleteWrapper>
    );
  }
}

export default Autocomplete;
