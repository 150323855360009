import styled from 'styled-components';

export const StyledCustomerLastOrderCard = styled.div`
  background-color: #fff;
  display: flex;
  width: 100%;
  padding: 1.5rem;
  justify-content: space-between;
  box-shadow: ${({ theme }) => theme.shadows.small};
  border-radius: 1rem;
`;
