import React, { useContext } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ThemeContext } from 'styled-components';
import {
  demoDataChartLabels,
  demoDataChartNumbers,
} from '../../../SalesByCityBox/consts';
import {
  CitySalesCircle,
  CitySalesLabel,
  DashboardSalesByCityChartStyledWrapper,
} from './styles';
import { StyledBox } from '../../../../styles/Grid/styles';
import Typography from '../../../../styles/Typography';

const DashboardSalesByCityChart: React.FC = () => {
  const theme = useContext(ThemeContext);
  const data = {
    labels: demoDataChartLabels,
    datasets: [
      {
        data: demoDataChartNumbers,
        backgroundColor: [
          theme.colors.doughnutChart.second,
          theme.colors.doughnutChart.first,
          theme.colors.doughnutChart.third,
          theme.colors.doughnutChart.fourth,
        ],
        borderWidth: 0,
      },
    ],
  };
  const options = {
    maintainAspectRatio: true,
    cutout: '25%',

    plugins: {
      legend: {
        position: 'right',
        align: 'center',
        onClick: null,
        display: false,
        labels: {
          padding: 20,
          boxWidth: 12,
          boxHeight: 12,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            family: theme.fonts.default,
            size: theme.fontSizes[2],
            weight: theme.fontWeights[0],
          },
          color: theme.colors.text.primary,
        },
      },
      tooltip: {
        boxWidth: 16,
        boxHeight: 16,
        usePointStyle: true,
        pointStyle: 'circle',
        padding: 8,
        bodyFont: {
          family: theme.fonts.default,
          size: theme.fontSizes[2],
          weight: theme.fontWeights[1],
          lineHeight: 'normal',
        },
      },
    },
  };

  const colorsMap: { [c: string]: string } = {
    Baghdad: theme.colors.doughnutChart.first,
    Erbil: theme.colors.doughnutChart.second,
    Najaf: theme.colors.doughnutChart.third,
    Anbar: theme.colors.doughnutChart.fourth,
  };

  const valuesMap: { [c: string]: string } = {
    Baghdad: '48',
    Erbil: '10',
    Najaf: '18',
    Anbar: '36',
  };

  return (
    <StyledBox justifyContent='space-evenly' width='100%' alignItems='center'>
      <DashboardSalesByCityChartStyledWrapper>
        {/* @ts-ignore */}
        <Doughnut {...{ data, options }} />
      </DashboardSalesByCityChartStyledWrapper>

      <StyledBox flexDirection='column' gap='1.1rem'>
        {demoDataChartLabels.map((c) => (
          <CitySalesLabel key={c}>
            <CitySalesCircle background={colorsMap[c]} />
            <Typography>
              {c} ({valuesMap[c]}%)
            </Typography>
          </CitySalesLabel>
        ))}
      </StyledBox>
    </StyledBox>
  );
};

export default DashboardSalesByCityChart;
