import { useCallback, useMemo } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import qs from 'qs';
import axios from 'axios';
import { get } from 'lodash';
import urljoin from 'url-join';
import { useIntl } from 'react-intl';
import { useToasts } from 'react-toast-notifications';
import apiRoutes from '../../setup/consts/apiRoutes';
import {
  UseDeleteOrderReturnType,
  UseProductDetailsReturnType,
  UseWarehouseProductsParams,
  UseWarehouseProductsReturn,
} from './types';
import { GetProductsResponseSingleItem } from '../../setup/apiTypes/products';
import { makeApiUrl } from '../../utils/reactQuery';
import { ShowProductResponse } from '../../setup/apiTypes/warehouse';
import { mapApiErrorsToFormErrors } from '../../utils/finalForm';
import { DELETE_PRODUCT_MUTATION_FN } from './consts';

interface PageResult<T> {
  items: T;
  pageIndex: number;
  pageSize: number;
  pagesCount: number;
  total: number;
}

export const useWarehouseProducts = (
  params: UseWarehouseProductsParams
): UseWarehouseProductsReturn => {
  const queryAsString = qs.stringify(
    {
      ...params,
    },
    {
      addQueryPrefix: true,
    }
  );

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery<
      UseQueryResponse<PageResult<GetProductsResponseSingleItem[]>>
    >(
      [urljoin(apiRoutes.PRODUCTS, queryAsString), params],
      async ({ queryKey, pageParam }: any) => {
        const [_, variables] = queryKey;
        const { data: res, headers } = await axios.get(
          makeApiUrl(apiRoutes.PRODUCTS),
          {
            params: {
              ...params,
              query: get(variables, 'query'),
              pageIndex: pageParam,
              pageSize: get(variables, 'pageSize'),
              ascending: true,
            },
          }
        );

        return {
          response: res,
          headers,
        };
      },
      {
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage.response.pageIndex + 1 <= lastPage.response.pagesCount
            ? lastPage.response.pageIndex + 1
            : undefined,
      }
    );

  const fetchMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return useMemo(() => {
    if (!data) {
      return {
        items: [],
        hasMore: false,
        isLoading,
        isFetching,
        fetchMore,
        refetch,
      };
    }

    return {
      items: data.pages.reduce(
        (acc, page) => [...acc, ...page.response.items],
        [] as GetProductsResponseSingleItem[]
      ),
      hasMore: !!hasNextPage,
      isLoading,
      isFetching,
      fetchMore,
      refetch,
    };
  }, [data, hasNextPage, isLoading, isFetching, fetchMore, refetch]);
};

export const useProductDetails = (
  productId: number
): UseProductDetailsReturnType => {
  const intl = useIntl();
  const { addToast } = useToasts();

  const { data, isLoading } = useQuery<UseQueryResponse<ShowProductResponse>>(
    apiRoutes.GET_WAREHOUSE_PRODUCT(productId),
    {
      enabled: !!productId,
      onError: (error) => {
        const { generalError } = mapApiErrorsToFormErrors(error, intl);

        const defaultError = intl.formatMessage({
          id: 'warehouse.fetchProductFailed',
          defaultMessage: "Couldn't load product data.",
        });

        addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
          appearance: 'error',
        });
      },
    }
  );

  return {
    product: data?.response?.product || null,
    loading: isLoading,
  };
};

export const useDeleteProduct = (
  productId: string,
  onSuccess: () => void
): UseDeleteOrderReturnType => {
  const intl = useIntl();
  const { addToast } = useToasts();

  const { mutateAsync: deleteProduct, isLoading } = useMutation(
    DELETE_PRODUCT_MUTATION_FN(productId)
  );

  return {
    deleteProduct: async () => {
      try {
        await deleteProduct({});

        onSuccess();

        addToast(
          intl.formatMessage({
            id: 'warehouse.deleteProductSuccess',
            defaultMessage: 'The product has been deleted.',
          }),
          {
            appearance: 'success',
          }
        );

        return undefined;
      } catch (error) {
        const { errors, generalError } = mapApiErrorsToFormErrors(error, intl);

        if (errors) {
          return errors;
        }

        const defaultError = intl.formatMessage({
          id: 'warehouse.deleteProductFailed',
          defaultMessage: 'There was an error deleting the product',
        });

        addToast(`${defaultError}${generalError ? ` - ${generalError}` : ''}`, {
          appearance: 'error',
        });

        return undefined;
      }
    },
    isLoading,
  };
};
