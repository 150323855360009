import React from 'react';
import { defineMessages, IntlShape } from 'react-intl';
import { ColumnDef } from '@tanstack/react-table';
import { useForm } from 'react-final-form';
import { SimpleSelectOption } from '../../../../../components/atoms/Fields/Select/templates/SimpleSelect/types';
import { IconDelete } from '../../../../../assets/svg/icons';
import IconButton from '../../../../../components/atoms/IconButton';
import { StyledBox } from '../../../../../components/styles/Grid/styles';
import { FormInput } from '../../../../../components/atoms/Fields/Input';
import ProductThumbnail from '../../../../../components/atoms/ProductThumbnail';
import PriceWithCurrency from '../../../../../components/atoms/PriceWithCurrency';
import Typography from '../../../../../components/styles/Typography';
import { ProductsTableItem } from './types';
import { PreferredCurrency } from '../../../../../setup/apiTypes/enums';

export const productsTableData: ProductsTableItem[] = [
  {
    idCol: '1',
    imageCol: 'https://source.unsplash.com/50x50/?products',
    productCol: 'Nike Air Force 1 ‘07',
    quantityCol: 2,
    priceCol: 10000,
    totalCol: { price: 20000, currency: 'IQD' },
    sku: '',
  },
  {
    idCol: '2',
    imageCol: 'https://source.unsplash.com/50x50/?products',
    productCol: 'Nike Air Force 1 ‘07',
    quantityCol: 2,
    priceCol: 10000,
    totalCol: { price: 30000, currency: 'IQD' },
    sku: '',
  },
  {
    idCol: '3',
    imageCol: '',
    productCol: 'Killshot 2 Leather',
    quantityCol: 2,
    priceCol: 10000,
    totalCol: { price: 20000, currency: 'IQD' },
    sku: '',
  },
];

export const productsTableColumns = (
  currency: string,
  intl: IntlShape
): ColumnDef<ProductsTableItem>[] => [
  {
    accessorKey: 'idCol',
  },
  {
    header: '',
    accessorKey: 'imageCol',
    size: 50,
    cell: ({ cell: { getValue } }) => (
      <ProductThumbnail src={getValue() as string} />
    ),
  },
  {
    header: intl.formatMessage({
      id: 'products.product',
      defaultMessage: 'Product',
    }),
    accessorKey: 'productCol',
    size: 160,
  },
  {
    header: intl.formatMessage({
      id: 'products.quantity',
      defaultMessage: 'Quantity',
    }),
    accessorKey: 'quantityCol',
    size: 54,
    cell: ({ cell: { row } }) => (
      <FormInput
        name={`products[${row.index}].quantityCol`}
        type='number'
        min='1'
      />
    ),
  },
  {
    header: intl.formatMessage({
      id: 'products.price',
      defaultMessage: 'Price',
    }),
    accessorKey: 'priceCol',
    size: 120,
    cell: ({ cell: { row } }) => (
      <FormInput
        name={`products[${row.index}].priceCol`}
        type='number'
        min={0}
        step={1}
        suffixComponent={
          <Typography variant='currencyBold'>{currency}</Typography>
        }
        disabled
      />
    ),
  },
  {
    header: intl.formatMessage({
      id: 'products.total',
      defaultMessage: 'Total',
    }),
    accessorKey: 'totalCol',
    size: 120,
    cell: ({ cell: { row } }) => {
      const form = useForm();

      const quantity = (form.getFieldState(`products[${row.index}].quantityCol`)
        ?.value ?? 1) as number;
      const price = (form.getFieldState(`products[${row.index}].priceCol`)
        ?.value ?? 0) as number;

      return <PriceWithCurrency price={price * quantity} {...{ currency }} />;
    },
  },
  {
    header: '',
    accessorKey: 'deleteCol',
    size: 50,
    cell: ({ cell: { row } }) => {
      const form = useForm();

      return (
        <StyledBox justifyContent='flex-end'>
          <IconButton
            icon={IconDelete}
            type='button'
            onClick={() => form.mutators.removeRow(row.index)}
          />
        </StyledBox>
      );
    },
  },
];

export const currencyOptions: SimpleSelectOption[] = Object.keys(
  PreferredCurrency
).map((currency) => ({
  label: currency,
  value: currency,
}));

export const missingProfileFeesModalMessages = defineMessages({
  title: {
    id: 'missingProfileFeesModal.title',
    defaultMessage: 'Missing Profile Fees',
  },
  description: {
    id: 'missingProfileFeesModal.description',
    defaultMessage:
      "Would you please contact Sandoog team in order to set the profile fees, so that you'll be able to complete your order.",
  },
  confirmButtonLabel: {
    id: 'missingProfileFeesModal.confirmButtonLabel',
    defaultMessage: 'Contact Us',
  },
});
