import { LoaderMode } from './enums';
import CashoutBoxTemplate from './templates/cashoutBox';
import FinancialBalanceBoxesTemplate from './templates/financialBalanceBoxes';
import OrdersTableLoaderTemplate from './templates/ordersTable';
import OrderSummaryTemplate from './templates/orderSummary';
import PlaceOrderLoaderTemplate from './templates/placeOrder';
import ProductOrderCardListLoaderTemplate from './templates/productOrderCardList';
import WarehouseAddProductTemplate from './templates/warehouseAddProduct';
import WarehouseShowProductTemplate from './templates/warehouseShowProduct';
import WarehouseGridListTemplate from './templates/warehouseGridList';
import WarehouseReportTemplate from './templates/warehouseReport';
import CustomerSummaryTemplate from './templates/customerSummary';

export const loaderTemplate = (mode: LoaderMode): ReactComponentReturnType => {
  switch (mode) {
    case LoaderMode.Table:
      return OrdersTableLoaderTemplate;
    case LoaderMode.PlaceOrder:
      return PlaceOrderLoaderTemplate;
    case LoaderMode.ProductOrderCardList:
      return ProductOrderCardListLoaderTemplate;
    case LoaderMode.FinancialBalanceBoxes:
      return FinancialBalanceBoxesTemplate;
    case LoaderMode.OrderSummary:
      return OrderSummaryTemplate;
    case LoaderMode.WarehouseGridList:
      return WarehouseGridListTemplate;
    case LoaderMode.WarehouseAddProduct:
      return WarehouseAddProductTemplate;
    case LoaderMode.WarehouseShowProduct:
      return WarehouseShowProductTemplate;
    case LoaderMode.WarehouseReport:
      return WarehouseReportTemplate;
    case LoaderMode.CashoutBox:
      return CashoutBoxTemplate;

    case LoaderMode.CustomerSummary:
      return CustomerSummaryTemplate;

    default:
      return OrdersTableLoaderTemplate;
  }
};
