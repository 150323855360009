import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  StyledProductDetailBox,
  StyledProductDetailLabel,
  StyledProductDetailValue,
} from './styles';
import PriceWithCurrency from '../../../../../components/atoms/PriceWithCurrency';
import { StyledBox } from '../../../../../components/styles/Grid/styles';
import { ShowProductResponse } from '../../../../../setup/apiTypes/warehouse';
import ImageUploader from '../../../../../components/molecules/ImageUploader';
import {
  CoverImage,
  UploadedProductImage,
} from '../../../productFormPage/types';
import Button from '../../../../../components/atoms/Button';
import { ButtonMode } from '../../../../../components/atoms/Button/enums';
import routes from '../../../../../setup/consts/routes';
import { useSaveProductImagesHandler } from '../../../productFormPage/components/ProductForm/hooks';

type Props = {
  product: ShowProductResponse['product'] | null;
};

const ProductDetails: React.FC<Props> = (props) => {
  // ** Props
  const { product } = props;

  // ** States
  const [uploadedCoverImage, setCoverImage] = useState<CoverImage>(null);
  const [uploadedImages, setUploadedImages] = useState<UploadedProductImage[]>(
    []
  );

  // ** Hooks
  const intl = useIntl();
  const history = useHistory();
  const { submitHandler, isLoading } = useSaveProductImagesHandler({
    productId: product?.id || 0,
    coverImage: uploadedCoverImage?.file,
    images: uploadedImages.map((i) => i.file),
  });

  // ** Handlers
  useEffect(() => {
    if (product?.images.length) {
      setUploadedImages([...product?.images.map((image) => ({ src: image }))]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  if (!product) {
    return (
      <p>
        {intl.formatMessage({
          id: 'warehouse.fetchProductFailed',
          defaultMessage: "Couldn't load product data.",
        })}
      </p>
    );
  }

  const { categoryName, coverImage, sellingPrice, name, sku, description } =
    product;

  const coverImageLabelText = intl.formatMessage({
    id: 'warehouse.coverImage',
    defaultMessage: 'Cover Image',
  });

  const coverImageSrc = uploadedCoverImage?.src
    ? { src: uploadedCoverImage.src }
    : coverImage
    ? { src: coverImage }
    : undefined;
  const hasUploadedImages = uploadedImages.length > 0;

  const onSave = () => {
    submitHandler();
  };

  return (
    <Row>
      <Col xs={12}>
        <StyledBox justifyContent='flex-end' mb='2rem' gap='1rem'>
          <Button
            mode={ButtonMode.Secondary}
            onClick={() => history.push(routes.WAREHOUSE)}
            type='button'
            disabled={isLoading}
          >
            <FormattedMessage
              id='forms.buttons.cancel'
              defaultMessage='Cancel'
            />
          </Button>
          <Button type='button' disabled={isLoading} onClick={onSave}>
            <FormattedMessage id='forms.buttons.save' defaultMessage='Save' />
          </Button>
        </StyledBox>
      </Col>

      <Col md={5}>
        <StyledBox flexDirection='column' gap='2.5rem'>
          {/* Sku */}
          <StyledProductDetailBox>
            <StyledProductDetailLabel>
              {intl.formatMessage({
                id: 'warehouse.productSku',
                defaultMessage: 'Product SKU',
              })}
            </StyledProductDetailLabel>
            <StyledProductDetailValue>{sku || '-'}</StyledProductDetailValue>
          </StyledProductDetailBox>

          {/* Name */}
          <StyledProductDetailBox>
            <StyledProductDetailLabel>
              {intl.formatMessage({
                id: 'warehouse.productName',
                defaultMessage: 'Product Name',
              })}
            </StyledProductDetailLabel>
            <StyledProductDetailValue>{name || '-'}</StyledProductDetailValue>
          </StyledProductDetailBox>

          {/* Price */}
          <StyledProductDetailBox>
            <StyledProductDetailLabel>
              {intl.formatMessage({
                id: 'warehouse.productPrice',
                defaultMessage: 'Product Price',
              })}
            </StyledProductDetailLabel>

            <PriceWithCurrency price={sellingPrice} />
          </StyledProductDetailBox>

          {/* Category */}
          <StyledProductDetailBox>
            <StyledProductDetailLabel>
              {intl.formatMessage({
                id: 'warehouse.category',
                defaultMessage: 'Product Category',
              })}
            </StyledProductDetailLabel>
            <StyledProductDetailValue>
              {categoryName || '-'}
            </StyledProductDetailValue>
          </StyledProductDetailBox>

          {/* Details */}
          <StyledProductDetailBox>
            <StyledProductDetailLabel>
              {intl.formatMessage({
                id: 'warehouse.productDetails',
                defaultMessage: 'Product Details',
              })}
            </StyledProductDetailLabel>
            <StyledProductDetailValue>
              {description || '-'}
            </StyledProductDetailValue>
          </StyledProductDetailBox>
        </StyledBox>
      </Col>

      {/* Images */}
      <Col md={7}>
        <StyledBox justifyContent='flex-end'>
          <StyledBox gap='1rem' flexWrap='wrap' flexDirection='column'>
            <StyledProductDetailLabel>
              {intl.formatMessage({
                id: 'warehouse.images',
                defaultMessage: 'Images',
              })}
            </StyledProductDetailLabel>

            <ImageUploader
              coverImageLabel={coverImageLabelText}
              coverImage={coverImageSrc}
              uploadedImages={hasUploadedImages ? uploadedImages : undefined}
              onChangeCoverImage={(file) => {
                const data = file
                  ? { file, src: URL.createObjectURL(file) }
                  : null;

                setCoverImage(data);
              }}
              onChangeUploadedImages={(files) => {
                const data = files.length
                  ? Object.values(files).map((f) => ({
                      file: f,
                      src: URL.createObjectURL(f),
                    }))
                  : [];

                setUploadedImages([...uploadedImages, ...data]);
              }}
              disabled={isLoading}
            />
          </StyledBox>
        </StyledBox>
      </Col>
    </Row>
  );
};

export default ProductDetails;
