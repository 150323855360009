import { IntlShape } from 'react-intl';
import routes from '../../../setup/consts/routes';
import { SideMenuItem } from '../../molecules/SideMenu/types';

export const CLIENT_SELECT_VALUE_COOKIE_NAME = 'CLIENT_SELECT_VALUE';

const {
  ACCOUNT_DETAILS,
  ENTITIES,
  USERS,
  BILLING_AND_PAYMENTS,
  SUPPORT_AND_FAQ,
  CONTACT_US,
} = routes;

export const sideMenuItems = (intl: IntlShape): SideMenuItem[] => [
  {
    label: intl.formatMessage({
      id: 'sidebar.settings.accountDetails',
      defaultMessage: 'Account details',
    }),
    to: ACCOUNT_DETAILS,
    key: '1',
  },
  {
    label: intl.formatMessage({
      id: 'sidebar.settings.entitiesAndUsers',
      defaultMessage: 'Entities and Users',
    }),
    to: ENTITIES,
    key: '2',
    isActive: (_, { pathname }) => [ENTITIES, USERS].includes(pathname),
  },
  {
    label: intl.formatMessage({
      id: 'sidebar.settings.billingAndPayments',
      defaultMessage: 'Billing \u0026 Payments',
    }),
    to: BILLING_AND_PAYMENTS,
    key: '3',
  },
  {
    label: intl.formatMessage({
      id: 'sidebar.settings.supportAndFaq',
      defaultMessage: 'Support \u0026 FAQ',
    }),
    to: SUPPORT_AND_FAQ,
    key: '4',
  },
];

export const sideMenuAdditionalItems = (intl: IntlShape): SideMenuItem[] => [
  {
    label: intl.formatMessage({
      id: 'sidebar.common.contactUs',
      defaultMessage: 'Contact Us',
    }),
    to: CONTACT_US,
    key: '1',
  },
];

export const CLIENTS_PAGE_SIZE = 10;
