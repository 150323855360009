import { rgba } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { StyledIconButtonProps } from './types';

export const iconButtonStyles = css<StyledIconButtonProps>`
  ${({ theme, isSecondary, isActive }) =>
    isSecondary
      ? css`
          width: 2.25rem;
          height: 2.25rem;
          border-radius: 0.5625rem;
          border: none;
          color: ${isActive
            ? theme.colors.background.dark
            : rgba(theme.colors.background.dark, 0.54)};
          background-color: ${isActive
            ? theme.colors.background.medium
            : 'transparent'};
        `
      : css`
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 0.5rem;
          border-width: 1px;
          border-style: solid;
          border-color: ${theme.colors.iconButton.border};
          background-color: ${theme.colors.iconButton.background};
          color: ${theme.colors.iconButton.color};
        `};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  transition: ${({ theme }) => theme.transitions.default};
  &:disabled,
  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }
  &:hover {
    opacity: 0.7;
  }
  svg {
    width: 1em;
    height: 1em;
    font-size: 1.5rem;
    fill: currentColor;
  }
`;

export const StyledIconButton = styled.button<StyledIconButtonProps>`
  ${iconButtonStyles}
`;

export const StyledIconButtonLink = styled(Link)<StyledIconButtonProps>`
  ${iconButtonStyles}
  text-decoration: none;
`;
