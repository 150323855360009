import React from 'react';
import { OrderSummaryContactListProps } from './types';
import {
  StyledOrderSummaryContactList,
  StyledOrderSummaryContactListLabel,
  StyledOrderSummaryContactListContent,
} from './styles';

const OrderSummaryContactList: React.FC<OrderSummaryContactListProps> = ({
  items,
}) => (
  <StyledOrderSummaryContactList>
    {items.map(({ key, label, description, onClick }) => (
      <React.Fragment {...{ key }}>
        <StyledOrderSummaryContactListLabel>
          {label}
        </StyledOrderSummaryContactListLabel>
        <StyledOrderSummaryContactListContent
          onClick={onClick}
          isClickable={Boolean(onClick)}
        >
          {description || '-'}
        </StyledOrderSummaryContactListContent>
      </React.Fragment>
    ))}
  </StyledOrderSummaryContactList>
);

export default OrderSummaryContactList;
