import React from 'react';
import { TabsNavigationProps } from './types';
import {
  StyledTabsNavigation,
  StyledTabsNavigationLink,
  StyledTabsNavigationList,
  StyledTabsNavigationListItem,
} from './styles';

const TabsNavigation: React.FC<TabsNavigationProps> = ({ items }) => (
  <StyledTabsNavigation>
    <StyledTabsNavigationList>
      {items.map(({ label, to, key }) => (
        <StyledTabsNavigationListItem {...{ key }}>
          <StyledTabsNavigationLink {...{ to }}>
            {label}
          </StyledTabsNavigationLink>
        </StyledTabsNavigationListItem>
      ))}
    </StyledTabsNavigationList>
  </StyledTabsNavigation>
);

export default TabsNavigation;
