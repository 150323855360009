import React from 'react';
import { TagProps } from './types';
import { StyledTag } from './styles';
import { TagStatus } from './enums';

const Tag: React.FC<TagProps> = ({
  status = TagStatus.Success,
  color,
  children,
}) => (
  <StyledTag {...{ status, color }}>
    <span>{children}</span>
  </StyledTag>
);

export default Tag;
