import styled from 'styled-components';
import { StyledProductThumbnailProps } from './types';

export const StyledProductThumbnail = styled.div<StyledProductThumbnailProps>`
  display: block;
  width: ${({ theme, isBig }) =>
    isBig
      ? theme.dimensions.productThumbnail.big
      : theme.dimensions.productThumbnail.small};
  height: ${({ theme, isBig }) =>
    isBig
      ? theme.dimensions.productThumbnail.big
      : theme.dimensions.productThumbnail.small};
  border-radius: 1rem;
  overflow: hidden;
`;

export const StyledProductImage = styled.img`
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
`;
