import React from 'react';
import { SelectIndicatorsContainerProps } from './types';
import { StyledSelectIndicatorsContainerArrow } from './styles';

function IndicatorsContainer<T>({
  icon: Icon,
  withoutIndicatorRotation,
  selectProps,
}: SelectIndicatorsContainerProps<T>): ReactComponentReturnType {
  const { menuIsOpen } = selectProps;

  return (
    <StyledSelectIndicatorsContainerArrow
      {...{ menuIsOpen, withoutIndicatorRotation }}
    >
      <Icon />
    </StyledSelectIndicatorsContainerArrow>
  );
}

export default IndicatorsContainer;
