import styled, { css } from 'styled-components';
import Typography from '../../styles/Typography';

export const StyledOrderSummaryTotals = styled.div`
  display: flex;
`;

export const StyledOrderSummaryTotalsBox = styled.div`
  &:not(:last-child) {
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? css`
            margin-left: 3rem;
          `
        : css`
            margin-right: 3rem;
          `};
  }
`;

export const StyledOrderSummaryTotalsLabel = styled(Typography).attrs({
  forwardedAs: 'span',
  variant: 'caption',
})`
  margin-bottom: 0.75rem;
  display: block;
`;

export const StyledOrderSummaryTotalsGrandTotalWrapper = styled.div`
  color: ${({ theme }) => theme.colors.text.tertiary};
`;
