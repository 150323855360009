import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { AvatarSize } from './enums';
import { StyledAvatarProps } from './types';

export const StyledAvatar = styled.div<StyledAvatarProps>`
  border-radius: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.background.medium};
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.secondary};
  height: ${({ theme, size = AvatarSize.Medium }) =>
    theme.dimensions.avatar.size[size]};
  width: ${({ theme, size = AvatarSize.Medium }) =>
    theme.dimensions.avatar.size[size]};
  ${({ noBorder, theme }) =>
    !noBorder &&
    css`
      border: 1px solid ${rgba(theme.colors.text.primary, 0.15)};
    `};
  svg {
    height: 1em;
    width: 1em;
    font-size: ${({ size = AvatarSize.Medium }) =>
      (size === AvatarSize.Big && '2.6875rem') ||
      (size === AvatarSize.Medium && '2rem') ||
      (size === AvatarSize.Small && '1.5rem')};
    fill: currentColor;
  }
`;

export const StyledAvatarImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
`;

export const StyledAvatarPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background.placeholder};
  color: ${({ theme }) => theme.colors.text.light};
`;

export const StyledAvatarPlaceholderText = styled.span<StyledAvatarProps>`
  color: inherit;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.default};
  text-transform: uppercase;
  font-size: ${({ theme, size = AvatarSize.Medium }) =>
    `calc(${theme.dimensions.avatar.size[size]} * 0.3)`};
`;
