import React from 'react';
import { SideMenuProps } from './types';
import {
  StyledSideMenu,
  StyledSideMenuIconWrapper,
  StyledSideMenuLink,
  StyledSideMenuList,
  StyledSideMenuListItem,
  StyledSideMenuRow,
} from './styles';

const SideMenu: React.FC<SideMenuProps> = ({ items, additionalItems }) => (
  <StyledSideMenu>
    <StyledSideMenuRow>
      <StyledSideMenuList hasMinHeight>
        {items.map(({ label, to, icon: Icon, key, isActive }) => (
          <StyledSideMenuListItem {...{ key }}>
            <StyledSideMenuLink {...{ to, isActive }}>
              {Icon && (
                <StyledSideMenuIconWrapper>
                  <Icon />
                </StyledSideMenuIconWrapper>
              )}
              {label}
            </StyledSideMenuLink>
          </StyledSideMenuListItem>
        ))}
      </StyledSideMenuList>
    </StyledSideMenuRow>
    {additionalItems && (
      <StyledSideMenuRow>
        <StyledSideMenuList>
          {additionalItems.map(({ label, to, icon: Icon, key }) => (
            <StyledSideMenuListItem {...{ key }}>
              <StyledSideMenuLink {...{ to }}>
                {Icon && (
                  <StyledSideMenuIconWrapper>
                    <Icon />
                  </StyledSideMenuIconWrapper>
                )}
                {label}
              </StyledSideMenuLink>
            </StyledSideMenuListItem>
          ))}
        </StyledSideMenuList>
      </StyledSideMenuRow>
    )}
  </StyledSideMenu>
);

export default SideMenu;
