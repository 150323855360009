import { rgba } from 'polished';
import styled from 'styled-components';
import Typography from '../../styles/Typography';

export const StyledProductOrderCard = styled.div`
  display: grid;
  grid-template-columns: 5rem 2fr 1fr 1fr 1fr 8.25rem;
  grid-template-areas: 'image name quantity date price action';
  align-items: center;
  grid-column-gap: 4rem;
  width: 100%;
  min-height: ${({ theme }) => theme.dimensions.productOrderCard.minHeight};
  padding: 1rem 0.625rem;
  background-color: ${({ theme }) => theme.colors.productOrderCard.background};
  border-radius: 1rem;
  box-shadow: ${({ theme }) => theme.shadows.small};
`;

export const StyledProductImageWrapper = styled.div`
  grid-area: image;
`;

export const StyledProductImage = styled.img`
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
`;

export const StyledProductImagePlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.colors.productOrderCard.imagePlaceholder.background};
  text-align: center;
`;

export const StyledProductNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: name;
`;

export const StyledProductQuantityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: quantity;
`;

export const StyledProductDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: date;
`;

export const StyledProductPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: price;
`;

export const StyledProductLabel = styled(Typography).attrs({
  forwardedAs: 'span',
  variant: 'caption',
})`
  color: ${({ theme }) => rgba(theme.colors.text.primary, 0.54)};
  margin-bottom: 0.75rem;
`;

export const StyledProductPrice = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const StyledProductCurrency = styled(Typography).attrs({
  forwardedAs: 'span',
  variant: 'currency',
})`
  margin-left: 0.75rem;
  text-transform: uppercase;
`;

export const StyledProductCardAction = styled.div`
  grid-area: action;
`;
