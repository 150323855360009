import React, { useCallback, useContext, useMemo } from 'react';
import { DateRangePicker, OnChangeProps } from 'react-date-range';
import { useIntl } from 'react-intl';
import { ThemeContext } from 'styled-components';
import { IconArrowForward, IconCalendar } from '../../../../assets/svg/icons';
import useToggle from '../../../../hooks/useToggle';
import { DatepickerPosition } from './enums';
import {
  StyledDatepicker,
  StyledDatepickerButton,
  StyledDatepickerButtonDecor,
  StyledDatepickerDecor,
} from './styles';

import { DatepickerProps } from './types';
import { formatDate, getStaticRanges } from './utils';

const Datepicker: React.FC<DatepickerProps> = ({
  onChange,
  value,
  position = DatepickerPosition.Left,
  disabled,
}) => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);
  const [isOpen, { toggle, registerContainerRef }] = useToggle();
  const handleOnChange = useCallback(
    (item: OnChangeProps) => {
      if ('selection' in item) {
        onChange({
          startDate: item.selection.startDate,
          endDate: item.selection.endDate,
        });
      }
    },
    [onChange]
  );

  const staticRanges = useMemo(() => getStaticRanges(intl), [intl]);

  return (
    <StyledDatepicker {...{ position }} ref={registerContainerRef}>
      <StyledDatepickerButton onClick={toggle} {...{ disabled }}>
        {value.startDate && formatDate(value.startDate)}
        <StyledDatepickerDecor>
          <IconArrowForward />
        </StyledDatepickerDecor>
        {value.endDate && formatDate(value.endDate)}
        <StyledDatepickerButtonDecor>
          <IconCalendar />
        </StyledDatepickerButtonDecor>
      </StyledDatepickerButton>
      {isOpen && (
        <DateRangePicker
          ranges={[{ ...value, key: 'selection' }]}
          staticRanges={staticRanges}
          onChange={handleOnChange}
          direction='horizontal'
          months={2}
          showDateDisplay={false}
          moveRangeOnFirstSelection
          showPreview={false}
          className='datepicker'
          rangeColors={[theme.colors.datepicker.dayBackground]}
          monthDisplayFormat='MMMM yyyy'
          weekdayDisplayFormat='EEEEEE'
          weekStartsOn={1}
        />
      )}
    </StyledDatepicker>
  );
};

export default Datepicker;
