import qs from 'qs';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import urlJoin from 'url-join';
import {
  GetAvailableBalanceResponse,
  GetPendingBalanceResponse,
  GetWithdrawnBalanceResponse,
} from '../../setup/apiTypes/settlements';
import apiRoutes from '../../setup/consts/apiRoutes';
import { GetStatementsResponse } from '../../setup/apiTypes/statements';
import {
  UseAvailableBalanceReturnType,
  UsePendingBalanceReturnType,
  UseStatementListReturnType,
  UseWithdrawnBalanceReturnType,
  UseYearDropdownHandlers,
} from './types';
import { mapCurrencyToCurrencyCode } from '../../utils/misc';

export const useStatementList = (year: string): UseStatementListReturnType => {
  const queryAsString = useMemo(
    () =>
      qs.stringify(
        {
          year,
        },
        {
          addQueryPrefix: true,
        }
      ),
    [year]
  );

  const { data, isLoading } = useQuery<UseQueryResponse<GetStatementsResponse>>(
    urlJoin(apiRoutes.STATEMENTS, queryAsString)
  );

  return {
    statements: useMemo(() => {
      if (!data) {
        return [];
      }

      return data.response.statements.map(
        ({
          statementId,
          status,
          date,
          pdfUrlAr,
          value,
          pdfUrlEn,
          excelUrl,
        }) => ({
          statusCol: status,
          valueIqdCol: value,
          dateCol: date,
          statementIdCol: statementId,
          downloadCol: { pdfUrlAr, pdfUrlEn, excelUrl },
        })
      );
    }, [data]),
    loading: isLoading,
  };
};

export const useAvailableBalance = (): UseAvailableBalanceReturnType => {
  const { data, isLoading } = useQuery<
    UseQueryResponse<GetAvailableBalanceResponse>
  >(apiRoutes.AVAILABLE_BALANCE);

  return useMemo(() => {
    if (!data) {
      return {
        availableBalance: 0,
        currency: '',
        isLoading,
      };
    }

    const {
      availableBalance: { balance, currency },
    } = data.response;

    return {
      availableBalance: balance ?? 0,
      currency: mapCurrencyToCurrencyCode(currency),
      isLoading,
    };
  }, [data, isLoading]);
};

export const usePendingBalance = (): UsePendingBalanceReturnType => {
  const { data, isLoading } = useQuery<
    UseQueryResponse<GetPendingBalanceResponse>
  >(apiRoutes.PENDING_BALANCE);

  return useMemo(() => {
    if (!data) {
      return {
        pendingBalance: 0,
        currency: '',
        isLoading,
      };
    }

    const {
      pendingBalance: { balance, currency },
    } = data.response;

    return {
      pendingBalance: balance ?? 0,
      currency: mapCurrencyToCurrencyCode(currency),
      isLoading,
    };
  }, [data, isLoading]);
};

export const useWithdrawnBalance = (): UseWithdrawnBalanceReturnType => {
  const { data, isLoading } = useQuery<
    UseQueryResponse<GetWithdrawnBalanceResponse>
  >(apiRoutes.WITHDRAWN_BALANCE);

  return useMemo(() => {
    if (!data) {
      return {
        withdrawnBalance: 0,
        currency: '',
        withdrawnBalances: [],
        isLoading,
      };
    }

    const {
      withdrawBalance: { totalBalance, currency, withdrawnBalances },
    } = data.response;

    return {
      withdrawnBalance: totalBalance ?? 0,
      withdrawnBalances,
      currency: mapCurrencyToCurrencyCode(currency),
      isLoading,
    };
  }, [data, isLoading]);
};

export const useYearDropdownHandlers = (): UseYearDropdownHandlers => {
  const [year, setYear] = useState(new Date().getFullYear());

  const handleYearChange = useCallback(
    (value: string) => setYear(parseInt(value, 10)),
    []
  );

  return [year.toString(), handleYearChange] as const;
};
